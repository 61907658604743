import { format } from "date-fns";
import { useMemo } from "react";

import { Badge, BadgeProps, Table } from "@fronterahealth/frontera-ui-components";

import { useDeleteAssessmentMutation } from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAdminData } from "@providers/AdminDataProvider";

export const STATUS_VALUES = [
  "Draft",
  "Published",
  "Report Page 1 Generating",
  "Report Page 2 Generating",
  "Long Term Goals Generating",
  "Short Term Goals Generating",
  "Targets Generating",
  "Report Page 1 Failed",
  "Report Page 2 Failed",
  "Long Term Goals Failed",
  "Short Term Goals Failed",
  "Targets Failed",
] as const;
export type StatusType = (typeof STATUS_VALUES)[number];

const BadgeSettingFromStatus: { [key in StatusType]: BadgeProps["appearance"] } = {
  Draft: "neutral",
  Published: "success",
  "Report Page 1 Generating": "ai",
  "Report Page 2 Generating": "ai",
  "Long Term Goals Generating": "ai",
  "Short Term Goals Generating": "ai",
  "Targets Generating": "ai",
  "Report Page 1 Failed": "error",
  "Report Page 2 Failed": "error",
  "Long Term Goals Failed": "error",
  "Short Term Goals Failed": "error",
  "Targets Failed": "error",
};

export interface AssessmentReport {
  id: string;
  clientName: string;
  dateCreated: Date;
  lastUpdated: Date;
  status: StatusType;
  learnerId?: string;
  pagesDone: number;
}

interface AssessmentReportsTableProps {
  reports: (AssessmentReport | null)[];
  onRowClick: (report: AssessmentReport) => void;
  loading?: boolean;
  refetch: () => Promise<unknown>;
}

export const AssessmentReportsTable: React.FC<AssessmentReportsTableProps> = ({
  reports,
  onRowClick,
  loading,
  refetch,
}) => {
  const deleteAssessmentMutation = useDeleteAssessmentMutation({});

  const { roles } = useAdminData();

  const actions = useMemo(() => {
    const isInternalUser = roles.includes("frontera-internal");

    return [
      {
        label: "View",
        callback: (rowData: AssessmentReport) => onRowClick(rowData),
      },
      ...(isInternalUser
        ? [
            {
              label: "Delete",
              textColorClassName: "text-red-700",
              callback: async (rowData: { id: string }) => {
                try {
                  await deleteAssessmentMutation.mutateAsync({ assessment: rowData.id });
                  await refetch();
                  notifySuccess("Report successfully deleted");
                } catch (err) {
                  console.error("Failed to delete report", err);
                  notifyError("Unable to delete report");
                }
              },
            },
          ]
        : []),
    ];
  }, [roles]);

  return (
    <div className="w-full bg-white mt-8 rounded-lg">
      <Table
        onRowClick={onRowClick}
        loading={loading}
        defaultItemsPerPage={20}
        showSearch
        inputClassName="w-96"
        showPagination
        itemsPerPageOptions={[5, 10, 20, 50]}
        actions={actions}
        data={(reports.filter((report) => report !== null) as AssessmentReport[]).sort((reportA, reportB) =>
          reportB.lastUpdated > reportA.lastUpdated ? 1 : -1,
        )}
        columns={[
          { dataIndex: "clientName", key: "clientName", title: "Client Name" },
          {
            dataIndex: "dateCreated",
            key: "dateCreated",
            title: "Date Created",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
          {
            dataIndex: "lastUpdated",
            key: "lastUpdated",
            title: "Last Updated",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
          {
            dataIndex: "status",
            key: "status",
            title: "Status",
            render: (value, record) => {
              const showDotInBadge =
                (value as StatusType) === "Draft" || (value as StatusType) === "Published" ? false : true;
              return (
                <Badge
                  dot={showDotInBadge}
                  appearance={BadgeSettingFromStatus[value as StatusType]}
                  text={
                    (value as StatusType) === "Draft" ? `Draft (Step ${record.pagesDone}/6)` : (value as StatusType)
                  }
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};

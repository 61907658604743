import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Divider } from "@fronterahealth/frontera-ui-components";

import { ApiOnboardingFormStatusStatusChoices, useLearnerByIdQuery } from "@api/graphql/types-and-hooks";
import { ClientDetailsFormsTable, Form } from "@components/ClientDetailsFormsTable/ClientDetailsFormsTable";

export const ClientDetailsForms: React.FC = () => {
  const { clientId } = useParams();
  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const intakePages =
    learnerDetailsQuery?.data?.learnerById?.formStatusForLearner.edges.map((edge) => edge?.node) || [];
  const intakeFinished = intakePages.find(
    (node) => node?.status === ApiOnboardingFormStatusStatusChoices.Completed && node.formName === "submit",
  );

  const intakeInProgress =
    intakePages.filter((page) => page?.status === ApiOnboardingFormStatusStatusChoices.Completed).length > 0;

  const intakeStarted =
    !!learnerDetailsQuery?.data?.learnerById?.relationshipSet?.edges?.[0]?.node?.caregiver.auth0User?.auth0id;

  const forms: Form[] = [
    {
      name: "Intake Form",
      dateCompleted: intakeFinished ? new Date(intakeFinished?.createdDate) : null,
      status: intakeFinished ? "Complete" : intakeInProgress ? "In Progress" : intakeStarted ? "Started" : "Awaiting",
    },
  ];

  const go = useNavigate();

  return (
    <>
      <ClientDetailsFormsTable
        forms={forms}
        onRowClick={(form) => {
          if (form.name === "Intake Form") {
            go(encodeURI("intake_form/all"));
          } else {
            alert(`You have clicked a form ${form.name}`);
          }
        }}
      />
      <Divider />
      <Outlet />
    </>
  );
};

import { GoalBankCard } from "@fronterahealth/frontera-ui-components";

import { convertReadableString } from "@components/forms/utils";

interface AddLongTermGoalsBankCardProps {
  id: string;
  goalName: string;
  goalType: string;
  description?: string | undefined | null;
  selected: boolean;
  onClick: () => void;
}

export const AddLongTermGoalsBankCard: React.FC<AddLongTermGoalsBankCardProps> = ({
  id,
  goalName,
  goalType,
  description,
  selected,
  onClick,
}) => {
  return (
    <GoalBankCard
      key={id}
      id={id}
      title={goalName}
      subtitle={convertReadableString(goalType)}
      body={description ?? ""}
      selected={selected}
      onClick={onClick}
    />
  );
};

import { Navigate } from "react-router-dom";

import {
  ApiReportReportStatusChoices,
  AssessmentToolType,
  ReportType,
  ReportTypeEnums,
  useAssessmentToolsQuery,
} from "@api/graphql/types-and-hooks";
import { EvaluationSubRoute } from "@pages/EvaluationDetails/EvaluationDetails";
import { useEvaluationData } from "@providers/EvaluationProvider";
import { getNumberOfPagesDone } from "@routes/Evaluations/Evaluations";

const getProperEvaluationRedirectPathFromReport = (
  evaluation: ReportType | undefined,
  supportedAssessmentsList: AssessmentToolType[],
): EvaluationSubRoute => {
  if (!evaluation) return "client-details";

  const reportIsCompleted = evaluation?.reportStatus === ApiReportReportStatusChoices.Completed;
  const reportHasClient = !!evaluation?.learner?.id;
  const pagesDone = getNumberOfPagesDone(evaluation, supportedAssessmentsList);

  switch (true) {
    case reportIsCompleted:
      return `review-and-sign`;
    case pagesDone === 3:
      return `report-part-1`;
    case pagesDone === 4:
      return `report-part-2`;
    case pagesDone === 5:
      return `conclusions`;
    case pagesDone === 6:
      return `recommendations`;
    case reportHasClient:
      return `upload-files`;
    default:
      return `client-details`;
  }
};

export const EvaluationDetailsRedirect = () => {
  const { evaluationQuery } = useEvaluationData();
  const evaluation = evaluationQuery?.data?.getReports?.edges[0]?.node;
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.DiagnosisEvaluation });
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];

  const pathToRedirectTo = getProperEvaluationRedirectPathFromReport(
    evaluation as ReportType,
    supportedAssessmentsList,
  );

  return <Navigate to={pathToRedirectTo} replace />;
};

import { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

export function useGetQueryConfig<T>(customQueryKey?: string): UseQueryOptions<T, unknown, T, QueryKey> {
  const { clientId } = useParams();
  const { pathname } = useLocation();

  const formPathName = pathname.split("intake_form").pop();

  /**
   * This is a little hacky but it should work. Throughout admin and the intake-sub pages, each sub page
   * will query for it's own data, and we set a query key so that if the user throughout a single session
   * clicks back to the same sub-form, we don't have to requery the backend. The queryKey is essentially
   * the key into graphql's cache. To make this easier, we use this useGetQueryConfig hook to set the query key
   * to be based on the route the admin user is on (ie: intake_form/client_details/general-information, intake_form/insurance, etc)
   *
   * The problem is that on the intake_form/all page where we show ALL of the sub-pages, this logic breaks.
   * Therefore, as a hacky workaround, each of the individual sub-pages now checks its own route
   *
   * if the subpage is rendered under a pathname of "intake_form/all" it will then pass in the customQueryKey
   * which states which "route" it should belong to, even though it belogs to the all route.
   *
   * This allows us to re-utilize the graphql-cache so that when the admin first lands the user on the intake_form/all page,
   * it will fetch majority of the data for the forms so that when they click on the other pages, all the data is there
   */
  const queryKey = [customQueryKey || formPathName, clientId];
  const enabled = !!clientId;
  const refetchOnMount = true;
  const staleTime = Infinity;

  return { queryKey, enabled, refetchOnMount, staleTime };
}

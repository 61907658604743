import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { PropsWithChildren } from "react";

import { Small } from "@fronterahealth/frontera-ui-components";

interface WarningCardProps extends PropsWithChildren {
  content: string;
}

export const WarningCard: React.FC<WarningCardProps> = ({ content, children }) => {
  return (
    <div className="border border-red-400 rounded-md text-center bg-red-50 p-2 gap-8 mt-4">
      <Small displayType="normal" className={"flex justify-center px-2 text-secondary text-red-500"}>
        <ExclamationTriangleIcon className="h-4 w-8 mt-0.5" />
        {content}
      </Small>
      {children}
    </div>
  );
};

import html2pdf from "html2pdf.js";
import { PDFDocument } from "pdf-lib";
import { useState } from "react";

import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";

const useGeneratePDFFile = () => {
  const [loading, setLoading] = useState(false);

  const generatePDFFile = async (element: HTMLElement | null, elementIDsToIgnore: string[]): Promise<File> => {
    setLoading(true);

    if (!element) {
      throw new Error("Element not found");
    }

    try {
      // Select each section marked with .pageBreak
      const sections = element.querySelectorAll(".pageBreak");

      const pdfBlobs = [];
      const logoImage = `<header style="text-align: center;">
    <img src="${logo}" style="margin: 20px auto; width: 100px; height: 30px;">
   </header>`;

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i] as HTMLElement;
        const htmlContentWithLogo = `<div>${logoImage}<div>${section.innerHTML}</div></div>`;

        const wrapperDiv = document.createElement("div");

        // Set innerHTML of the div to your HTML content with logo
        wrapperDiv.innerHTML = htmlContentWithLogo;
        const opt = {
          margin: [40, 15, 20, 15] as unknown as number, //top, left, buttom, right
          filename: "assessment_report.pdf",
          image: { type: "jpeg", quality: 1.0 },
          html2canvas: {
            scale: 2,
            ignoreElements: (elementData: HTMLElement) => {
              return elementIDsToIgnore.includes(elementData.id);
            },
          },
          jsPDF: { unit: "pt", format: "letter", orientation: "portrait", compress: true },
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        };

        const pdf = html2pdf();
        pdfBlobs.push(
          await pdf
            .from(i === 0 ? wrapperDiv : section)
            .set(opt)
            .output("blob"),
        );
      }
      const mergedPdfDoc = await PDFDocument.create();

      for (const pdfBlob of pdfBlobs) {
        const pdfBytes = await pdfBlob.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
        copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
      }

      const mergedPdfBytes = await mergedPdfDoc.save();
      const mergedPdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });

      const pdfFile = new File([mergedPdfBlob], "assessment_report.pdf", { type: "application/pdf" });
      setLoading(false);
      return pdfFile;
    } catch (error) {
      console.error("Error generating PDF", error);
      setLoading(false);
      throw new Error(`Error generating PDF: ${error}`);
    }
  };

  return { generatePDFFile, loading };
};

export default useGeneratePDFFile;

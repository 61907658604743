import { config } from "@config/config";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { getDifferenceInYearsAndMonths } from "@utils/utils";
import { format, parseISO } from "date-fns";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Badge, Button, Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import {
  AiReportPredictionOfEnum,
  ApiAssessmentAiReport1PredictionStatusChoices,
  ApiInsuranceDetailsInsurerChoices,
  AssessmentHistoryOfOtherServicesType,
  AssessmentReportFieldsEnums,
  AssessmentReportFieldsType,
  AssessmentTreatmentRecommendationServiceType,
  useAssessmentReportFieldsQuery,
  useGetAssessmentQuery,
} from "@api/graphql/types-and-hooks";
import { InputType } from "@components/SmartInputField/SmartInputField";
import { convertReadableString } from "@components/forms/utils";
import { FooterButtonRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/FooterButtonRow";
import { GenerationErrorPage } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/GenerationErrorPage";
import { BIPs } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/BIPs";
import { HistoryOfOtherServicesTable } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/HistoryOfOtherServicesTable";
import { SmartFieldWrapper } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/SmartFieldWrapper";
import { SmartInputFieldWrapper } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/SmartInputFieldWrapper";
import { TreatmentRecommendationsTable } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/TreatmentRecommendationsTable";
import {
  headerToIconMapping,
  headerToListOfFieldsMapping,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/assessmentFieldEnumMapping";
import { generateConfidentialityNotice } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/helpers";
import {
  HeadingSection,
  StickyNav,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import { ReportNotReadyYet } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/ReportNotReadyYet";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

export const AssessmentReportHeadings = [
  "Client Details",
  "Insurance Details",
  "Statement of Confidentiality",
  "Reason for Referral",
  "Background Information",
  "Education History",
  "History of Other Services",
  "Assessments / Information Used",
  "Assessment Write-Up",
  "Clinical Observation",
  "Learner Preferences and Aversions",
  "Functional Behavior Assessment",
  "Assessment Summary",
  "BIP",
  "Treatment Recommendations",
] as const;

export const getInputType = (input: AssessmentReportFieldsEnums): InputType => {
  switch (input) {
    case AssessmentReportFieldsEnums.DevelopmentalDisabilityDiagnosis:
      return "text";
    case AssessmentReportFieldsEnums.DateOfDiagnosis:
      return "date";
    case AssessmentReportFieldsEnums.DateAndTimeOfAssessment:
      return "datetime-local";
    case AssessmentReportFieldsEnums.DateOfReportWriteUp:
      return "date";
    case AssessmentReportFieldsEnums.InsuranceName:
      return "select";
    case AssessmentReportFieldsEnums.MemberId:
      return "text";
    case AssessmentReportFieldsEnums.LocationOfServices:
      return "text";
    case AssessmentReportFieldsEnums.BcbaCompletingAssessmentAndReportWriteUp:
      return "text";
    default:
      return "text";
  }
};

export type AssessmentReportHeadingType = (typeof AssessmentReportHeadings)[number];

interface ContentWrapperProps {
  title: string;
  content: string;
}
export const ContentWrapper: React.FC<ContentWrapperProps> = ({ title, content }) => {
  return (
    <div className="mb-4 mt-4 flex flex-col">
      <label className="block">
        <Small className="">{title}</Small>
      </label>
      <Paragraph displayType="normal" colorType="primary">
        {content}
      </Paragraph>
    </div>
  );
};

type ReportSummaryPart1Props = {
  isDisplayModeForReview: boolean;
};

export const ReportSummaryPart1: React.FC<ReportSummaryPart1Props> = ({ isDisplayModeForReview }) => {
  const { assessmentId } = useParams();
  const go = useNavigate();
  const { assessmentReport, refetch: refetchAssessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || "<missing-learner-id>";

  const { data: assessmentQueryData } = useGetAssessmentQuery(
    {
      assessmentId: assessmentId || "<missing-assessment-id>",
    },
    {
      queryKey: ["get-assessment", assessmentId],
      refetchOnWindowFocus: false,
      refetchInterval: (query) => {
        const aiPredictionStatus = query.state.data?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
          ?.aiReport1PredictionStatus;
        if (aiPredictionStatus === ApiAssessmentAiReport1PredictionStatusChoices.ReportFieldPredictionPending) {
          return config.REPORT_POLLING_INTERVAL;
        } else {
          return false;
        }
      },
    },
  );

  const aiPredictionStatus = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.aiReport1PredictionStatus;

  const reportStillGenerating =
    !isDisplayModeForReview &&
    aiPredictionStatus === ApiAssessmentAiReport1PredictionStatusChoices.ReportFieldPredictionPending;

  const reportNotYetKickedOff =
    !isDisplayModeForReview && aiPredictionStatus === ApiAssessmentAiReport1PredictionStatusChoices.Pending;

  const { data, refetch } = useAssessmentReportFieldsQuery(
    {
      assessmentId: assessmentId ? assessmentId : "<missing-assessment-id>",
      learnerId,
    },
    {
      refetchOnMount: false,
      queryKey: ["assessment-report-fields-query", assessmentId, learnerId, reportStillGenerating],
      enabled: !!assessmentId && !!learnerId && !reportStillGenerating,
    },
  );

  useEffect(() => {
    refetch();
    refetchAssessmentReport();
  }, []);

  const reportGenerationFailed = aiPredictionStatus === ApiAssessmentAiReport1PredictionStatusChoices.Failure;
  const reportGenerationFailureReason = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.failureReason;

  const abFields = data?.assessmentReportFields?.edges?.map((edge) => edge?.node) || [];

  const clientDetails = assessmentReport?.assessmentClientDetails;

  const historyOfServices = (
    (assessmentReport?.assessmentHistoryOfServices?.edges?.map((edge) => edge?.node)?.filter(Boolean) ||
      []) as AssessmentHistoryOfOtherServicesType[]
  ).map((s) => ({ ...s, hours: String(s.hours) }));

  const treatmentRecommendations =
    (
      assessmentReport?.assessmentTreatmentRecommendationService?.edges
        ?.map((edge) => edge?.node)
        ?.filter(Boolean) as AssessmentTreatmentRecommendationServiceType[]
    )?.map((s) => ({
      ...s,
      hoursRequested: String(s?.hoursRequested),
      totalUnitsPerAuth: String(s?.totalUnitsPerAuth),
    })) || [];

  const clientName = `${clientDetails?.clientFirstName} ${clientDetails?.clientLastName}`;
  const guardianName = `${clientDetails?.guardianFirstName} ${clientDetails?.guardianLastName}`;
  const addressLine1 = `${clientDetails?.address}`;
  const addressLine2 = `${clientDetails?.addressLine2}`;
  const city = `${clientDetails?.city}`;
  const state = `${clientDetails?.state}`;
  const zip = `${clientDetails?.zip}`;

  const date = new Date();
  const isoString = date.toISOString();
  const birthDateOrDate = clientDetails?.birthDate || isoString;
  const birthDateFormatted = format(parseISO(birthDateOrDate), "MM/dd/yyyy");

  const age = getDifferenceInYearsAndMonths(birthDateOrDate, assessmentReport?.createdDate);

  if (reportNotYetKickedOff) {
    return <ReportNotReadyYet item="Report Part 1" />;
  }

  if (reportStillGenerating) {
    return (
      <div className="mt-10 flex flex-col items-center justify-center gap-y-4">
        <SparklesIcon className="h-12 w-12 text-limestone-200" />
        <Heading type="h4">Good news! Your Assessment Report (Part 1) is underway</Heading>
        <Paragraph displayType="normal" colorType="secondary" className="text-center">
          This could take some time to complete. <br />
          You may leave this page and continue working and come back soon
        </Paragraph>

        <ul className="flex flex-col gap-y-2">
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center justify-end">
              <Small displayType="loud">Report Part One Generation: </Small>
            </div>
            <div className="flex items-center justify-start">
              {aiPredictionStatus === ApiAssessmentAiReport1PredictionStatusChoices["ReportFieldPredictionPending"] ? (
                <Badge dot text={"Generating"} appearance={"ai"} />
              ) : aiPredictionStatus ===
                ApiAssessmentAiReport1PredictionStatusChoices["ReportFieldPredictionPredicted"] ? (
                <Badge text={"Done"} appearance={"success"} />
              ) : null}
            </div>
          </div>
        </ul>
        <Button
          appearance="primary"
          text="Return to Assessments"
          onClick={() => {
            go("/assessment-reports");
          }}
        />
      </div>
    );
  }

  if (reportGenerationFailed && !isDisplayModeForReview) {
    return (
      <GenerationErrorPage
        page={AiReportPredictionOfEnum.AssessmentReport_1}
        errorMessage={reportGenerationFailureReason}
      />
    );
  }

  const isAddressLine2 = addressLine2.length && addressLine2 !== "null" && addressLine2 !== "undefined";

  return (
    <div className={`w-full flex flex-col ${isDisplayModeForReview ? "pb-0" : "pb-0"}`}>
      <div className="grid grid-cols-4 gap-x-1">
        <div className={`${isDisplayModeForReview ? "col-span-4" : "col-span-3"} flex w-full flex-col gap-y-4`}>
          {AssessmentReportHeadings.map((section, index) => {
            const idFromSection = section.toLocaleLowerCase().split(" ").join("-");
            const fieldsForSection = headerToListOfFieldsMapping[section];
            const iconForSection = headerToIconMapping[section];
            return (
              <div className="pageBreak" key={idFromSection}>
                <HeadingSection
                  Icon={iconForSection}
                  key={idFromSection}
                  id={idFromSection}
                  isDisplayOnly={section === "Statement of Confidentiality"}
                  label={section === "BIP" ? "Behavior Goals and Behavior Intervention Plan (BIP)" : section}
                  isLast={index === AssessmentReportHeadings.length - 1}
                  description={
                    section === "BIP"
                      ? "Problem behavior(s) targeted for decrease based on parent report and/or observation"
                      : undefined
                  }
                >
                  {section === "Client Details" ? (
                    <>
                      <ContentWrapper title={"Client's Name"} content={clientName} />
                      <ContentWrapper title={"Guardian's Name"} content={guardianName} />
                      <ContentWrapper title={"Address Line One"} content={addressLine1} />
                      {isAddressLine2 ? <ContentWrapper title={"Address Line Two"} content={addressLine2} /> : null}
                      <ContentWrapper title={"City"} content={city} />
                      <ContentWrapper title={"State"} content={state} />
                      <ContentWrapper title={"Zip"} content={zip} />
                      <ContentWrapper title={"Date of Birth"} content={birthDateFormatted} />
                      <ContentWrapper title={"Age"} content={age} />

                      {fieldsForSection.map((field) => {
                        const existingContent = abFields?.find((node) => node?.fieldKey === field);
                        return (
                          <SmartInputFieldWrapper
                            key={`${section}-${field}`}
                            field={field}
                            section={section}
                            existingContent={existingContent?.value}
                            inputType={getInputType(field)}
                            displayOnlyMode={existingContent?.aiGenerated || isDisplayModeForReview}
                          />
                        );
                      })}
                    </>
                  ) : section === "Insurance Details" ? (
                    <>
                      {fieldsForSection.map((field) => {
                        const existingContent = abFields?.find((node) => node?.fieldKey === field);
                        return (
                          <SmartInputFieldWrapper
                            key={`${section}-${field}`}
                            field={field}
                            section={section}
                            existingContent={existingContent?.value}
                            inputType={getInputType(field)}
                            displayOnlyMode={existingContent?.aiGenerated || isDisplayModeForReview}
                            items={
                              field === AssessmentReportFieldsEnums.InsuranceName
                                ? Object.values(ApiInsuranceDetailsInsurerChoices).map((o) => ({
                                    primary: convertReadableString(o),
                                  }))
                                : []
                            }
                          />
                        );
                      })}
                    </>
                  ) : section === "Statement of Confidentiality" ? (
                    <Paragraph>
                      {generateConfidentialityNotice(assessmentReport?.assessmentClientDetails?.clientFirstName || "")}
                    </Paragraph>
                  ) : section === "BIP" ? (
                    fieldsForSection.map((field) => {
                      const existingContent = abFields?.filter((node) => node?.fieldKey === field);
                      return (
                        <div key={`${section}-${field}`}>
                          <BIPs
                            key={`${section}-${field}`}
                            field={field}
                            section={section}
                            onRefetch={refetch}
                            existingContent={existingContent as AssessmentReportFieldsType[]}
                            isDisplayModeForReview={isDisplayModeForReview}
                          />
                        </div>
                      );
                    })
                  ) : section === "History of Other Services" ? (
                    <div className="flex flex-col gap-y-4">
                      <SmartFieldWrapper
                        field={AssessmentReportFieldsEnums["AbaBackgroundInSchool"]}
                        section={section}
                        existingContent={
                          abFields.find(
                            (node) => node?.fieldKey === AssessmentReportFieldsEnums["AbaBackgroundInSchool"],
                          )?.value
                        }
                        isDisplayMode={isDisplayModeForReview}
                        existingId={
                          abFields.find(
                            (node) => node?.fieldKey === AssessmentReportFieldsEnums["AbaBackgroundInSchool"],
                          )?.id
                        }
                      />
                      <HistoryOfOtherServicesTable
                        isDisplayModeForReview={isDisplayModeForReview}
                        historyOfServices={historyOfServices}
                      />
                    </div>
                  ) : section === "Treatment Recommendations" ? (
                    <div className="flex flex-col gap-y-4">
                      <TreatmentRecommendationsTable
                        isDisplayModeForReview={isDisplayModeForReview}
                        treatmentRecommendations={treatmentRecommendations}
                      />
                    </div>
                  ) : (
                    fieldsForSection.map((field) => {
                      const existingContent = abFields?.find((node) => node?.fieldKey === field);
                      return (
                        <SmartFieldWrapper
                          key={`${section}-${field}`}
                          field={field}
                          section={section}
                          existingContent={existingContent?.value}
                          existingId={existingContent?.id}
                          isDisplayMode={isDisplayModeForReview}
                        />
                      );
                    })
                  )}
                </HeadingSection>
              </div>
            );
          })}
        </div>
        {!isDisplayModeForReview && (
          <div className="flex w-full flex-col items-end ">
            <StickyNav state={{}} headingType="h4" />
          </div>
        )}
      </div>
      {!isDisplayModeForReview && <FooterButtonRow />}
    </div>
  );
};

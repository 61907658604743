import { DocumentIcon } from "@heroicons/react/24/outline";

import { Button, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

export const INTAKE_VALUES = ["Complete", "In Progress", "Awaiting", "Started"] as const;
export type IntakeType = (typeof INTAKE_VALUES)[number];

export interface DownloadData {
  assessment: string;
  name: string;
  fronteraFileLink: string;
  officalFileLink?: string;
}

interface DownloadsTableProps {
  data: DownloadData[];
  isLoading: boolean;
}

export const DownloadsTable: React.FC<DownloadsTableProps> = ({ data, isLoading }) => {
  return (
    <div className="flow-root w-full">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full border-collapse border-0">
            <thead>
              <tr className="grid grid-cols-3 gap-5 mb-2 p-2 bg-limestone-100 rounded-lg ">
                <th scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-limestone-900 sm:pl-6">
                  Name
                </th>
                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-limestone-900">
                  Official Source
                </th>
                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-limestone-900">
                  Frontera Health Template
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={4} className="text-center py-4">
                    <div className="loader">Loading...</div> {/* Customize the loader as needed */}
                  </td>
                </tr>
              ) : data.length ? (
                data.map(({ assessment, name, fronteraFileLink, officalFileLink }) => (
                  <tr
                    key={assessment}
                    className="grid grid-cols-3 gap-5 mb-2 p-2 bg-white border border-limestone-100 rounded-lg "
                  >
                    <td className="py-2 px-3 text-sm font-medium text-limestone-900 flex items-center">
                      <DocumentIcon className="mr-2 h-4 w-4" /> {name}
                    </td>
                    <td className="py-2 px-3 text-sm font-medium text-limestone-900 flex items-end">
                      {officalFileLink === "-" || !officalFileLink ? (
                        <Small className="pl-4">{officalFileLink}</Small>
                      ) : (
                        <a href={officalFileLink} target="_blank" rel="noreferrer">
                          <Button
                            text={
                              <div className="flex items-center">
                                <span>View </span>
                              </div>
                            }
                            appearance="link"
                            onClick={() => {}}
                            style={{ padding: 2 }}
                            className="max-h-5 !text-green-700 hover:no-underline"
                          />
                        </a>
                      )}
                    </td>
                    <td className="py-2 px-3 text-sm font-medium text-limestone-900 flex items-end">
                      <a href={fronteraFileLink} target="_blank" rel="noreferrer">
                        <Button
                          text={
                            <div className="flex items-center">
                              <span>View</span>
                              {/* NOTE: Bring back if we need to indicate new tab is being opened */}
                              {/* <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 leading-7" /> */}
                            </div>
                          }
                          appearance="link"
                          onClick={() => {}}
                          style={{ padding: 2 }}
                          className="max-h-5 !text-green-700 hover:no-underline"
                        />
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="py-5 flex flex-col align-middle bg-white border border-limestone-300 rounded-lg text-center">
                  <Paragraph displayType="loud">No Files to Show</Paragraph>
                  <Small>Agreements and documents added to this client will show up here.</Small>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

import { useEffect } from "react";
import {
  FieldValues,
  FormState,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import { Select, SelectItem, SelectProps } from "@fronterahealth/frontera-ui-components";

import { getErrorFromFormKey } from "@components/forms/utils";

interface Props<T extends FieldValues> extends Omit<SelectProps, "selected" | "setSelected"> {
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  clearErrors: UseFormClearErrors<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  required?: boolean;
}

export const FormSelect = <T extends FieldValues>({
  formState,
  formKey,
  watch,
  register,
  setValue,
  trigger,
  title,
  required = true,
  ...props
}: Props<T>) => {
  const selected = watch(formKey);

  const errorMessage = getErrorFromFormKey(formKey, formState.errors);

  useEffect(() => {
    register(formKey, {
      required: {
        value: required,
        message: `${title} is required`,
      },
    });
  }, [register, required, formKey, formState]);

  const setSelected = (val: SelectItem) => {
    setValue(formKey, (val.id && val.id !== "undefined" ? val.id : val.primary) as PathValue<T, Path<T>>);
    trigger(formKey);
  };

  const selectedItem = props.items.find(
    (item) =>
      (item.id !== "undefined" && selected && item.id === String(selected)) ||
      (selected && item.primary === String(selected)),
  );

  return (
    <Select
      hintText={required ? undefined : "Optional"}
      title={title}
      selected={{ primary: selectedItem ? selectedItem.primary : "" }}
      setSelected={setSelected}
      errorMessage={errorMessage}
      {...props}
    />
  );
};

import { GenderApiValues } from "@utils/constants";
import { capitalizeString } from "@utils/utils";
import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { Divider, Heading, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  ApiCaregiverMetadataMaritalStatusChoices,
  LearnerGuardianDetailsQuery,
  useLearnerGuardianDetailsQuery,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer, FormSpacer, TripleColumnHeader } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import {
  GuardianDetailsDataType,
  RelationShipDataType,
  guardianDetailsMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/HomeEnvironment/Mappings/guardianDetailsMapping";
import {
  RELATIONSHIP_TO_CLIENT_OPTIONS,
  YesNoRadioItems,
  getGenderValue,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { USA_STATES_LIST } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/state";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const extractGuardianDetailsData = (queryData: LearnerGuardianDetailsQuery) => {
  const converted = {
    guardianDetails: queryData.learnerById?.relationshipSet?.edges?.map((edge) => ({
      socialRelationship: edge?.node?.socialRelationship,
      caregiverMetadata: {
        ...edge?.node?.caregiver.caregivermetadata,
        requiresInterpreter: edge?.node?.caregiver.caregivermetadata?.requiresInterpreter ? "yes" : "no",
        gender: getGenderValue(edge?.node?.caregiver.caregivermetadata?.gender),
        maritalStatus: capitalizeString(edge?.node?.caregiver.caregivermetadata?.maritalStatus || ""),
      },
    })) as unknown as RelationShipDataType[],
  };
  return converted;
};

export const GuardianDetails: React.FC = () => {
  const { clientId } = useParams();
  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/home-environment/guardian-details" : undefined;

  const config = useGetQueryConfig<LearnerGuardianDetailsQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerGuardianDetailsQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching: formLoading } = learnerDetailsQuery;

  const {
    formState,
    onSubmit,
    control,
    RegisteredFormInput,
    RegisteredFormTextArea,
    RegisteredFormSelected,
    RegisteredFormRadioInput,
    reset,
    watch,
  } = useFormUtils<GuardianDetailsDataType>({
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  const { fields } = useFieldArray({
    control,
    name: "guardianDetails",
  });

  useEffect(() => {
    if (data) {
      const converted = extractGuardianDetailsData(data);
      reset(converted);
    }
  }, [data, reset]);

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        {fields.map((field, index) => {
          const genderWatch = watch(`guardianDetails.${index}.caregiverMetadata.gender`) as string;

          return (
            <div className="flex-col" key={field.id}>
              <div className="flex w-full items-center justify-start ">
                <div className="flex items-center">
                  <Heading type="h3" className="my-4">
                    Guardian {index + 1}
                  </Heading>
                </div>
              </div>

              {/* Easiest way to send in the guardianId back in is to include it in the form but not display the input */}
              <DoubleColumnContainer>
                <RegisteredFormInput
                  formState={formState}
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.firstName[chosenLanguage]}
                  formKey={`guardianDetails.${index}.caregiverMetadata.firstName`}
                  readOnly
                  isLoading={formLoading}
                />
                <RegisteredFormInput
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.lastName[chosenLanguage]}
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.lastName`}
                  readOnly
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormSelected
                  title={guardianDetailsMapping.guardianDetails.socialRelationship[chosenLanguage]}
                  formKey={`guardianDetails.${index}.socialRelationship`}
                  formState={formState}
                  items={RELATIONSHIP_TO_CLIENT_OPTIONS.map((o) => ({ primary: o }))}
                  placeholderText={""}
                  isLoading={formLoading}
                  readOnly
                />
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.preferredLanguage[chosenLanguage]}
                  formKey={`guardianDetails.${index}.caregiverMetadata.preferredLanguage`}
                  formState={formState}
                  isLoading={formLoading}
                  readOnly
                />
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormRadioInput
                  title={
                    guardianDetailsMapping.guardianDetails.caregiverMetadata.requiresInterpreter?.[chosenLanguage] ?? ""
                  }
                  items={YesNoRadioItems}
                  formKey={`guardianDetails.${index}.caregiverMetadata.requiresInterpreter`}
                  formState={formState}
                  readOnly
                />
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormInput
                  // @ts-ignore: Ignoring the compiler and risking bugs because: birthdate has any type
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.birthDate?.[chosenLanguage] ?? ""}
                  formKey={`guardianDetails.${index}.caregiverMetadata.birthDate`}
                  type="date"
                  formState={formState}
                  isLoading={formLoading}
                  readOnly
                />
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormSelected
                  title={guardianDetailsMapping.guardianDetails.caregiverMetadata.gender?.[chosenLanguage] ?? ""}
                  formKey={`guardianDetails.${index}.caregiverMetadata.gender`}
                  formState={formState}
                  items={
                    Object.values(GenderApiValues).map((o) => ({
                      primary: getGenderValue(o),
                    })) as SelectItem[]
                  }
                  placeholderText={""}
                  isLoading={formLoading}
                  readOnly
                />

                {genderWatch === "Gender identity is not listed" && (
                  <RegisteredFormInput
                    formKey={`guardianDetails.${index}.caregiverMetadata.specifyGender`}
                    formState={formState}
                    label={
                      guardianDetailsMapping.guardianDetails.caregiverMetadata.specifyGender?.[chosenLanguage] ?? ""
                    }
                    isLoading={formLoading}
                    required={false}
                    readOnly
                  />
                )}
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormSelected
                  readOnly
                  title={guardianDetailsMapping.guardianDetails.caregiverMetadata.maritalStatus?.[chosenLanguage] ?? ""}
                  required={true}
                  formKey={`guardianDetails.${index}.caregiverMetadata.maritalStatus`}
                  formState={formState}
                  items={Object.values(ApiCaregiverMetadataMaritalStatusChoices).map((o) => ({
                    primary: capitalizeString(o),
                  }))}
                  placeholderText={""}
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>

              <FormSpacer />

              <DoubleColumnContainer>
                <RegisteredFormInput
                  readOnly
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.addressLine1?.[chosenLanguage] ?? ""}
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.addressLine1`}
                  isLoading={formLoading}
                />
                <RegisteredFormInput
                  readOnly
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.addressLine2?.[chosenLanguage] ?? ""}
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.addressLine2`}
                  hintText="Optional"
                  required={false}
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>

              <TripleColumnHeader>
                <RegisteredFormInput
                  readOnly
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.city?.[chosenLanguage] ?? ""}
                  isLoading={formLoading}
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.city`}
                />
                <RegisteredFormSelected
                  readOnly
                  title={guardianDetailsMapping.guardianDetails.caregiverMetadata.state?.[chosenLanguage] ?? ""}
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.state`}
                  items={USA_STATES_LIST.map((o: string) => ({ primary: o }))}
                  placeholderText={""}
                  isLoading={formLoading}
                />
                <RegisteredFormInput
                  readOnly
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.zip?.[chosenLanguage] ?? ""}
                  isLoading={formLoading}
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.zip`}
                />
              </TripleColumnHeader>

              <DoubleColumnContainer>
                <RegisteredFormInput
                  formState={formState}
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.phoneNumber1?.[chosenLanguage] ?? ""}
                  formKey={`guardianDetails.${index}.caregiverMetadata.phoneNumber1`}
                  readOnly
                  isLoading={formLoading}
                />
                <RegisteredFormInput
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.phoneNumber2?.[chosenLanguage] ?? ""}
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.phoneNumber2`}
                  isLoading={formLoading}
                  required={false}
                  readOnly
                />
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormInput
                  formState={formState}
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.email?.[chosenLanguage] ?? ""}
                  formKey={`guardianDetails.${index}.caregiverMetadata.email`}
                  readOnly
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>

              <DoubleColumnContainer>
                <RegisteredFormInput
                  isLoading={formLoading}
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.occupation?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.occupation`}
                />
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={guardianDetailsMapping.guardianDetails.caregiverMetadata.employer?.[chosenLanguage] ?? ""}
                  isLoading={formLoading}
                  readOnly
                  formState={formState}
                  formKey={`guardianDetails.${index}.caregiverMetadata.employer`}
                />
              </DoubleColumnContainer>

              <RegisteredFormTextArea
                label={
                  guardianDetailsMapping.guardianDetails.caregiverMetadata.abaClientGoalsForGuardian?.[
                    chosenLanguage
                  ] ?? ""
                }
                readOnly
                formState={formState}
                formKey={`guardianDetails.${index}.caregiverMetadata.abaClientGoalsForGuardian`}
              />

              <Divider />
            </div>
          );
        })}
      </FormContainer>
    </div>
  );
};

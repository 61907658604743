import { RadioInputItem } from "@fronterahealth/frontera-ui-components/RadioInput/RadioInput";

import { TendenciesQuestionType } from "@api/graphql/types-and-hooks";
import { LANGUAGE_KEYS, LanguageObjectType } from "@components/forms/interfaces";

/**
 *
 * This file contains the logical grouping of which Tendency questions belong to which page
 *
 * At the data layer, these are all just LearnerOnboardingQuestions with question keys that belong
 * to a massive Enum on the backend / Python Graphene side. We wanted to use one mutation on the backend side
 * (instead of having 10 different mutations for each Tendency type) and because GraphQL currently does not allow
 * Input Union types (https://github.com/graphql/graphql-spec/issues/488) we couldn't separate each enum into a separate one
 * based on the form page it belonged to
 *
 * Therefore, ALL tendencies question_keys are in one Enum = the TendenciesQuestionType - and the responsibility therefore lies
 * on the UI and the following mapping files to dictate which questions will fall on which page
 */

export type TendencyRoutes =
  | "forms/intake_form/all"
  | "forms/intake_form/tendencies/attention"
  | "forms/intake_form/tendencies/defiance"
  | "forms/intake_form/tendencies/aggression"
  | "forms/intake_form/tendencies/mood"
  | "forms/intake_form/tendencies/odd-ideas"
  | "forms/intake_form/tendencies/attachment"
  | "forms/intake_form/tendencies/trauma"
  | "forms/intake_form/tendencies/somatic"
  | "forms/intake_form/tendencies/language"
  | "forms/intake_form/tendencies/social-skills"
  | "forms/intake_form/tendencies/executive-functions"
  | "forms/intake_form/tendencies/strengths";
export const TendenciesQuestionGroupings: { [key in TendencyRoutes]: TendenciesQuestionType[] } = {
  "forms/intake_form/all": [
    // attention
    TendenciesQuestionType.HasDifficultyPayingAttentionOrMakesCarelessMistakes,
    TendenciesQuestionType.IsEasilyDistracted,
    TendenciesQuestionType.IsOnTheGoOrActsAsIfDrivenByAMotor,
    TendenciesQuestionType.RushesThroughTasksOrGivesUpEasily,
    TendenciesQuestionType.CantSitStill,
    TendenciesQuestionType.AvoidsTasksThatTakeALotOfEffort,
    TendenciesQuestionType.DoesNotSeemToListenWhenSpokenToDirectly,
    TendenciesQuestionType.HasManyThoughtsAtOnce,
    TendenciesQuestionType.ActsWithoutThinking,
    TendenciesQuestionType.AttentionComment,
    // defiance
    TendenciesQuestionType.LosesTemperOrTemperTantrums,
    TendenciesQuestionType.ArguesWithAdults,
    TendenciesQuestionType.DefiantRefusesWhatYouTellThemToDo,
    TendenciesQuestionType.BlamesOthersForMisbehavior,
    TendenciesQuestionType.IsAngry,
    TendenciesQuestionType.DoesNotApologizeForPoorBehavior,
    TendenciesQuestionType.HasAnExplosiveTemperOrHasRageReactions,
    TendenciesQuestionType.FrequentConflictsWithOtherPeopleInTheHome,
    TendenciesQuestionType.DefianceComment,
    //aggression
    TendenciesQuestionType.BulliesThreatensOrIntimidatesOthers,
    TendenciesQuestionType.PhysicalFights,
    TendenciesQuestionType.CrueltyToAnimals,
    TendenciesQuestionType.HasDestroyedOthersProperty,
    TendenciesQuestionType.HasStartedFires,
    TendenciesQuestionType.LiesToGetThingsOrAvoidResponsibility,
    TendenciesQuestionType.HasStolenThingsFromOthers,
    TendenciesQuestionType.GangInvolvement,
    TendenciesQuestionType.SubstanceAbuse,
    TendenciesQuestionType.AggressionComment,
    // mood

    TendenciesQuestionType.IsSadOrIrritatedMostOfTheDay,
    TendenciesQuestionType.ShowsLittleInterestInPleasurableActivities,
    TendenciesQuestionType.TalksAboutKillingThemselvesOrNotWantingToLive,
    TendenciesQuestionType.HasLowEnergyOrIsTiredForNoApparentReason,
    TendenciesQuestionType.IsVeryTenseWorriedOrAnxious,
    TendenciesQuestionType.DisplaysAbruptRapidMoodSwings,
    TendenciesQuestionType.SometimesIsSoWoundUpOrHyperThatTheyDoNotSleepForADayOrTwo,
    TendenciesQuestionType.MoodComment,

    // odd ideas
    TendenciesQuestionType.HearsVoicesEspeciallyTellingThemToDoThings,
    TendenciesQuestionType.HasExtremelyStrangeOrIllogicalIdeas,
    TendenciesQuestionType.TalksToImaginaryPeople,
    TendenciesQuestionType.HasCompulsiveBehaviors,
    TendenciesQuestionType.IsHypersensitiveOrHyposensitive,
    TendenciesQuestionType.CutsOnSelfOrEngagesInOtherTypesOfSelfHarm,
    TendenciesQuestionType.OddIdeasComment,
    // attachment
    TendenciesQuestionType.ShowsLittleOrNotInterestInOtherPeoplesFeelings,
    TendenciesQuestionType.DoesNotTrustAnyone,
    TendenciesQuestionType.HasNoFearOrStrangersWillGoWithAnyone,
    TendenciesQuestionType.PoorEyeContactEvenIfNotInTrouble,
    TendenciesQuestionType.RelatesToOthersOnOnlyASuperficialLevel,
    TendenciesQuestionType.ShowsLittleAffectionDoesNotAcceptAffectionFromOthers,
    TendenciesQuestionType.HypervigilantAlwaysWatchingOthers,
    TendenciesQuestionType.VeryManipulative,
    TendenciesQuestionType.LackOfRemorseOrConscience,
    TendenciesQuestionType.SeemsToHaveALackOfPurpose,
    TendenciesQuestionType.AttachmentComment,
    // trauma
    TendenciesQuestionType.ClientHaveHistory,
    TendenciesQuestionType.CannotGetUpsettingThoughtsOrMemoriesOutOfTheirMind,
    TendenciesQuestionType.StaresEasily,
    TendenciesQuestionType.SeemsEmotionallyNumb,
    TendenciesQuestionType.DissociatesSeemsToBeOffInTheirOwnWorld,
    TendenciesQuestionType.ReportsTraumaDreams,
    TendenciesQuestionType.ReenactsTraumaThroughPlayOrActsOutSexuallyOrAggressively,
    TendenciesQuestionType.TraumaComment,
    // somatic
    TendenciesQuestionType.ComplainsOfHeadachesStomachachesOrBodilyPains,
    TendenciesQuestionType.HasDifficultyFallingAsleep,
    TendenciesQuestionType.HasBadDreamsOrNightTerrors,
    TendenciesQuestionType.WakesFrequentlyInTheNightAndHasTroubleGettingBackToSleep,
    TendenciesQuestionType.PoorAppetite,
    TendenciesQuestionType.EatsTooMuchOrEatsPoorly,
    TendenciesQuestionType.WetsOrSoilsTheBed,
    TendenciesQuestionType.WetsOrSoilsSelfDuringDaytimeHours,
    TendenciesQuestionType.SomaticComment,
    // language
    TendenciesQuestionType.ArticulationProblemsDifficultToUnderstandSpeech,
    TendenciesQuestionType.HasDifficultyExpressingThoughtsAndOrFeelings,
    TendenciesQuestionType.HasDifficultyUnderstandingWhatIsBeingSaid,
    TendenciesQuestionType.LanguageComment,
    // social skills
    TendenciesQuestionType.HasFewOrNoFriends,
    TendenciesQuestionType.HasDifficultyAccuratelyUnderstandingSocialAndCommunicationCues,
    TendenciesQuestionType.InterruptsOthers,
    TendenciesQuestionType.DoesNotListenVeryWellToOtherPeoplesPointOfViewOrPerspective,
    TendenciesQuestionType.SocialSkillsComment,

    // executive functions
    TendenciesQuestionType.DifficultyHandlingTransitions,
    TendenciesQuestionType.DifficultyHandlingChangesInRoutine,
    TendenciesQuestionType.DoesntStartTasksWithoutALotOfPrompting,
    TendenciesQuestionType.DifficultyFollowingMultiStepDirections,
    TendenciesQuestionType.DifficultyWithOrganizingTasksOrSchoolWork,
    TendenciesQuestionType.DifficultyDealingWithFrustrationInCompletingTasks,
    TendenciesQuestionType.DifficultyAdjustingToUnforeseenProblemsOrUnclearDirections,
    TendenciesQuestionType.TroubleCompletingTasks,
    TendenciesQuestionType.FailsToUnderstandHowHisOrHerBehaviorAffectsOthers,
    TendenciesQuestionType.FailsToLearnFromHisOrHerExperienceSuccessOrFailure,
    TendenciesQuestionType.ExecutiveFunctionsComment,
    // strengths
    TendenciesQuestionType.HelpsWithChoresAroundTheHouse,
    TendenciesQuestionType.LikesSchool,
    TendenciesQuestionType.CompletesHomeworkWithMinimalReminders,
    TendenciesQuestionType.GetsAlongWellWithFamilyMembers,
    TendenciesQuestionType.IsArtisticCreativeOrMusical,
    TendenciesQuestionType.LikesAnimals,
    TendenciesQuestionType.HasGoodManners,
    TendenciesQuestionType.ApologizesWhenHurtsSomeonesFeelings,
    TendenciesQuestionType.IsAffectionate,
    TendenciesQuestionType.SenseOfHumor,
    TendenciesQuestionType.StrengthsComment,
  ],
  "forms/intake_form/tendencies/attention": [
    TendenciesQuestionType.HasDifficultyPayingAttentionOrMakesCarelessMistakes,
    TendenciesQuestionType.IsEasilyDistracted,
    TendenciesQuestionType.IsOnTheGoOrActsAsIfDrivenByAMotor,
    TendenciesQuestionType.RushesThroughTasksOrGivesUpEasily,
    TendenciesQuestionType.CantSitStill,
    TendenciesQuestionType.AvoidsTasksThatTakeALotOfEffort,
    TendenciesQuestionType.DoesNotSeemToListenWhenSpokenToDirectly,
    TendenciesQuestionType.HasManyThoughtsAtOnce,
    TendenciesQuestionType.ActsWithoutThinking,
    TendenciesQuestionType.AttentionComment,
  ],
  "forms/intake_form/tendencies/defiance": [
    TendenciesQuestionType.LosesTemperOrTemperTantrums,
    TendenciesQuestionType.ArguesWithAdults,
    TendenciesQuestionType.DefiantRefusesWhatYouTellThemToDo,
    TendenciesQuestionType.BlamesOthersForMisbehavior,
    TendenciesQuestionType.IsAngry,
    TendenciesQuestionType.DoesNotApologizeForPoorBehavior,
    TendenciesQuestionType.HasAnExplosiveTemperOrHasRageReactions,
    TendenciesQuestionType.FrequentConflictsWithOtherPeopleInTheHome,
    TendenciesQuestionType.DefianceComment,
  ],
  "forms/intake_form/tendencies/aggression": [
    TendenciesQuestionType.BulliesThreatensOrIntimidatesOthers,
    TendenciesQuestionType.PhysicalFights,
    TendenciesQuestionType.CrueltyToAnimals,
    TendenciesQuestionType.HasDestroyedOthersProperty,
    TendenciesQuestionType.HasStartedFires,
    TendenciesQuestionType.LiesToGetThingsOrAvoidResponsibility,
    TendenciesQuestionType.HasStolenThingsFromOthers,
    TendenciesQuestionType.GangInvolvement,
    TendenciesQuestionType.SubstanceAbuse,
    TendenciesQuestionType.AggressionComment,
  ],
  "forms/intake_form/tendencies/mood": [
    TendenciesQuestionType.IsSadOrIrritatedMostOfTheDay,
    TendenciesQuestionType.ShowsLittleInterestInPleasurableActivities,
    TendenciesQuestionType.TalksAboutKillingThemselvesOrNotWantingToLive,
    TendenciesQuestionType.HasLowEnergyOrIsTiredForNoApparentReason,
    TendenciesQuestionType.IsVeryTenseWorriedOrAnxious,
    TendenciesQuestionType.DisplaysAbruptRapidMoodSwings,
    TendenciesQuestionType.SometimesIsSoWoundUpOrHyperThatTheyDoNotSleepForADayOrTwo,
    TendenciesQuestionType.MoodComment,
  ],
  "forms/intake_form/tendencies/odd-ideas": [
    TendenciesQuestionType.HearsVoicesEspeciallyTellingThemToDoThings,
    TendenciesQuestionType.HasExtremelyStrangeOrIllogicalIdeas,
    TendenciesQuestionType.TalksToImaginaryPeople,
    TendenciesQuestionType.HasCompulsiveBehaviors,
    TendenciesQuestionType.IsHypersensitiveOrHyposensitive,
    TendenciesQuestionType.CutsOnSelfOrEngagesInOtherTypesOfSelfHarm,
    TendenciesQuestionType.OddIdeasComment,
  ],
  "forms/intake_form/tendencies/attachment": [
    TendenciesQuestionType.ShowsLittleOrNotInterestInOtherPeoplesFeelings,
    TendenciesQuestionType.DoesNotTrustAnyone,
    TendenciesQuestionType.HasNoFearOrStrangersWillGoWithAnyone,
    TendenciesQuestionType.PoorEyeContactEvenIfNotInTrouble,
    TendenciesQuestionType.RelatesToOthersOnOnlyASuperficialLevel,
    TendenciesQuestionType.ShowsLittleAffectionDoesNotAcceptAffectionFromOthers,
    TendenciesQuestionType.HypervigilantAlwaysWatchingOthers,
    TendenciesQuestionType.VeryManipulative,
    TendenciesQuestionType.LackOfRemorseOrConscience,
    TendenciesQuestionType.SeemsToHaveALackOfPurpose,
    TendenciesQuestionType.AttachmentComment,
  ],
  "forms/intake_form/tendencies/trauma": [
    TendenciesQuestionType.ClientHaveHistory,
    TendenciesQuestionType.CannotGetUpsettingThoughtsOrMemoriesOutOfTheirMind,
    TendenciesQuestionType.StaresEasily,
    TendenciesQuestionType.SeemsEmotionallyNumb,
    TendenciesQuestionType.DissociatesSeemsToBeOffInTheirOwnWorld,
    TendenciesQuestionType.ReportsTraumaDreams,
    TendenciesQuestionType.ReenactsTraumaThroughPlayOrActsOutSexuallyOrAggressively,
    TendenciesQuestionType.TraumaComment,
  ],
  "forms/intake_form/tendencies/somatic": [
    TendenciesQuestionType.ComplainsOfHeadachesStomachachesOrBodilyPains,
    TendenciesQuestionType.HasDifficultyFallingAsleep,
    TendenciesQuestionType.HasBadDreamsOrNightTerrors,
    TendenciesQuestionType.WakesFrequentlyInTheNightAndHasTroubleGettingBackToSleep,
    TendenciesQuestionType.PoorAppetite,
    TendenciesQuestionType.EatsTooMuchOrEatsPoorly,
    TendenciesQuestionType.WetsOrSoilsTheBed,
    TendenciesQuestionType.WetsOrSoilsSelfDuringDaytimeHours,
    TendenciesQuestionType.SomaticComment,
  ],
  "forms/intake_form/tendencies/language": [
    TendenciesQuestionType.ArticulationProblemsDifficultToUnderstandSpeech,
    TendenciesQuestionType.HasDifficultyExpressingThoughtsAndOrFeelings,
    TendenciesQuestionType.HasDifficultyUnderstandingWhatIsBeingSaid,
    TendenciesQuestionType.LanguageComment,
  ],
  "forms/intake_form/tendencies/social-skills": [
    TendenciesQuestionType.HasFewOrNoFriends,
    TendenciesQuestionType.HasDifficultyAccuratelyUnderstandingSocialAndCommunicationCues,
    TendenciesQuestionType.InterruptsOthers,
    TendenciesQuestionType.DoesNotListenVeryWellToOtherPeoplesPointOfViewOrPerspective,
    TendenciesQuestionType.SocialSkillsComment,
  ],
  "forms/intake_form/tendencies/executive-functions": [
    TendenciesQuestionType.DifficultyHandlingTransitions,
    TendenciesQuestionType.DifficultyHandlingChangesInRoutine,
    TendenciesQuestionType.DoesntStartTasksWithoutALotOfPrompting,
    TendenciesQuestionType.DifficultyFollowingMultiStepDirections,
    TendenciesQuestionType.DifficultyWithOrganizingTasksOrSchoolWork,
    TendenciesQuestionType.DifficultyDealingWithFrustrationInCompletingTasks,
    TendenciesQuestionType.DifficultyAdjustingToUnforeseenProblemsOrUnclearDirections,
    TendenciesQuestionType.TroubleCompletingTasks,
    TendenciesQuestionType.FailsToUnderstandHowHisOrHerBehaviorAffectsOthers,
    TendenciesQuestionType.FailsToLearnFromHisOrHerExperienceSuccessOrFailure,
    TendenciesQuestionType.ExecutiveFunctionsComment,
  ],
  "forms/intake_form/tendencies/strengths": [
    TendenciesQuestionType.HelpsWithChoresAroundTheHouse,
    TendenciesQuestionType.LikesSchool,
    TendenciesQuestionType.CompletesHomeworkWithMinimalReminders,
    TendenciesQuestionType.GetsAlongWellWithFamilyMembers,
    TendenciesQuestionType.IsArtisticCreativeOrMusical,
    TendenciesQuestionType.LikesAnimals,
    TendenciesQuestionType.HasGoodManners,
    TendenciesQuestionType.ApologizesWhenHurtsSomeonesFeelings,
    TendenciesQuestionType.IsAffectionate,
    TendenciesQuestionType.SenseOfHumor,
    TendenciesQuestionType.StrengthsComment,
  ],
};

const clientHaveHistoryMapping: { [key: string]: LanguageObjectType } = {
  physicalAbuse: {
    en: "Physical Abuse",
    es: "Abuso físico",
  },
  sexualAbuse: {
    en: "Sexual Abuse",
    es: "Abuso sexual",
  },
  exposure: {
    en: "Exposure to Domestic Violence",
    es: "Exposición a la violencia doméstica",
  },
  otherViolence: {
    en: "Other Violence",
    es: "Otro tipo de violencia",
  },
};

type CustomCheckboxType = {
  type: string;
  items: (lang: LANGUAGE_KEYS) => RadioInputItem[];
};

export const customTendenciesQuestionConfig: { [key in TendenciesQuestionType]?: CustomCheckboxType } = {
  CLIENT_HAVE_HISTORY: {
    type: "checkboxlist",
    items: (lang: LANGUAGE_KEYS) => [
      { id: "Physical Abuse", title: clientHaveHistoryMapping["physicalAbuse"][lang] },
      { id: "Sexual Abuse", title: clientHaveHistoryMapping["sexualAbuse"][lang] },
      { id: "Exposure to Domestic Violence", title: clientHaveHistoryMapping["exposure"][lang] },
      { id: "Other Violence", title: clientHaveHistoryMapping["otherViolence"][lang] },
    ],
  },
  ATTENTION_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  DEFIANCE_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  AGGRESSION_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  MOOD_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  ODD_IDEAS_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  ATTACHMENT_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  TRAUMA_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  SOMATIC_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  LANGUAGE_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  SOCIAL_SKILLS_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  EXECUTIVE_FUNCTIONS_COMMENT: {
    type: "textArea",
    items: () => [],
  },
  STRENGTHS_COMMENT: {
    type: "textArea",
    items: () => [],
  },
};

import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { LearnerEmergencyContactQuery, useLearnerEmergencyContactQuery } from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import {
  EmergencyContactType,
  emergencyContactMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/ClientDetails/Mappings/emergencyContactMapping";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const extractEmergencyContactData = (queryData: LearnerEmergencyContactQuery) => {
  return queryData?.learnerById?.learneremergencycontact as EmergencyContactType;
};

export const EmergencyContact: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/client-details/emergency-contact" : undefined;

  const config = useGetQueryConfig<LearnerEmergencyContactQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerEmergencyContactQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormInput, reset } = useFormUtils<EmergencyContactType>({
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  useEffect(() => {
    if (data) {
      const dataValues = extractEmergencyContactData(data);
      reset(dataValues);
    }
  }, [data, reset]);

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={isFetching}
            readOnly
            formKey="firstName"
            inputSize="full"
            formState={formState}
            label={emergencyContactMapping.firstName[chosenLanguage]}
            errorMessage=""
          />
          <RegisteredFormInput
            isLoading={isFetching}
            readOnly
            formKey="lastName"
            inputSize="full"
            formState={formState}
            label={emergencyContactMapping.lastName[chosenLanguage]}
            errorMessage=""
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={isFetching}
            formKey="relationshipToClient"
            inputSize="full"
            formState={formState}
            readOnly
            label={emergencyContactMapping.relationshipToClient[chosenLanguage]}
            errorMessage=""
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={isFetching}
            readOnly
            formKey="phoneNumber"
            inputSize="full"
            formState={formState}
            label={emergencyContactMapping.phoneNumber?.[chosenLanguage] ?? "Phone Number"}
            errorMessage=""
          />
        </DoubleColumnContainer>
      </FormContainer>
    </div>
  );
};

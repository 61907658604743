import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Dialog, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import {
  AssessmentReportFieldsEnums,
  AssessmentReportFieldsType,
  useAddUpdateAssessmentFieldValuesMutation,
} from "@api/graphql/types-and-hooks";
import { NoDataCard } from "@components/NoDataCard/NoDataCard";
import { notifyError } from "@components/notifications/notifications";
import { AssessmentReportHeadingType } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/ReportSummaryPart1";
import { SmartFieldWrapper } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/SmartFieldWrapper";

interface BIPsProps {
  field: AssessmentReportFieldsEnums;
  section: AssessmentReportHeadingType;
  existingContent?: AssessmentReportFieldsType[];
  onRefetch: () => void;
  isDisplayModeForReview?: boolean;
}

interface BipData {
  title: string;
  content?: string;
  id?: string;
  dbIndex: number;
}

const contentTemplate = `
<p><strong>Behavior</strong></p>
<p></p>

<p><strong>Definition</strong></p>
<p></p>

<p><strong>Example</strong></p>
<p></p>

<p><strong>Non-Example</strong></p>
<p></p>

<p><strong>Frequency, Severity, and Duration</strong></p>
<p></p>

<p><strong>Common Antecedents</strong></p>
<p></p>

<p><strong>Current Consequences</strong></p>
<p></p>

<p><strong>Functional Hypothesis</strong></p>
<p></p>

<p><strong>Replacement Behaviors</strong></p>
<p></p>

<p><strong>Antecedent Modifications</strong></p>
<p></p>

<p><strong>Strategies for Reinforcing Replacement Behavior</strong></p>
<p></p>

<p><strong>Strategies for Reducing the Target Behavior</strong></p>
<p></p>

<p><strong>Summary Statements</strong></p>
<p></p>

`;

export const BIPs: React.FC<BIPsProps> = ({ field, section, existingContent, onRefetch, isDisplayModeForReview }) => {
  const [bips, setBips] = useState<BipData[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const { assessmentId } = useParams();
  const addUpdateAssessmentFieldValuesMutation = useAddUpdateAssessmentFieldValuesMutation({});

  const { isPending } = addUpdateAssessmentFieldValuesMutation;

  useEffect(() => {
    if (existingContent?.length) {
      // @ts-ignore: Ignoring the compiler and risking bugs because: hacking index
      const sortedContent = existingContent.sort((a, b) => a?.index - b?.index);
      const tempList = sortedContent.map((item, index) => ({
        title: `Target Behavior ${index + 1}`,
        content: item.value,
        dbIndex: item.index,
        id: item.id,
      }));
      setBips(tempList as BipData[]);
    } else {
      setBips([]);
    }
  }, [existingContent]);

  const addBip = async () => {
    await addUpdateAssessmentFieldValuesMutation
      .mutateAsync({
        assessmentId: assessmentId || "<missing-id>",
        assessmentFieldDetail: {
          fieldKey: field,
          fieldValue: contentTemplate,
          behaviorIndex: bips.length ? bips[bips.length - 1]?.dbIndex + 1 : 1,
        },
      })
      .then(() => {
        onRefetch();
      })
      .catch((error) => {
        console.error("Error saving new BIP", error);
        notifyError("Error adding new target behavior");
      });
  };

  const removeBip = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const deleteRecordFromDB = async () => {
    if (selectedIndex !== undefined) {
      await addUpdateAssessmentFieldValuesMutation
        .mutateAsync({
          assessmentId: assessmentId || "<missing-id>",
          assessmentFieldDetail: {
            fieldKey: field,
            fieldValue: bips[selectedIndex].content || "",
            behaviorIndex: bips[selectedIndex].dbIndex,
            deleteBehavior: true,
          },
        })
        .then(() => {
          setOpen(false);
          onRefetch();
        })
        .catch((error) => {
          console.error("Error deleting BIP", error);
          notifyError("Error deleting target behavior");
        });
    }
  };

  return (
    <div>
      {bips.length === 0 ? (
        <NoDataCard
          title="You have no behaviors added"
          description="You must have at least one behavior to continue."
        />
      ) : (
        bips.map((bip, index) => (
          <BehaviorCard
            key={index}
            bip={bip}
            index={index}
            section={section}
            field={field}
            onRemove={removeBip}
            onSave={() => {
              onRefetch();
            }}
            isDisplayModeForReview={isDisplayModeForReview}
          />
        ))
      )}
      {!isDisplayModeForReview ? (
        <div className="flex flex-col md:flex-row md:justify-end">
          <Button
            onClick={addBip}
            disabled={isPending}
            text={
              <Small className="flex items-center px-2 text-secondary">
                <PlusIcon className="mr-2 h-4 w-4" />
                Add Behavior
              </Small>
            }
            buttonAction="destructive"
            appearance="secondary"
          />
        </div>
      ) : (
        ""
      )}
      <Dialog
        title="Delete BIP Record"
        overflowBehavior="scroll"
        open={open}
        setOpen={setOpen}
        primaryButton={{
          disabled: isPending,
          buttonAction: "destructive",
          text: isPending ? "Deleting..." : "Delete",
          onClick: deleteRecordFromDB,
        }}
        secondaryButton={{ text: "Return", onClick: () => setOpen(false) }}
        size="sm"
      >
        <Paragraph>
          Are you sure you would like to remove{" "}
          <b className="font-extrabold">
            {selectedIndex !== undefined ? `Target Behavior ${selectedIndex + 1}` : "<missing target behavior>"}
          </b>{" "}
          from the system?
        </Paragraph>
      </Dialog>
    </div>
  );
};

const BehaviorCard: React.FC<{
  bip: BipData;
  index: number;
  section: AssessmentReportHeadingType;
  field: AssessmentReportFieldsEnums;
  onRemove: (index: number) => void;
  onSave: () => void;
  isDisplayModeForReview?: boolean;
}> = ({ bip, index, section, field, onRemove, onSave, isDisplayModeForReview }) => (
  <div key={`Target-behavior-${index}`} className="-mt-2">
    <div className={`relative flex flex-row justify-between -mb-4 ${isDisplayModeForReview ? "" : "mx-4"}  `}>
      <Small
        displayType="normal"
        colorType="secondary"
        className={`content-center ${isDisplayModeForReview ? "h-14" : ""}`}
        data-target-behavior
      >
        {bip.title}
      </Small>
      {!isDisplayModeForReview ? (
        <Button
          onClick={() => onRemove(index)}
          text={
            <Small className="flex items-center px-3 text-red-700">
              <TrashIcon className="mr-2 h-4 w-4" />
              Remove Behavior
            </Small>
          }
          buttonAction="destructive"
          appearance="link"
          className="hover:no-underline"
        />
      ) : (
        ""
      )}
    </div>
    <SmartFieldWrapper
      key={`${section}-${field}`}
      field={field}
      section={section}
      existingContent={bip.content || ""}
      existingId={bip.id || ""}
      behaviorIndex={bip.dbIndex}
      onValueSave={onSave}
      isDisplayMode={isDisplayModeForReview}
      showTitle={false}
    />
  </div>
);

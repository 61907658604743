import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { LearnerAdditionalDetailsQuery, useLearnerAdditionalDetailsQuery } from "@api/graphql/types-and-hooks";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import {
  AdditionalDetailsData,
  additionalDetailsMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/AdditionalDetails/AdditionalDetails.languageMapping";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const getAdditionalDetailsData = (queryData: LearnerAdditionalDetailsQuery) => {
  return {
    ...((queryData.learnerById?.learnerAdditionalDetails as AdditionalDetailsData) || {}),
  };
};

export const AddditionalDetails: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/additional-details" : undefined;

  const config = useGetQueryConfig<LearnerAdditionalDetailsQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerAdditionalDetailsQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching: formLoading } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormInput, RegisteredFormTextArea, reset } =
    useFormUtils<AdditionalDetailsData>({
      mutationFn: async () => {
        await Promise.resolve();
      },
      displayOnlyMode: pathname.includes("all"),
    });

  useEffect(() => {
    if (data) {
      reset({
        ...getAdditionalDetailsData(data),
      });
    }
  }, [data, reset]);

  return (
    <FormContainer onSubmit={onSubmit}>
      <RegisteredFormTextArea
        label={
          additionalDetailsMapping["anythingThatCameUpToBeMentioned"]
            ? additionalDetailsMapping["anythingThatCameUpToBeMentioned"][chosenLanguage]
            : ""
        }
        readOnly
        formKey="anythingThatCameUpToBeMentioned"
        formState={formState}
      />
      <RegisteredFormTextArea
        label={
          additionalDetailsMapping["anythingThatWeDidNotCoverEnough"]
            ? additionalDetailsMapping["anythingThatWeDidNotCoverEnough"][chosenLanguage]
            : ""
        }
        readOnly
        formKey="anythingThatWeDidNotCoverEnough"
        formState={formState}
      />
      <RegisteredFormTextArea
        label={additionalDetailsMapping["mainConcerns"] ? additionalDetailsMapping["mainConcerns"][chosenLanguage] : ""}
        readOnly
        formKey="mainConcerns"
        formState={formState}
      />

      <RegisteredFormInput
        label={additionalDetailsMapping["whoReferredYou"]?.[chosenLanguage] ?? ""}
        readOnly
        formKey="whoReferredYou"
        formState={formState}
        isLoading={formLoading}
      />

      <RegisteredFormTextArea
        label={additionalDetailsMapping["mchatResultForClient"]?.[chosenLanguage] ?? ""}
        readOnly
        formKey="mchatResultForClient"
        formState={formState}
      />
    </FormContainer>
  );
};

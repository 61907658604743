import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { Dialog } from "@fronterahealth/frontera-ui-components";
import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";

import { useAdminData } from "@providers/AdminDataProvider";

interface WalkthroughDialogProps {}
declare global {
  interface Window {
    NavatticEmbed: { openPopup: (url: string) => void; initPopup: (url: string, config: object) => void };
  }
}

export const WalkthroughDialog: React.FC<WalkthroughDialogProps> = () => {
  const { isSelfServeUser } = useAdminData();
  const NavatticEmbed = window.NavatticEmbed;

  const [walkthroughTrigger, setWalkthroughTrigger] = useLocalStorage("will-trigger-walkthrough", true);

  const [searchParams, setSearchParams] = useSearchParams();
  const triggerWalkthroughStr = searchParams.get("trigger-walkthrough");
  const willTriggerWalkthroughFromParams =
    !!triggerWalkthroughStr &&
    typeof triggerWalkthroughStr === "string" &&
    triggerWalkthroughStr.toLowerCase() === "true";

  const [isDialogOpen, setDialogOpen] = useState(
    isSelfServeUser && (walkthroughTrigger === true || walkthroughTrigger === null || willTriggerWalkthroughFromParams),
  );

  useEffect(() => {
    const config = { title: "Self Service Get Started" };
    NavatticEmbed.initPopup("https://capture.navattic.com/clzd9s42a000409ku11zn6nfs", config);
  }, []);

  const beginWalkthrough = () => {
    if (willTriggerWalkthroughFromParams) {
      setSearchParams({});
    }

    setDialogOpen(false);
    setWalkthroughTrigger(false);
    NavatticEmbed.openPopup("https://capture.navattic.com/clzd9s42a000409ku11zn6nfs");
  };

  const setWalkthroughDialogOpen = (open: boolean) => {
    if (!open) {
      setWalkthroughTrigger(false);
      if (willTriggerWalkthroughFromParams) {
        setSearchParams({});
      }
    }

    setDialogOpen(open);
  };

  return (
    <Dialog
      title={""}
      open={isDialogOpen}
      setOpen={setWalkthroughDialogOpen}
      primaryButton={{
        text: "Get Started",
        onClick: beginWalkthrough,
      }}
      secondaryButton={{
        text: "Skip",
        onClick: () => setWalkthroughDialogOpen(false),
      }}
      size="md"
    >
      <div>
        <div className="flex justify-center mb-8 mt-[-32px]">
          <div className="w-48">
            <img src={logo} alt="" />
          </div>
        </div>
        <p className="text-center text-md font-semibold text-limestone-900 mb-4">
          Welcome to Frontera Health's Assessment Builder
        </p>
        <p className="text-center text-sm text-limestone-900">View our guided walkthrough to see how to get started.</p>
        <p className="text-center text-sm text-limestone-600">
          Note: You can return to this walkthrough at any time by selecting "Get Started" from the main navigation
        </p>
      </div>
    </Dialog>
  );
};

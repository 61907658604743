import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { Button, Divider, Heading, Small } from "@fronterahealth/frontera-ui-components";

import useGeneratePDFFile from "@components/PDFGenerator/useGeneratePDFFile";
import { AddditionalDetails } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/AdditionalDetails/AdditionalDetails";
import { Behaviors } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Behaviors/Behaviors";
import { EmergencyContact } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/ClientDetails/EmergencyContact";
import { GeneralDetails } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/ClientDetails/GeneralDetails";
import { Communication } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Communication/Communication";
import { DevelopmentalMilestone } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/DevelopmentalMilestone/DevelopmentalMilestone";
import { EarlyInterventionServices } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/EarlyInterventionServices/EarlyInterventionServices";
import { Education } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/Education/Education";
import { BirthHistory } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/BirthHistory/BirthHistory";
import { MedicalHistory } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/MedicalHistory/MedicalHistory";
import { GuardianDetails } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/HomeEnvironment/GuardianDetails";
import { LivingConditions } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/HomeEnvironment/LivingConditions";
import { Insurance } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Insurance/Insurance";
import { Tendencies } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Tendencies/Tendencies";
import { AllRoutes } from "@pages/ClientDetailsSubPages/IntakeForm/Links/config";
import { getCurrentPageTitle } from "@pages/ClientDetailsSubPages/IntakeForm/Links/utils";

export const IntakeFormPageSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const { generatePDFFile, loading } = useGeneratePDFFile();

  return (
    <div>
      <Heading className="mb-4" type="h1">
        {pathname.includes("all") ? "All Fields" : getCurrentPageTitle(pathname.split("intake_form/").pop() || "")}
        {pathname.includes("all") ? (
          <Small displayType="normal" className="my-2 flex flex-row justify-between align-middle">
            <i>
              You can use <code>ctrl/cmd + f</code> to search for any values across the clients intake form here
            </i>
            <Button
              text={loading ? "Loading...." : "Download PDF"}
              appearance="soft"
              onClick={async () => {
                const element = document.getElementById("allIntakeForms");

                const file = await generatePDFFile(element, ["btnRow", "add", "remove"]);
                if (file) {
                  const url = URL.createObjectURL(file);
                  window.open(url);
                }
              }}
            />
          </Small>
        ) : null}
      </Heading>
      <Content />
    </div>
  );
};

const Content = () => {
  const { pathname } = useLocation();

  const intakeFormPath = pathname.split("intake_form/").pop();

  switch (intakeFormPath as AllRoutes) {
    case "all":
      return (
        <div id="allIntakeForms">
          <div className="pageBreak">
            <Heading type="h3">General Details</Heading>
            <GeneralDetails />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Emergency Contact</Heading>
            <EmergencyContact />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Guardian Details</Heading>
            <GuardianDetails />
            <Divider />
          </div>

          <div className="pageBreak">
            <Heading type="h3">Living Conditions</Heading>
            <LivingConditions />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Insurance</Heading>
            <Insurance />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Medical History</Heading>
            <MedicalHistory />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Birth History</Heading>
            <BirthHistory />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Education</Heading>
            <Education />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Early Intervention Services</Heading>
            <EarlyInterventionServices />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Developmental Milestones</Heading>
            <DevelopmentalMilestone />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Behaviors</Heading>
            <Behaviors />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Communication</Heading>
            <Communication />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Tendencies</Heading>
            <Tendencies />
            <Divider />
          </div>
          <div className="pageBreak">
            <Heading type="h3">Additional Details</Heading>
            <AddditionalDetails />
            <Divider />
          </div>
        </div>
      );
    case "client-details":
      return <Navigate to="general-details" />;
    case "client-details/general-details":
      return <GeneralDetails />;
    case "client-details/emergency-contact":
      return <EmergencyContact />;
    case "home-environment/guardian-details":
      return <GuardianDetails />;
    case "home-environment/living-conditions":
      return <LivingConditions />;
    case "insurance":
      return <Insurance />;
    case "health/medical-history":
      return <MedicalHistory />;
    case "health/birth-history":
      return <BirthHistory />;
    case "development/education":
      return <Education />;
    case "development/early-intervention-services":
      return <EarlyInterventionServices />;
    case "development/developmental-milestones":
      return <DevelopmentalMilestone />;

    case "behaviors/diet":
    case "behaviors/general":
    case "behaviors/interests":
    case "behaviors/movement-&-play":
    case "behaviors/sensory":
    case "behaviors/sleep":
      return <Behaviors />;
    case "communication/non-verbal-communication":
    case "communication/verbal-communication":
      return <Communication />;
    case "tendencies/aggression":
    case "tendencies/attachment":
    case "tendencies/attention":
    case "tendencies/defiance":
    case "tendencies/executive-functions":
    case "tendencies/language":
    case "tendencies/mood":
    case "tendencies/odd-ideas":
    case "tendencies/social-skills":
    case "tendencies/somatic":
    case "tendencies/strengths":
    case "tendencies/trauma":
      return <Tendencies />;
    case "additional-details":
      return <AddditionalDetails />;
    default:
      return null;
  }
};

import { datadogRum } from "@datadog/browser-rum";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Badge, Button, Collapsible, Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { AiPredictionOfEnum, AiReportPredictionOfEnum } from "@api/graphql/types-and-hooks";

export type GenerationPages =
  | AiReportPredictionOfEnum.AssessmentReport_1
  | AiReportPredictionOfEnum.AssessmentReport_2
  | AiPredictionOfEnum.LongTermGoal
  | AiPredictionOfEnum.ShortTermGoal;
interface Props {
  errorMessage?: string | null;
  page: GenerationPages;
}

export const GenerationTypeLabelMapping: { [key in GenerationPages]: string } = {
  [AiReportPredictionOfEnum.AssessmentReport_1]: "Report Part 1",
  [AiReportPredictionOfEnum.AssessmentReport_2]: "Report Part 2",
  [AiPredictionOfEnum.LongTermGoal]: "Long Term Goals",
  [AiPredictionOfEnum.ShortTermGoal]: "Short Term Goals",
};
export const GenerationErrorPage: React.FC<Props> = ({ errorMessage, page }) => {
  useEffect(() => {
    datadogRum.addAction("ab-error-page-loaded", {
      abErrorPage: {
        errorMessage,
        page,
      },
    });
  }, [errorMessage]);
  const go = useNavigate();
  return (
    <div className="mt-10 flex flex-col items-center justify-center gap-y-4">
      <ExclamationTriangleIcon className="h-14 w-14 text-red-500" />

      <Heading type="h4">Uh oh... Something went wrong on our end generating your report.</Heading>
      <Paragraph displayType="normal" colorType="secondary" className="max-w-[884px] my-2 text-center">
        We had some trouble with {GenerationTypeLabelMapping[page]}. We're most likely already working on this. In the
        mean time you can try going to the previous step and triggering our workflow again or reaching out to Frontera
        Support.
      </Paragraph>

      <div className="flex flex-col gap-y-2 items-start min-w-[380px] max-w-[380px]  ">
        <div className="grid grid-cols-2 gap-2 ">
          <div className="flex items-center justify-end">
            <Small displayType="loud">{GenerationTypeLabelMapping[page]}: </Small>
          </div>
          <div className="flex items-center justify-start">
            <Badge dot text={"Errored Out"} appearance={"error"} />
          </div>
        </div>
        <div className="flex items-center justify-start min-w-[380px]">
          <Collapsible
            headerContent={
              <div className="">
                <Small displayType="loud">What Went Wrong</Small>
              </div>
            }
            bodyContent={
              <div className="max-w-[350px] py-4 px-2  text-red-700">
                <Small className="text-red-700">
                  <b>Error Reason: </b>
                  {errorMessage}
                </Small>
              </div>
            }
          />
        </div>
      </div>
      <Button
        appearance="primary"
        text="Return to previous step"
        onClick={() => {
          go(-1);
        }}
      />
    </div>
  );
};

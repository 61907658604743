import { PropsWithChildren } from "react";

import { Dialog } from "@fronterahealth/frontera-ui-components";

import { FolderManagementAction } from "@components/GoalsBank/constants";

export interface FolderManagementDialogProps extends PropsWithChildren {
  onSaveClick: () => void;
  onCancelClick: () => void;
  open: boolean;
  action: FolderManagementAction;
}

export const FolderManagementDialog: React.FC<FolderManagementDialogProps> = ({
  onSaveClick,
  onCancelClick,
  open,
  action,
  children,
}) => {
  const title = () => {
    switch (action) {
      case FolderManagementAction.MOVE:
        return "Move to Folder";
      case FolderManagementAction.SAVE:
        return "Save to Folder";
    }
  };

  return (
    <Dialog
      showCloseButton={false}
      open={open}
      setOpen={() => {}}
      title={title()}
      primaryButton={{
        text: "Confirm",
        onClick: (e) => {
          e?.stopPropagation();
          onSaveClick();
        },
        type: "submit",
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: (e) => {
          e?.stopPropagation();
          onCancelClick();
        },
      }}
      size="sm"
    >
      {children}
    </Dialog>
  );
};

// import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";
import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Link, Outlet, useLocation } from "react-router-dom";

import {
  Button,
  Paragraph,
  SkeletonLoader,
  TrainStopProgressNav,
  TrainStopProgressStep,
} from "@fronterahealth/frontera-ui-components";

import {
  ApiAssessmentAssessmentStatusChoices,
  AssessmentStatusEnums,
  useUpdateAssessmentMutation,
} from "@api/graphql/types-and-hooks";
import ExportWithTemplate from "@components/PDFGenerator/ExportWithTemplate";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

export type AssessmentReportSubRoute =
  | "client-details"
  | "upload-files"
  | "report-summary-part-1"
  | "prioritize"
  | "long-term-goals"
  | "short-term-goals"
  | "report-summary-part-2"
  | "review-and-sign";

export const routeLabelMapping: { [key in AssessmentReportSubRoute]: string } = {
  "client-details": "Details",
  "upload-files": "Upload Files",
  "report-summary-part-1": "Report Part 1",
  prioritize: "Prioritize",
  "long-term-goals": "Long Term Goals",
  "short-term-goals": "Short Term Goals",
  "report-summary-part-2": "Report Part 2",
  "review-and-sign": "Review",
};

export const orderedAssessmentReportSubRoutes: AssessmentReportSubRoute[] = [
  "client-details",
  "upload-files",
  "report-summary-part-1",
  "prioritize",
  "long-term-goals",
  "short-term-goals",
  "report-summary-part-2",
  "review-and-sign",
];

export const getNextRoute = (route: AssessmentReportSubRoute): AssessmentReportSubRoute | null => {
  switch (route) {
    case "client-details":
      return "upload-files";
    case "upload-files":
      return "report-summary-part-1";
    case "report-summary-part-1":
      return "prioritize";
    case "prioritize":
      return "long-term-goals";
    case "long-term-goals":
      return "short-term-goals";
    case "short-term-goals":
      return "report-summary-part-2";
    case "report-summary-part-2":
      return "review-and-sign";
    case "review-and-sign":
      return "review-and-sign";
    default:
      return null;
  }
};

export const getPastRoute = (route: AssessmentReportSubRoute): AssessmentReportSubRoute | null => {
  switch (route) {
    case "upload-files":
      return "client-details";
    case "report-summary-part-1":
      return "upload-files";
    case "prioritize":
      return "report-summary-part-1";
    case "long-term-goals":
      return "prioritize";
    case "short-term-goals":
      return "long-term-goals";
    case "report-summary-part-2":
      return "short-term-goals";
    case "review-and-sign":
      return "report-summary-part-2";
    case "client-details":
      return null;
    default:
      return null;
  }
};

const statusFromCurrentRoute = (
  currentRoute: AssessmentReportSubRoute,
  navStepRoute: AssessmentReportSubRoute,
): TrainStopProgressStep["status"] => {
  return currentRoute === navStepRoute
    ? "current"
    : orderedAssessmentReportSubRoutes.indexOf(currentRoute) > orderedAssessmentReportSubRoutes.indexOf(navStepRoute)
      ? "complete"
      : "upcoming";
};

export const AssessmentReportDetails: React.FC = () => {
  const { pathname, state } = useLocation();
  const { assessmentReport, refetch } = useAssessmentBuilderData();
  const flags = useFlags<FeatureFlags>();

  const updateAssessment = useUpdateAssessmentMutation({});

  const { isPending } = updateAssessment;

  const updateAssessmentStatus = async () => {
    await updateAssessment
      .mutateAsync({
        assessment: assessmentReport.id,
        assessmentStatus: AssessmentStatusEnums.Draft,
      })
      .then((data) => {
        if (data.updateAssessment?.status) {
          refetch();
        }
      });
  };

  if (!assessmentReport) return null;

  const reportIsCompleted = assessmentReport.assessmentStatus === ApiAssessmentAssessmentStatusChoices.Completed;

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as AssessmentReportSubRoute) ||
    ("upload-files" as AssessmentReportSubRoute);

  const configuredClient = Boolean(assessmentReport?.assessmentClientDetails ?? false);
  const clientName = `${assessmentReport?.assessmentClientDetails?.clientFirstName || "N/A"} ${assessmentReport?.assessmentClientDetails?.clientLastName || ""}`;

  return (
    <div className="-mt-12 flex w-full flex-col items-start justify-between">
      <div className="d-flex flex-col sticky top-4 pt-3  w-full z-20">
        <div className="-mt-7 flex flex-col gap-y-2 bg-white -ml-6 w-[calc(100%+48px)] p-4 ">
          <div className="flex w-full items-center justify-between px-2">
            <SkeletonLoader loading={!assessmentReport}>
              <Paragraph colorType="primary" displayType="loud">
                {clientName} Assessment Report{" "}
              </Paragraph>
            </SkeletonLoader>
            {reportIsCompleted && (
              <div>
                <Button
                  text="Edit"
                  disabled={isPending}
                  appearance="secondary"
                  onClick={() => updateAssessmentStatus()}
                  className="mr-2 ring-green-400"
                />
                {flags["report-export"] ? (
                  <ExportWithTemplate assessmentData={assessmentReport} />
                ) : (
                  <Button
                    text="Export"
                    appearance="primary"
                    onClick={() => {
                      if (assessmentReport.assessmentReportSignedUrl) {
                        window.open(assessmentReport.assessmentReportSignedUrl);
                      }
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {assessmentReport?.assessmentStatus !== ApiAssessmentAssessmentStatusChoices.Completed ? (
          <div className="-ml-8 w-[calc(100%+64px)] bg-background-secondary">
            <TrainStopProgressNav
              steps={orderedAssessmentReportSubRoutes.map((route, index) => ({
                navigationToEnabled: true,
                index,
                name: routeLabelMapping[route],
                status: statusFromCurrentRoute(currentRoute, route),
                to: route,
                Component: ({ children, className, to }) => (
                  <Link
                    to={to}
                    className={configuredClient ? className : `${className} pointer-events-none`}
                    state={state}
                  >
                    {children}
                  </Link>
                ),
              }))}
            />
          </div>
        ) : null}
      </div>
      <div className="w-full py-6 h-full">
        <Outlet />
      </div>
    </div>
  );
};

import { ApiSessionEngagementInsightInsightTypeChoices } from "@api/graphql/types-and-hooks";
import { renderTooltip } from "@pages/SessionAnalyzer/SessionDetails/SocialEngagement/GraphTooltip";
import { AnnotationType } from "@pages/SessionAnalyzer/SessionDetails/SocialEngagement/SocialEnagagement";

export interface positionType {
  left: number;
  top: number;
}

export const getChartOptions = (
  series: ApexAxisChartSeries,
  annotationData: AnnotationType[],
  selectedDataPointIndex: number | null,
  handleAnnotationClick: (data: AnnotationType, index: number, position: positionType) => void,
  isTooltipInteracting: boolean,
  setisTooltipInteracting: (val: boolean) => void,
  videoDuration: number,
  handleToPlay: (time: number) => void,
): ApexCharts.ApexOptions => {
  let position = { left: 0, top: 0 };
  return {
    chart: {
      height: 246,
      type: "line",
      id: "lineChart",
      zoom: {
        enabled: false,
      },
      animations: { enabled: false },
      toolbar: {
        show: false,
      },
      stacked: true,
      events: {
        click: (_event, _chartContext, config) => {
          const { seriesIndex, dataPointIndex } = config;
          const dataPoint = series[seriesIndex]?.data[dataPointIndex];
          let xValue = 0;

          if (typeof dataPoint === "object" && dataPoint !== null && "x" in dataPoint) {
            xValue = dataPoint.x;
          } else if (typeof dataPoint === "number") {
            xValue = dataPoint;
          }

          const isAnnotationAtPoint = annotationData.some((annotation) => {
            const annotationX = annotation?.startTime ? annotation.startTime / 60 : 0;

            return annotationX === xValue;
          });

          if (isAnnotationAtPoint) {
            return;
          }

          const minutes = Math.floor(xValue);
          const seconds = Math.round((xValue - minutes) * 60);
          const totalSeconds = minutes * 60 + seconds;
          handleToPlay(totalSeconds);
        },
      },
    },
    series: series,
    markers: {
      size: 1,
      strokeWidth: 4,
      strokeColors: "#4E7E6C",
      showNullDataPoints: true,
      hover: {
        sizeOffset: 3,
        size: 0,
      },
      shape: "line",
    },
    colors: ["#4E7E6C"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 4,
    },
    grid: {
      strokeDashArray: 4,
      padding: {
        right: 30,
        left: 20,
      },
    },
    title: {
      text: "% Engagement",
      align: "left",
      style: {
        fontSize: "18px",
        color: "#4E4440",
        fontWeight: 600,
      },
    },
    xaxis: {
      type: "category",
      tickAmount: videoDuration > 900 ? 15 : undefined,
      title: {
        text: "Time (min)",
      },
      labels: {
        show: true,
        showDuplicates: false,
        style: {
          cssClass: "custom-axis-label",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      stepSize: 10,
      show: true,
      labels: {
        style: {
          cssClass: "custom-axis-label",
        },
        formatter(value) {
          return `${value}%`;
        },
      },
    },
    tooltip: {
      enabled: true,

      x: {
        show: true,
        formatter: (value: number) => {
          const minutes = Math.floor(value);
          const seconds = Math.round((value - minutes) * 60);
          return `Click to play ${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
        },
      },
      y: {
        formatter: function (value) {
          return `${value}%`;
        },
      },
      onDatasetHover: {
        highlightDataSeries: true,
      },
      intersect: false,
      shared: true,
      custom: ({ series: sr, seriesIndex, dataPointIndex, w }) => {
        const value = sr[seriesIndex][dataPointIndex];
        const chart = w.globals.dom.baseEl.getBoundingClientRect();
        const currentDataPoint = series[seriesIndex]?.data[dataPointIndex];
        let currentX: number | null = null;

        if (typeof currentDataPoint === "object" && currentDataPoint !== null && "x" in currentDataPoint) {
          currentX = currentDataPoint.x;
        } else if (typeof currentDataPoint === "number") {
          currentX = currentDataPoint;
        }
        const isAvailableX = annotationData.find((item) => parseFloat((item.startTime / 60).toFixed(2)) === currentX);

        const tempTooltip = document.createElement("div");

        tempTooltip.style.position = "absolute";
        tempTooltip.style.visibility = "hidden";
        tempTooltip.innerHTML = isAvailableX
          ? renderTooltip({
              engagement: value,
              isTooltipInteracting,
              setIsTooltipInteracting: setisTooltipInteracting,
              endTimeInSeconds: isAvailableX.endTime || 0,
              timeInSeconds: isAvailableX.startTime,
              insight: isAvailableX.engagementInsight,
              engagementType: isAvailableX.engagementType || "",
            })
          : "";

        document.body.appendChild(tempTooltip);

        // Measure tooltip dimensions
        const tooltipWidth = tempTooltip.offsetWidth;
        const tooltipHeight = tempTooltip.offsetHeight;
        document.body.removeChild(tempTooltip);

        let top = w.globals.clientY - chart.height;
        let left = w.globals.clientX - chart.left + 10;

        // Adjust for boundary conditions

        if (left + tooltipWidth > chart.width) {
          left = w.globals.clientX - chart.left - tooltipWidth;
        }
        if (top + tooltipHeight > chart.height) {
          top = chart.height - tooltipHeight;
        }
        if (top < 0 || top > 100) {
          top = 50;
        }

        position = {
          top: top,
          left: left,
        };

        return isAvailableX && !isTooltipInteracting
          ? renderTooltip({
              engagement: value,
              isTooltipInteracting,
              setIsTooltipInteracting: setisTooltipInteracting,
              endTimeInSeconds: isAvailableX.endTime || 0,
              timeInSeconds: isAvailableX.startTime,
              insight: isAvailableX.engagementInsight,
              engagementType: isAvailableX.engagementType || "",
            })
          : "";
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetX: -10,
      showForSingleSeries: true,
      onItemClick: {
        toggleDataSeries: false,
      },

      fontSize: "14px",
      fontWeight: 400,
      itemMargin: {
        horizontal: 8,
      },
      markers: {
        fillColors: ["#4E7E6C", "#4E7E6C"],
        shape: ["circle", "square"],
        offsetX: -3,
      },
      customLegendItems: ["Engagement", "Disengagement"],
    },

    annotations: {
      points: annotationData.map((point, index) => {
        const time = point?.startTime ? point.startTime / 60 : 0;
        return {
          x: time,
          y: point.engagement,
          marker: {
            shape:
              point?.engagementType === ApiSessionEngagementInsightInsightTypeChoices.Disengagement
                ? "square"
                : "circle",
            size: 10,
            fillColor: index === selectedDataPointIndex ? "#355449" : "#4E7E6C",
            strokeWidth: 3,
            strokeColor: "#fff",
          },
          click: () => {
            handleAnnotationClick(point, index, position);
          },
        };
      }),
    },
  };
};

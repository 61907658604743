import { useEffect, useState } from "react";

import { CheckboxButtonList, Dialog, Input, Paragraph } from "@fronterahealth/frontera-ui-components";

import { useAddAssessmentFeedbackMutation, useAddSessionAnalyzerFeedbackMutation } from "@api/graphql/types-and-hooks";
import { notifyError } from "@components/notifications/notifications";

export type AiFeedbackType = "ltg" | "stg" | "reportField" | "target" | "sessionAnalyzer";

interface AiFeedbackDialogProps {
  setDialogOpen: (open: boolean) => void;
  isDialogOpen: boolean;
  objectType: AiFeedbackType;
  objectId: string | null;
  positiveFeedback: boolean;
  promptQuestion?: string;
  promptAnswer?: string;
  time?: string;
  handleSubmittedFeedback?: () => void;
}

interface CheckboxItem {
  id: string;
  title: string;
}

export const AiFeedbackDialog: React.FC<AiFeedbackDialogProps> = ({
  isDialogOpen,
  setDialogOpen,
  objectType,
  objectId,
  time,
  positiveFeedback,
  promptQuestion = "",
  promptAnswer = "",
  handleSubmittedFeedback,
}) => {
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [suggestion, setSuggestion] = useState<string>("");
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const addAiFeedbackMutation = useAddAssessmentFeedbackMutation({});
  const { data, isPending, error } = addAiFeedbackMutation;

  const addSessionAnalyzerFeedback = useAddSessionAnalyzerFeedbackMutation({});
  const {
    data: sessionFeedbackData,
    isPending: isSessionFeedbackpending,
    error: sessionFeedbackError,
  } = addSessionAnalyzerFeedback;
  const items: CheckboxItem[] = positiveFeedback
    ? [
        { id: "Accurate Response", title: "Accurate Response" },
        { id: "Good Length", title: "Good Length" },
        { id: "Innovative", title: "Innovative" },
        { id: "Contextually Appropriate", title: "Contextually Appropriate" },
        { id: "Up to Date", title: "Up to Date" },
        { id: "Helpful", title: "Helpful" },
      ]
    : [
        { id: "Too Long", title: "Too Long" },
        { id: "Too Short", title: "Too Short" },
        { id: "Harmful or Dangerous", title: "Harmful or Dangerous" },
        { id: "Inaccurate", title: "Inaccurate" },
        { id: "Out of Date", title: "Out of Date" },
        { id: "Not Helpful", title: "Not Helpful" },
      ];

  useEffect(() => {
    setSuggestion("");
    setSelectedList([]);
    if (isDialogOpen) {
      setIsFormSubmitted(false);
    }
  }, [isDialogOpen]);

  useEffect(() => {
    if (error || sessionFeedbackError) {
      console.error("Error while adding AI feedback", error);
      notifyError("Error saving feedback");
    }
  }, [error, sessionFeedbackError]);

  useEffect(() => {
    if (!data || !sessionFeedbackData) return;

    if (data.addAssessmentFeedback?.status || sessionFeedbackData.addSessionAnalyzerFeedback?.status) {
      setIsFormSubmitted(true);
    } else {
      notifyError(data.addAssessmentFeedback?.message || "Error saving feedback");
    }
  }, [data, sessionFeedbackData]);

  const handleSecondaryButtonClick = () => {
    setDialogOpen(false);
  };

  const primaryButtonDisableStatus = () => {
    return !selectedList.length;
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedList((prevSelectedValues) =>
      prevSelectedValues.includes(value)
        ? prevSelectedValues.filter((item) => item !== value)
        : [...prevSelectedValues, value],
    );
  };

  const submitAiFeedback = async () => {
    try {
      await addAiFeedbackMutation.mutateAsync({
        assessmentFeedbackDetails: {
          reportField: objectType === "reportField" ? objectId : null,
          longTermGoal: objectType === "ltg" ? objectId : null,
          shortTermGoal: objectType === "stg" ? objectId : null,
          target: objectType === "target" ? objectId : null,
          promptQuestion: promptQuestion,
          promptAnswer: promptAnswer,
          improveOptions: selectedList,
          improveText: suggestion,
          positiveFeedback: positiveFeedback,
        },
      });
    } catch (err) {
      console.error("Submission error", err);
    }
  };

  const submitSessionAnalyzerFeedback = async () => {
    try {
      await addSessionAnalyzerFeedback
        .mutateAsync({
          sessionAnalyzerFeedbackDetails: {
            therapySession: objectId || "",
            videoTimestamp: time,
            improveOptions: selectedList,
            improveText: suggestion,
            positiveFeedback: positiveFeedback,
            version: "1",
          },
        })
        .then(() => {
          setIsFormSubmitted(true);
          if (handleSubmittedFeedback) {
            handleSubmittedFeedback();
          }
        });
    } catch (err) {
      console.error("Submission error", err);
    }
  };

  return (
    <Dialog
      title="Help Us Improve"
      open={isDialogOpen}
      setOpen={setDialogOpen}
      primaryButton={
        isFormSubmitted
          ? { text: "Close", onClick: () => setDialogOpen(false) }
          : {
              text: isPending || isSessionFeedbackpending ? "Saving..." : "Continue",
              onClick: objectType === "sessionAnalyzer" ? submitSessionAnalyzerFeedback : submitAiFeedback,
              disabled: primaryButtonDisableStatus(),
            }
      }
      secondaryButton={{ text: isFormSubmitted ? "" : "Cancel", onClick: handleSecondaryButtonClick }}
      size="xl"
      overflowBehavior="scroll"
    >
      {isFormSubmitted ? (
        <div>
          <Paragraph displayType="normal" colorType="secondary">
            Thank you for your feedback!
          </Paragraph>
        </div>
      ) : (
        <div>
          <Paragraph displayType="normal" colorType="secondary">
            Why did you choose this rating?
          </Paragraph>
          <CheckboxButtonList title="" legend="What did you dislike?" items={items} onChange={handleCheckboxChange} />

          <Input
            label="How else can we improve?"
            name="suggestion"
            placeholder="Your feedback..."
            required={false}
            inputSize="full"
            hintText="Optional"
            value={suggestion}
            onChange={(e) => setSuggestion(e.target.value)}
          />
        </div>
      )}
    </Dialog>
  );
};

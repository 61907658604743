import { format } from "date-fns";

import { Badge, BadgeProps, Table } from "@fronterahealth/frontera-ui-components";

export const STATUS_VALUES = [
  "Client Details Needed",
  "At Upload Files",
  "Completed",
  "At Report Page 1",
  "At Report Page 2",
  "At Conclusions",
  "At Recommendations",
  "Generating Report Page 1",
  "Generating Report Page 2",
  "Generating Conclusions",
  "Generating Recommendations",
  "Error on Report Page 1",
  "Error on Report Page 2",
  "Error on Conclusions",
  "Error on Recommendations",
] as const;
export type StatusType = (typeof STATUS_VALUES)[number];

const BadgeSettingFromStatus: { [key in StatusType]: BadgeProps["appearance"] } = {
  "Generating Report Page 1": "ai",
  "Generating Report Page 2": "ai",
  "Generating Conclusions": "ai",
  "Generating Recommendations": "ai",
  "At Report Page 1": "neutral",
  "At Report Page 2": "neutral",
  "At Conclusions": "neutral",
  "At Recommendations": "neutral",
  Completed: "success",
  "Error on Report Page 1": "error",
  "Error on Report Page 2": "error",
  "Error on Conclusions": "error",
  "Error on Recommendations": "error",
  "At Upload Files": "neutral",
  "Client Details Needed": "warn",
};

export interface Evaluation {
  id: string;
  clientName: string;
  dateCreated: Date;
  lastUpdated: Date;
  status: StatusType;
  pagesDone: number;
}

interface EvaluationsTableProps {
  data: (Evaluation | null)[];
  onRowClick: (report: Evaluation) => void;
  loading?: boolean;
  refetch: () => Promise<unknown>;
}

export const EvaluationsTable: React.FC<EvaluationsTableProps> = ({ data, onRowClick, loading }) => {
  return (
    <div className="w-full bg-white mt-8 rounded-lg">
      <Table
        dataTestId={"evaluations-table"}
        onRowClick={onRowClick}
        loading={loading}
        defaultItemsPerPage={10}
        showSearch
        inputClassName="w-96"
        showPagination
        itemsPerPageOptions={[5, 10, 20, 50]}
        actions={[
          {
            label: "View",
            callback: (rowData) => onRowClick(rowData),
          },
        ]}
        data={(data.filter((evaluation) => evaluation !== null) as Evaluation[]).sort((evaluationA, evaluationB) =>
          evaluationB.lastUpdated > evaluationA.lastUpdated ? 1 : -1,
        )}
        columns={[
          { dataIndex: "clientName", key: "clientName", title: "Client Name" },
          {
            dataIndex: "dateCreated",
            key: "dateCreated",
            title: "Date Created",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
          {
            dataIndex: "lastUpdated",
            key: "lastUpdated",
            title: "Last Updated",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
          {
            dataIndex: "status",
            key: "status",
            title: "Status",
            render: (value) => {
              return <Badge appearance={BadgeSettingFromStatus[value as StatusType]} text={value as StatusType} />;
            },
          },
        ]}
      />
    </div>
  );
};

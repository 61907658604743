import { useEffect } from "react";
import {
  FieldValues,
  FormState,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import PhoneInput, { FeatureProps, Value } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { Input } from "@fronterahealth/frontera-ui-components";

import { getErrorFromFormKey } from "@components/forms/utils";

interface Props<T extends FieldValues> extends FeatureProps<{ label: string }> {
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  clearErrors: UseFormClearErrors<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  required?: boolean;
}

export const FormPhoneNumberInput = <T extends FieldValues>({
  formState,
  formKey,
  watch,
  register,
  setValue,
  trigger,
  required = true,
  label,
  ...props
}: Props<T>) => {
  const value = watch(formKey);
  const errorMessage = getErrorFromFormKey(formKey, formState.errors);

  useEffect(() => {
    register(formKey, {
      required: {
        value: required,
        message: `${label} is required`,
      },
    });
  }, [register, required]);

  const onChange = (val: Value) => {
    setValue(formKey, val as PathValue<T, Path<T>>);
    trigger(formKey);
  };

  return (
    <PhoneInput
      numberInputProps={{ label, helpText: value, errorMessage }}
      inputComponent={Input}
      placeholder="Enter phone number"
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

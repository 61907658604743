import { config } from "@config/config";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Badge,
  Button,
  GoalOverview,
  GoalOverviewItem,
  Heading,
  Paragraph,
  Small,
} from "@fronterahealth/frontera-ui-components";

import {
  AiReportPredictionOfEnum,
  ApiAiSuggestedTargetsTimelineEstimationTypeChoices,
  ApiAssessmentAiReport2PredictionStatusChoices,
  ApiLongTermGoalGoalTypeChoices,
  ApiLongTermGoalTimelineEstimationTypeChoices,
  ApiShortTermGoalTimelineEstimationTypeChoices,
  ApiTargetTimelineEstimationTypeChoices,
  AssessmentReportFieldsEnums,
  GetLongTermShortTermGoalsQuery,
  useAssessmentReportFieldsQuery,
  useGetAssessmentQuery,
  useGetLongTermShortTermGoalsQuery,
} from "@api/graphql/types-and-hooks";
import { convertReadableString } from "@components/forms/utils";
import { FooterButtonRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/FooterButtonRow";
import { GenerationErrorPage } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/GenerationErrorPage";
import { SmartFieldWrapper } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/SmartFieldWrapper";
import {
  headerToIconMapping,
  headerToListOfFieldsMapping,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/assessmentFieldEnumMapping";
import {
  HeadingSection,
  StickyNav,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/StickyNav/StickyNav";
import { ReportNotReadyYet } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/components/ReportNotReadyYet";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

export const AssessmentReportHeadings = [
  "Behavior Reduction Goals",
  "Replacement Behavior Goals",
  "Skill Acquisition Goals",
  "Parent / Caregiver Goals",
  "Coordination of Care",
  "Program Recommendations",
  "Service Reduction and Termination",
  "Risk Evaluation & Emergency Plan",
  "Program Contacts for Clients",
  "References",
] as const;

export type AssessmentReportHeadingType = (typeof AssessmentReportHeadings)[number];

const convertNullToPlaceholder = (
  val1: number | null | undefined,
  val2:
    | ApiTargetTimelineEstimationTypeChoices
    | ApiLongTermGoalTimelineEstimationTypeChoices
    | ApiAiSuggestedTargetsTimelineEstimationTypeChoices
    | ApiShortTermGoalTimelineEstimationTypeChoices
    | null
    | undefined,
  placeholder: string,
): string => {
  if (val1 && val2) {
    return `${val1} ${convertReadableString(val2)}`;
  } else if (val1) {
    return `${val1}`;
  } else if (val2) {
    return `${convertReadableString(val2)}`;
  } else {
    return placeholder;
  }
};

export const LTG_STG_Targets_To_Overview = (data: GetLongTermShortTermGoalsQuery | undefined): GoalOverviewItem[] => {
  return (
    data?.getAssessments?.edges?.[0]?.node?.longtermgoalSet?.edges
      ?.map((edge) => edge?.node)
      ?.map((ltg) => {
        return {
          description: ltg?.description || "<missing-goal-description>",
          goalName: ltg?.goalName || "<missing-goal-name>",
          goalType: ltg?.goalType || "<missing-goal-type>",
          masteryCriteria: ltg?.masteryCriteria || "<missing-mastery-criteria>",
          timelineEstimation: convertNullToPlaceholder(
            ltg?.timelineEstimationValue,
            ltg?.timelineEstimationType,
            "<missing-timeline-estimation>",
          ),
          baselineData: ltg?.baselineData || "<missing-baseline-data>",
          establishBaselineOnTreatment: ltg?.establishBaselineOnTreatment ? "Yes" : "No",
          programGoal: ltg?.programGoal || "<missing-program-goal>",
          type: "ltg",
          children: ltg?.shorttermgoalSet?.edges
            ?.map((edge) => edge?.node)
            ?.map((stg) => {
              const targets: GoalOverviewItem[] =
                stg?.targetSet?.edges
                  ?.map((edge) => edge?.node)
                  ?.map((target) => {
                    return {
                      description: target?.targetDescription || "<missing-target-description>",
                      goalName: target?.targetName || "<missing-target-name>",
                      goalType: ltg?.goalType || "<missing-goal-type>",
                      programGoal: target?.targetGoal || "<missing-target-goal>",
                      masteryCriteria: target?.masteryCriteria || "<missing-target-mastery-criteria>",
                      baselineData: target?.baselineData || "<missing-baseline-data>",
                      establishBaselineOnTreatment: target?.establishBaselineOnTreatment ? "Yes" : "No",
                      timelineEstimation: convertNullToPlaceholder(
                        target?.timelineEstimationValue,
                        target?.timelineEstimationType,
                        "<missing-target-timeline-estimation>",
                      ),
                      type: "target",
                    };
                  }) || [];
              return {
                description: stg?.description || "<missing-goal-description>",
                goalName: stg?.goalName || "<missing-goal-name>",
                goalType: ltg?.goalType || "<missing-goal-type>",
                masteryCriteria: stg?.masteryCriteria || "<missing-mastery-criteria>",
                timelineEstimation: convertNullToPlaceholder(
                  stg?.timelineEstimationValue,
                  stg?.timelineEstimationType,
                  "<missing-timeline-estimation>",
                ),
                programGoal: stg?.programGoal || "<missing-program-goal>",
                type: "stg",
                baselineData: stg?.baselineData || "<missing-baseline-data>",
                establishBaselineOnTreatment: stg?.establishBaselineOnTreatment ? "Yes" : "No",
                children: targets,
              };
            }),
        };
      }) || []
  );
};

type ReportSummaryPart2Props = {
  isDisplayModeForReview: boolean;
};
export const ReportSummaryPart2: React.FC<ReportSummaryPart2Props> = ({ isDisplayModeForReview }) => {
  const { assessmentId } = useParams();
  const go = useNavigate();
  const { assessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || "<missing-learner-id>";

  const { data: assessmentQueryData } = useGetAssessmentQuery(
    {
      assessmentId: assessmentId || "<missing-assessment-id>",
    },
    {
      queryKey: ["get-assessment", assessmentId],
      refetchOnWindowFocus: false,
      refetchInterval: (query) => {
        const aiReportPart2PredictionStatus = query.state.data?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
          ?.aiReport2PredictionStatus;
        if (
          aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPending
        ) {
          return config.REPORT_POLLING_INTERVAL;
        } else {
          return false;
        }
      },
    },
  );

  const aiReportPart2PredictionStatus = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.aiReport2PredictionStatus;

  const reportStillGenerating =
    !isDisplayModeForReview &&
    aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPending;

  const reportDone =
    aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPredicted;

  const reportNotYetKickedOff =
    !isDisplayModeForReview && aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.Pending;

  const { data, refetch } = useAssessmentReportFieldsQuery(
    {
      assessmentId: assessmentId ? assessmentId : "<missing-assessment-id>",
      learnerId,
    },
    {
      refetchOnMount: reportStillGenerating ? false : "always",
      queryKey: ["assessment-report-fields-query-page-2", assessmentId, learnerId, reportDone],
      enabled: !!assessmentId && !!learnerId && reportDone,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  const reportGenerationFailed =
    aiReportPart2PredictionStatus === ApiAssessmentAiReport2PredictionStatusChoices.Failure;
  const reportGenerationFailureReason = assessmentQueryData?.getAssessments?.edges?.map((edge) => edge?.node)?.[0]
    ?.failureReason;

  const { data: longTermShortTermTargets } = useGetLongTermShortTermGoalsQuery(
    { assessmentId: assessmentId ? assessmentId : "<missing-assessmentId>" },
    {
      queryKey: ["get-long-term-short-term-goals-query", learnerId, reportDone],
      enabled: !!assessmentId && !!learnerId && reportDone,
      retry: false,
      refetchOnMount: reportStillGenerating ? false : "always",
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const abFields = data?.assessmentReportFields?.edges?.map((edge) => edge?.node) || [];

  if (reportNotYetKickedOff) {
    return <ReportNotReadyYet item="Report Part 2" />;
  }

  if (reportStillGenerating) {
    return (
      <div className="mt-10 flex flex-col items-center justify-center gap-y-4">
        <SparklesIcon className="h-12 w-12 text-limestone-200" />
        <Heading type="h4">Good news! Your Assessment Report (Part 2) is underway</Heading>
        <Paragraph displayType="normal" colorType="secondary" className="text-center">
          This could take some time to complete. <br />
          You may leave this page and continue working and come back soon
        </Paragraph>

        <ul className="flex flex-col gap-y-2">
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center justify-end">
              <Small displayType="loud">Report Part Two Generation: </Small>
            </div>
            <div className="flex items-center justify-start">
              {aiReportPart2PredictionStatus ===
              ApiAssessmentAiReport2PredictionStatusChoices["ReportFieldPredictionPending"] ? (
                <Badge dot text={"Generating"} appearance={"ai"} />
              ) : aiReportPart2PredictionStatus ===
                ApiAssessmentAiReport2PredictionStatusChoices["ReportFieldPredictionPredicted"] ? (
                <Badge text={"Done"} appearance={"success"} />
              ) : null}
            </div>
          </div>
        </ul>
        <Button
          appearance="primary"
          text="Return to Assessments"
          onClick={() => {
            go("/assessment-reports");
          }}
        />
      </div>
    );
  }

  if (reportGenerationFailed && !isDisplayModeForReview) {
    return (
      <GenerationErrorPage
        page={AiReportPredictionOfEnum.AssessmentReport_2}
        errorMessage={reportGenerationFailureReason}
      />
    );
  }

  return (
    <div className={`grid grid-cols-4 gap-x-1 ${isDisplayModeForReview ? "pb-0" : "pb-20"}`}>
      <div className={`${isDisplayModeForReview ? "col-span-4" : "col-span-3"} flex w-full flex-col gap-y-4`}>
        {/* TODO: Bring this back when we want to ship with reference */}
        {AssessmentReportHeadings.filter((header) => header !== "References").map((section, index) => {
          const idFromSection = section.toLocaleLowerCase().split(" ").join("-");
          const fieldsForSection = headerToListOfFieldsMapping[section];
          const iconForSection = headerToIconMapping[section];

          if (section === "Behavior Reduction Goals") {
            const existingContent = abFields?.find(
              (node) => node?.fieldKey === AssessmentReportFieldsEnums.BehaviorReductionSummary,
            );
            const goalItems = LTG_STG_Targets_To_Overview(longTermShortTermTargets);
            return (
              <div className="pageBreak" key={idFromSection}>
                <HeadingSection
                  key={idFromSection}
                  Icon={iconForSection}
                  id={idFromSection}
                  label={section}
                  isLast={index === AssessmentReportHeadings.length - 1}
                >
                  <div className="flex flex-col">
                    <SmartFieldWrapper
                      field={AssessmentReportFieldsEnums.BehaviorReductionSummary}
                      section={section}
                      existingContent={existingContent?.value}
                      existingId={existingContent?.id || "<missing-id>"}
                      isDisplayMode={isDisplayModeForReview}
                    />
                    <div className="goals">
                      <GoalOverview
                        defaultOpenState={isDisplayModeForReview}
                        longTermGoals={goalItems.filter(
                          (ltg) => ltg.goalType === ApiLongTermGoalGoalTypeChoices["BehaviorReduction"],
                        )}
                      />
                    </div>
                  </div>
                </HeadingSection>
              </div>
            );
          }

          if (section === "Replacement Behavior Goals") {
            const existingContent = abFields?.find(
              (node) => node?.fieldKey === AssessmentReportFieldsEnums.ReplacementBehaviorSummary,
            );
            const goalItems = LTG_STG_Targets_To_Overview(longTermShortTermTargets);
            return (
              <div className="pageBreak" key={idFromSection}>
                <HeadingSection
                  key={idFromSection}
                  Icon={iconForSection}
                  id={idFromSection}
                  label={section}
                  isLast={index === AssessmentReportHeadings.length - 1}
                >
                  <div className="flex flex-col">
                    <SmartFieldWrapper
                      field={AssessmentReportFieldsEnums.ReplacementBehaviorSummary}
                      section={section}
                      existingContent={existingContent?.value}
                      isDisplayMode={isDisplayModeForReview}
                      existingId={existingContent?.id}
                    />
                    <div className="goals">
                      <GoalOverview
                        defaultOpenState={isDisplayModeForReview}
                        longTermGoals={goalItems.filter(
                          (ltg) => ltg.goalType === ApiLongTermGoalGoalTypeChoices["ReplacementBehavior"],
                        )}
                      />
                    </div>
                  </div>
                </HeadingSection>
              </div>
            );
          }
          if (section === "Skill Acquisition Goals") {
            const existingContent = abFields?.find(
              (node) => node?.fieldKey === AssessmentReportFieldsEnums.SkillAcquisitionSummary,
            );
            const goalItems = LTG_STG_Targets_To_Overview(longTermShortTermTargets);
            return (
              <div className="pageBreak" key={idFromSection}>
                <HeadingSection
                  key={idFromSection}
                  Icon={iconForSection}
                  id={idFromSection}
                  label={section}
                  isLast={index === AssessmentReportHeadings.length - 1}
                >
                  <div className="flex flex-col ">
                    <SmartFieldWrapper
                      field={AssessmentReportFieldsEnums.SkillAcquisitionSummary}
                      section={section}
                      existingContent={existingContent?.value}
                      isDisplayMode={isDisplayModeForReview}
                      existingId={existingContent?.id}
                    />
                    <div className="goals">
                      <GoalOverview
                        defaultOpenState={isDisplayModeForReview}
                        longTermGoals={goalItems.filter(
                          (ltg) => ltg.goalType === ApiLongTermGoalGoalTypeChoices["SkillAcquisition"],
                        )}
                      />
                    </div>
                  </div>
                </HeadingSection>
              </div>
            );
          }
          if (section === "Parent / Caregiver Goals") {
            const existingContent = abFields?.find(
              (node) => node?.fieldKey === AssessmentReportFieldsEnums.ParentCaregiverSummary,
            );
            const goalItems = LTG_STG_Targets_To_Overview(longTermShortTermTargets);
            return (
              <div className="pageBreak" key={idFromSection}>
                <HeadingSection
                  key={idFromSection}
                  Icon={iconForSection}
                  id={idFromSection}
                  label={section}
                  isLast={index === AssessmentReportHeadings.length - 1}
                >
                  <div className="flex flex-col">
                    <SmartFieldWrapper
                      field={AssessmentReportFieldsEnums.ParentCaregiverSummary}
                      section={section}
                      existingContent={existingContent?.value}
                      isDisplayMode={isDisplayModeForReview}
                      existingId={existingContent?.id}
                    />
                    <GoalOverview
                      defaultOpenState={isDisplayModeForReview}
                      longTermGoals={goalItems.filter(
                        (ltg) => ltg.goalType === ApiLongTermGoalGoalTypeChoices["ParentCaregiverInvolvement"],
                      )}
                    />
                  </div>
                </HeadingSection>
              </div>
            );
          }
          return (
            <div className="pageBreak" key={idFromSection}>
              <HeadingSection
                Icon={iconForSection}
                key={idFromSection}
                id={idFromSection}
                label={section}
                isLast={index === AssessmentReportHeadings.length - 1}
              >
                {fieldsForSection.map((field) => {
                  const existingContent = abFields?.find((node) => node?.fieldKey === field);
                  return (
                    <SmartFieldWrapper
                      key={`${section}-${field}`}
                      field={field}
                      section={section}
                      existingContent={existingContent?.value}
                      isDisplayMode={isDisplayModeForReview}
                      existingId={existingContent?.id}
                    />
                  );
                })}
              </HeadingSection>
            </div>
          );
        })}
      </div>
      {!isDisplayModeForReview && (
        <div className="flex w-full flex-col items-end ">
          <StickyNav state={{}} headingType="h4" />
        </div>
      )}
      {!isDisplayModeForReview && <FooterButtonRow />}
    </div>
  );
};

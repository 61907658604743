import { SelectItem } from "@fronterahealth/frontera-ui-components";

import { MoveGoalBankShortTermGoalInput, useMoveShortTermGoalToFolderMutation } from "@api/graphql/types-and-hooks";
import { FolderManagementAction } from "@components/GoalsBank/constants";
import { FolderManagementDialog } from "@components/GoalsBank/dialogs/FolderManagementDialog";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { findFolderSelection } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

interface MoveShortTermGoalToFolderProps {
  currentFolderId: string;
  goalBankShortTermId?: string;
  onCloseClick: () => void;
  open: boolean;
  foldersSelection: SelectItem[];
  refetch: () => void;
}

export const MoveShortTermGoalToFolder: React.FC<MoveShortTermGoalToFolderProps> = ({
  currentFolderId,
  goalBankShortTermId,
  onCloseClick,
  open,
  foldersSelection,
  refetch,
}) => {
  const moveShortTermGoal = useMoveShortTermGoalToFolderMutation({});
  const { formState, RegisteredFormSelected, reset, onSubmit, watch } = useFormUtils<MoveGoalBankShortTermGoalInput>({
    mutationFn: async (params) => {
      if (params.newFolderId && goalBankShortTermId && params.goalBankLongTermId)
        moveShortTermGoal.mutateAsync(
          {
            input: {
              goalBankShortTermId,
              goalBankLongTermId: params.goalBankLongTermId,
              currentFolderId,
              newFolderId: params.newFolderId,
            },
          },
          {
            onSuccess: async () => {
              await refetch();
              notifySuccess(`Successfully Moved Short Term Goal`);
              reset();
              await onCloseClick();
            },
            onError: async (error: unknown) => {
              console.error(`Error when moving short term goal `, error);
            },
          },
        );
    },
  });
  const newFolderIdWatch = watch("newFolderId");
  const { longTermGoals } = useGetLongTermGoals(newFolderIdWatch ?? currentFolderId);

  const placeholderText = () => {
    const selection = findFolderSelection(newFolderIdWatch ?? currentFolderId, foldersSelection);
    return selection ? selection.primary : "Select a folder";
  };

  return (
    <FolderManagementDialog
      action={FolderManagementAction.MOVE}
      open={open}
      onCancelClick={onCloseClick}
      onSaveClick={onSubmit}
    >
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormSelected
          formKey="newFolderId"
          required
          formState={formState}
          items={foldersSelection}
          title={"Select Folder"}
          placeholderText={placeholderText()}
        />
        <RegisteredFormSelected
          required={true}
          formKey="goalBankLongTermId"
          formState={formState}
          items={
            (longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
              return {
                id: edge?.node?.id,
                primary: edge?.node?.goalName,
                secondary: convertReadableString(edge?.node?.goalType),
              };
            }) as SelectItem[]) ?? []
          }
          title="Long Term Goal"
          placeholderText={"Select Long Term Goal"}
        />
      </FormContainer>
    </FolderManagementDialog>
  );
};

/**
 *
 * This file contains the logical grouping of which Tendency questions belong to which page
 *
 * At the data layer, these are all just LearnerOnboardingQuestions with question keys that belong
 * to a massive Enum on the backend / Python Graphene side. We wanted to use one mutation on the backend side
 * (instead of having 10 different mutations for each Tendency type) and because GraphQL currently does not allow
 * Input Union types (https://github.com/graphql/graphql-spec/issues/488) we couldn't separate each enum into a separate one
 * based on the form page it belonged to
 *
 * Therefore, ALL tendencies question_keys are in one Enum = the TendenciesQuestionType - and the responsibility therefore lies
 * on the UI and the following mapping files to dictate which questions will fall on which page
 */
import { RadioInputItem } from "@fronterahealth/frontera-ui-components/RadioInput/RadioInput";

import { BehaviorsQuestionType } from "@api/graphql/types-and-hooks";
import { LANGUAGE_KEYS, LanguageObjectType } from "@components/forms/interfaces";
import { YesNoRadioItemsWithLang } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";

export type BehaviorsRoutes =
  | "forms/intake_form/all"
  | "forms/intake_form/behaviors/general"
  | "forms/intake_form/behaviors/movement-&-play"
  | "forms/intake_form/behaviors/sensory"
  | "forms/intake_form/behaviors/sleep"
  | "forms/intake_form/behaviors/diet"
  | "forms/intake_form/behaviors/interests";

export const BehaviorsQuestionGroupings: {
  [key in BehaviorsRoutes]: BehaviorsQuestionType[];
} = {
  "forms/intake_form/all": [
    // general
    BehaviorsQuestionType.WhenFirstConcerned,
    BehaviorsQuestionType.WalkingTalkingToiletTraining,
    BehaviorsQuestionType.MostConcernedBehaviors,
    BehaviorsQuestionType.BehaviorTrendRecentWeeks,
    BehaviorsQuestionType.EffectiveBehaviorStrategies,
    BehaviorsQuestionType.SignsOfTantrumsOrAggression,
    BehaviorsQuestionType.SignsOfSelfInjury,
    // diet
    BehaviorsQuestionType.IsPickyOrRestrictiveDiet,
    // interests
    BehaviorsQuestionType.FavoriteThings,
    BehaviorsQuestionType.RepeatedActivities,
    BehaviorsQuestionType.ExcessiveActivities,
    BehaviorsQuestionType.RepeatedTvPrograms,
    BehaviorsQuestionType.TypicalInterestsForAge,
    BehaviorsQuestionType.OddInterestsForAge,
    // movement & play
    BehaviorsQuestionType.RepetitiveMovements,
    BehaviorsQuestionType.OddOrStrangeMovements,
    BehaviorsQuestionType.PlayDescription,
    BehaviorsQuestionType.PretendPlay,
    BehaviorsQuestionType.PlayWithOthers,
    BehaviorsQuestionType.LiningObjectsUp,
    BehaviorsQuestionType.PlayingWithToyAsIntended,
    BehaviorsQuestionType.OverFocusedOnSpecificParts,
    BehaviorsQuestionType.BigChangesVsLittleChanges,
    BehaviorsQuestionType.RigidRoutines,
    BehaviorsQuestionType.InsistsOnCertainThings,
    BehaviorsQuestionType.RigidChangingMindOrPerspectives,

    // sensory
    BehaviorsQuestionType.IrritatedBySoundsSmellsFeels,
    BehaviorsQuestionType.OversensitiveToSensoryStimuli,
    BehaviorsQuestionType.SeekingSpecificSensoryExperiences,
    BehaviorsQuestionType.VisualInspection,
    BehaviorsQuestionType.MovementActivities,

    // sleep
    BehaviorsQuestionType.IrritatedBySoundsSmellsFeels,
    BehaviorsQuestionType.OversensitiveToSensoryStimuli,
    BehaviorsQuestionType.SeekingSpecificSensoryExperiences,
    BehaviorsQuestionType.VisualInspection,
    BehaviorsQuestionType.MovementActivities,
  ],
  "forms/intake_form/behaviors/diet": [BehaviorsQuestionType.IsPickyOrRestrictiveDiet],
  "forms/intake_form/behaviors/general": [
    BehaviorsQuestionType.WhenFirstConcerned,
    BehaviorsQuestionType.WalkingTalkingToiletTraining,
    BehaviorsQuestionType.MostConcernedBehaviors,
    BehaviorsQuestionType.BehaviorTrendRecentWeeks,
    BehaviorsQuestionType.EffectiveBehaviorStrategies,
    BehaviorsQuestionType.SignsOfTantrumsOrAggression,
    BehaviorsQuestionType.SignsOfSelfInjury,
  ],
  "forms/intake_form/behaviors/interests": [
    BehaviorsQuestionType.FavoriteThings,
    BehaviorsQuestionType.FavoriteFoodDrinks,
    BehaviorsQuestionType.FavoriteToysAndPlayItems,
    BehaviorsQuestionType.FavoriteTechnology,
    BehaviorsQuestionType.FavoriteSocialActivity,
    BehaviorsQuestionType.RepeatedActivities,
    BehaviorsQuestionType.ExcessiveActivities,
    BehaviorsQuestionType.RepeatedTvPrograms,
    BehaviorsQuestionType.TypicalInterestsForAge,
    BehaviorsQuestionType.OddInterestsForAge,
  ],
  "forms/intake_form/behaviors/movement-&-play": [
    BehaviorsQuestionType.RepetitiveMovements,
    BehaviorsQuestionType.OddOrStrangeMovements,
    BehaviorsQuestionType.PlayDescription,
    BehaviorsQuestionType.PretendPlay,
    BehaviorsQuestionType.PlayWithOthers,
    BehaviorsQuestionType.LiningObjectsUp,
    BehaviorsQuestionType.PlayingWithToyAsIntended,
    BehaviorsQuestionType.OverFocusedOnSpecificParts,
    BehaviorsQuestionType.BigChangesVsLittleChanges,
    BehaviorsQuestionType.RigidRoutines,
    BehaviorsQuestionType.InsistsOnCertainThings,
    BehaviorsQuestionType.RigidChangingMindOrPerspectives,
  ],
  "forms/intake_form/behaviors/sensory": [
    BehaviorsQuestionType.IrritatedBySoundsSmellsFeels,
    BehaviorsQuestionType.OversensitiveToSensoryStimuli,
    BehaviorsQuestionType.SeekingSpecificSensoryExperiences,
    BehaviorsQuestionType.VisualInspection,
    BehaviorsQuestionType.MovementActivities,
  ],
  "forms/intake_form/behaviors/sleep": [
    BehaviorsQuestionType.TroubleFallingOrStayingAsleep,
    BehaviorsQuestionType.SleepTroubleStart,
  ],
};

type CustomRadioType = {
  type: "radio";
  items: (lang: LANGUAGE_KEYS) => RadioInputItem[];
};

const playWithOthersMapping: { [key: string]: LanguageObjectType } = {
  directive: {
    en: "Directive",
    es: "",
  },
  flexible: {
    en: "Flexible",
    es: "",
  },
};

const pretendPlayMapping: { [key: string]: LanguageObjectType } = {
  repetitive: {
    en: "Repetitive",
    es: "",
  },
  varied: {
    en: "Varied",
    es: "",
  },
};

export const customQuestionConfig: { [key in BehaviorsQuestionType]?: CustomRadioType } = {
  PRETEND_PLAY: {
    type: "radio",

    items: (lang: LANGUAGE_KEYS) => [
      { id: "repetitive", title: pretendPlayMapping["repetitive"][lang] },
      { id: "varied", title: pretendPlayMapping["varied"][lang] },
    ],
  },
  PLAY_WITH_OTHERS: {
    type: "radio",
    items: (lang: LANGUAGE_KEYS) => [
      { id: "directive", title: playWithOthersMapping["directive"][lang] },
      { id: "flexible", title: playWithOthersMapping["flexible"][lang] },
    ],
  },
  LINING_OBJECTS_UP: {
    type: "radio",
    items:
      typeof YesNoRadioItemsWithLang === "function"
        ? (lang: LANGUAGE_KEYS) => YesNoRadioItemsWithLang(lang)
        : YesNoRadioItemsWithLang,
  },
  PLAYING_WITH_TOY_AS_INTENDED: {
    type: "radio",
    items:
      typeof YesNoRadioItemsWithLang === "function"
        ? (lang: LANGUAGE_KEYS) => YesNoRadioItemsWithLang(lang)
        : YesNoRadioItemsWithLang,
  },
  OVER_FOCUSED_ON_SPECIFIC_PARTS: {
    type: "radio",
    items:
      typeof YesNoRadioItemsWithLang === "function"
        ? (lang: LANGUAGE_KEYS) => YesNoRadioItemsWithLang(lang)
        : YesNoRadioItemsWithLang,
  },
};

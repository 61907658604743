import { UseQueryResult } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";

import { ProvidersQuery, useProvidersQuery } from "@api/graphql/types-and-hooks";

interface ProvidersDataContextI {
  providersQuery: UseQueryResult<ProvidersQuery, unknown>;
}

export const ProvidersDataContext = createContext<ProvidersDataContextI | undefined>(undefined);

export const useProvidersData = () => {
  const context = useContext(ProvidersDataContext);
  if (context === undefined) {
    throw new Error("useProvidersData must be used within a ProvidersDataProvider");
  }
  return context;
};

export const ProvidersDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  const providersQuery = useProvidersQuery(
    {},
    {
      queryKey: ["providers"],
      refetchOnWindowFocus: false,
      retry: false,
      enabled: pathname.includes("/providers"),
    },
  );

  if (!providersQuery) return null;

  return (
    <ProvidersDataContext.Provider
      value={{
        providersQuery: providersQuery,
      }}
    >
      {children}
    </ProvidersDataContext.Provider>
  );
};

import { AlignmentType, Document, ISectionOptions, LevelFormat, NumberFormat, Packer } from "docx";
import html2canvas from "html2canvas";
import { useCallback, useState } from "react";

import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";

import { parseHtmlToDocx } from "@components/HtmlToDocx/ParseHtmlToDocx";

const borderColor = "#EDEAE7";
const textSecondaryColor = "#89776D";
const textSecondaryFontSize = "12";
const textPrimaryColor = "#4E443F";

const useGenerateDocx = () => {
  const [loading, setLoading] = useState(false);

  const convertElementToImage = async (element: HTMLElement): Promise<string> => {
    const canvas = await html2canvas(element, { scale: 2 });
    return canvas.toDataURL("image/png", 1.0);
  };

  const convertImageToBase64 = (url: string, width: number, height: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  };

  const processElement = async (element: HTMLElement | null): Promise<void> => {
    if (!element) {
      console.error("Element not found");
      return;
    }

    element.querySelectorAll("div.ql-tooltip.ql-hidden").forEach((tooltip) => tooltip.remove());
    element.querySelectorAll("svg").forEach((svg) => {
      const parentDiv = svg.closest("span");
      if (parentDiv) {
        parentDiv.remove();
      }
    });

    element.querySelectorAll("[data-dynamic-section]").forEach((h4) => {
      const h3 = document.createElement("h3");
      h3.style.fontWeight = "600";
      h3.style.color = textPrimaryColor;
      Array.from(h4.attributes).forEach((attr) => {
        h3.setAttribute(attr.name, attr.value);
      });
      h3.innerHTML = h4.innerHTML;
      h4.parentNode?.replaceChild(h3, h4);
    });

    element.querySelectorAll(".pageBreak").forEach((pageBreakElement) => {
      const hrAfter = document.createElement("hr");
      hrAfter.style.color = borderColor;
      hrAfter.style.backgroundColor = borderColor;
      pageBreakElement.parentNode?.insertBefore(hrAfter, pageBreakElement.nextSibling);
    });

    element.querySelectorAll("input").forEach((inputElement) => {
      const textNode = document.createTextNode(inputElement.value);
      inputElement.parentNode?.replaceChild(textNode, inputElement);
    });

    element.querySelectorAll("label").forEach((label) => {
      const parentDiv = label.parentElement;
      if (parentDiv) {
        const newP = document.createElement("p");
        newP.setAttribute("converted", "true");

        Array.from(parentDiv.childNodes).forEach((child) => {
          const childClone = child.cloneNode(true) as HTMLElement;
          newP.appendChild(childClone);
        });

        parentDiv.parentNode?.replaceChild(newP, parentDiv);
      }
    });

    element.querySelectorAll("[converted]").forEach((convertedElement) => {
      convertedElement.querySelectorAll("label").forEach((label) => {
        const parentDiv = label.parentElement;
        if (parentDiv) {
          const nextParagraph = label.nextElementSibling as HTMLElement | null;

          if (nextParagraph && nextParagraph.tagName.toLowerCase() === "p") {
            nextParagraph.style.margin = "0";
            nextParagraph.style.padding = "0";
          }

          const paragraph = label.querySelector("p");
          if (paragraph) {
            paragraph.style.color = textSecondaryColor;
            paragraph.style.fontSize = textSecondaryFontSize;
            paragraph.style.margin = "0";
            paragraph.style.padding = "0";
            paragraph.setAttribute("convertedLabel", "");
            parentDiv.replaceChild(paragraph, label);
          }
        }
      });
    });

    element.querySelectorAll("[data-dynamic-field]").forEach((p) => {
      const paragraph = p as HTMLElement;
      paragraph.style.margin = "0";
      paragraph.style.padding = "0";
      paragraph.style.fontSize = textSecondaryFontSize;
      paragraph.style.color = textSecondaryColor;
    });

    const liElements = element.querySelectorAll("li");
    liElements.forEach((li) => {
      const newP = document.createElement("span");
      while (li.firstChild) {
        newP.appendChild(li.firstChild);
      }
      li.appendChild(newP);
    });

    // ToDo: handle s3 images in the doc once we figure out how to do this.
    const imgElements = element.querySelectorAll("img");
    imgElements.forEach((imgElement) => {
      imgElement.remove();
    });
  };

  const generateDocx = useCallback(async (element: HTMLElement | null): Promise<File | void> => {
    if (!element) {
      console.error("Element not found");
      return;
    }

    setLoading(true);

    try {
      const logoBase64 = await convertImageToBase64(logo, 120, 40);

      const hiddenDiv = document.createElement("div");
      hiddenDiv.style.display = "none";
      document.body.appendChild(hiddenDiv);

      hiddenDiv.innerHTML = element.outerHTML;

      processElement(hiddenDiv);

      // Convert the signature element to an image
      const signatureElement = element.querySelector(".captureSignature");
      const hiddenSignature = hiddenDiv.querySelector(".captureSignature");
      if (signatureElement && hiddenSignature) {
        const imgDataUrl = await convertElementToImage(signatureElement as HTMLElement);
        const img = new Image();
        img.src = imgDataUrl;
        img.width = 600; // Set desired width
        img.height = 80; // Set desired height
        hiddenSignature.parentNode?.replaceChild(img, hiddenSignature);
      }

      const htmlContent = `
                <html>
                  <head>
                  <meta charset="UTF-8">
                     <style>
                        body {
                           font-family: Helvetica, Arial, sans-serif;
                          }

                        .logo-container {
                           text-align: center;
                        }
                     </style>
                  </head>
                  <body>
                  <header style="text-align: center;">
                    <div class="logo-container">
                         <img src="${logoBase64}"  width="120" height='40'>
                     </div>
                  </header>
                  ${hiddenDiv.innerHTML}
                  </body>
               </html>
            `;
      const paragraphs = parseHtmlToDocx(htmlContent);

      const doc = new Document({
        styles: {
          default: {
            heading1: {
              run: {
                size: 36,
                bold: true,
                color: textPrimaryColor,
              },
              paragraph: {
                spacing: {
                  after: 120,
                },
              },
            },
            heading2: {
              run: {
                size: 32,
                bold: true,
                color: textPrimaryColor,
              },
              paragraph: {
                spacing: {
                  after: 120,
                },
              },
            },
            heading3: {
              run: {
                size: 28, // measured in half pts
                bold: true,
                color: textPrimaryColor,
              },
              paragraph: {
                spacing: {
                  after: 300,
                  before: 200,
                },
              },
            },
            heading4: {
              run: {
                size: 24,
                bold: false,
                color: textSecondaryColor,
              },
              paragraph: {
                spacing: {
                  after: 0,
                  before: 0,
                },
              },
            },
            listParagraph: {
              run: {
                color: textPrimaryColor,
              },
            },
            document: {
              run: {
                size: 24,
                font: "Helvetica",
                color: textPrimaryColor,
              },
            },
          },
          paragraphStyles: [
            {
              id: "imageType",
              name: "imageType",
              paragraph: {
                alignment: AlignmentType.CENTER,
              },
            },
            {
              id: "labelType",
              name: "labelType",
              paragraph: {
                alignment: AlignmentType.LEFT,
                spacing: {
                  after: 0,
                },
              },
            },
            {
              id: "noSpacing",
              name: "noSpacing",
              basedOn: "Normal",
              quickFormat: true,
              run: {},
            },
          ],
        },
        numbering: {
          config: [
            {
              reference: "ordered-list",
              levels: [
                {
                  level: 0,
                  format: LevelFormat.DECIMAL,
                  text: "%1.",
                  alignment: AlignmentType.LEFT,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 360 },
                    },
                  },
                },
              ],
            },
            {
              reference: "unordered-list",
              levels: [
                {
                  level: 0,
                  format: NumberFormat.BULLET,
                  text: "\u2022",
                  alignment: AlignmentType.LEFT,
                  style: {
                    run: {
                      size: 24,
                    },
                    paragraph: {
                      indent: { left: 720, hanging: 360 },
                    },
                  },
                },
              ],
            },
          ],
        },
        sections: [
          {
            properties: {},
            children: paragraphs,
          },
        ] as unknown as ISectionOptions[],
      });

      const docxBlob = await Packer.toBlob(doc);

      const docxFile = new File([docxBlob], "output.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      hiddenDiv.remove();
      return docxFile;
    } catch (error) {
      console.error("Error generating DOCX:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  return { generateDocx, loading };
};

export default useGenerateDocx;

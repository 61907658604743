import debounce from "lodash.debounce";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToAnchor = () => {
  const location = useLocation();
  const lastHash = useRef("");
  const [isScrolling, setIsScrolling] = useState<boolean>();

  useLayoutEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: "smooth", block: "start" });
        setTimeout(() => {}, 1000);
      }, 100);
    }
  }, [location]);

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }
    let found = false;
    const handleScroll = debounce(() => {
      if (lastHash.current && document.getElementById(lastHash.current)) {
        const element = document.getElementById(lastHash.current);
        if (element && !found) {
          const bounding = element.getBoundingClientRect();
          if (bounding.top >= 0 && bounding.top <= window.innerHeight / 2) {
            found = true;
            setIsScrolling(false);
          } else {
            setIsScrolling(true);
          }
        }
      }
    }, 0);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  return isScrolling;
};

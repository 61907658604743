import { MedicalHistoryConditionsQuestionType } from "src/api/graphql/types-and-hooks";
import { LanguageObjectType } from "src/components/forms/interfaces";

export const medicalHistoryConditionsQuestionsMapping: {
  [key in MedicalHistoryConditionsQuestionType]: LanguageObjectType;
} = {
  ALLERGIES: {
    en: "Allergies",
    es: "Alergias",
  },
  ANEMIA: {
    en: "Anemia",
    es: "Anemia",
  },
  ASPHYXIA: {
    en: "Asphyxia",
    es: "Asfixia",
  },
  ASTHMA: {
    en: "Asthma",
    es: "Asma",
  },
  BLADDER_CONTROL: {
    en: "Bladder Control",
    es: "Control de la Vejiga",
  },
  BLEEDING_PROBLEMS: {
    en: "Bleeding Problems",
    es: "Problemas de Sangrado",
  },
  BONE_OR_JOINT_DISEASE: {
    en: "Bone or Joint Disease",
    es: "Enfermedad de Huesos o Articulaciones",
  },
  CANCER: {
    en: "Cancer",
    es: "Cáncer",
  },
  CHARGE_SYNDROME: {
    en: "Charge Syndrome",
    es: "Síndrome de Charge",
  },
  CONVULSIONS: {
    en: "Convulsions",
    es: "Convulsiones",
  },
  DIABETES: {
    en: "Diabetes",
    es: "Diabetes",
  },
  DIZZINESS: {
    en: "Dizziness",
    es: "Mareos",
  },
  DOWNS_SYNDROME: {
    en: "Downs Syndrome",
    es: "Síndrome de Down",
  },
  EAR_INFECTIONS: {
    en: "Ear Infections",
    es: "Infecciones de Oído",
  },
  EATING_PROBLEMS: {
    en: "Eating Problems",
    es: "Problemas de Comer",
  },
  ENCEPHALITIS: {
    en: "Encephalitis",
    es: "Encefalitis",
  },
  EXTREME_TIREDNESS: {
    en: "Extreme Tiredness",
    es: "Cansancio Extremo",
  },
  EYE_PROBLEMS: {
    en: "Eye Problems",
    es: "Problemas de Visión",
  },
  FAINTING_SPELLS: {
    en: "Fainting Spells",
    es: "Desmayos",
  },
  FETAL_ALCOHOL_SYNDROME: {
    en: "Fetal Alcohol Syndrome",
    es: "Síndrome de Alcoholismo Fetal",
  },
  FREQUENT_OR_SEVERE_HEADACHES: {
    en: "Frequent or Severe Headaches",
    es: "Dolores de Cabeza Frecuentes o Severos",
  },
  HEAD_INJURY: {
    en: "Head Injury",
    es: "Lesión en la Cabeza",
  },
  HEART_DISEASE_OR_CONDITION: {
    en: "Heart Disease or Condition",
    es: "Enfermedad o Condición Cardíaca",
  },
  HERPES: {
    en: "Herpes",
    es: "Herpes",
  },
  HIGH_BLOOD_PRESSURE: {
    en: "High Blood Pressure",
    es: "Presión Arterial Alta",
  },
  HIGH_FEVER: {
    en: "High Fever",
    es: "Fiebre Alta",
  },
  HYDROCEPHALUS: {
    en: "Hydrocephalus",
    es: "Hidrocefalia",
  },
  LOSS_OF_CONSCIOUSNESS: {
    en: "Loss of Consciousness",
    es: "Pérdida de Conciencia",
  },
  MATERNAL_DRUG_USE: {
    en: "Maternal Drug Use",
    es: "Uso de Drogas Maternas",
  },
  MEMORY_PROBLEMS: {
    en: "Memory Problems",
    es: "Problemas de Memoria",
  },
  MENINGITIS: {
    en: "Meningitis",
    es: "Meningitis",
  },
  MICROCEPHALY: {
    en: "Microcephaly",
    es: "Microcefalia",
  },
  OTHER_MULTIPLE_ANOMALY: {
    en: "Other Multiple Anomaly",
    es: "Otra Anomalía Múltiple",
  },
  OTHER_POSTNATAL: {
    en: "Other Postnatal",
    es: "Otro Postnatal",
  },
  OTHER_PRENATAL_CONDITIONS: {
    en: "Other Prenatal Conditions",
    es: "Otras Condiciones Prenatales",
  },
  OTHER_SYNDROME: {
    en: "Other Syndrome",
    es: "Otro Síndrome",
  },
  PARALYSIS: {
    en: "Paralysis",
    es: "Parálisis",
  },
  POOR_COORDINATION: {
    en: "Poor Coordination",
    es: "Coordinación Deficiente",
  },
  RHEUMATIC_FEVER: {
    en: "Rheumatic Fever",
    es: "Fiebre Reumática",
  },
  RUBELLA: {
    en: "Rubella",
    es: "Rubéola",
  },
  SCARLET_FEVER: {
    en: "Scarlet Fever",
    es: "Fiebre Escarlata",
  },
  SEIZURES: {
    en: "Seizures",
    es: "Convulsiones",
  },
  SLEEPING_PROBLEMS: {
    en: "Sleeping Problems",
    es: "Problemas de Sueño",
  },
  STROKE: {
    en: "Stroke",
    es: "Accidente Cerebrovascular",
  },
  SYPHILIS: {
    en: "Syphilis",
    es: "Sífilis",
  },
  TOXOPLASMOSIS: {
    en: "Toxoplasmosis",
    es: "Toxoplasmosis",
  },
  TRISOMY_13: {
    en: "Trisomy 13",
    es: "Trisomía 13",
  },
  TUBERCULOSIS: {
    en: "Tuberculosis",
    es: "Tuberculosis",
  },
  TUBES_IN_EARS: {
    en: "Tubes in Ears",
    es: "Tubos en los Oídos",
  },
  USHERS: {
    en: "Ushers",
    es: "Ushers",
  },
};

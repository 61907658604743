import { LearnerLivingConditionsQuery } from "@api/graphql/types-and-hooks";
import { LearnerIndividualsThatLiveInHomeType } from "@api/graphql/types-and-hooks";
import { LanguageMappingInterface } from "@components/forms/interfaces";

export type LearnerIndividualsThatLiveInHomeDataType = Omit<
  NonNullable<LearnerIndividualsThatLiveInHomeType>,
  "id" | "learner" | "createdDate" | "lastUpdatedDate" | "organization"
>;
export type LearnerLivingConditionDataType = NonNullable<
  NonNullable<NonNullable<LearnerLivingConditionsQuery["learnerById"]>["learnerlivingconditions"]>
>;

export type LivingConditionDataType = {
  learnerindividualsthatliveinhomeSet: Array<LearnerIndividualsThatLiveInHomeDataType>;
  learnerlivingconditions: LearnerLivingConditionDataType;
};
export const livingConditionsMapping: LanguageMappingInterface<LivingConditionDataType> = {
  learnerindividualsthatliveinhomeSet: {
    age: {
      en: "Age",
      es: "Edad",
    },
    name: {
      en: "Name",
      es: "Nombre",
    },
    relationshipToClient: {
      en: "Relationship",
      es: "Relación con el Cliente",
    },
  },

  learnerlivingconditions: {
    delaysOrDiagnosesInClientSiblings: {
      en: "Delays or Diagnoses in Client Siblings",
      es: "Retrasos o diagnósticos en hermanos del cliente",
    },
    clientInFosterCare: {
      en: "Client in Foster Care",
      es: "Cliente en cuidado de crianza",
    },
    cyfdCaseManagerName: {
      en: "CYFD Case Manager Name",
      es: "Nombre del gerente de casos de CYFD",
    },
    cyfdCaseManagerPhone: {
      en: "CYFD Case Manager Phone Number",
      es: "Número de teléfono del gerente de casos de CYFD",
    },
    clientLivesBetweenParentsAndGrandparents: {
      en: "Client Lives Between Parents and Grandparents",
      es: "El cliente vive entre padres y abuelos",
    },
    clientHouseholdParentCount: {
      en: "Client Household Parent Count",
      es: "Recuento de padres en el hogar del cliente",
    },
    clientTimeSpentWithOtherParent: {
      en: "Client Time Spent with Other Parent",
      es: "Tiempo que pasa el cliente con el otro padre",
    },
  },
};

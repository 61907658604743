import { format } from "date-fns";
import React from "react";
import Chart from "react-apexcharts";

import { Divider, Select, SelectItem } from "@fronterahealth/frontera-ui-components";

import "@components/SessionAnalyzer/OverTimeEngagementChart/graph.css";

export interface Datum {
  date: Date;
  engagement: number | null;
  technician: string;
  sessionId: string;
}

interface OverTimeEngagementChartProps {
  data: Datum[];
  technicians: SelectItem[];
}

type SessionRangeType = "Last 7 Sessions" | "Last 14 Sessions" | "All Sessions";

const convertDatumToApexSeries = (data: Datum[], range: string, techFilter: string | null) => {
  const rangeLimit = range === "Last 7 Sessions" ? 7 : range === "Last 14 Sessions" ? 14 : undefined;
  const seriesData = data
    .filter((d) => (techFilter === null ? true : d.technician === techFilter))
    .slice(0, rangeLimit)
    .map((d) => ({
      x: d.date.getTime(),
      y: d.engagement,
      meta: {
        sessionId: d.sessionId,
      },
    }));
  const sortedData = seriesData.sort((a, b) => {
    return new Date(a.x).getTime() - new Date(b.x).getTime();
  });

  return [
    {
      name: "Engagement",
      data: sortedData.map((data) => ({
        ...data,
        x: format(data.x, "dd MMM"),
      })),
    },
  ];
};

export const OverTimeEngagementChart: React.FC<OverTimeEngagementChartProps> = ({ data, technicians }) => {
  const [sessionsRange, setSessionsRange] = React.useState<SessionRangeType>("Last 14 Sessions");
  const [selectedTech, setSelectedTech] = React.useState<string | null>(null);

  const series = convertDatumToApexSeries(data, sessionsRange, selectedTech);
  const dataSeries = [{ name: "Engagement", data: series[0].data.map((item) => item.y) }];

  const options: ApexCharts.ApexOptions = {
    chart: {
      height: 246,
      type: "area",
      id: "areachart-2",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 3,
        left: 0,
        blur: 4,
        opacity: 0.2,
      },
    },
    series: series,
    markers: {
      size: 1,
    },
    colors: ["#4E7E6C"],
    dataLabels: {
      enabled: true,
      background: {
        foreColor: "#ffffff",
      },
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      strokeDashArray: 4,
      padding: {
        right: 30,
        left: 20,
      },
    },
    title: {
      text: "% Engagement",
      align: "left",
      style: {
        fontSize: "18px",
        color: "#4E4440",
        fontWeight: 600,
      },
    },
    xaxis: {
      type: "category",
      categories: series[0].data.map((d) => d.x),
      sorted: true,
      labels: {
        style: {
          cssClass: "custom-axis-label",
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      y: {
        formatter: function (value) {
          return `${value}%`;
        },
      },
      intersect: true,
      shared: false,
    },
    yaxis: {
      min: 0,
      max: 100,
      stepSize: 10,
      show: false,
      labels: {
        style: {
          cssClass: "custom-axis-label",
        },
      },
    },
  };

  return (
    <div className="bg-white py-4 px-6 rounded-2xl shadow-card-shadow w-full my-8">
      <div id="chart">
        <Chart options={options} series={dataSeries} type="area" height={246} />
      </div>

      <Divider borderClassName="border-limestone-100" />
      <div className="flex w-full items-center justify-between">
        <Select
          title={""}
          className="min-w-[200px]"
          items={[
            {
              id: "last-7",
              primary: "Last 7 Sessions",
            },
            {
              id: "last-14",
              primary: "Last 14 Sessions",
            },
            {
              id: "all",
              primary: "All Sessions",
            },
          ]}
          placeholderText={"Last 7 Sessions"}
          selected={{ primary: sessionsRange }}
          setSelected={(item) => setSessionsRange(item.primary as SessionRangeType)}
          formNoValidate
        />
        <Select
          className="min-w-[200px]"
          title={""}
          items={[...technicians, { primary: "All Technicians" }]}
          placeholderText={"All Technicians"}
          selected={{ primary: selectedTech ? selectedTech : "All Technicians" }}
          setSelected={(item) =>
            item.primary === "All Technicians" ? setSelectedTech(null) : setSelectedTech(item.primary)
          }
          formNoValidate
        />
      </div>
    </div>
  );
};

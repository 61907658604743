import {
  BookOpenIcon,
  BuildingOfficeIcon,
  CheckBadgeIcon,
  EyeIcon,
  HeartIcon,
  IdentificationIcon,
  LockClosedIcon,
  PencilIcon,
  PuzzlePieceIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  UserIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import { HeroIconType } from "@utils/utils";

import { AssessmentReportFieldsEnums } from "@api/graphql/types-and-hooks";
import { AssessmentReportHeadingType } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/ReportSummaryPart1";

export const fieldEnumToHumanLabelMapping: { [key in AssessmentReportFieldsEnums]: string } = {
  [AssessmentReportFieldsEnums.DevelopmentalDisabilityDiagnosis]: "Developmental Disability Diagnosis",
  [AssessmentReportFieldsEnums.DateOfDiagnosis]: "Date of Diagnosis",
  [AssessmentReportFieldsEnums.DateAndTimeOfAssessment]: "Date and Time of Assessment",
  [AssessmentReportFieldsEnums.DateOfReportWriteUp]: "Date of Report Write-up",
  [AssessmentReportFieldsEnums.InsuranceName]: "Insurance Name",
  [AssessmentReportFieldsEnums.MemberId]: "Member ID",
  [AssessmentReportFieldsEnums.LocationOfServices]: "Location of Services",
  [AssessmentReportFieldsEnums.BcbaCompletingAssessmentAndReportWriteUp]:
    "BCBA completing Assessment and Report Write-up",
  [AssessmentReportFieldsEnums.StatementOfConfidentiality]: "Statement of Confidentiality",
  [AssessmentReportFieldsEnums.DiagnosticInformation]: "Diagnostic Information",
  [AssessmentReportFieldsEnums.ReasonForReferral]: "Reason for referral",
  [AssessmentReportFieldsEnums.DevelopmentalHistory]: "Developmental History",
  [AssessmentReportFieldsEnums.FamilyHistoryOrInformation]: "Family History / Information",
  [AssessmentReportFieldsEnums.OtherMedicalDiagnosesOfClient]: "Other medical diagnoses of client",
  [AssessmentReportFieldsEnums.MedicationPrescribed]: "Medication Prescribed",
  [AssessmentReportFieldsEnums.AllergiesOrDietaryRestrictions]: "Allergies or dietary restrictions",
  [AssessmentReportFieldsEnums.FamilyHistoryOfDiagnosis]: "Family history of diagnosis: (Maternal/Paternal/Siblings)",
  [AssessmentReportFieldsEnums.OtherHealthConcernsOfClient]: "Other health concerns of client",
  [AssessmentReportFieldsEnums.NameOfSchool]: "Name of School",
  [AssessmentReportFieldsEnums.GradeAndEducationalSetting]: "Grade and educational setting",
  [AssessmentReportFieldsEnums.IepOrBipInSchool]: "IEP/BIP in school",
  [AssessmentReportFieldsEnums.TherapeuticServicesInSchoolAndHours]: "Therapeutic services in school and hours",
  [AssessmentReportFieldsEnums.TherapyServiceHoursNameOfProviderDates]:
    "Chart: Therapy/Service, Hours of Services Provided, Name of Service Provider, Dates of Services Provided",
  [AssessmentReportFieldsEnums.AssessmentsOrInformationUsed]: "Assessments/Information Used",
  [AssessmentReportFieldsEnums.AssessmentWriteUp]: "Assessment Write-Up",
  [AssessmentReportFieldsEnums.ClinicalObservationDuringAssessment]: "Clinical Observation During Assessment",
  [AssessmentReportFieldsEnums.LearnerStrengths]: "Learner Strengths",
  [AssessmentReportFieldsEnums.PreferredItems]: "Preferred Items",
  [AssessmentReportFieldsEnums.KnownAversions]: "Known aversions",
  [AssessmentReportFieldsEnums.QabfOrNarrativeFunctionalAssessment]: "QABF/Narrative functional assessment",
  [AssessmentReportFieldsEnums.AssessmentSummary]: "Assessment Summary",
  [AssessmentReportFieldsEnums.BehaviorInterventionPlanBip]: "Behavior Intervention Plan (BIP)",
  [AssessmentReportFieldsEnums.CoordinationOfCare]: "Coordination of Care", // Added based on pattern
  [AssessmentReportFieldsEnums.ProgramRecommendations]: "Treatment Recommendations/Services Requests",
  [AssessmentReportFieldsEnums.MedicalNecessity]: "Medical Necessity", // Added based on pattern
  [AssessmentReportFieldsEnums.TitrationPlan]: "Titration Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.DischargePlan]: "Discharge Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.RiskEvaluation]: "Risk Evaluation", // Added
  [AssessmentReportFieldsEnums.EmergencyPlan]: "Emergency Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.ProgramContactsForClient]: "Program Contacts For Client", // Added based on pattern
  [AssessmentReportFieldsEnums.References]: "References", // Added based on pattern
  [AssessmentReportFieldsEnums.AbaBackgroundInSchool]: "ABA Background In School", // Added based on pattern
  [AssessmentReportFieldsEnums.BehaviorReductionSummary]: "Summary",
  [AssessmentReportFieldsEnums.ReplacementBehaviorSummary]: "Summary",
  [AssessmentReportFieldsEnums.SkillAcquisitionSummary]: "Summary",
  [AssessmentReportFieldsEnums.ParentCaregiverSummary]: "Summary",
  [AssessmentReportFieldsEnums.ReportSignature]: "Signature",
};

export const headerToIconMapping: {
  [key in AssessmentReportHeadingType]: HeroIconType;
} = {
  "Client Details": UserIcon,
  "Insurance Details": IdentificationIcon,
  "Reason for Referral": BuildingOfficeIcon,
  "Background Information": PuzzlePieceIcon,
  "Education History": BookOpenIcon,
  "History of Other Services": UserGroupIcon,
  "Assessments / Information Used": QuestionMarkCircleIcon,
  "Assessment Write-Up": QuestionMarkCircleIcon,
  "Clinical Observation": EyeIcon,
  "Learner Preferences and Aversions": HeartIcon,
  "Functional Behavior Assessment": QuestionMarkCircleIcon,
  "Assessment Summary": PencilIcon,
  BIP: ViewColumnsIcon,
  "Treatment Recommendations": CheckBadgeIcon,
  "Statement of Confidentiality": LockClosedIcon,
};

export const headerToListOfFieldsMapping: { [key in AssessmentReportHeadingType]: AssessmentReportFieldsEnums[] } = {
  "Client Details": [
    AssessmentReportFieldsEnums.DevelopmentalDisabilityDiagnosis,
    AssessmentReportFieldsEnums.DateOfDiagnosis,
  ],
  "Insurance Details": [
    AssessmentReportFieldsEnums.DateAndTimeOfAssessment,
    AssessmentReportFieldsEnums.DateOfReportWriteUp,
    AssessmentReportFieldsEnums.InsuranceName,
    AssessmentReportFieldsEnums.MemberId,
    AssessmentReportFieldsEnums.LocationOfServices,
    AssessmentReportFieldsEnums.BcbaCompletingAssessmentAndReportWriteUp,
  ],
  "Statement of Confidentiality": [],
  "Reason for Referral": [
    AssessmentReportFieldsEnums.DiagnosticInformation,
    AssessmentReportFieldsEnums.ReasonForReferral,
  ],
  "Background Information": [
    AssessmentReportFieldsEnums.DevelopmentalHistory,
    AssessmentReportFieldsEnums.FamilyHistoryOrInformation,
    AssessmentReportFieldsEnums.OtherMedicalDiagnosesOfClient,
    AssessmentReportFieldsEnums.MedicationPrescribed,
    AssessmentReportFieldsEnums.AllergiesOrDietaryRestrictions,
    AssessmentReportFieldsEnums.FamilyHistoryOfDiagnosis,
    AssessmentReportFieldsEnums.OtherHealthConcernsOfClient,
  ],
  "Education History": [
    AssessmentReportFieldsEnums.NameOfSchool,
    AssessmentReportFieldsEnums.GradeAndEducationalSetting,
    AssessmentReportFieldsEnums.IepOrBipInSchool,
    AssessmentReportFieldsEnums.TherapeuticServicesInSchoolAndHours,
  ],
  "History of Other Services": [],
  "Assessments / Information Used": [AssessmentReportFieldsEnums.AssessmentsOrInformationUsed],
  "Assessment Write-Up": [AssessmentReportFieldsEnums.AssessmentWriteUp],
  "Clinical Observation": [
    AssessmentReportFieldsEnums.ClinicalObservationDuringAssessment,
    AssessmentReportFieldsEnums.LearnerStrengths,
  ],
  "Learner Preferences and Aversions": [
    AssessmentReportFieldsEnums.PreferredItems,
    AssessmentReportFieldsEnums.KnownAversions,
  ],
  "Functional Behavior Assessment": [AssessmentReportFieldsEnums.QabfOrNarrativeFunctionalAssessment],
  "Assessment Summary": [AssessmentReportFieldsEnums.AssessmentSummary],
  BIP: [AssessmentReportFieldsEnums.BehaviorInterventionPlanBip],
  "Treatment Recommendations": [],
};

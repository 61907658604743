import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  ApiInsuranceDetailsInNetworkChoices,
  ApiInsuranceDetailsInsuranceTypeChoices,
  ApiInsuranceDetailsInsurerChoices,
  InsuranceInNetworkEnums,
  InsuranceTypeEnums,
  InsurerEnums,
  UpdateLearnerInsuranceDetailsMutationVariables,
  useCreateLearnerInsuranceMutation,
  useLearnerByIdQuery,
  useUpdateLearnerInsuranceDetailsMutation,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

export const ClientDetailsInsurance: React.FC = () => {
  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ?? "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch, isFetching } = learnerDetailsQuery;
  const updateLearnerInsuranceDetailsMutation = useUpdateLearnerInsuranceDetailsMutation({});
  const createLearnerInsuranceMutation = useCreateLearnerInsuranceMutation({});

  const inNetworkOptions = Object.keys(ApiInsuranceDetailsInNetworkChoices).map((key) => {
    return {
      title: key,
      id: key.toLowerCase(),
    };
  });

  const { formState, onSubmit, RegisteredFormSelected, RegisteredFormRadioInput, reset } =
    useFormUtils<UpdateLearnerInsuranceDetailsMutationVariables>({
      mutationFn: async (params) => {
        if (params?.insuranceData.insuranceId) {
          await updateLearnerInsuranceDetailsMutation.mutateAsync(
            {
              ...params,
              insuranceData: {
                ...params.insuranceData,
                insuranceType: convertDBString(params.insuranceData.insuranceType) as unknown as InsuranceTypeEnums,
                insurer: convertDBString(params?.insuranceData?.insurer) as unknown as InsurerEnums,
                inNetwork: params.insuranceData.inNetwork.toUpperCase() as InsuranceInNetworkEnums,
              },
              learner: clientId ?? "",
            },
            {
              onSuccess: () => {
                notifySuccess("Insurance details updated successfully");
              },
              onError: () => {
                notifyError("Could not update insurance details");
              },
            },
          );
        } else {
          await createLearnerInsuranceMutation.mutateAsync(
            {
              input: {
                learnerId: clientId ?? "",
                insuranceType: convertDBString(params.insuranceData.insuranceType) as unknown as InsuranceTypeEnums,
                insurer: convertDBString(params?.insuranceData?.insurer) as unknown as InsurerEnums,
                inNetwork: params.insuranceData.inNetwork.toUpperCase() as InsuranceInNetworkEnums,
              },
            },
            {
              onSuccess: () => {
                notifySuccess("Insurance details created successfully");
              },
              onError: () => {
                notifyError("Could not create insurance details");
              },
            },
          );
        }
        await refetch();
      },
    });

  const formLoading = isFetching;

  useEffect(() => {
    if (data) {
      const insuranceNode = data.learnerById?.insuranceForLearner.edges[0]?.node;
      reset({
        insuranceData: {
          inNetwork: insuranceNode?.inNetwork
            ? (insuranceNode?.inNetwork?.toLowerCase() as InsuranceInNetworkEnums)
            : undefined,
          insuranceId: insuranceNode?.id,
          insurer: insuranceNode?.insuranceType
            ? (convertReadableString(insuranceNode?.insurer) as InsurerEnums)
            : undefined,
          insuranceType: insuranceNode?.insuranceType
            ? (convertReadableString(insuranceNode?.insuranceType) as InsuranceTypeEnums)
            : undefined,
        },
      });
    }
  }, [data, reset]);

  return (
    <div className="flex w-full flex-col items-start">
      <FormContainer onSubmit={onSubmit}>
        <DoubleColumnContainer>
          <RegisteredFormSelected
            isLoading={formLoading}
            formKey={`insuranceData.insuranceType`}
            title="Insurance Type"
            formState={formState}
            items={Object.values(ApiInsuranceDetailsInsuranceTypeChoices).map((o) => ({
              primary: convertReadableString(o),
            }))}
            placeholderText={""}
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormSelected
            isLoading={formLoading}
            formKey={`insuranceData.insurer`}
            title="Insurer"
            formState={formState}
            items={Object.values(ApiInsuranceDetailsInsurerChoices).map((o) => ({ primary: convertReadableString(o) }))}
            placeholderText={""}
          />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormRadioInput
            formState={formState}
            formKey="insuranceData.inNetwork"
            title="In-Network"
            items={inNetworkOptions}
          />
        </DoubleColumnContainer>
        <div className="mt-6">
          <SubmitButton
            isLoading={formState.isSubmitting}
            buttonText={data?.learnerById?.insuranceForLearner.edges[0]?.node ? "Update" : "Add"}
          />
        </div>
      </FormContainer>
    </div>
  );
};

import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { convertHeadersToObject } from "@utils/utils";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

import { FileUploadAndParseProps } from "@fronterahealth/frontera-ui-components";

import { FronteraGraphQLClient } from "@api/graphql/graphql-service";
import {
  ApiLearnerFileStatusChoices,
  CreateReportLearnerFileInputMutation,
  FileTypeEnum,
  GetReportLearnerFilesDocument,
  LearnerFileAssessmentTemplateTypeEnums,
  MarkLearnerFileAsUploadedMutation,
  StartLearnerFileValidationMutation,
  useCreateReportLearnerFileInputMutation,
  useDeleteLearnerFileMutation,
  useMarkLearnerFileAsUploadedMutation,
  useStartLearnerFileValidationMutation,
} from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { ApiStatusToFileStatus } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/UploadFiles/UploadFiles";
import { useEvaluationData } from "@providers/EvaluationProvider";

type ExtendedFileUploadAndParseProps = {
  S3BucketUrlRetriever: (params: {
    fileKind: string;
    fileName: string;
    mimeType: string;
    fileSize: number;
    assessmentTemplateType?: LearnerFileAssessmentTemplateTypeEnums;
  }) => Promise<{ bucket: string; fileId: string }>;
};

export const useGetS3BucketUrlRetriever: ({
  metadata,
  assessmentTemplateType,
}: {
  metadata?: object;
  assessmentTemplateType?: LearnerFileAssessmentTemplateTypeEnums;
}) => ExtendedFileUploadAndParseProps["S3BucketUrlRetriever"] = ({ metadata: _metadata }) => {
  const { evaluationId: reportId } = useParams();
  const createReportLearnerFileInputMutation = useCreateReportLearnerFileInputMutation({
    throwOnError: false,
  });
  const metadata = _metadata ? JSON.stringify(_metadata) : undefined;
  return async ({ fileKind, fileName, mimeType, fileSize, assessmentTemplateType }) => {
    console.debug(
      `%c<FILE-API> | S3BucketURLRetriever | Requesting S3 Bucket URL and creating file record |` +
        `%c fileKind: ${fileKind} fileName: ${fileName} mimeType: ${mimeType} reportId: ${reportId} metadata: ${JSON.stringify(metadata)}" `,
      `color:black`,
      `color:red`,
    );

    let res: CreateReportLearnerFileInputMutation | null;
    try {
      res = await createReportLearnerFileInputMutation.mutateAsync({
        input: {
          fileKind: fileKind as FileTypeEnum,
          fileSize,
          mimeType,
          originalFileName: fileName,
          reportId: reportId || "<missing-report-id>",
          metadata,
          assessmentTemplateType: assessmentTemplateType,
        },
      });

      datadogRum.addAction("create-learner-file", {
        fileMetadata: {
          fileKind,
          mimeType,
          fileName,
          metadata,
          fileId: res?.createReportLearnerFile?.learnerFile?.id,
        },
      });

      console.debug(
        `<FILE-API> | S3BucketURLRetriever | Received following bucket information | ` + `%c${JSON.stringify(res)}`,
        "color:red;",
      );
      const learnerFile = res?.createReportLearnerFile?.learnerFile;
      const bucket = learnerFile?.signedS3Url || "<missing-bucket-id>";
      const fileId = learnerFile?.id || "<missing-file-id>";
      //
      return {
        bucket,
        fileId,
      };
    } catch (err) {
      console.error(
        `<FILE-API> | S3BucketURLRetriever | ERROR Received following bucket information | ` +
          `%c${JSON.stringify(err)}`,
        "color:red;",
      );
      throw new Error(String(err));
    }
  };
};

export const useGetReportFileUploadCompleteCallback: () => FileUploadAndParseProps["reportFileUploadCompleteCallback"] =
  () => {
    const markLearnerFileAsUploadedMutation = useMarkLearnerFileAsUploadedMutation({
      throwOnError: false,
    });

    const startLearnerFileValidationMutation = useStartLearnerFileValidationMutation({
      throwOnError: false,
    });

    return async (fileId) => {
      console.debug(
        `<FILE-API> | reportFileUploadCompleteCallback | Reporting file upload complete to FDM | ` +
          `%cfileId: ${fileId}`,
        "color:red;",
      );
      let res: MarkLearnerFileAsUploadedMutation | null;
      let fileValidationRes: StartLearnerFileValidationMutation | null;
      try {
        res = await markLearnerFileAsUploadedMutation.mutateAsync({
          fileId,
        });

        datadogRum.addAction("mark-learner-file-as-uploaded", {
          fileMetadata: {
            fileId,
          },
        });

        console.debug(`<FILE-API> | reportFileUploadCompleteCallback | Done reporting file upload complete to FDM`);
        console.debug(``);
        const status = res?.markLearnerFileAsUploaded?.learnerFile?.status;
        console.debug({ status });

        console.debug(
          `<FILE-API> | startLearnerFileValidation | Reporting to start file parsing to FDM | ` + `%cfileId: ${fileId}`,
          "color:red;",
        );

        fileValidationRes = await startLearnerFileValidationMutation.mutateAsync({
          learnerFileId: fileId,
        });

        console.debug(`<FILE-API> | startLearnerFileValidation | Done reporting file parsing to FDM`);
        console.debug(
          fileValidationRes.startLearnerFileValidation?.status,
          fileValidationRes.startLearnerFileValidation?.learnerFile?.status,
        );

        return;
      } catch (err) {
        console.error(
          `<FILE-API> | reportFileUploadCompleteCallback | ERROR Received following bucket information | ` +
            `%c${JSON.stringify(err)}`,
          "color:red;",
        );
        throw new Error(String(err));
      }
    };
  };

export const useGetS3BucketUploadCallback: () => FileUploadAndParseProps["S3BucketUploadCallback"] = () => {
  return async (params) => {
    const { file, mimeType, url } = params;
    console.debug(
      `<FILE-API> | S3BucketUploadCallback | Sending file contents directly to S3 through S3 SDK | ` +
        `%c file: ${file} url: ${url} mimeType: ${mimeType}`,
      "color:red;",
    );
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: new Headers({ "Content-Type": mimeType }),
        body: file,
      });

      console.debug(`<FILE-API> | S3BucketUploadCallback | Done sending data to S3`);
      console.debug(``);
      if (response.status === 200) {
        console.debug({ response });
        datadogRum.addAction("learner-file-uploaded-successfully", {
          fileMetadata: {
            fileName: file.name,
            mimeType,
          },
        });
        return;
      } else {
        console.error(`<FILE-API> | S3BucketUploadCallback | Error in sending data`);
        throw new Error(`Non 200 error message: ${response.status} ${response.statusText}`);
      }
    } catch (err) {
      console.error("Error calling S3BucketUploadCallback", err);
      throw new Error(String(err));
    }
  };
};

export const useGetFileParseStatusRetriever: () => FileUploadAndParseProps["fileParseStatusRetriever"] = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { evaluationId: reportId } = useParams();
  // const headers = convertHeadersToObject(authHeaders);

  return useCallback(
    async (fileId: string) => {
      try {
        const token = await getAccessTokenSilently();
        const authHeaders: HeadersInit = new Headers();
        authHeaders.append("Authorization", `Bearer ${token}`);
        const headers = convertHeadersToObject(authHeaders);
        const response = await FronteraGraphQLClient.request(
          GetReportLearnerFilesDocument,
          { reportId, fileId },
          headers,
        );

        const apiStatus: ApiLearnerFileStatusChoices =
          response?.getReportLearnerFiles?.edges?.[0]?.node?.status ||
          ("<missing-status" as ApiLearnerFileStatusChoices as ApiLearnerFileStatusChoices);
        const status = ApiStatusToFileStatus[apiStatus];

        if (status === "parsing-failed") {
          datadogRum.addError("file-parsing-failed", {
            fileMetadata: {
              fileId,
              status,
            },
          });
          datadogRum.addAction("file-parsing-failed", {
            fileMetadata: {
              fileId,
              status,
            },
          });
        }
        if (
          status === "uploading-failed-bucket-upload" ||
          status === "uploading-failed-upload-notification" ||
          status === "uploading-failed-url-retrieval"
        ) {
          datadogRum.addError("file-upload-failed", {
            fileMetadata: {
              fileId,
              status,
            },
          });
          datadogRum.addAction("file-upload-failed", {
            fileMetadata: {
              fileId,
              status,
            },
          });
        }
        if (status === "done") {
          datadogRum.addAction("file-parsing-succeeded", {
            fileMetadata: {
              fileId,
              status,
            },
          });
        }
        console.debug(`<FILE-API> | fileParseStatusRetriever | Got the following parse status from FDM | ${status}`);
        console.debug(``);
        return {
          fileId,
          status,
        };
      } catch (err) {
        console.error(
          `<FILE-API> | fileParseStatusRetriever | ERROR Received error trying to get parse status | ` +
            `%c${JSON.stringify(err)}`,
          "color:red;",
        );
        throw new Error(String(err));
      }
    },
    [reportId, isAuthenticated],
  );
};

export const useGetRemoveFileCallback: () => FileUploadAndParseProps["removeFileCallback"] = () => {
  const deleteLearnerFileMutation = useDeleteLearnerFileMutation({
    throwOnError: false,
  });

  const { evaluationQuery } = useEvaluationData();

  const { refetch } = evaluationQuery;
  return async (fileId: string) => {
    try {
      if (fileId && !fileId.startsWith("<")) {
        await deleteLearnerFileMutation.mutateAsync({ fileId });
        await refetch();
        notifySuccess(`Successfully deleted file`);
      }
    } catch (err) {
      console.error("Error with deleting file", err);
      notifyError(`Failed to delete file. Something went wrong on our end`);
    }
  };
};

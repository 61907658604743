import { Paragraph, TextRun, convertInchesToTwip } from "docx";

import {
  SectionChildrenType,
  Style,
  createHeading,
  extractStyle,
  getIndent,
  getSpacing,
  parseHorizontalRule,
  parseImage,
  parseList,
  parseTable,
  parseText,
} from "@components/HtmlToDocx/DocxHelperFunctions";

export const parseHtmlToDocx = (htmlContent: string): SectionChildrenType[] => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(htmlContent, "text/html");
  const paragraphs: SectionChildrenType[] = [];
  const parseNode = (node: Node, styles: Style[] = []) => {
    const style = styles.reduce((merged, s) => ({ ...merged, ...s }), {});

    switch (node.nodeType) {
      case Node.ELEMENT_NODE: {
        const element = node as HTMLElement;
        const tagName = element.tagName.toLowerCase();
        const indent = getIndent(element);
        switch (tagName) {
          case "p":
          case "div":
            const paragraphdata = parseParagraph(element, styles);
            paragraphs.push(...paragraphdata);
            return null;
          case "span":
          case "strong":
          case "b":
          case "em":
          case "i": {
            return parseInlineElement(element, style);
          }
          case "h1":
          case "h2":
          case "h3":
          case "label":
          case "h4":
            const headingParagraph = createHeading(element, tagName, indent);
            if (headingParagraph) {
              paragraphs.push(headingParagraph);
            }
            break;
          case "ul":
          case "ol":
            const listParagraphs = parseList(element);
            paragraphs.push(...listParagraphs);
            break;
          case "img":
            const src = element.getAttribute("src");
            if (src) {
              const imageParagraphs = parseImage(element);
              paragraphs.push(
                new Paragraph({
                  style: "imageType",
                  children: imageParagraphs ? [imageParagraphs] : [],
                }),
              );
            }
            break;
          case "hr":
            const hrParagraph = parseHorizontalRule();
            paragraphs.push(hrParagraph);
            break;
          case "table":
            const tableData = parseTable(element);
            paragraphs.push(tableData);
            const spacingParagraph = new Paragraph({
              text: "",
              spacing: { after: 100 },
            });
            paragraphs.push(spacingParagraph); // pushing empty paragraph to add some space after table in docx
            break;
          default:
            parseChildren(element.childNodes, styles);
            break;
        }
        break;
      }
      case Node.TEXT_NODE: {
        const text = parseText(node.textContent || "").trim();
        if (text.length === 0) return null;
        return [
          new TextRun({
            text,
            bold: style.bold || false,
            italics: style.italic || false,
            color: style.color || undefined,
            highlight: style.backgroundColor || undefined,
            size: style.size || undefined,
          }),
        ];
      }
      default:
        return null;
    }
  };

  const parseChildren = (childNodes: NodeListOf<ChildNode>, styles: Style[] = []) => {
    const children: (TextRun | Paragraph)[] = [];
    childNodes.forEach((child) => {
      const result = parseNode(child, styles);

      if (result) {
        if (Array.isArray(result)) {
          children.push(...result);
        } else {
          children.push(result);
        }
      }
    });
    return children;
  };

  const parseInlineElement = (element: HTMLElement, style: Style): (Paragraph | TextRun)[] => {
    return parseChildren(element.childNodes, [
      style,
      extractStyle(element),
      {
        bold: element.tagName.toLowerCase() === "b" || element.tagName.toLowerCase() === "strong",
        italic: element.tagName.toLowerCase() === "i",
      },
    ]);
  };

  const parseParagraph = (element: HTMLElement, style: Style[]): Paragraph[] => {
    const children = parseChildren(element.childNodes, [...style, extractStyle(element)]);
    const indent = getIndent(element);
    const spacing = getSpacing(element);

    const paragraphs: Paragraph[] = [];

    // If there are children to add to paragraphs
    if (children.length > 0) {
      const paragraph = new Paragraph({
        children,
        run: { ...extractStyle(element) },
        indent: { left: convertInchesToTwip(indent) },
        spacing,
      });
      paragraphs.push(paragraph);
    }

    return paragraphs;
  };

  parseNode(htmlDoc.documentElement);

  return paragraphs;
};

import { LanguageMappingInterface } from "src/components/forms/interfaces";

import { LearnerAdditionalDetailsQuery } from "@api/graphql/types-and-hooks";

type LearnerAdditionalDetailsDataType = NonNullable<
  NonNullable<NonNullable<LearnerAdditionalDetailsQuery["learnerById"]>["learnerAdditionalDetails"]>
>;
export type AdditionalDetailsData = Omit<
  LearnerAdditionalDetailsDataType,
  "id" | "createdDate" | "learner" | "lastUpdatedDate"
>;

export const additionalDetailsMapping: LanguageMappingInterface<AdditionalDetailsData> = {
  anythingThatCameUpToBeMentioned: {
    en: "Is there anything that did not come up that you wanted to be sure to mention?",
    es: "",
  },
  anythingThatWeDidNotCoverEnough: {
    en: "Is there anything we did not cover enough?",
    es: "",
  },
  mainConcerns: {
    en: "What are your main concerns?",
    es: "",
  },
  whoReferredYou: {
    en: "Who were you referred to Frontera Health by?",
    es: "",
  },
  mchatResultForClient: {
    en: "Do you have an M-CHAT result for the client you would like to share?",
    es: "",
  },
};

import { config } from "@config/config";
import { UseQueryResult } from "@tanstack/react-query";
import { PropsWithChildren, createContext, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ApiReportSectionsAiGeneratedStatusChoices,
  GetReportByIdQuery,
  ReportType,
  ReportTypeEnums,
  useGetReportByIdQuery,
} from "@api/graphql/types-and-hooks";
import { FilterSectionSubRoute, getFilteredPageSectionsByLocation } from "@pages/EvaluationDetails/Components/helper";
import { EvaluationSubRoute } from "@pages/EvaluationDetails/EvaluationDetails";

interface EvaluationDataI {
  evaluationQuery: UseQueryResult<GetReportByIdQuery, unknown>;
}

export const EvaluationContext = createContext<EvaluationDataI | undefined>(undefined);

export const useEvaluationData = () => {
  const context = useContext(EvaluationContext);
  if (!context) {
    throw new Error("useEvaluationData must be used within an EvaluationProvider");
  }
  return context;
};

export const EvaluationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { evaluationId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const evaluationQuery = useGetReportByIdQuery(
    {
      reportId: evaluationId ?? "<missing-evaluation-id>",
      reportType: ReportTypeEnums.DiagnosisEvaluation,
    },
    {
      queryKey: ["get-evaluation-by-id", evaluationId, pathname],
      refetchOnWindowFocus: false,
      refetchInterval: (query) => {
        const currentRoute =
          (pathname.split("#")?.pop()?.split("/").pop() as FilterSectionSubRoute) ||
          ("upload-files" as FilterSectionSubRoute);
        const report = query.state.data?.getReports?.edges[0]?.node as ReportType;
        const sectionsForCurrentRoute = getFilteredPageSectionsByLocation(report, currentRoute);
        const someSectionsStillPredicting = sectionsForCurrentRoute.some(
          (s) => s.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
        );
        if (someSectionsStillPredicting) {
          return config.REPORT_POLLING_INTERVAL;
        } else {
          return false;
        }
      },
    },
  );

  if (!evaluationQuery || evaluationQuery.isLoading) return null;

  const evaluation = evaluationQuery.data?.getReports?.edges[0]?.node;

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as EvaluationSubRoute) || ("upload-files" as EvaluationSubRoute);

  if (evaluation && !evaluation?.learner?.id && currentRoute !== "client-details") navigate("client-details");

  return <EvaluationContext.Provider value={{ evaluationQuery }}>{children}</EvaluationContext.Provider>;
};

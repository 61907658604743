import {
  ReportSectionFieldsType,
  ReportSectionsType,
  useUpdateReportSectionFieldMutation,
} from "@api/graphql/types-and-hooks";
import { FieldComponent } from "@components/FieldComponent/FieldComponent";
import { notifyError } from "@components/notifications/notifications";
import { FieldBlockCardWrapper } from "@pages/EvaluationDetails/Components/FieldBlockCardWrappper";
import { useEvaluationData } from "@providers/EvaluationProvider";

interface FieldComponentWrapperProps {
  section: ReportSectionsType;
  field: ReportSectionFieldsType | undefined;
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
}

export const FieldComponentWrapper: React.FC<FieldComponentWrapperProps> = ({ section, field, permissions }) => {
  const { evaluationQuery } = useEvaluationData();
  const updateFieldTitle = useUpdateReportSectionFieldMutation({});

  const onEditTitle = async (newTitle: string) => {
    await updateFieldTitle
      .mutateAsync({
        reportSectionId: section.id ?? "<missing-section-id>",
        reportSectionFieldId: field?.id ?? "<missing-section-id>",
        title: newTitle,
      })
      .then((data) => {
        if (data.updateReportSectionField?.status) {
          evaluationQuery?.refetch();
        }
      })
      .catch((error) => {
        console.error("Error while updating field title : ", error);
        notifyError("Error Updating Field Title");
      });
  };

  if (!field) return null;

  return (
    <FieldComponent
      key={field.id}
      id={field.id}
      onEditTitle={onEditTitle}
      permissions={permissions}
      title={field.title}
      placeholder="Enter Title"
    >
      <FieldBlockCardWrapper permissions={permissions} section={section} field={field} />
    </FieldComponent>
  );
};

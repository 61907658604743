import { AI_GENERATED } from "@fronterahealth/frontera-ui-components";

import { useGoalBankShortTermGoalsQuery } from "@api/graphql/types-and-hooks";

export const useGetShortTermGoals = (selectedFolderId: string) => {
  const {
    data: shortTermGoals,
    refetch: refetchStg,
    isLoading: stgIsLoading,
  } = useGoalBankShortTermGoalsQuery(
    { folderId: selectedFolderId },
    {
      queryKey: ["short-term-goals-" + selectedFolderId],
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      refetchInterval: 200000,
      enabled: !!selectedFolderId && selectedFolderId !== AI_GENERATED,
    },
  );

  return {
    shortTermGoals,
    refetchStg,
    stgIsLoading,
  };
};

export const useGetShortTermGoalsByLongTermGoalBankId = (selectedFolderId: string, longTermGoalBankId: string) => {
  const {
    data: shortTermGoals,
    refetch: refetchStg,
    isLoading: stgIsLoading,
  } = useGoalBankShortTermGoalsQuery(
    { folderId: selectedFolderId, longTermGoalBankId },
    {
      queryKey: ["short-term-goals-" + selectedFolderId + "long-term-id-" + longTermGoalBankId],
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      refetchInterval: 200000,
      enabled: !!selectedFolderId && selectedFolderId !== AI_GENERATED,
    },
  );

  return {
    shortTermGoals,
    refetchStg,
    stgIsLoading,
  };
};

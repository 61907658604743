import React from "react";
import { useParams } from "react-router-dom";

import { TableBuilder } from "@fronterahealth/frontera-ui-components";

import { useAddUpdateAssessmentHistoryOfOtherServicesMutation } from "@api/graphql/types-and-hooks";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

const historyOfServiceHeaders = ["service", "hours", "location", "provider", "id"] as const;
type historyOfServiceHeaderType = (typeof historyOfServiceHeaders)[number];
type HistoryOfService = Record<historyOfServiceHeaderType, string>;

interface HistoryOfOtherServicesTableProps {
  historyOfServices: HistoryOfService[];
  isDisplayModeForReview?: boolean;
}
export const HistoryOfOtherServicesTable: React.FC<HistoryOfOtherServicesTableProps> = ({
  historyOfServices,
  isDisplayModeForReview,
}) => {
  const { refetch } = useAssessmentBuilderData();
  const { assessmentId } = useParams();
  const { mutateAsync } = useAddUpdateAssessmentHistoryOfOtherServicesMutation({});
  return (
    <div className="pr-12">
      <TableBuilder<HistoryOfService>
        displayOnlyMode={isDisplayModeForReview}
        valueValidator={{
          hours: (hours) => {
            const value = parseInt(hours);
            if (isNaN(value)) return "Hours field must be number";
            return null;
          },
        }}
        labelMapping={{
          hours: "Hours",
          location: "Location",
          provider: "Provider",
          service: "Service",
        }}
        title={"History of Services"}
        headers={["hours", "location", "provider", "service"]}
        data={historyOfServices}
        onRowAdd={async (row) => {
          await mutateAsync({
            assessmentId: assessmentId || "<missing-id>",
            assessmentHistoryOfService: { ...row, hours: parseFloat(row.hours) },
          });
          await refetch();
        }}
        onRowDelete={async (row) => {
          await mutateAsync({
            assessmentId: assessmentId || "<missing-id>",
            assessmentHistoryOfService: { ...row, hours: parseFloat(row.hours), isDeleted: true },
          });
          await refetch();
        }}
        onRowEdit={async (_, row) => {
          await mutateAsync({
            assessmentId: assessmentId || "<missing-id>",
            assessmentHistoryOfService: { ...row, hours: parseFloat(row.hours) },
          });
          await refetch();
        }}
      />
    </div>
  );
};

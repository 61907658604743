import { PlusIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

import { Button } from "@fronterahealth/frontera-ui-components";

import {
  CreateRecommendationBankItemMutationVariables,
  useCreateRecommendationBankItemMutation,
} from "@api/graphql/types-and-hooks";
import { renderRecommendationBankFormDialog } from "@components/RecommendationsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifySuccess } from "@components/notifications/notifications";

interface RecommendationBankRecommendationsAddNewFormProps {
  folderId: string;
  refetchRecommendations: () => void;
  hasRecommendations: boolean;
}

export const RecommendationBankRecommendationsAddNewForm: React.FC<
  RecommendationBankRecommendationsAddNewFormProps
> = ({ folderId, refetchRecommendations, hasRecommendations = false }) => {
  const [open, setOpen] = useState(false);
  const createRecommendation = useCreateRecommendationBankItemMutation({});
  const { formState, RegisteredFormInput, RegisteredFormTextArea, reset, onSubmit } =
    useFormUtils<CreateRecommendationBankItemMutationVariables>({
      defaultValues: { input: { recommendationContent: "" } },
      mutationFn: async (params) => {
        createRecommendation.mutateAsync(
          {
            input: {
              recommendationContent: params.input.recommendationContent,
              recommendationTitle: params.input.recommendationTitle,
              recommendationCategory: params.input.recommendationCategory,
              folderId: folderId || params.input.folderId,
            },
          },
          {
            onSuccess: async () => {
              await refetchRecommendations();
              notifySuccess(`Successfully Created New Recommendation`);
              setOpen(false);
              reset();
            },
            onError: async (error: unknown) => {
              console.error(`Error when saving New Recommendation`, error);
              setOpen(true);
            },
          },
        );
      },
    });

  const dialog = renderRecommendationBankFormDialog({
    title: "Add Recommendation",
    openForm: open,
    setOpenForm: setOpen,
    onSubmit: async () => {
      await onSubmit();
    },
    children: (
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormInput formKey="input.recommendationTitle" formState={formState} label="Title" required />
        <RegisteredFormTextArea
          formKey="input.recommendationContent"
          formState={formState}
          label="Content"
          required
          rows={6}
        />
      </FormContainer>
    ),
  });

  return (
    <>
      {hasRecommendations ? (
        <div
          onClick={() => setOpen(true)}
          className="group cursor-pointer content-center items-center justify-center rounded-lg border border-dashed border-limestone-200 px-6 hover:border-globemallow-600"
        >
          <div className="flex items-center justify-center">
            <PlusIcon className="size-6 text-globemallow-600 group-hover:text-globemallow-500" />
            <Button
              text="Add New"
              className="pl-1 text-base group-hover:text-globemallow-500"
              appearance="text"
              buttonAction="action"
              onClick={(e) => {
                if (e) {
                  e.stopPropagation();
                }
                setOpen(true);
              }}
            />
          </div>
          {dialog}
        </div>
      ) : (
        <>
          <Button appearance="primary" buttonAction="action" text={"+ Add New"} onClick={() => setOpen(true)} />
          {dialog}
        </>
      )}
    </>
  );
};

import { LanguageMappingInterface } from "src/components/forms/interfaces";

import { LearnerEarlyInterventionServicesQuery } from "@api/graphql/types-and-hooks";

export type LearnerEarlyInterventionServicesDataType = NonNullable<
  NonNullable<NonNullable<LearnerEarlyInterventionServicesQuery["learnerById"]>["learnerEarlyInterventionServices"]>
>;
export const earlyInterventionServicesMapping: LanguageMappingInterface<LearnerEarlyInterventionServicesDataType> = {
  clientEarlyInterventionEnrollment: {
    en: "Has the client ever been enrolled in early intervention services?",
    es: "¿Alguna vez el cliente ha estado inscrito en servicios de intervención temprana?",
  },
  earlyInterventionStartAge: {
    en: "At what age did the client begin early intervention services?",
    es: "¿A qué edad comenzó el cliente los servicios de intervención temprana?",
  },
  dismissedOrTransferredToPreschool: {
    en: "Was the client dismissed or did they transfer to preschool?",
    es: "¿Se despidió al cliente o se transfirieron a preescolar?",
  },
  specialEducationOrOutsideServices: {
    en: "Does the client receive special education or outside services now?",
    es: "¿El cliente recibe educación especial o servicios externos ahora?",
  },
};

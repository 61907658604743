import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

import { Badge, Heading, Small } from "@fronterahealth/frontera-ui-components";

export const FeatureFlagPreviewer: React.FC = () => {
  const flags = useFlags<FeatureFlags>();

  return (
    <div className="flex flex-col px-4 py-2 bg-globemallow-50 border border-globemallow-200 rounded-md w-full">
      <Heading className="text-sm underline" type="h4">
        Feature Flags
      </Heading>
      <Small className="my-1 text-xs italic">(This too is behind a feature flag)</Small>
      <div className="max-h-24 overflow-auto">
        {Object.entries(flags).map(([key, value]) => {
          return (
            <div key={key} className="flex items-center justify-between gap-x-1">
              <Small displayType="loud" className="hover:text-text-primary text-xs">
                <a
                  href={`https://app.launchdarkly.com/projects/default/flags/${key}/targeting?env=development&env=staging&env=production&selected-env=development`}
                >
                  {key}
                </a>
              </Small>
              <Badge
                text={Boolean(value) ? "enabled" : "disabled"}
                appearance={Boolean(value) ? "success" : "neutral"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

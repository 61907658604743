import { TagIcon } from "@heroicons/react/24/outline";
import React, { PropsWithChildren } from "react";

import { Small } from "@fronterahealth/frontera-ui-components";
import { PopoverMenu } from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

import { useRemoveTargetMutation } from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

interface TargetCardProps extends PropsWithChildren {
  onClick: () => void;
  title: string;
  targetId: string;
  refetchGoals: () => Promise<void>;
  onSaveToFolder?: () => void;
}

export const TargetCard: React.FC<TargetCardProps> = ({
  title,
  onClick,
  children,
  refetchGoals,
  targetId,
  onSaveToFolder,
}) => {
  const { assessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || "<missing-learner-id>";
  const removeTargetMutation = useRemoveTargetMutation({});
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);

  return (
    <div>
      <div
        className="hover:bg-limestone-100 rounded-md py-2 pl-4 pr-2 mb-1 cursor-pointer bg-limestone-50"
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <div className="flex items-center justify-between">
          <div>
            <Small displayType="loud" colorType="primary">
              <TagIcon className="mr-2 h-4 w-4 inline-block" />
              {title}
            </Small>
          </div>

          <PopoverMenu
            openDirection="to-left"
            menuItems={[
              {
                label: "Edit",
                callback: () => onClick(),
              },

              {
                label: "Save to Folder...",
                callback: () => {
                  if (onSaveToFolder) onSaveToFolder();
                },
              },

              {
                label: deleteLoading ? "Deleting..." : "Delete",
                callback: async () => {
                  setDeleteLoading(true);
                  try {
                    await removeTargetMutation.mutateAsync({ learner: learnerId, targetId });
                    notifySuccess("Successfully Deleted Target");
                    await refetchGoals();
                    setDeleteLoading(false);
                  } catch (err) {
                    console.error("Failed To Delete Target", err);
                    notifyError("Failed To Delete Target");
                  }
                },
              },
            ]}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

import { useCallback, useEffect } from "react";

import { Paragraph, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  ShortTermTimelineEstimationEnums,
  UpdateGoalBankShortTermGoalInput,
  useUpdateGoalBankShortTermGoalMutation,
} from "@api/graphql/types-and-hooks";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { renderGoalBankFormDialog } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

export interface ShortTermGoalBankFormProps {
  description?: string;
  goalName: string;
  id: string;
  longTermGoalBank: {
    id: string;
    goalName: string;
  };
  masteryCriteria?: string;
  medicalRationale?: string;
  programGoal?: string;
  timelineEstimationType?: ShortTermTimelineEstimationEnums;
  timelineEstimationValue?: number;
}

interface GoalBankShortTermGoalsEditFormProps {
  folderId: string;
  refetchGoals: () => void;
  selectedGoalBankShortTermGoal: ShortTermGoalBankFormProps;
  openEditForm: boolean;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GoalBankShortTermGoalsEditForm: React.FC<GoalBankShortTermGoalsEditFormProps> = ({
  refetchGoals,
  selectedGoalBankShortTermGoal,
  openEditForm,
  setOpenEditForm,
  folderId,
}) => {
  const updateShortTermGoal = useUpdateGoalBankShortTermGoalMutation();
  const { longTermGoals } = useGetLongTermGoals(folderId);

  const handleSubmit = useCallback(
    async (params: UpdateGoalBankShortTermGoalInput) => {
      try {
        await updateShortTermGoal.mutateAsync({
          input: {
            description: params?.description,
            goalBankShortTermId: selectedGoalBankShortTermGoal?.id,
            goalName: params?.goalName,
            longTermGoalBankId: params?.longTermGoalBankId,
            masteryCriteria: params?.masteryCriteria,
            medicalRationale: params?.medicalRationale,
            programGoal: params?.programGoal,
            timelineEstimationValue: params?.timelineEstimationValue ? Number(params?.timelineEstimationValue) : null,
            timelineEstimationType: params?.timelineEstimationType
              ? (convertDBString(params?.timelineEstimationType) as ShortTermTimelineEstimationEnums)
              : null,
          },
        });
        refetchGoals();
        notifySuccess("Successfully Updated Short Term Goal");
        setOpenEditForm(false);
      } catch (error) {
        console.error("Error when saving Short Term Goal", error);
      }
    },
    [updateShortTermGoal, selectedGoalBankShortTermGoal, refetchGoals, setOpenEditForm],
  );

  const { formState, RegisteredFormInput, RegisteredFormTextArea, RegisteredFormSelected, reset, onSubmit } =
    useFormUtils<UpdateGoalBankShortTermGoalInput>({
      mutationFn: handleSubmit,
    });

  useEffect(() => {
    if (selectedGoalBankShortTermGoal) {
      reset({
        description: selectedGoalBankShortTermGoal?.description,
        goalBankShortTermId: selectedGoalBankShortTermGoal?.id,
        goalName: selectedGoalBankShortTermGoal?.goalName,
        longTermGoalBankId: selectedGoalBankShortTermGoal?.longTermGoalBank?.id,
        masteryCriteria: selectedGoalBankShortTermGoal?.masteryCriteria,
        medicalRationale: selectedGoalBankShortTermGoal?.medicalRationale,
        programGoal: selectedGoalBankShortTermGoal?.programGoal,
        timelineEstimationValue: selectedGoalBankShortTermGoal?.timelineEstimationValue,
        timelineEstimationType: convertReadableString(
          selectedGoalBankShortTermGoal?.timelineEstimationType,
        ) as ShortTermTimelineEstimationEnums,
      });
    }
  }, [reset, selectedGoalBankShortTermGoal]);

  const dialog = renderGoalBankFormDialog({
    title: "Edit Short Term Goal",
    openForm: openEditForm,
    setOpenForm: setOpenEditForm,
    onSubmit: onSubmit,
    children: (
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormInput formKey="goalName" formState={formState} label="Goal Name" />
        <RegisteredFormSelected
          required={true}
          formKey="longTermGoalBankId"
          formState={formState}
          items={
            longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
              return {
                id: edge?.node?.id,
                primary: edge?.node?.goalName,
                secondary: convertReadableString(edge?.node?.goalType),
              };
            }) as SelectItem[]
          }
          title="Long Term Goal"
          placeholderText={"Select Long Term Goal"}
        />
        <RegisteredFormTextArea
          required={false}
          rows={4}
          formKey="description"
          formState={formState}
          label="Description"
        />
        {/* // Note: Commenting this out for now because it might come back in the future  */}
        {/* <RegisteredFormInput
          required={false}
          formKey="medicalRationale"
          formState={formState}
          label="Medical Rationale"
        /> */}
        {/* <RegisteredFormInput required={false} formKey="programGoal" formState={formState} label="Program Goal" /> */}
        <RegisteredFormInput
          required={false}
          formKey="masteryCriteria"
          formState={formState}
          label="Mastery Criteria"
        />
        <Paragraph displayType="loud" colorType="primary">
          Expected Mastery Date
        </Paragraph>
        <div className="w-full flex items-center justify-start mt-2 gap-x-4">
          <RegisteredFormSelected
            required={false}
            formKey="timelineEstimationValue"
            formState={formState}
            items={
              Array.from({ length: 60 }, (_, index) => ({
                primary: `${index + 1}`,
              })) as unknown as SelectItem[]
            }
            title="Duration"
            placeholderText={"Select Duration"}
            className="w-full z-50"
          />
          <RegisteredFormSelected
            required={false}
            formKey="timelineEstimationType"
            formState={formState}
            items={
              Object.values(ShortTermTimelineEstimationEnums).map((o) => ({
                primary: convertReadableString(o),
              })) as SelectItem[]
            }
            title="Time Period"
            className="w-full z-50"
            placeholderText={"Select Time Period"}
          />
        </div>
      </FormContainer>
    ),
  });

  return <>{dialog}</>;
};

import { useEffect, useState } from "react";

import {
  ApiOnboardingFormStatusStatusChoices,
  LearnerAllIntakeDataQuery,
  useLearnerAllIntakeDataQuery,
} from "@api/graphql/types-and-hooks";
import { LanguageObjectType } from "@components/forms/interfaces";
import { QuestionData, convertOnboardingQuestionListToQuestionMap } from "@components/forms/utils";
import { getAdditionalDetailsData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/AdditionalDetails/AdditionalDetails";
import { additionalDetailsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/AdditionalDetails/AdditionalDetails.languageMapping";
import { behaviorsQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Behaviors/BehaviorsQuestions.languageMapping";
import { extractEmergencyContactData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/ClientDetails/EmergencyContact";
import { extractGeneralDetailsData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/ClientDetails/GeneralDetails";
import { emergencyContactMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/ClientDetails/Mappings/emergencyContactMapping";
import { generalDetailsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/ClientDetails/Mappings/generalDetailsMapping";
import { communicationQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Communication/CommunicationQuestions.languageMapping";
import {
  getLabelsFromMapping,
  translateObjectKeys,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/DataProcessingHelpers";
import { developmentalMilestonesQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/DevelopmentalMilestone/DevelopmentalMilestonesQuestions.languageMapping";
import { extractEarlyInterventionServicesData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/EarlyInterventionServices/EarlyInterventionServices";
import { earlyInterventionServicesMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/EarlyInterventionServices/earlyInterventionServicesMapping";
import { extractEducationData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/Education/Education";
import { educationMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/Education/educationMapping";
import { extractBirthHistoryData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/BirthHistory/BirthHistory";
import { learnerBirthHistoryLanguageMap } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/BirthHistory/BirthHistory.languageMapping";
import { extractMedicalHistoryData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/MedicalHistory/MedicalHistory";
import {
  MedicalHistoryDataType,
  medicalHistoryMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/MedicalHistory/MedicalHistory.languageMapping";
import { medicalHistoryConditionsQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/MedicalHistory/MedicalHistoryQuestions.languageMapping";
import { extractGuardianDetailsData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/HomeEnvironment/GuardianDetails";
import { extractLivingConditionData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/HomeEnvironment/LivingConditions";
import { guardianDetailsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/HomeEnvironment/Mappings/guardianDetailsMapping";
import { livingConditionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/HomeEnvironment/Mappings/livingConditionsMapping";
import { extractInsuranceData } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Insurance/Insurance";
import { insuranceMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Insurance/insuranceMapping";
import { tendenciesQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Tendencies/TendenciesQuestions.languageMapping";

interface FormData {
  [key: string]: string | boolean | number | null | undefined;
}

interface QuestionsMapping {
  [key: string]: LanguageObjectType;
}

export const useLearnerIntakeData = (learnerId: string | null | undefined) => {
  const [formData, setFormData] = useState<FormData>({});
  const [isLearnerOnboardingIntakeDataExists, setIsLearnerOnboardingIntakeDataExists] = useState<boolean>(false);

  const { data, isLoading } = useLearnerAllIntakeDataQuery(
    {
      learnerId: learnerId || "no-id",
    },
    {
      enabled: !!learnerId,
      queryKey: ["learner-by-id", learnerId],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const chosenLanguage = "en";

  const processAndAddBulkQuestions = (
    queryData: LearnerAllIntakeDataQuery,
    pageOrigin: string,
    formDataKey: string,
    questionsMapping: QuestionsMapping,
  ) => {
    const questions = queryData.learnerById?.onboardingQuestionsForLearner?.edges
      ?.filter((question) => question?.node?.questionPageOrigin?.includes(pageOrigin))
      ?.map((edge) => ({
        ...edge?.node,
      }));

    const formattedQuestions = convertOnboardingQuestionListToQuestionMap(questions as QuestionData[]);
    addFormData(formDataKey, translateObjectKeys(formattedQuestions, "en", questionsMapping));
  };

  useEffect(() => {
    if (data && data.learnerById) {
      const intakePages = data.learnerById.formStatusForLearner?.edges?.map((edge) => edge?.node);
      const intakeInProgress =
        intakePages.filter((page) => page?.status === ApiOnboardingFormStatusStatusChoices.Completed).length > 0;
      setIsLearnerOnboardingIntakeDataExists(intakeInProgress);

      addFormData(
        "generalDetails",
        getLabelsFromMapping(extractGeneralDetailsData(data), chosenLanguage, generalDetailsMapping),
      );

      addFormData(
        "emergencyContact",
        getLabelsFromMapping(extractEmergencyContactData(data), chosenLanguage, emergencyContactMapping),
      );

      addFormData(
        "guardianDetails",
        getLabelsFromMapping(extractGuardianDetailsData(data), chosenLanguage, guardianDetailsMapping),
      );

      addFormData(
        "livingCondition",
        getLabelsFromMapping(extractLivingConditionData(data), chosenLanguage, livingConditionsMapping),
      );

      addFormData(
        "insuranceDetails",
        getLabelsFromMapping(extractInsuranceData(data), chosenLanguage, insuranceMapping),
      );

      addFormData(
        "medicalHistory",
        getLabelsFromMapping(extractMedicalHistoryProcessingData(data), chosenLanguage, medicalHistoryMapping),
      );

      addFormData(
        "birthHistory",
        getLabelsFromMapping(extractBirthHistoryData(data), chosenLanguage, learnerBirthHistoryLanguageMap),
      );

      addFormData("education", getLabelsFromMapping(extractEducationData(data), chosenLanguage, educationMapping));

      addFormData(
        "earlyInterventionServices",
        getLabelsFromMapping(
          extractEarlyInterventionServicesData(data),
          chosenLanguage,
          earlyInterventionServicesMapping,
        ),
      );

      processAndAddBulkQuestions(data, "tendencies", "tendencies", tendenciesQuestionsMapping);

      processAndAddBulkQuestions(data, "behaviors", "behaviors", behaviorsQuestionsMapping);

      processAndAddBulkQuestions(data, "communication", "communication", communicationQuestionsMapping);

      processAndAddBulkQuestions(
        data,
        "development-milestones",
        "developmentalMilestone",
        developmentalMilestonesQuestionsMapping,
      );

      addFormData(
        "additionalDetails",
        getLabelsFromMapping(getAdditionalDetailsData(data), chosenLanguage, additionalDetailsMapping),
      );
    }
  }, [data]);

  const extractMedicalHistoryProcessingData = (queryData: LearnerAllIntakeDataQuery) => {
    const medicalHistory = extractMedicalHistoryData(queryData) as unknown as MedicalHistoryDataType;

    const medicationQuestions = queryData.learnerById?.onboardingQuestionsForLearner?.edges
      ?.filter((question) => question?.node?.questionPageOrigin?.includes("medical-history"))
      ?.map((edge) => ({
        ...edge?.node,
      }));

    const formattedQuestions = convertOnboardingQuestionListToQuestionMap(medicationQuestions as QuestionData[]);
    return {
      ...medicalHistory,
      onboardingQuestionsForLearner: translateObjectKeys(
        formattedQuestions,
        chosenLanguage,
        medicalHistoryConditionsQuestionsMapping,
      ),
    };
  };

  // eslint-disable-next-line
  const addFormData = (key: string, value: any) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const getIntakeJSONData = () => JSON.stringify(formData);

  return { getIntakeJSONData, formData, isLoading, isLearnerOnboardingIntakeDataExists };
};

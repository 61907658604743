import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  UpdateLearnerCaregiverDetailsMutationVariables,
  useLearnerByIdQuery,
  useUpdateLearnerCaregiverDetailsMutation,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import {
  NEAREST_LOCATIONS_OPTIONS,
  REFERRAL_SOURCE_OPTIONS,
  SMSAgreementItem,
} from "@pages/NewClient/NewClient.interfaces";

export const ClientDetailsCaregivers: React.FC = () => {
  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch, isFetching } = learnerDetailsQuery;

  const updateLearnerCaregiverDetailsMutation = useUpdateLearnerCaregiverDetailsMutation({});

  const { data: updateData } = updateLearnerCaregiverDetailsMutation;

  useEffect(() => {
    if (updateData?.updateLearnerCaregiverDetails) {
      if (updateData?.updateLearnerCaregiverDetails.status) {
        notifySuccess(
          updateData.updateLearnerCaregiverDetails?.message ? updateData.updateLearnerCaregiverDetails?.message : "",
        );
      } else {
        notifyError(
          updateData.updateLearnerCaregiverDetails?.message ? updateData.updateLearnerCaregiverDetails?.message : "",
        );
      }
    }
  }, [updateData]);

  const {
    formState,
    onSubmit,
    RegisteredFormSelected,
    RegisteredFormInput,
    RegisteredPhoneNumberInput,
    reset,
    RegisteredCheckboxList,
  } = useFormUtils<UpdateLearnerCaregiverDetailsMutationVariables>({
    mutationFn: async (params) => {
      await updateLearnerCaregiverDetailsMutation.mutateAsync({
        ...params,
        caregiverDetails: {
          ...params.caregiverDetails,
          smsAgreement: params.caregiverDetails.smsAgreement === ("yes" as unknown as boolean),
        },
        learner: clientId ? clientId : "",
      });
      await refetch();
    },
  });

  useEffect(() => {
    if (data) {
      const caregiver = data.learnerById?.relationshipSet.edges?.[0]?.node?.caregiver?.caregivermetadata;
      reset({
        caregiverDetails: {
          caregiverId: caregiver?.id || "",
          firstName: caregiver?.firstName || "",
          lastName: caregiver?.lastName || "",
          email: caregiver?.email || "",
          phoneNumber1: caregiver?.phoneNumber1 || "",
          nearestLocation: caregiver?.nearestLocation || "",
          referralSource: caregiver?.referralSource || "",
          smsAgreement: caregiver?.smsAgreement || false,
        },
      });
    }
  }, [data]);

  const formLoading = isFetching;

  const isReadonly = data && data.learnerById?.relationshipSet.edges?.[0]?.node?.caregiver?.auth0User !== null;

  return (
    <div className="flex w-full flex-col items-start">
      <FormContainer onSubmit={onSubmit}>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="caregiverDetails.firstName"
            inputSize="full"
            formState={formState}
            label="First Name"
          />
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="caregiverDetails.lastName"
            inputSize="full"
            formState={formState}
            label="Last Name"
          />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="caregiverDetails.email"
            inputSize="full"
            formState={formState}
            label="Email Address"
            readOnly={isReadonly}
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredPhoneNumberInput
            formKey="caregiverDetails.phoneNumber1"
            formState={formState}
            label="Phone Number"
            readOnly={isReadonly}
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormSelected
            isLoading={formLoading}
            formKey="caregiverDetails.nearestLocation"
            formState={formState}
            placeholderText={""}
            title="Nearest Location"
            items={NEAREST_LOCATIONS_OPTIONS.map((o) => ({ primary: o }))}
          />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormSelected
            isLoading={formLoading}
            formKey="caregiverDetails.referralSource"
            formState={formState}
            placeholderText={""}
            title="Referral Source"
            required={false}
            items={REFERRAL_SOURCE_OPTIONS.map((o) => ({ primary: o }))}
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredCheckboxList
            formKey="caregiverDetails.smsAgreement"
            formState={formState}
            required={false}
            title="SMS Agreement"
            items={SMSAgreementItem}
          />
        </DoubleColumnContainer>
        <div className="mt-6">
          <SubmitButton isLoading={formState.isSubmitting} buttonText="Update" />
        </div>
      </FormContainer>
    </div>
  );
};

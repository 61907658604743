import { GoalBankCard } from "@fronterahealth/frontera-ui-components";

interface AddTargetsBankCardProps {
  id: string;
  targetName: string;
  targetDescription?: string | undefined | null;
  selected: boolean;
  onClick: () => void;
}

export const AddTargetsBankCard: React.FC<AddTargetsBankCardProps> = ({
  id,
  targetName,
  targetDescription,
  selected,
  onClick,
}) => {
  return (
    <GoalBankCard
      data-testid="goal-bank-card"
      key={id}
      id={id}
      title={targetName}
      subtitle=""
      body={targetDescription ?? ""}
      selected={selected}
      onClick={onClick}
    />
  );
};

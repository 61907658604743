// import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

import { Button, Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

export const Login: React.FC = () => {
  const { loginWithRedirect, user, error } = useAuth0();
  if (user) return <Navigate to="/clients" />;
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <Heading type="h1" className="mb-10 text-text-secondary">
        Frontera Portal
      </Heading>
      {error && (
        <div className="flex flex-col items-center">
          <Heading type="h3" className="text-red-500">
            Error From Auth0
          </Heading>
          <Paragraph>Something went wrong on our when we tried logging you in. Here is our error message: </Paragraph>
          <span className="my-4 text-red-700">{error.message}</span>
        </div>
      )}
      <Button
        onClick={() => {
          loginWithRedirect({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            },
          });
        }}
        appearance="primary"
        text="Sign in to Continue"
      />
    </div>
  );
};

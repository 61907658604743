import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useOutletContext, useParams } from "react-router-dom";

import { useGetSessionEngagementScoreBySessionIdQuery } from "@api/graphql/types-and-hooks";
import { useGetSesionEngagementInsightQuery } from "@api/graphql/types-and-hooks";
import "@components/SessionAnalyzer/OverTimeEngagementChart/graph.css";
import { notifyError } from "@components/notifications/notifications";
import { transformDataForIntervals } from "@pages/SessionAnalyzer/DataHelpers";
import { sessionDetailOutletContext } from "@pages/SessionAnalyzer/SessionDetails/SessionDetails";
import { getChartOptions } from "@pages/SessionAnalyzer/SessionDetails/SocialEngagement/GraphOptions";
import { GraphTooltip } from "@pages/SessionAnalyzer/SessionDetails/SocialEngagement/GraphTooltip";

interface SocialEnagagementProps {
  date?: Date;
}

export interface AnnotationType {
  startTime: number;
  endTime: number;
  engagement: number | null;
  engagementInsight: string;
  engagementType: string;
}
export interface Datum {
  time: number;
  engagement: number | null;
  engagementTime: number;
  engagemnetInsight: string;
}

export const SocialEnagagement: React.FC<SocialEnagagementProps> = () => {
  const outletContext = useOutletContext<sessionDetailOutletContext>();
  const { sessionId } = useParams();

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState<AnnotationType | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const [isTooltipInteracting, setIsTooltipInteracting] = useState<boolean>(false);
  const [selectedDataPointIndex, setSelectedDataPointIndex] = useState<number | null>(null);
  const [annotationData, setAnnotationData] = useState<AnnotationType[]>([]);

  const { data, isFetching, error } = useGetSessionEngagementScoreBySessionIdQuery(
    {
      sessionId: sessionId || "",
      videoDuration: outletContext.videoDuration,
    },
    {
      queryKey: ["getSessionEngagementScoreBySessionIdQuery"],
      retry: false,
      refetchOnWindowFocus: false,
      enabled: sessionId !== undefined,
    },
  );

  const { data: insightData, isFetching: isInsightfetching } = useGetSesionEngagementInsightQuery(
    {
      sessionId: sessionId || "",
    },
    {
      queryKey: ["getSessionEngagementInsightQuery"],
      retry: false,
      refetchOnWindowFocus: false,
      enabled: sessionId !== undefined,
    },
  );

  const series = useMemo(
    () =>
      [
        {
          name: "Engagement",
          data:
            outletContext.videoDuration && data?.getSessionEngagementScoreBySessionId
              ? transformDataForIntervals(
                  data?.getSessionEngagementScoreBySessionId.map((d) => ({
                    x: d?.timeInMinute ?? 0,
                    y: d?.engagementScore ?? null,
                  })),
                  outletContext.videoDuration,
                )
              : [],
        },
      ] as unknown as ApexAxisChartSeries,
    [data, outletContext.videoDuration],
  );

  useEffect(() => {
    if (error) {
      notifyError("No engagement data found for this session.");
    }
  }, [error]);

  useEffect(() => {
    if (insightData && series) {
      const annotation = insightData?.getSessionEngagementInsight?.edges.map((point) => {
        const startTimeInMinutes = point?.node?.startTime ? point.node.startTime / 60 : 0;
        const graphData = series[0]?.data || [];

        const closestPoint = graphData.reduce(
          (closest, dataPoint) => {
            if (typeof dataPoint === "object" && dataPoint !== null && "x" in dataPoint) {
              return Math.abs(dataPoint.x - startTimeInMinutes) < Math.abs(closest.x - startTimeInMinutes)
                ? dataPoint
                : closest;
            }
            return closest;
          },
          (graphData[0] as { x: number; y: number }) || { x: 0, y: 0 },
        );

        const engagement = closestPoint ? closestPoint.y : 0;

        return {
          startTime: point?.node?.startTime,
          engagement,
          endTime: point?.node?.endTime,
          engagementInsight: point?.node?.description || "",
          engagementType: point?.node?.insightType,
          closestX: closestPoint ? closestPoint.x : 0,
        };
      }) as unknown as AnnotationType[];

      setAnnotationData(annotation as unknown as AnnotationType[]);
    }
  }, [data, insightData, series]);

  const handleAnnotationClick = (
    data: AnnotationType,
    dataPointIndex: number,
    position: { left: number; top: number },
  ) => {
    if (dataPointIndex === selectedDataPointIndex) return;

    setSelectedDataPointIndex(dataPointIndex);
    setIsTooltipInteracting(true);
    setTooltipData(data);
    setTooltipPosition(position);
    setTooltipVisible(true);
  };
  const options = getChartOptions(
    series,
    annotationData,
    selectedDataPointIndex,
    handleAnnotationClick,
    isTooltipInteracting,
    setIsTooltipInteracting,
    outletContext.videoDuration,
    outletContext.handleStartToPlay,
  );

  const handleTooltipClose = () => {
    setTooltipVisible(false);
    setIsTooltipInteracting(false);
    setSelectedDataPointIndex(null);
  };

  if (outletContext.videoUrl === "" && !outletContext.mediaLoading) {
    return (
      <div className="bg-white py-4 px-8 rounded-lg shadow-card-shadow w-full">No Media Found For This Session.</div>
    );
  }

  if (error) {
    return (
      <div className="bg-white py-4 px-8 rounded-lg shadow-card-shadow w-full">
        No Engagement Data Found For This Session.
      </div>
    );
  }
  return (
    <div className="bg-white py-3 px-6 rounded-2xl shadow-card-shadow w-full">
      {isFetching || isInsightfetching || outletContext.mediaLoading || !outletContext.videoDuration ? (
        "Loading..."
      ) : (
        <div id="chart" className="relative w-full h-fit">
          <Chart options={options} series={series} type="line" height={300} />

          {tooltipVisible && (
            <div
              className="absolute rounded-lg z-10 p-2"
              style={{
                left: tooltipPosition.left,
                top: tooltipPosition.top,
              }}
            >
              <GraphTooltip
                engagement={tooltipData?.engagement || 0}
                engagementType={tooltipData?.engagementType || ""}
                handleSeekTo={(time, eTime) => outletContext.handleSeekTo(time, eTime)}
                isTooltipInteracting={isTooltipInteracting}
                setIsTooltipInteracting={setIsTooltipInteracting}
                handleCloseTooltip={handleTooltipClose}
                insight={tooltipData?.engagementInsight}
                timeInSeconds={tooltipData?.startTime ? tooltipData.startTime : 0}
                endTimeInSeconds={tooltipData?.endTime ? tooltipData.endTime : 0}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

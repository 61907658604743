import { LANGUAGE_KEYS } from "@internationalization/index";
import { LinkName } from "@pages/ClientDetailsSubPages/IntakeForm/Links/config";

export const routeLabelMappings: { [key in LinkName]: { [key in LANGUAGE_KEYS]: string } } = {
  "client-details": {
    en: "Client Details",
    es: "Detalles del cliente",
  },
  "general-details": {
    en: "General Details",
    es: "Información general",
  },
  "emergency-contact": {
    en: "Emergency Contact",
    es: "Contacto de emergencia",
  },
  "home-environment": {
    en: "Home Environment",
    es: "Ambiente hogareño",
  },
  "guardian-details": {
    en: "Guardian Details",
    es: "Detalles del tutor",
  },
  "living-conditions": {
    en: "Living Conditions",
    es: "Condiciones de vida",
  },
  insurance: {
    en: "Insurance",
    es: "Seguro",
  },
  health: {
    en: "Health",
    es: "Salud",
  },
  "medical-history": {
    en: "Medical History",
    es: "Historial médico",
  },
  "birth-history": {
    en: "Birth History",
    es: "Historial de nacimiento",
  },
  development: {
    en: "Development",
    es: "Desarrollo",
  },
  education: {
    en: "Education",
    es: "Educación",
  },
  "early-intervention-services": {
    en: "Early Intervention Services",
    es: "Servicios de intervención temprana",
  },
  "developmental-milestones": {
    en: "Developmental Milestones",
    es: "Hitos del desarrollo",
  },
  behaviors: {
    en: "Behaviors",
    es: "Comportamientos",
  },
  general: {
    en: "General",
    es: "General",
  },
  "movement-&-play": {
    en: "Movement & Play",
    es: "Movimiento y juego",
  },
  sensory: {
    en: "Sensory",
    es: "Sensorial",
  },
  sleep: {
    en: "Sleep",
    es: "Sueño",
  },
  diet: {
    en: "Diet",
    es: "Dieta",
  },
  interests: {
    en: "Interests",
    es: "Intereses",
  },
  communication: {
    en: "Communication",
    es: "Comunicación",
  },
  "verbal-communication": {
    en: "Verbal Communication",
    es: "Comunicación verbal",
  },
  "non-verbal-communication": {
    en: "Non-Verbal Communication",
    es: "Comunicación no verbal",
  },
  tendencies: {
    en: "Tendencies",
    es: "Tendencias",
  },
  attention: {
    en: "Attention",
    es: "Atención",
  },
  defiance: {
    en: "Defiance",
    es: "Desafío",
  },
  aggression: {
    en: "Aggression",
    es: "Agresión",
  },
  mood: {
    en: "Mood",
    es: "Estado de ánimo",
  },
  "odd-ideas": {
    en: "Odd Ideas",
    es: "Ideas extrañas",
  },
  attachment: {
    en: "Attachment",
    es: "Apego",
  },
  trauma: {
    en: "Trauma",
    es: "Trauma",
  },
  somatic: {
    en: "Somatic",
    es: "Somático",
  },
  language: {
    en: "Language",
    es: "Lenguaje",
  },
  "social-skills": {
    en: "Social Skills",
    es: "Habilidades sociales",
  },
  "executive-functions": {
    en: "Executive Functions",
    es: "Funciones ejecutivas",
  },
  strengths: {
    en: "Strengths",
    es: "Fortalezas",
  },
  schedule: {
    en: "Schedule",
    es: "Horario",
  },
  "additional-details": {
    en: "Additional Details",
    es: "Detalles adicionales",
  },
};

import { LearnerGeneralDetailsQuery } from "@api/graphql/types-and-hooks";
import { LanguageMappingInterface } from "@components/forms/interfaces";

export type GeneralDetailsType = NonNullable<
  NonNullable<NonNullable<LearnerGeneralDetailsQuery["learnerById"]>["learnermetadata"]>
>;

export const generalDetailsMapping: LanguageMappingInterface<GeneralDetailsType> = {
  firstName: {
    en: "First Name",
    es: "Nombre",
  },
  lastName: {
    en: "Last Name",
    es: "Apellido",
  },
  preferredLanguage: {
    en: "Preferred Language",
    es: "",
  },
  preferredName: {
    en: "Preferred Name",
    es: "Nombre Preferido",
  },
  birthDate: {
    en: "Date of Birth",
    es: "Fecha de nacimiento",
  },
  gender: {
    en: "Sex",
    es: "Sexo",
  },
  specifyGender: {
    en: "Specify Gender",
    es: "Especificar género",
  },
  ssn: {
    en: "SSN",
    es: "SSN",
  },
  address: {
    en: "Address Line 1",
    es: "Dirección Línea 1",
  },
  addressLine2: {
    en: "Address Line 2",
    es: "Dirección Línea 2",
  },
  city: {
    en: "City",
    es: "Ciudad",
  },
  state: {
    en: "State / Province",
    es: "Estado / Provincia",
  },
  zip: {
    en: "Zip",
    es: "Código Postal",
  },
};

import { ExistingFileInterface } from "@fronterahealth/frontera-ui-components";

import { INTERVIEW_TYPE_TYPE } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/constants";

export const groupInterviewsByType = (interviews: ExistingFileInterface[]) => {
  return interviews.reduce(
    (acc, interview) => {
      try {
        const interviewMetadata = JSON.parse(interview.metadata);
        const interviewType = interviewMetadata.interview_type as INTERVIEW_TYPE_TYPE;

        if (acc[interviewType]?.length) {
          acc[interviewType]?.push(interview);
        } else {
          acc[interviewType] = [interview];
        }
        return acc;
      } catch (err) {
        console.error("Failed to read interview.metadata.interview_type, defaulting to Other");
        acc["other"] = [interview];
        return acc;
      }
    },
    {} as { [key in INTERVIEW_TYPE_TYPE]?: ExistingFileInterface[] },
  );
};

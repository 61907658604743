import { useEffect, useState } from "react";

import { Button, Dialog, Heading, Input, RadioInput, SkeletonLoader } from "@fronterahealth/frontera-ui-components";

export interface EmptyStateProps {
  /**
   * The main header text to display in the empty state view. This typically provides a summary or primary message to the user.
   */
  header?: string;

  /**
   * The subheader text to provide additional context or information beneath the main header. This often explains why the view is empty or offers more details.
   */
  subHeader?: string;

  /**
   * The text for the action button in the empty state view. This button usually provides an option for the user to take action, such as creating new content. Example: "+ create new folder", "+ add new"
   */
  buttonText?: string;
}

export interface RecommendationBankPanelProps {
  /**
   * An array of React nodes representing the actual goals to be displayed in the panel.
   */
  recommendationsToDisplay: React.ReactNode[];

  /**
   * The properties for rendering the empty state message when there are no goals or folders to display.
   */
  emptyState: EmptyStateProps;

  /**
   * A boolean indicating whether the panel is currently empty, meaning there are no goals or folders to show. Used to conditionally render the empty state view.
   */
  isEmpty: boolean;

  isLoading: boolean;

  /**
   * Render an add new button. This will be displayed when there are no goals and when there are goals already saved.
   * Adjust the button styles depending if there are goals or not.
   */
  addNewButton: () => React.ReactNode;

  /**
   * A callback function to create a new folder. Is called when a user submits the folder creation dialog.
   *
   * @param folderName - The name of the folder to create.
   * @param folderType - The type of the folder to create, such as "personal" or "organizational".
   */
  onCreateFolder: (folderName: string, folderType: string) => void;
}

export interface NewFolder {
  name: string;
  type: string;
}

export const RecommendationsBankPanel: React.FC<RecommendationBankPanelProps> = ({
  emptyState,
  recommendationsToDisplay,
  isEmpty,
  isLoading,
  onCreateFolder,
  addNewButton,
}) => {
  const hasRecommendations = !!recommendationsToDisplay?.length;

  const [openNewFolderDialog, setNewFolderDialogOpen] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>("");
  const [folderType, setFolderType] = useState<string>("");

  const resetNewFolderDialog = () => {
    setNewFolderDialogOpen(false);
    setTimeout(() => {
      setFolderName("");
      setFolderType("personal");
    }, 200);
  };
  useEffect(() => {
    if (!folderType) setFolderType("personal");
    isLoading = true;
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-full w-full flex-col overflow-auto bg-limestone-100">
        <div className="grid h-full w-full grow flex-col p-4">
          <SkeletonLoader loading={isLoading}>
            <div className="h-60 w-60"></div>
          </SkeletonLoader>
        </div>
      </div>
    );
  }
  const emptyStateButton = () => {
    return emptyState.buttonText ? (
      <Button
        appearance="primary"
        buttonAction="action"
        text={emptyState?.buttonText}
        onClick={() => setNewFolderDialogOpen(true)}
      ></Button>
    ) : (
      <></>
    );
  };

  if (isEmpty) {
    return (
      <div className="flex h-full w-full flex-col overflow-auto bg-limestone-100">
        <div className="w-5/5 mx-4 my-4 flex h-2/6 flex-col items-center justify-evenly rounded-md border-2 border-limestone-200 ">
          <Heading type="h4">{emptyState?.header}</Heading>
          <span className="text-sm font-normal leading-5 text-text-secondary"> {emptyState?.subHeader}</span>
          {emptyStateButton()}
          <Dialog
            title="Create New Folder"
            open={openNewFolderDialog}
            setOpen={setNewFolderDialogOpen}
            primaryButton={{
              text: "Create Folder",
              onClick: () => {
                onCreateFolder(folderName, folderType);
                resetNewFolderDialog();
              },
              type: "submit",
            }}
            secondaryButton={{
              text: "Cancel",
              onClick: () => resetNewFolderDialog(),
            }}
            size="sm"
          >
            <Input
              name="folder-name"
              label="Folder Name"
              inputSize="full"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />

            <RadioInput
              name=""
              title="Folder Type"
              legend="folder choice"
              selectedId={folderType}
              onChange={(e) => setFolderType(e.target.id)}
              items={[
                {
                  id: "personal",
                  title: "Personal",
                },
                {
                  id: "organizational",
                  title: "Organizational",
                },
              ]}
            />
          </Dialog>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex min-h-[calc(100vh-30vh)] min-w-[calc(100vh-2vh)] grow flex-col">
        <div className="flex h-full w-full flex-col overflow-auto bg-limestone-100">
          {hasRecommendations ? (
            <div className="grid grid-cols-3 grid-rows-2 gap-4 p-4">
              {addNewButton()}
              {recommendationsToDisplay}
            </div>
          ) : (
            <div className="w-5/5 mx-4 my-4 flex h-2/6 flex-col items-center justify-evenly rounded-md border-2 border-limestone-200 ">
              <Heading type="h4">Nothing Here Yet</Heading>
              <span className="text-sm font-normal leading-5 text-text-secondary">
                Click "Add New" to begin organizing.
              </span>
              {addNewButton()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, Select, SelectItem, Small } from "@fronterahealth/frontera-ui-components";

import {
  ReportTypeEnums,
  useCreateReportMutation,
  useGetProviderLearnersQuery,
  useGetReportsQuery,
} from "@api/graphql/types-and-hooks";
import { Evaluation } from "@components/EvaluationsReportsTable/EvaluationsReportsTable";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

interface NewEvaluationDialogProps {
  setDialogOpen: (open: boolean) => void;
  isDialogOpen: boolean;
}

export const NewEvaluationDialog: React.FC<NewEvaluationDialogProps> = ({ isDialogOpen, setDialogOpen }) => {
  const [selected, setSelected] = useState<SelectItem | undefined>();
  const go = useNavigate();
  const reportType = ReportTypeEnums.DiagnosisEvaluation;

  useEffect(() => {
    setSelected(undefined);
  }, [isDialogOpen]);

  // Get all of the client names to populate in the drop down
  const learnerQuery = useGetProviderLearnersQuery(
    {},
    {
      queryKey: ["get-provider-learners"],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const getLearnerEvaluation = useGetReportsQuery(
    { learnerId: selected?.id, reportType: reportType },
    {
      queryKey: ["get-learner-evaluation", selected?.id],
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const createLearnerEvaluationMutation = useCreateReportMutation({});

  // Data for all current clients(learners)
  const { data, isLoading } = learnerQuery;
  // Data in case a client(learner) already has an evaluation
  const { data: learnerEvaluationData, isLoading: learnerEvaluationLoading } = getLearnerEvaluation;
  // Data to create a new evaluation
  const { data: createEvaluationData, isPending, error } = createLearnerEvaluationMutation;

  const itemsData = useMemo(() => {
    const items: SelectItem[] = [];

    if (data?.getProviderLearners) {
      const learnerItems = data.getProviderLearners?.edges
        .map((learner) => ({
          primary: `${learner?.node?.learnermetadata?.firstName} ${learner?.node?.learnermetadata?.lastName}`,
          id: learner?.node?.id,
        }))
        .sort((la, lb) => (la.primary > lb.primary ? 1 : -1));
      return learnerItems;
    }

    return items;
  }, [data]);

  const createReportObject = async () => {
    await createLearnerEvaluationMutation.mutateAsync({
      reportType: reportType,
      learnerId: selected?.id,
    });
  };

  useEffect(() => {
    if (error) {
      console.error("Error while creating evaluation", error);
      notifyError("Error creating evaluation");
    }
  }, [error]);

  useEffect(() => {
    if (!createEvaluationData) return;

    const evaluation = createEvaluationData.createReport;
    const reportStatus = evaluation?.status;

    if (reportStatus) {
      notifySuccess(evaluation?.message || "Evaluation created successfully");

      const learnerEvaluation = evaluation?.report;

      if (learnerEvaluation) {
        const { clientFirstName, clientLastName } = learnerEvaluation?.reportClientDetails || {};

        setDialogOpen(false);
        go(`/evaluations/${learnerEvaluation?.id}`, {
          state: {
            id: learnerEvaluation?.id,
            clientName: `${clientFirstName || "N/A"} ${clientLastName || ""}`,
            dateCreated: new Date(learnerEvaluation?.createdDate) || new Date(),
            lastUpdated: new Date(learnerEvaluation?.lastUpdatedDate) || new Date(),
          },
        });
      }
    } else {
      notifyError(evaluation?.message || "Error creating evaluation");
    }
  }, [createEvaluationData]);

  const handleSecondaryButtonClick = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const validateObject = async () => {
      await getLearnerEvaluation.refetch();
    };

    if (selected) {
      validateObject();
    }
  }, [selected]);

  const goToReport = () => {
    setDialogOpen(false);
    go(`/evaluations/${learnerEvaluationData?.getReports?.edges[0]?.node?.id}`, {
      state: {
        id: learnerEvaluationData?.getReports?.edges[0]?.node?.id,
        clientName: selected?.primary,
        dateCreated: new Date(learnerEvaluationData?.getReports?.edges[0]?.node?.createdDate),
        lastUpdated: new Date(learnerEvaluationData?.getReports?.edges[0]?.node?.lastUpdatedDate),
      } as Evaluation,
    });
  };

  const primaryButtonDisableStatus = () => {
    if ((selected && learnerEvaluationData?.getReports?.edges[0]?.node) || isPending) return true;
    else return false;
  };

  return (
    <Dialog
      title="New Evaluation"
      open={isDialogOpen}
      setOpen={setDialogOpen}
      primaryButton={{
        text: isPending ? "Creating..." : "Yes, Continue",
        onClick: createReportObject,
        disabled: primaryButtonDisableStatus(),
      }}
      secondaryButton={{ text: "No, Cancel", onClick: handleSecondaryButtonClick }}
      size="md"
    >
      <Select
        title="Select Client"
        isLoading={isLoading}
        items={itemsData}
        placeholderText=""
        selected={selected}
        setSelected={setSelected}
        actionItem={{
          label: "Create New",
          onClick: () => createReportObject(),
        }}
      />
      {learnerEvaluationLoading && <Small>Loading...</Small>}
      {learnerEvaluationData?.getReports?.edges[0]?.node ? (
        <>
          <Small displayType="normal" className="-mt-6">
            An evaluation for this client already exists.
            <br /> Click{" "}
            <span className="cursor-pointer text-secondary underline" onClick={() => goToReport()}>
              here
            </span>{" "}
            to view
          </Small>
        </>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

import { useEffect } from "react";
import {
  FieldValues,
  FormState,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import { MultiSelect, MultiSelectItem, MultiSelectProps } from "@fronterahealth/frontera-ui-components";

import { getErrorFromFormKey } from "@components/forms/utils";

type multiSelectType = "tags" | "normal"; // replace this type declreation once it is exported from ui comp. library

interface Props<T extends FieldValues> extends Omit<MultiSelectProps, "selected" | "setSelected"> {
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  clearErrors: UseFormClearErrors<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  required?: boolean;
  mode: multiSelectType;
}

export const FormMultiSelect = <T extends FieldValues>({
  formState,
  formKey,
  watch,
  register,
  setValue,
  trigger,
  title,
  required = true,
  mode,
  ...props
}: Props<T>) => {
  const selected = watch(formKey);

  const errorMessage = getErrorFromFormKey(formKey, formState.errors);

  useEffect(() => {
    register(formKey, {
      required: {
        value: required,
        message: `${title} is required`,
      },
    });
  }, [register, required, formKey, formState]);

  const setSelected = (val: MultiSelectItem[]) => {
    setValue(formKey, val as PathValue<T, Path<T>>);
    trigger(formKey);
  };

  return (
    <MultiSelect
      hintText={required ? undefined : "Optional"}
      title={title}
      selected={selected?.length ? selected : []}
      setSelected={setSelected}
      errorMessage={errorMessage}
      mode={mode}
      {...props}
    />
  );
};

import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { Divider, Heading } from "@fronterahealth/frontera-ui-components";

import {
  LearnerMedicalHistoryQuery,
  MedicalHistoryConditionsQuestionType,
  useLearnerMedicalHistoryQuery,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer, FormSpacer, TripleColumnHeader } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { QuestionData, convertOnboardingQuestionListToQuestionMap, transformQueryData } from "@components/forms/utils";
import {
  MedicalHistoryDataType,
  medicalHistoryMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/MedicalHistory/MedicalHistory.languageMapping";
import { medicalHistoryConditionsQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/MedicalHistory/MedicalHistoryQuestions.languageMapping";
import {
  NeverPreviouslyOngoingItems,
  YesNoRadioItems,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const extractMedicalHistoryData = (queryData: LearnerMedicalHistoryQuery) => {
  const medicationQuestions = queryData.learnerById?.onboardingQuestionsForLearner?.edges?.map((edge) => ({
    ...edge?.node,
  }));
  const formattedQuestions = convertOnboardingQuestionListToQuestionMap(medicationQuestions as QuestionData[]);
  const transformed = transformQueryData(
    {
      learnerById: {
        ...queryData.learnerById,
        onboardingQuestionsForLearner: formattedQuestions,
      },
    },
    {
      "learnerById.learnermedicalhistorymetadata.clientHasBeenHospitalized": (value) => (value ? "yes" : "no"),
      "learnerById.learnermedicalhistorymetadata.clientHasCurrentCounselor": (value) => (value ? "yes" : "no"),
      "learnerById.learnermedicalhistorymetadata.clientHasMedicalDiagnoses": (value) => (value ? "yes" : "no"),
      "learnerById.learnermedicalhistorymetadata.clientGiDifficulties": (value) => (value ? "yes" : "no"),
    },
  ) as unknown as LearnerMedicalHistoryQuery;

  const converted = {
    medicationsForLearner: transformed.learnerById?.medicationsForLearner?.edges?.length
      ? transformed.learnerById?.medicationsForLearner?.edges?.map((edge) => edge?.node)
      : [],
    learnermedicalhistorymetadata: { ...transformed.learnerById?.learnermedicalhistorymetadata },
    medicalHistoryForLearner: { ...transformed.learnerById?.medicalHistoryForLearner },
    onboardingQuestionsForLearner: {
      ...transformed.learnerById?.onboardingQuestionsForLearner,
    },
  } as unknown as MedicalHistoryDataType;
  return converted;
};

export const MedicalHistory: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/health/medical-history" : undefined;

  const config = useGetQueryConfig<LearnerMedicalHistoryQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerMedicalHistoryQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching: formLoading } = learnerDetailsQuery;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormRadioInput,
    RegisteredFormTextArea,
    reset,
    control,
    watch,
  } = useFormUtils<MedicalHistoryDataType>({
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  const { fields } = useFieldArray({
    control,
    name: "medicationsForLearner",
  });
  useEffect(() => {
    if (data) {
      reset(extractMedicalHistoryData(data));
    }
  }, [data, reset]);

  const clientHasBeenHospitalized =
    // @ts-ignore: Ignoring the compiler and risking bugs because: We are hacking booleans and radio inputs
    watch("learnermedicalhistorymetadata.clientHasBeenHospitalized") === "yes";

  // @ts-ignore: Ignoring the compiler and risking bugs because: We are hacking booleans and radio inputs
  const clientHasCounselor = watch("learnermedicalhistorymetadata.clientHasCurrentCounselor") === "yes";

  const clientHasMedicalDiagnoses =
    // @ts-ignore: Ignoring the compiler and risking bugs because: We are hacking booleans and radio inputs
    watch("learnermedicalhistorymetadata.clientHasMedicalDiagnoses") === "yes";

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        <div className="flex-col">
          <DoubleColumnContainer>
            <RegisteredFormInput
              label={medicalHistoryMapping.medicalHistoryForLearner.primaryCareProvider[chosenLanguage]}
              readOnly
              formKey={`medicalHistoryForLearner.primaryCareProvider`}
              formState={formState}
              isLoading={formLoading}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormInput
              label={medicalHistoryMapping.medicalHistoryForLearner.address[chosenLanguage]}
              readOnly
              formState={formState}
              formKey="medicalHistoryForLearner.address"
              isLoading={formLoading}
            />
            <RegisteredFormInput
              label={medicalHistoryMapping.medicalHistoryForLearner.addressLine2?.[chosenLanguage] ?? "Address Line 2"}
              readOnly
              formState={formState}
              formKey="medicalHistoryForLearner.addressLine2"
              isLoading={formLoading}
            />
          </DoubleColumnContainer>
          <TripleColumnHeader>
            <RegisteredFormInput
              label={medicalHistoryMapping.medicalHistoryForLearner.city[chosenLanguage]}
              readOnly
              formState={formState}
              formKey="medicalHistoryForLearner.city"
              isLoading={formLoading}
            />
            <RegisteredFormInput
              label={medicalHistoryMapping.medicalHistoryForLearner.state[chosenLanguage]}
              readOnly
              formState={formState}
              formKey="medicalHistoryForLearner.state"
              isLoading={formLoading}
            />
            <RegisteredFormInput
              label={medicalHistoryMapping.medicalHistoryForLearner.zip[chosenLanguage]}
              readOnly
              formState={formState}
              formKey="medicalHistoryForLearner.zip"
              isLoading={formLoading}
            />
          </TripleColumnHeader>

          <FormSpacer />
          <RegisteredFormRadioInput
            title={medicalHistoryMapping.learnermedicalhistorymetadata.clientHasBeenHospitalized[chosenLanguage]}
            items={YesNoRadioItems}
            formKey="learnermedicalhistorymetadata.clientHasBeenHospitalized"
            formState={formState}
            disabled
          />
          {clientHasBeenHospitalized && (
            <>
              <RegisteredFormTextArea
                required={clientHasBeenHospitalized}
                formKey="learnermedicalhistorymetadata.clientHospitalizedDetails"
                formState={formState}
                label={
                  medicalHistoryMapping.learnermedicalhistorymetadata.clientHospitalizedDetails?.[chosenLanguage] ??
                  "When did that occur, and why?"
                }
                disabled
              />
              <FormSpacer />
            </>
          )}

          <RegisteredFormRadioInput
            items={YesNoRadioItems}
            formKey="learnermedicalhistorymetadata.clientHasCurrentCounselor"
            formState={formState}
            title={medicalHistoryMapping.learnermedicalhistorymetadata.clientHasCurrentCounselor[chosenLanguage]}
            disabled
          />
          {clientHasCounselor && (
            <>
              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={
                    medicalHistoryMapping.learnermedicalhistorymetadata.clientCurrentCounselorName?.[chosenLanguage] ??
                    "Counselor or Therapist Name"
                  }
                  readOnly
                  formKey="learnermedicalhistorymetadata.clientCurrentCounselorName"
                  formState={formState}
                />
                <RegisteredFormInput
                  label={
                    medicalHistoryMapping.learnermedicalhistorymetadata.clientCurrentCounselorPhone?.[chosenLanguage] ??
                    ""
                  }
                  readOnly
                  formKey="learnermedicalhistorymetadata.clientCurrentCounselorPhone"
                  formState={formState}
                />
              </DoubleColumnContainer>
              <RegisteredFormTextArea
                label={
                  medicalHistoryMapping.learnermedicalhistorymetadata.clientThoughtsForParticipatingCounseling?.[
                    chosenLanguage
                  ] ?? ""
                }
                readOnly
                formKey="learnermedicalhistorymetadata.clientThoughtsForParticipatingCounseling"
                formState={formState}
              />
              <FormSpacer />
            </>
          )}
          <RegisteredFormRadioInput
            items={YesNoRadioItems}
            formKey="learnermedicalhistorymetadata.clientHasMedicalDiagnoses"
            formState={formState}
            title={medicalHistoryMapping.learnermedicalhistorymetadata.clientHasMedicalDiagnoses[chosenLanguage]}
            readOnly
          />
          {clientHasMedicalDiagnoses && (
            <>
              <RegisteredFormInput
                label={
                  medicalHistoryMapping.learnermedicalhistorymetadata.clientMedicalDiagnosesDetails?.[chosenLanguage] ??
                  ""
                }
                readOnly
                formKey="learnermedicalhistorymetadata.clientMedicalDiagnosesDetails"
                formState={formState}
              />
              <FormSpacer />
            </>
          )}

          <Heading type="h2" className="my-4">
            Medications
          </Heading>

          {fields.map((field, index) => {
            return (
              <div className="flex-col" key={field.id}>
                <div className="flex w-full items-center justify-between">
                  <div className="flex items-center">
                    <Heading type="h4" className="my-4">
                      Medication {index + 1}
                    </Heading>
                  </div>
                </div>

                <TripleColumnHeader>
                  <RegisteredFormInput
                    label={medicalHistoryMapping.medicationsForLearner.name[chosenLanguage]}
                    readOnly
                    key={field.id}
                    formState={formState}
                    formKey={`medicationsForLearner.${index}.name`}
                  />
                  <RegisteredFormInput
                    label={medicalHistoryMapping.medicationsForLearner.dosage[chosenLanguage]}
                    readOnly
                    key={field.id}
                    formState={formState}
                    formKey={`medicationsForLearner.${index}.dosage`}
                  />
                  <RegisteredFormInput
                    label={medicalHistoryMapping.medicationsForLearner.prescriber[chosenLanguage]}
                    readOnly
                    key={field.id}
                    formState={formState}
                    formKey={`medicationsForLearner.${index}.prescriber`}
                  />
                </TripleColumnHeader>

                <Divider />
              </div>
            );
          })}
          <RegisteredFormTextArea
            label={
              medicalHistoryMapping.learnermedicalhistorymetadata.sideEffectsOfEmotionalBehaviourMedication?.[
                chosenLanguage
              ] ?? ""
            }
            readOnly
            formKey="learnermedicalhistorymetadata.sideEffectsOfEmotionalBehaviourMedication"
            formState={formState}
          />
          <RegisteredFormTextArea
            label={
              medicalHistoryMapping.learnermedicalhistorymetadata.allergiesOrDietaryRestrictions?.[chosenLanguage] ?? ""
            }
            readOnly
            formKey="learnermedicalhistorymetadata.allergiesOrDietaryRestrictions"
            formState={formState}
          />
          <RegisteredFormTextArea
            label={medicalHistoryMapping.learnermedicalhistorymetadata.familyHistoryOfDiagnoses?.[chosenLanguage] ?? ""}
            readOnly
            formKey="learnermedicalhistorymetadata.familyHistoryOfDiagnoses"
            formState={formState}
          />
          <RegisteredFormTextArea
            label={
              medicalHistoryMapping.learnermedicalhistorymetadata.hospitalizationsOrSurgeries?.[chosenLanguage] ?? ""
            }
            readOnly
            formKey="learnermedicalhistorymetadata.hospitalizationsOrSurgeries"
            formState={formState}
          />
          <RegisteredFormTextArea
            label={medicalHistoryMapping.learnermedicalhistorymetadata.clientOverallHealth?.[chosenLanguage] ?? ""}
            readOnly
            formKey="learnermedicalhistorymetadata.clientOverallHealth"
            formState={formState}
          />

          <RegisteredFormTextArea
            label={
              medicalHistoryMapping.learnermedicalhistorymetadata.clientOtherHealthConcerns?.[chosenLanguage] ?? ""
            }
            readOnly
            formKey="learnermedicalhistorymetadata.clientOtherHealthConcerns"
            formState={formState}
          />

          <RegisteredFormRadioInput
            title={medicalHistoryMapping.learnermedicalhistorymetadata.clientGiDifficulties[chosenLanguage]}
            disabled
            items={YesNoRadioItems}
            formKey="learnermedicalhistorymetadata.clientGiDifficulties"
            formState={formState}
          />

          <Heading className="my-4" type="h2">
            Syndromes
          </Heading>
          {Object.values(MedicalHistoryConditionsQuestionType).map((questionType) => {
            return (
              <>
                {questionType === MedicalHistoryConditionsQuestionType.Asphyxia ? (
                  <Heading className="my-4" type="h2">
                    Postnal
                  </Heading>
                ) : questionType === MedicalHistoryConditionsQuestionType.Herpes ? (
                  <Heading className="my-4" type="h2">
                    Prenatal Dysfunctions
                  </Heading>
                ) : questionType === MedicalHistoryConditionsQuestionType.ChargeSyndrome ? (
                  <Heading className="my-4" type="h2">
                    Multiple Congenital Anomalies
                  </Heading>
                ) : questionType === MedicalHistoryConditionsQuestionType.HighFever ? (
                  <Heading className="my-4" type="h2">
                    Other Illness or Conditions
                  </Heading>
                ) : null}

                <RegisteredFormRadioInput
                  key={questionType}
                  items={NeverPreviouslyOngoingItems(chosenLanguage)}
                  title={medicalHistoryConditionsQuestionsMapping[questionType][chosenLanguage]}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                  formKey={`onboardingQuestionsForLearner.${questionType}`}
                  formState={formState}
                  disabled
                />
              </>
            );
          })}
        </div>
      </FormContainer>
    </div>
  );
};

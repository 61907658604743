import { FieldValues } from "react-hook-form";

import { LANGUAGE_KEYS, LanguageMappingInterface } from "@components/forms/interfaces";

export type InputObjectType = {
  [key: string]: string;
};

export type TranslatedObjectType = {
  [key: string]: string;
};

interface LanguageObjectType {
  en: string;
  es: string;
}

export interface QuestionsMapping {
  [key: string]: LanguageObjectType;
}

export const translateObjectKeys = (
  inputObject: TranslatedObjectType,
  language: LANGUAGE_KEYS,
  mapping: QuestionsMapping,
): TranslatedObjectType => {
  const translatedObject: TranslatedObjectType = {};

  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      const languageObject = mapping[key];
      const translatedKey = languageObject ? languageObject[language] : key;
      translatedObject[translatedKey] = inputObject[key] || "";
    }
  }

  return translatedObject;
};

type TranslateKeys<T> = {
  [K in keyof T]: T[K] extends Array<infer U>
    ? Array<U extends object ? TranslateKeys<U> : U>
    : T[K] extends object
      ? TranslateKeys<T[K]>
      : T[K];
};

export const getLabel = <T extends FieldValues>(
  key: string,
  chosenLanguage: string,
  mapping: LanguageMappingInterface<T>,
): string => {
  return (mapping[key] as { [language: string]: string })?.[chosenLanguage] ?? key;
};

export const getLabelsFromMapping = <T extends FieldValues>(
  data: T | null,
  language: "en" | "es",
  mapping: LanguageMappingInterface<T>,
): TranslateKeys<T> => {
  const result = {} as TranslateKeys<T>;

  if (data === null) return result;
  if (data === undefined) return result;
  Object.entries(data).forEach(([key, value]) => {
    if (mapping[key]) {
      const label = getLabel(key, language, mapping);
      if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        // eslint-disable-next-line
        (result as any)[label] = getLabelsFromMapping(value, language, mapping[key] as LanguageMappingInterface<any>);
      } else if (Array.isArray(value)) {
        // eslint-disable-next-line
        (result as any)[label] = value.map((item) =>
          typeof item === "object" && item !== null
            ? // eslint-disable-next-line
              getLabelsFromMapping(item, language, mapping[key] as LanguageMappingInterface<any>)
            : item,
        );
      } else {
        // eslint-disable-next-line
        (result as any)[label] = value;
      }
    } else {
      // eslint-disable-next-line
      (result as any)[key] = value;
    }
  });

  return result;
};

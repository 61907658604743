import React from "react";

import { Small } from "@fronterahealth/frontera-ui-components";
import {
  PopoverMenu,
  PopoverMenuItem,
} from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

export interface RecommendationBankCardSettingsProps {
  menuItems: PopoverMenuItem[];
  openDirection: OpenDirections;
}

type OpenDirections = "to-right" | "to-left" | "to-bottom-right" | "to-bottom-left";

export interface RecommendationBankCardProps {
  onClick: (card: RecommendationBankCardItem) => void;
  id: string;
  title: string;
  body: string;
  selected?: boolean;
  cardIsUsed?: boolean;
  settings?: RecommendationBankCardSettingsProps;
}

export type RecommendationBankCardItem = Pick<RecommendationBankCardProps, "title" | "body" | "id">;

export const RecommendationBankCard: React.FC<RecommendationBankCardProps> = ({
  title,
  body,
  onClick,
  selected,
  id,
  cardIsUsed,
  settings,
}) => {
  return (
    <div
      onClick={() => !cardIsUsed && onClick({ body, id, title })}
      className={`relative transition-all ${cardIsUsed ? "" : selected ? "cursor-pointer hover:[outline:1.5px_solid_#A094F1]" : "cursor-pointer opacity-100 hover:[outline:1px_solid_#968579] active:[outline:1px_solid_#5E37CA]"} max-h-[240px] min-h-[240px] overflow-y-hidden ${selected ? "[outline:1.5px_solid_#5E37CA]" : "[outline:1px_solid_#edeae7]"} w-full rounded-md bg-white px-4 pb-10 pt-4 shadow-card-shadow`}
    >
      <div
        className={`${cardIsUsed ? "opacity-60" : "opacity-100"} mb-4 flex max-h-[210px] items-baseline justify-between`}
      >
        <div className="flex-1">
          <div className="mb-4">
            <Small displayType="loud" colorType="primary">
              {title}
            </Small>
          </div>

          <Small
            colorType="primary"
            displayType="normal"
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 7,
            }}
          >
            {body}
          </Small>
        </div>

        {settings ? (
          <PopoverMenu
            menuItems={settings?.menuItems || []}
            openDirection={settings?.openDirection || "to-bottom-left"}
          />
        ) : null}
      </div>
    </div>
  );
};

import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ApiReportReportStatusChoices,
  useAddOrUpdateReportSignatureSectionDetailMutation,
  useUpdateReportStatusAndUploadReportFileMutation,
} from "@api/graphql/types-and-hooks";
import useGenerateDocx from "@components/HtmlToDocx/useGenerateDOCX";
import { SupportedContentType } from "@components/SectionWidget/SectionWidget";
import { SignatureSection } from "@components/SignatureSection/SignatureSection";
import { notifyError, notifySuccess, notifyWarn } from "@components/notifications/notifications";
import { GeneralInfoSection } from "@pages/EvaluationDetails/Components/GeneralInfoSection";
import { SectionWidgetWrapper } from "@pages/EvaluationDetails/Components/SectionWidgetWrapper";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/EvaluationDetails/Components/helper";
import { FooterButtonRow } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/FooterButtonRow";
import { StickyNav } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/StickyNav/StickyNav";
import { useEvaluationData } from "@providers/EvaluationProvider";

const pageLocation: FilterSectionSubRoute = "review-and-sign";

export const EvaluationReviewAndSign: React.FC = () => {
  const { evaluationId } = useParams();
  const [signature, setSignature] = useState<string>("");
  const [closingRemark, setClosingRemark] = useState<string>("");
  const [timestamp, setTimestamp] = useState<Date>(new Date());
  const filteredSections = useGetFilteredPageSectionsByLocation(pageLocation);
  const addOrUpdateReportSignatureSectionDetailMutation = useAddOrUpdateReportSignatureSectionDetailMutation({});
  const updateReportStatusAndUploadReportFileMutation = useUpdateReportStatusAndUploadReportFileMutation({});
  const flags = useFlags<FeatureFlags>();
  const { evaluationQuery } = useEvaluationData();
  const evaluation = evaluationQuery.data?.getReports?.edges[0]?.node;
  const reportIsCompleted = evaluation?.reportStatus === ApiReportReportStatusChoices.Completed;
  const [isReportGenerating, setIsReportGenerating] = useState<boolean>(false);
  const { isLoading } = evaluationQuery;
  useEffect(() => {
    if (reportIsCompleted) {
      if (evaluation) {
        setSignature(evaluation?.signatureSectionDetail?.signedBy || "");
        setTimestamp(new Date(evaluation?.signatureSectionDetail?.signedAt) || new Date());
        setClosingRemark(evaluation?.signatureSectionDetail?.closingRemark || "");
      }
    }
  }, [reportIsCompleted]);

  const handleSignReport = async (file: File) => {
    await addOrUpdateReportSignatureSectionDetailMutation
      .mutateAsync({
        input: {
          reportId: evaluationId ?? "<missing-evaluation-id>",
          signedBy: signature,
          signedAt: timestamp,
          closingRemark: closingRemark,
        },
      })
      .catch((error) => {
        console.error("Error saving signature for report: ", error);
        notifyError("Error saving signature for report");
      });

    await updateReportStatusAndUploadReportFileMutation.mutateAsync(
      {
        reportId: evaluation?.id || "",
        reportFile: file,
      },
      {
        onSuccess: async () => {
          setIsReportGenerating(false);
          await evaluationQuery.refetch();
          notifySuccess("Successfully Submitted Report");
        },
        onError: async (error) => {
          setIsReportGenerating(false);
          console.error("Error Saving Report", error);
          notifyError("Error Saving Report");
        },
      },
    );
  };

  const { generateDocx, loading } = useGenerateDocx();

  const supportedContentTypes = ["Text", "Table"] as SupportedContentType[];
  if (flags["upload-images-component"]) {
    supportedContentTypes.push("Image");
  }

  return (
    <div className="flex w-full flex-col">
      <div className="grid grid-cols-4 gap-x-1">
        <div id="EvaluationReport" className={`col-span-3 flex w-full flex-col gap-y-4`}>
          <GeneralInfoSection />
          {filteredSections.map((section) => (
            <SectionWidgetWrapper
              key={section.id}
              section={section}
              permissions={{
                canDelete: false,
                canEdit: false,
              }}
              supportedContentTypes={supportedContentTypes}
              allowInLineEdit={!reportIsCompleted}
            />
          ))}
          <SignatureSection
            id="signature"
            signature={signature}
            setSignature={setSignature}
            closingRemark={closingRemark}
            setClosingRemark={setClosingRemark}
            timestamp={timestamp}
            setTimestamp={setTimestamp}
            isReadOnly={reportIsCompleted || isReportGenerating}
          />
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav state={filteredSections} />
        </div>
      </div>
      {!reportIsCompleted ? (
        <FooterButtonRow
          primaryButtonTitle="Sign & Complete"
          primaryButtonLoading={loading || isReportGenerating || isLoading}
          primaryButtonAction={async () => {
            if (signature.replace(/\s/g, "").length === 0) {
              notifyWarn("To generate the report, you need to provide your signature.");
              return window.scroll({
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
            } else {
              setIsReportGenerating(true);
              const element = document.getElementById("EvaluationReport");
              const file = await generateDocx(element);
              if (file) {
                await handleSignReport(file);
              }
            }
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

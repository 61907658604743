import { User, useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";

import "./App.css";
import { IndexLayout } from "./layouts";

/**
 * App will handle all inbound traffic. It starts by checking if Auth0 is loading in which case it render a loader.
 * Otherwise it will do one of three things
 *  - if a user is logged in, it will redirect them to the first page of the dashboard
 * @returns
 */
function App() {
  const { user, isAuthenticated } = useAuth0();

  const initializePendo = (user: User) => {
    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user?.sub,
          email: user?.email,
          full_name: `${user?.given_name} ${user?.family_name}`,
        },
        account: {
          id: user?.org_id,
          orgName: user?.[`${config.auth0Audience}/orgName`],
        },
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
      datadogRum.setUser({
        email: user?.email,
        id: user?.sub,
        name: user?.name,
        orgId: user?.org_id || "<missing-org-id>",
        orgName: orgName,
      });

      initializePendo(user);
    }
  }, [isAuthenticated, user]);

  return <IndexLayout />;
}

export default App;

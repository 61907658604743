import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { QueryClient } from "@tanstack/react-query";
import { ReactNode, useState } from "react";

import { AI_GENERATED, Button, Folder, FolderExplorer, GoalBankPanel } from "@fronterahealth/frontera-ui-components";

import {
  AiSuggestedTargetsType,
  AiSuggestedTargetsTypeEdge,
  TargetTimelineEstimationEnums,
  TargetType,
  TargetTypeEdge,
  useCreateUpdateTargetMutation,
  useGoalBankTargetsQuery,
} from "@api/graphql/types-and-hooks";
import { AddTargetsBankCard } from "@components/GoalsBank/AddTargetsBank/AddTargetsBankCard";
import { GoalBankTargetsSaveToFolder } from "@components/GoalsBank/SavedGoals/Targets/GoalBankTargetsSaveToFolder";
import { DeleteFolderDialog } from "@components/GoalsBank/dialogs/DeleteFolderDialog";
import { useGoalBankFolders } from "@components/GoalsBank/hooks/useGoalBankFolders";
import {
  findFolder,
  getAiGeneratedFolderSection,
  getOrgFolderSection,
  getUserFolderSection,
} from "@components/GoalsBank/utils";
import { convertDBString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

export interface AddTargetsBankProps {
  onAdd: () => void;
  assessmentId: string;
  shortTermGoalId: string;
  shortTermGoalBankId?: string;
  learnerId: string;
  targets: TargetTypeEdge[];
  aiTargets: AiSuggestedTargetsTypeEdge[];
}

export const AddTargetsBank: React.FC<AddTargetsBankProps> = ({
  aiTargets,
  targets,
  assessmentId,
  onAdd,
  learnerId,
  shortTermGoalId,
  shortTermGoalBankId,
}) => {
  const [queryClient] = useState(() => new QueryClient());
  const { user } = useAuth0();
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? ""}`;
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");
  const { userFolders, orgFolders, createNewFolder, deleteFolder, editFolder } = useGoalBankFolders();
  const [deleteFolderId, setDeleteFolderId] = useState<string>("");
  const [openDeleteFolderDialog, setDeleteFolderDialogOpen] = useState(false);
  const [isSaveToFolderDialogOpen, setSaveToFolderDialogOpen] = useState<boolean>(false);
  const [selectedGoalIds, setSelectedGoalIds] = useState<string[]>([]);
  const [selectedAiGoalIds, setSelectedAiGoalIds] = useState<string[]>([]);
  const createUpdateTargetMutation = useCreateUpdateTargetMutation({});
  const goalBankTargets = useGoalBankTargetsQuery(
    { folderId: selectedFolderId, shortTermGoalBankId },
    {
      queryKey: ["targets-" + selectedFolderId],
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
      refetchInterval: 100000,
      enabled: !!selectedFolderId && selectedFolderId !== AI_GENERATED,
    },
  );

  const onSelectedFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);
  };

  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);
  const aiSuggestedFoldersSection = getAiGeneratedFolderSection();

  const goalsToAdd = (): ReactNode[] => {
    if (selectedFolderId === AI_GENERATED) {
      return aiTargets.length
        ? aiTargets.map((edge) => {
            const node = edge?.node as AiSuggestedTargetsType;
            return node ? (
              <AddTargetsBankCard
                id={node.id}
                targetName={node.targetName}
                targetDescription={node.targetDescription}
                selected={selectedAiGoalIds.includes(node.id)}
                onClick={() => {
                  if (selectedAiGoalIds.includes(node.id)) {
                    setSelectedAiGoalIds([...selectedAiGoalIds.filter((id) => id != node?.id)]);
                    return;
                  }
                  setSelectedAiGoalIds([...selectedAiGoalIds, node.id]);
                }}
              />
            ) : (
              <></>
            );
          })
        : [];
    }
    return (
      goalBankTargets.data?.getGoalBankTargets?.edges.map((edge) => {
        const node = edge?.node;
        return node ? (
          <AddTargetsBankCard
            id={node.id}
            targetName={node.targetName}
            targetDescription={node.targetDescription}
            selected={selectedGoalIds.includes(node.id)}
            onClick={() => {
              if (selectedGoalIds.includes(node.id)) {
                setSelectedGoalIds([...selectedGoalIds.filter((id) => id != node?.id)]);
                return;
              }
              setSelectedGoalIds([...selectedGoalIds, node.id]);
            }}
          />
        ) : (
          <></>
        );
      }) || []
    );
  };

  const addGoals = async () => {
    if (selectedGoalIds.length > 0) {
      try {
        //cannot add ai suggested since they already have 'em until we do FH-2668
        if (selectedFolderId !== AI_GENERATED) {
          const targetsToAdd = goalBankTargets.data?.getGoalBankTargets?.edges.filter((edge) =>
            selectedGoalIds.includes(edge?.node?.id ?? "<missing-target-id>"),
          );
          targetsToAdd?.forEach(async (target) => {
            const node = target?.node;
            await createUpdateTargetMutation.mutateAsync(
              {
                targetData: {
                  targetName: node?.targetName ?? "",
                  shortTermGoalId,
                  targetDescription: node?.targetDescription,
                  targetGoal: node?.targetGoal,
                  masteryCriteria: node?.masteryCriteria,
                  timelineEstimationType: node?.timelineEstimationType
                    ? (convertDBString(node?.timelineEstimationType) as TargetTimelineEstimationEnums)
                    : null,
                  timelineEstimationValue: node?.timelineEstimationValue,
                  assessmentId,
                },
                learnerId: learnerId ? learnerId : "<missing-learner-id>",
              },
              {
                onSuccess: async () => {
                  await onAdd();
                },
              },
            );
          });
        }
      } catch (err) {
        notifyError("Failed To Add Target(s)");
        console.error("Error adding AI goals", err);
      } finally {
        setSelectedGoalIds([]);
        notifySuccess("Successfully Added Target(s)");
      }
    }
  };

  const showDeleteFolderDialog = (folderId: string) => {
    setDeleteFolderId(folderId);
    setDeleteFolderDialogOpen(true);
  };

  return (
    <>
      <FolderExplorer
        selectedFolderId={selectedFolderId}
        onDeleteFolder={(folderId) => {
          showDeleteFolderDialog(folderId);
        }}
        onEditFolder={(id, newValue) => editFolder(findFolder(id, userFolders.concat(orgFolders)), newValue)}
        onCreateFolder={async (folderName, folderType) => {
          const newFolder = await createNewFolder(folderName, folderType);
          setSelectedFolderId(newFolder.id);
        }}
        onSelectedFolder={onSelectedFolder}
        title={"Add Targets"}
        folderSections={
          orgName
            ? [aiSuggestedFoldersSection, userFoldersSection, orgFoldersSection]
            : [aiSuggestedFoldersSection, userFoldersSection]
        }
        modalButton={
          <Button
            data-testid="suggested-target-button"
            text="+ Suggested Target"
            appearance="text"
            buttonAction="action"
          />
        }
        footer={{
          addButtonText: "Add Target(s)",
          onAdd: addGoals,
          onSaveToFolder: () => setSaveToFolderDialogOpen(true),
          displaySaveToFolderButton: selectedFolderId === AI_GENERATED,
        }}
        panel={
          <GoalBankPanel
            isLoading={goalBankTargets.isFetching || goalBankTargets.isLoading}
            key={goalsToAdd().length}
            noFolders={false}
            goalsToDisplay={goalBankTargets.isFetching || goalBankTargets.isLoading ? [] : goalsToAdd()}
            goalsTabs={[]}
            onCreateFolder={() => {}}
            noGoalsEmptyState={{
              header: selectedFolderId == AI_GENERATED ? "No AI-Generated Items Available" : "Nothing to Show",
              subHeader:
                selectedFolderId == AI_GENERATED
                  ? "You may add saved items from the folders on the left, or add custom items on the previous screen."
                  : "Save items to this folder for later use.",
            }}
          ></GoalBankPanel>
        }
      >
        <GoalBankTargetsSaveToFolder
          key={selectedAiGoalIds.length + selectedGoalIds.length}
          open={isSaveToFolderDialogOpen}
          aiTargets={
            aiTargets
              .filter((aiTarget) => !!aiTarget && selectedAiGoalIds.includes(aiTarget.node?.id ?? ""))
              .map((edge) => edge.node) as AiSuggestedTargetsType[]
          }
          targets={
            targets
              .filter((target) => !!target && selectedGoalIds.includes(target.node?.id ?? ""))
              .map((edge) => edge.node) as TargetType[]
          }
          refetch={async () => await queryClient.refetchQueries()}
          onCloseClick={() => {
            setSaveToFolderDialogOpen(false);
          }}
        />
        <DeleteFolderDialog
          open={openDeleteFolderDialog}
          onCancelClick={() => {
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
          onDeleteClick={() => {
            if (deleteFolderId == selectedFolderId) {
              setSelectedFolderId("");
            }
            deleteFolder(findFolder(deleteFolderId, userFolders.concat(orgFolders)));
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
        />
      </FolderExplorer>
    </>
  );
};

import { Outlet } from "react-router-dom";

import { ClientDetailsIntakeFormNav } from "@pages/ClientDetailsSubPages/IntakeForm/Links/ClientDetailsIntakeFormNav";

export const ClientDetailsFormsIntakeForm: React.FC = () => {
  return (
    <div className="grid h-full w-full grid-cols-6 ">
      <div className="bg-background-white col-span-2 flex grow flex-col items-start  overflow-y-auto  border-r-2 border-background-secondary ">
        <ClientDetailsIntakeFormNav />
      </div>
      <div className="col-span-4 px-4">
        <Outlet />
      </div>
    </div>
  );
};

import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Table } from "@fronterahealth/frontera-ui-components";

import { useSessionsByLearnerIdQuery } from "@api/graphql/types-and-hooks";
import { OverTimeEngagementChart } from "@components/SessionAnalyzer/OverTimeEngagementChart/OverTimeEngagementChart";
import {
  LearnerSessionsDataType,
  getDistinctTechniciansFromLearnerSessions,
  transformLearnerSessionsIntoEngagementData,
  transformLearnerSessionsToDatum,
} from "@pages/SessionAnalyzer/DataHelpers";

interface LearnerSessionsPropType {}

export const LearnerSessions: React.FC<LearnerSessionsPropType> = () => {
  const { learnerId, sessionId } = useParams();

  const { data, isFetching } = useSessionsByLearnerIdQuery(
    {
      learnerId: learnerId || "",
    },
    {
      queryKey: ["sessionsByLearnerId"],
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  const go = useNavigate();

  const sessions = data ? transformLearnerSessionsIntoEngagementData(data) : [];

  const onRowClick = (data: LearnerSessionsDataType) => {
    go(`/session-analyzer/${learnerId}/${data.sessionId}/socialEngagement`);
  };

  const graphData = transformLearnerSessionsToDatum(sessions);

  return (
    <>
      {learnerId && !sessionId ? (
        <>
          <div className=" flow-root w-full">
            <OverTimeEngagementChart
              data={graphData}
              technicians={getDistinctTechniciansFromLearnerSessions(sessions)}
            />
          </div>
          <div className="mt-8 flow-root w-full bg-white rounded-lg">
            <Table
              loading={isFetching}
              data={sessions || []}
              columns={[
                { dataIndex: "session", key: "date", title: "Date" },
                { dataIndex: "duration", key: "duration", title: "Length" },
                { dataIndex: "technician", key: "technician", title: "Technicians" },
                {
                  dataIndex: "engagementScore",
                  key: "engagementScore",
                  title: "Engagement Score",
                  render: (value) => {
                    return value ? `${value}%` : "";
                  },
                },
              ]}
              showPagination
              defaultItemsPerPage={10}
              onRowClick={onRowClick}
            />
          </div>
        </>
      ) : (
        <Outlet />
      )}
    </>
  );
};

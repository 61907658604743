import { faker } from "@faker-js/faker";
import { useEffect } from "react";

import { Button, Paragraph, SelectItem, SlideOver } from "@fronterahealth/frontera-ui-components";

import {
  CreateUpdateShortTermGoalMutationVariables,
  LongTermGoalType,
  LongTermTimelineEstimationEnums,
  ShortTermGoalType,
  ShortTermTimelineEstimationEnums,
  useCreateUpdateShortTermGoalMutation,
} from "@api/graphql/types-and-hooks";
import { DevOnlyWrapper } from "@components/DevOnlyWrapper/DevOnlyWrapper";
import { FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { YesNoRadioItems } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

interface ShortTermGoalPanelProps {
  title: string;
  isPanelOpen: boolean;
  setPanelOpen: (open: boolean) => void;
  longTermGoal: LongTermGoalType | undefined;
  selectedGoal?: ShortTermGoalType | undefined;
  refetchLongTermGoalsList: () => Promise<unknown>;
}

export const ShortTermGoalPanel: React.FC<ShortTermGoalPanelProps> = ({
  isPanelOpen,
  setPanelOpen,
  title,
  selectedGoal,
  refetchLongTermGoalsList,
  longTermGoal,
}) => {
  const { assessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || null;

  const createUpdateShortTermGoalMutation = useCreateUpdateShortTermGoalMutation({});

  const { isPending } = createUpdateShortTermGoalMutation;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormSelected,
    RegisteredFormTextArea,
    RegisteredFormRadioInput,
    setValue,
    trigger,
    reset,
  } = useFormUtils<CreateUpdateShortTermGoalMutationVariables>({
    defaultValues: {
      shortTermData: {
        goalName: "",
        description: "",
        masteryCriteria: "",
        programGoal: "",
        baselineData: "",
        // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
        establishBaselineOnTreatment: "no",
      },
    },
    mutationFn: async (params) => {
      const goalId = selectedGoal?.id || null;
      await createUpdateShortTermGoalMutation.mutateAsync(
        {
          shortTermData: {
            ...params.shortTermData,
            // @ts-ignore: Ignoring the compiler and risking bugs because: see below
            timelineEstimationValue: parseInt(params?.shortTermData?.timelineEstimationValue),
            timelineEstimationType: params.shortTermData.timelineEstimationType
              ? (convertDBString(params.shortTermData.timelineEstimationType) as ShortTermTimelineEstimationEnums)
              : null,
            longTermGoalId: longTermGoal ? longTermGoal.id : "<missing-long-term-goal-id>",
            shortTermGoalId: goalId,
            assessmentId: assessmentReport.id,
            // @ts-ignore: Ignoring the compiler and risking bugs because: see below
            establishBaselineOnTreatment: params.shortTermData.establishBaselineOnTreatment === "yes" ? true : false,
          },
          learnerId: learnerId ? learnerId : "<missing-learner-id>",
        },
        {
          onSuccess: async () => {
            // refetch long term goal updated list
            await refetchLongTermGoalsList();
            notifySuccess(goalId ? "Successfully Updated Short Term Goal" : "Successfully Created Short Term Goal");
            setPanelOpen(false);
            reset();
          },
          onError: async (error) => {
            console.error("Error when saving Short Term Goal ", error);
            notifyError(goalId ? "Error Updating Short Term Goal" : "Error Creating Short Term Goal");
          },
        },
      );
    },
  });

  useEffect(() => {
    if (selectedGoal) {
      reset({
        shortTermData: {
          goalName: selectedGoal?.goalName,
          baselineData: selectedGoal?.baselineData,
          // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
          establishBaselineOnTreatment: selectedGoal?.establishBaselineOnTreatment ? "yes" : "no",
          description: selectedGoal?.description,
          programGoal: selectedGoal?.programGoal,
          masteryCriteria: selectedGoal?.masteryCriteria,
          timelineEstimationValue: selectedGoal?.timelineEstimationValue,
          // @ts-ignore: Ignoring the compiler and risking bugs because: hacking ShortTermTimelineEstimationEnums
          timelineEstimationType: convertReadableString(selectedGoal?.timelineEstimationType || ""),
        },
      });
    } else {
      reset({
        shortTermData: {
          // @ts-ignore: Ignoring the compiler and risking bugs because: API needs boolean / UI Radio input uses yes/no - conversion happens
          establishBaselineOnTreatment: "no",
        },
      });
    }
  }, [reset, selectedGoal]);

  return (
    <div className="flex flex-col">
      <SlideOver title={title} open={isPanelOpen} setOpen={setPanelOpen}>
        <FormContainer onSubmit={onSubmit}>
          <DevOnlyWrapper>
            <div className="my-4 flex items-center">
              <Button
                appearance="link"
                className="mr-2"
                text="Fill Fields (dev only)"
                onClick={() => {
                  setValue("shortTermData.goalName", `${faker.word.verb()} ${faker.word.noun()}`);
                  setValue("shortTermData.description", faker.word.words({ count: 20 }));
                  setValue("shortTermData.programGoal", faker.word.words({ count: 20 }));
                  setValue("shortTermData.masteryCriteria", faker.word.words({ count: 20 }));
                  // @ts-ignore: Ignoring the compiler and risking bugs because: This is only for dev ease of use
                  setValue("shortTermData.timelineEstimationValue", String(faker.number.int({ min: 1, max: 60 })));
                  setValue("shortTermData.baselineData", String(faker.word.words({ count: 10 })));
                  setValue(
                    "shortTermData.timelineEstimationType",
                    // @ts-ignore: Ignoring the compiler and risking bugs because: This is only for dev ease of use
                    convertReadableString(faker.helpers.arrayElement(Object.values(ShortTermTimelineEstimationEnums))),
                  );
                  trigger();
                }}
              />
              <Button
                appearance="link"
                text="Clear Fields (dev only)"
                onClick={() => {
                  reset();
                  trigger();
                }}
              />
            </div>
          </DevOnlyWrapper>
          <div>
            <RegisteredFormInput formKey="shortTermData.goalName" formState={formState} label="Goal Name" />
          </div>
          <div>
            <RegisteredFormTextArea
              rows={6}
              formKey="shortTermData.description"
              formState={formState}
              label="Description"
            />
          </div>
          {/* // Note: Commenting this out for now because it might come back in the future  */}
          {/* <div>
            <RegisteredFormTextArea
              rows={6}
              formKey="shortTermData.programGoal"
              formState={formState}
              label="Program Goal"
            />
          </div> */}
          <div>
            <RegisteredFormTextArea
              formKey="shortTermData.masteryCriteria"
              formState={formState}
              rows={6}
              label="Mastery Criteria"
            />
          </div>
          <div className="w-full flex flex-col">
            <Paragraph displayType="loud" colorType="primary">
              Expected Mastery Date
            </Paragraph>
            <div className="w-full flex items-center justify-start mt-2 gap-x-4">
              <RegisteredFormSelected
                formKey="shortTermData.timelineEstimationValue"
                formState={formState}
                required
                items={
                  Array.from({ length: 60 }, (_, index) => ({
                    primary: `${index + 1}`,
                  })) as unknown as SelectItem[]
                }
                title="Duration"
                placeholderText={"Select Duration"}
                className="w-full"
              />
              <RegisteredFormSelected
                formKey="shortTermData.timelineEstimationType"
                required
                formState={formState}
                items={
                  Object.values(LongTermTimelineEstimationEnums).map((o) => ({
                    primary: convertReadableString(o),
                  })) as SelectItem[]
                }
                title="Time Period"
                className="w-full"
                placeholderText={"Select Time Period"}
              />
            </div>
          </div>
          <div>
            <RegisteredFormTextArea
              formKey="shortTermData.baselineData"
              formState={formState}
              rows={6}
              label="Baseline Data"
            />
          </div>
          <div>
            <RegisteredFormRadioInput
              formKey="shortTermData.establishBaselineOnTreatment"
              formState={formState}
              title="Establish Baseline on Treatment"
              items={YesNoRadioItems}
            />
          </div>
          <div className="flex justify-end mt-6">
            <Button onClick={() => setPanelOpen(false)} text={"Cancel"} appearance="secondary" className="mr-2" />
            <SubmitButton isLoading={isPending} buttonText={selectedGoal ? "Update" : "Create"} />
          </div>
        </FormContainer>
      </SlideOver>
    </div>
  );
};

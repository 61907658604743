import { Link, useLocation } from "react-router-dom";

import { Heading, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { links } from "@pages/ClientDetailsSubPages/IntakeForm/Links/config";
import { getPageTitleFromName } from "@pages/ClientDetailsSubPages/IntakeForm/Links/utils";

export const ClientDetailsIntakeFormNav: React.FC = () => {
  const { pathname } = useLocation();

  const forwardLink = (linkName: string): string => {
    switch (linkName) {
      case "home-environment":
        return `${linkName}/guardian-details`;
      case "health":
        return `${linkName}/medical-history`;
      case "development":
        return `${linkName}/education`;
      case "behaviors":
        return `${linkName}/general`;
      case "communication":
        return `${linkName}/verbal-communication`;
      case "tendencies":
        return `${linkName}/attention`;
      default:
        return linkName;
    }
  };
  return (
    <div className="flex w-full flex-col">
      <Heading className="mb-4 ml-4" type="h3">
        Intake Form
      </Heading>
      <Link to={"all"} className="w-full cursor-pointer">
        <Paragraph
          displayType="loud"
          className={`w-full px-4 py-2 hover:bg-limestone-100 ${pathname.includes("all") ? "bg-limestone-100" : ""}`}
        >
          All Fields
        </Paragraph>
      </Link>
      {links.map((link) => {
        const current = pathname.split("/")[pathname.split("/").length - 1] === link.name;
        return (
          <div key={link.name} className="w-full">
            <Link to={forwardLink(link.name)} className="w-full cursor-pointer">
              <Small className={`w-full px-4 py-2 hover:bg-limestone-100 ${current ? "bg-limestone-100" : ""}`}>
                {getPageTitleFromName(link.name, "en")}
              </Small>
            </Link>

            {pathname.includes(`/${link.name}`)
              ? link.sublinks?.map((sublink) => {
                  const current = pathname.split("/")[pathname.split("/").length - 1] === sublink.name;
                  return (
                    <Link key={`${link.name}/${sublink.name}`} to={`${link.name}/${sublink.name}`}>
                      <Small
                        className={`w-full py-2 pl-10 hover:bg-limestone-100 ${current ? "bg-limestone-100" : ""}`}
                      >
                        {getPageTitleFromName(sublink.name, "en")}
                      </Small>
                    </Link>
                  );
                })
              : null}
          </div>
        );
      })}
    </div>
  );
};

import { getGenderValue } from "@utils/helpers";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ApiLearnerMetadataGenderChoices, LearnerByIdQuery, useLearnerByIdQuery } from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer, TripleColumnHeader } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";

export const ClientDetailsEHRData: React.FC = () => {
  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, isFetching } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormInput, reset } = useFormUtils<LearnerByIdQuery>({
    mutationFn: async () => {
      await Promise.resolve();
    },
  });

  useEffect(() => {
    if (data) {
      const formattedGender: ApiLearnerMetadataGenderChoices = getGenderValue(
        data?.learnerById?.learnermetadata?.gender,
      ) as ApiLearnerMetadataGenderChoices;
      reset({
        ...data,
        learnerById: {
          ...data.learnerById,
          learnermetadata: {
            ...data?.learnerById?.learnermetadata,
            gender: formattedGender,
          },
        },
      });
    }
  }, [data, reset]);

  const formLoading = isFetching;

  return (
    <div className="flex w-full flex-col items-start">
      <FormContainer onSubmit={onSubmit} noValidate>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            readOnly
            formKey="learnerById.learnermetadata.firstName"
            inputSize="full"
            formState={formState}
            label="Client's First Name"
            errorMessage=""
          />
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.learnermetadata.lastName"
            inputSize="full"
            readOnly
            formState={formState}
            label="Client's Last Name"
            errorMessage=""
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.learnermetadata.birthDate"
            inputSize="full"
            type="date"
            readOnly
            formState={formState}
            label="Date of Birth"
            errorMessage=""
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.learnermetadata.gender"
            inputSize="full"
            readOnly
            formState={formState}
            label="Gender"
            errorMessage=""
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.learnermetadata.address"
            inputSize="full"
            formState={formState}
            label="Address Line 1"
            errorMessage=""
            readOnly
          />
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.learnermetadata.addressLine2"
            readOnly
            inputSize="full"
            formState={formState}
            label="Address Line 2"
            errorMessage=""
          />
        </DoubleColumnContainer>
        <TripleColumnHeader>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.learnermetadata.city"
            inputSize="full"
            formState={formState}
            readOnly
            label="City"
            errorMessage=""
          />
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.learnermetadata.state"
            inputSize="full"
            formState={formState}
            readOnly
            label="State / Province"
            errorMessage=""
          />
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.learnermetadata.zip"
            readOnly
            inputSize="full"
            formState={formState}
            label="Zip Code"
            errorMessage=""
          />
        </TripleColumnHeader>

        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.relationshipSet.edges.0.node.caregiver.caregivermetadata.firstName"
            readOnly
            inputSize="full"
            formState={formState}
            label="Caregiver's First Name"
            errorMessage=""
          />
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.relationshipSet.edges.0.node.caregiver.caregivermetadata.lastName"
            inputSize="full"
            formState={formState}
            label="Caregiver's Last Name"
            readOnly
            errorMessage=""
          />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.relationshipSet.edges.0.node.socialRelationship"
            inputSize="full"
            formState={formState}
            readOnly
            label="Relationship to Client"
            errorMessage=""
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="learnerById.relationshipSet.edges.0.node.caregiver.caregivermetadata.email"
            inputSize="full"
            readOnly
            formState={formState}
            label="Caregiver's Email Address"
            errorMessage=""
          />
        </DoubleColumnContainer>
      </FormContainer>
    </div>
  );
};

import { useEffect, useState } from "react";

import { Folder } from "@fronterahealth/frontera-ui-components";

import {
  ApiRecommendationBankFolderFolderTypeChoices,
  RecommendationBankFolderTypeEnum,
  useCreateRecommendationBankFolderMutation,
  useDeleteRecommendationBankFolderMutation,
  useRecommendationBankFoldersQuery,
  useUpdateRecommendationBankFolderMutation,
} from "@api/graphql/types-and-hooks";
import { convertDBString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

export const useRecommendationsBankFolders = () => {
  const { data, isFetched, refetch, isRefetching } = useRecommendationBankFoldersQuery({});
  const [userFolders, setUserFolders] = useState<Folder[]>([]);
  const [orgFolders, setOrgFolders] = useState<Folder[]>([]);
  const createFolder = useCreateRecommendationBankFolderMutation({});
  const deleteFolderMutation = useDeleteRecommendationBankFolderMutation({});
  const updateRecommendationBankFolderMutation = useUpdateRecommendationBankFolderMutation({});

  useEffect(() => {
    setUserFolders(
      data?.getRecommendationBankFolders?.userFolders?.map((folder) => {
        return { name: folder?.name, id: folder?.id, type: folder?.folderType } as Folder;
      }) ?? [],
    );

    setOrgFolders(
      data?.getRecommendationBankFolders?.organizationFolders?.map((folder) => {
        return { name: folder?.name, id: folder?.id, type: folder?.folderType } as Folder;
      }) ?? [],
    );
  }, [isFetched, isRefetching]);

  const createNewFolder = async (folderName: string, folderType: string): Promise<Folder> => {
    const response = await createFolder.mutateAsync({
      input: {
        folderType:
          folderType === "personal"
            ? RecommendationBankFolderTypeEnum.User
            : RecommendationBankFolderTypeEnum.Organization,
        name: folderName,
      },
    });
    notifySuccess("Successfully Created Folder");
    const newFolder = {
      id: response.createRecommendationBankFolder?.recommendationBankFolder?.id ?? "",
      name: response.createRecommendationBankFolder?.recommendationBankFolder?.name ?? "",
      type: response.createRecommendationBankFolder?.recommendationBankFolder?.folderType ?? "",
    };
    if (newFolder.type === ApiRecommendationBankFolderFolderTypeChoices.Organization) {
      setOrgFolders((prev) => [...prev, newFolder]);
    } else if (newFolder.type === ApiRecommendationBankFolderFolderTypeChoices.User) {
      setUserFolders((prev) => [...prev, newFolder]);
    }
    refetch();
    return newFolder as Folder;
  };

  const deleteFolder = async (folder: Folder) => {
    await deleteFolderMutation.mutateAsync(
      {
        input: {
          folderId: folder.id,
          folderType: convertDBString(folder.type) as RecommendationBankFolderTypeEnum,
        },
      },
      {
        onSuccess: () => {
          notifySuccess("Folder Deleted");
          refetch();
        },
      },
    );
  };

  const editFolder = async (folder: Folder, newValue: string) => {
    if (newValue && folder.name != newValue)
      await updateRecommendationBankFolderMutation.mutateAsync(
        {
          input: {
            folderId: folder.id,
            name: newValue,
            folderType: convertDBString(folder.type) as RecommendationBankFolderTypeEnum,
          },
        },
        {
          onSuccess: () => {
            refetch();
          },
        },
      );
  };

  return {
    userFolders,
    orgFolders,
    createNewFolder,
    deleteFolder,
    editFolder,
  };
};

import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { Heading, TabNavigation } from "@fronterahealth/frontera-ui-components";

import { useProviderByIdQuery } from "@api/graphql/types-and-hooks";
import "@components/notifications/notifications.css";

const providerDetailsNavigationTabs = ["details", "clients"];

const tabToName = (t: string) =>
  t
    .split("-")
    .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
    .join(" ");

export const ProviderDetails: React.FC = () => {
  const { pathname, state } = useLocation();
  const [providerName, setProviderName] = useState<string>("");

  const { providerId } = useParams();

  const providerDetailsQuery = useProviderByIdQuery(
    { providerId: providerId ? providerId : "" },
    {
      queryKey: ["provider-by-id", providerId],
      enabled: !!providerId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data } = providerDetailsQuery;

  useEffect(() => {
    if (data && data.providerById) {
      setProviderName(
        `${data.providerById?.providermetadata?.firstName} ${data.providerById?.providermetadata?.lastName}`,
      );
    }
  }, [data]);

  return (
    <div className="w-full">
      <Heading type="h1">{providerName}</Heading>
      <div className="my-4 flex items-center"></div>
      <TabNavigation
        tabs={providerDetailsNavigationTabs.map((tab) => ({
          current: pathname.split("/")[pathname.split("/").length - 1] === tab,
          type: "component",
          name: tab,
          Component: (
            <Link to={tab} state={state}>
              {tabToName(tab)}
            </Link>
          ),
        }))}
      />
      <div className="py-8">
        <Outlet />
      </div>
    </div>
  );
};

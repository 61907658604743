import { capitalizeString } from "@utils/utils";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { useScrollToAnchor } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/StickyNav/useScrollToAnchor";

interface Props {
  state: object;
}

export const StickyNav: React.FC<Props> = ({ state }) => {
  const { hash } = useLocation();
  const [headings, setHeadings] = React.useState<string[]>([]);

  useEffect(() => {
    const _headings = Array.from(document.querySelectorAll(`div[data-sticky-nav-header]`)).map((div) => div.id);
    setHeadings(_headings);
  }, [state]);

  useScrollToAnchor();

  return (
    <nav className="sticky top-[138px] flex flex-col items-start ">
      <Paragraph className="mb-4">On this page</Paragraph>

      <div className="flex flex-col items-start max-h-[calc(100vh-260px)] overflow-y-scroll ">
        {headings.map((heading) => {
          const actualHeading = heading.split(/-(.+)/)[1]; // split by first occurrence of "-"
          const active = hash.replace("#", "") === heading;
          const headingLabel = actualHeading
            .split("-")
            .map((h) => capitalizeString(h))
            .join(" ");

          return (
            <Link to={`#${heading}`} key={heading}>
              <div
                className={`transition-all cursor-pointer ${active ? "border-l-2" : "border-l"}   py-2 pl-2 hover:border-interaction-primary  ${active ? "border-interaction-primary" : "border-limestone-100"} `}
              >
                <Small
                  className={`transition-all hover:text-text-primary`}
                  displayType={active ? "loud" : "normal"}
                  colorType={active ? "primary" : "secondary"}
                >
                  {headingLabel}
                </Small>
              </div>
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

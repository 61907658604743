import {
  FieldValues,
  FormState,
  Path,
  PathValue,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import { RadioInput, RadioInputProps } from "@fronterahealth/frontera-ui-components";

import { getErrorFromFormKey } from "@components/forms/utils";

interface Props<T extends FieldValues> extends Omit<RadioInputProps, "name" | "legend" | "subtitle" | "ref"> {
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  watch: UseFormWatch<T>;
  trigger: UseFormTrigger<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  required?: boolean;
}

export const FormRadioInput = <T extends FieldValues>({
  formState,
  formKey,
  watch,
  register,
  setValue,
  trigger,
  required = true,
  title,
  ...props
}: Props<T>) => {
  const selected = watch(formKey);

  const errorMessage = getErrorFromFormKey(formKey, formState.errors);
  const registerOutput = register(formKey, {
    required: {
      value: props.disabled ? false : required,
      message: `${title} is required`,
    },
  });

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as PathValue<T, Path<T>>;

    setValue(formKey, value);
    await trigger(formKey);
  };

  return (
    <>
      <RadioInput
        title={title}
        errorMessage={errorMessage}
        legend={`${title} choice`}
        {...registerOutput}
        selectedId={selected}
        {...(props.disabled || props.readOnly
          ? { checked: props.items.some((item) => item.id === selected) }
          : { defaultChecked: props.items.some((item) => item.id === selected) })}
        displayOnlyMode={false}
        onChange={onChange}
        {...props}
      />
      {props.disabled && <input type="hidden" name={formKey} value={selected || ""} />}
    </>
  );
};

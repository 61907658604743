import { toast } from "react-toastify";

export const notifyError = (msg: string) =>
  toast(msg, { theme: "colored", position: "bottom-right", type: "error", className: "w-full" });

export const notifyWarn = (msg: string) =>
  toast(msg, { theme: "colored", position: "bottom-right", type: "warning", className: "w-full" });

export const notifySuccess = (msg: string) =>
  toast(msg, {
    theme: "colored",
    position: "bottom-right",
    type: "success",
    className: "w-full",
    autoClose: 1000,
  });

import { SparklesIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useParams } from "react-router-dom";

import { TableBuilder, Tooltip } from "@fronterahealth/frontera-ui-components";

import { useAddUpdateAssessmentTreatmentRecommendServicesMutation } from "@api/graphql/types-and-hooks";
import { notifyError } from "@components/notifications/notifications";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

const treatmentRecommendationsHeaders = [
  "codeNumber",
  "service",
  "hoursRequested",
  "frequency",
  "totalUnitsPerAuth",
  "id",
] as const;
type treatmentRecommendationsHeaderType = (typeof treatmentRecommendationsHeaders)[number];
type TreatmentRecommendations = Record<treatmentRecommendationsHeaderType, string>;

interface TreatmentRecommendationsTableProps {
  treatmentRecommendations: TreatmentRecommendations[];
  isDisplayModeForReview?: boolean;
}
export const TreatmentRecommendationsTable: React.FC<TreatmentRecommendationsTableProps> = ({
  treatmentRecommendations,
  isDisplayModeForReview = false,
}) => {
  const { refetch } = useAssessmentBuilderData();
  const { assessmentId } = useParams();
  const { mutateAsync } = useAddUpdateAssessmentTreatmentRecommendServicesMutation({});
  return (
    <div className="pr-12 relative overflow-y-auto">
      <TableBuilder<TreatmentRecommendations>
        displayOnlyMode={isDisplayModeForReview}
        valueValidator={{
          hoursRequested: (hours) => {
            const value = parseInt(hours);
            if (isNaN(value)) return "Hours field must be number";
            if (!value) return "Hours field is required";
            return null;
          },
        }}
        labelMapping={{
          codeNumber: "Code",
          frequency: "Frequency",
          hoursRequested: "Hours Requested",
          service: "Service",
          totalUnitsPerAuth: "Units / Auth",
        }}
        title={"Treatment Recommendations"}
        headers={["codeNumber", "service", "hoursRequested", "frequency", "totalUnitsPerAuth"]}
        data={treatmentRecommendations}
        onRowAdd={async (row) => {
          try {
            await mutateAsync({
              assessmentId: assessmentId || "<missing-id>",
              assessmentTreatmentRecommendService: { ...row, hoursRequested: parseInt(row.hoursRequested) },
            });
            await refetch();
          } catch (err) {
            console.error("Error adding Treatment Recommendations Table row", err);
            notifyError("Error adding row");
          }
        }}
        onRowDelete={async (row) => {
          try {
            await mutateAsync({
              assessmentId: assessmentId || "<missing-id>",
              assessmentTreatmentRecommendService: {
                ...row,
                hoursRequested: parseInt(row.hoursRequested),
                isDeleted: true,
              },
            });
            await refetch();
          } catch (err) {
            console.error("Error deleting Treatment Recommendations Table row", err);
            notifyError("Error deleting row");
          }
        }}
        onRowEdit={async (_, row) => {
          try {
            await mutateAsync({
              assessmentId: assessmentId || "<missing-id>",
              assessmentTreatmentRecommendService: {
                ...row,
                hoursRequested: parseInt(row.hoursRequested),
              },
            });
            await refetch();
          } catch (err) {
            console.error("Error editing Treatment Recommendations Table row", err);
            notifyError("Error editing row");
          }
        }}
      />
      <div className="absolute top-0 right-12 flex items-center">
        <SparklesIcon className="h-4 w-4 text-blurple-500 mr-1" />
        <span className="text-sm text-blurple-500">Experimental</span>
        <div className="whitespace-nowrap">
          <Tooltip text={"This feature may sometimes generate inaccurate data"} />
        </div>
      </div>
    </div>
  );
};

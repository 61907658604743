import { PropsWithChildren, createContext, useContext, useState } from "react";

import { LANGUAGE_KEYS } from "@internationalization/index";

interface InternationalizationContextI {
  chosenLanguage: LANGUAGE_KEYS;
  setChosenLanguage: (lang: LANGUAGE_KEYS) => void;
}

export const InternationalizationContext = createContext<InternationalizationContextI>({
  chosenLanguage: "en",
  setChosenLanguage: () => {},
});

export const useInternationalization = () => useContext(InternationalizationContext);

export const InternationalizationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [chosenLanguage, setChosenLanguage] = useState<LANGUAGE_KEYS>("en");

  return (
    <InternationalizationContext.Provider
      value={{
        chosenLanguage,
        setChosenLanguage,
      }}
    >
      {children}
    </InternationalizationContext.Provider>
  );
};

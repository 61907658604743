import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";

import { SupportedContentType } from "@components/SectionWidget/SectionWidget";
import { AddSection } from "@pages/EvaluationDetails/Components/AddSection";
import { SectionWidgetWrapper } from "@pages/EvaluationDetails/Components/SectionWidgetWrapper";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/EvaluationDetails/Components/helper";
import { FooterButtonRow } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/FooterButtonRow";
import { StickyNav } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/StickyNav/StickyNav";

const pageLocation: FilterSectionSubRoute = "recommendations";

export const EvaluationRecommendations: React.FC = () => {
  const filteredSections = useGetFilteredPageSectionsByLocation(pageLocation);
  const flags = useFlags<FeatureFlags>();

  const supportedContentTypes = ["Text", "Table"] as SupportedContentType[];
  if (flags["upload-images-component"]) {
    supportedContentTypes.push("Image");
  }

  return (
    <div className="flex w-full flex-col pb-6">
      <div className="grid grid-cols-4 gap-x-1">
        <div className={`col-span-3 flex w-full flex-col gap-y-4`}>
          <AddSection newRankOrder={1} defaultNewSectionTitle="Enter Section Title" pageLocation={pageLocation} />
          {filteredSections.map((section) => (
            <div key={section.id}>
              <SectionWidgetWrapper
                sectionType={"recommendation"}
                section={section}
                permissions={{
                  canDelete: true,
                  canEdit: true,
                }}
                supportedContentTypes={supportedContentTypes}
              />
              <AddSection
                newRankOrder={section.rankOrder + 1}
                defaultNewSectionTitle="Enter Section Title"
                pageLocation={pageLocation}
              />
            </div>
          ))}
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav state={filteredSections} />
        </div>
      </div>
      <FooterButtonRow primaryButtonTitle={"Next"} />
    </div>
  );
};

import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

// Import the feature flags

type FeatureFlagProps = {
  /**
   * The key of the flag from Launch Darkly to be used. This is constrained to keys that are
   * auto generated using `npm run flaggen` and they pull directly from the relevant environment
   */
  flagKey: keyof FeatureFlags; // The prop for flag key, constrained to valid feature flag keys
  children: React.ReactNode; // The children to conditionally render
};

export const FeatureFlagGate: React.FC<FeatureFlagProps> = ({ flagKey, children }) => {
  const flags = useFlags<FeatureFlags>();
  const flagEnabled = flags[flagKey];

  if (!flagEnabled) {
    return null; // Don't render children if the flag is disabled
  }

  return <>{children}</>; // Render children if the flag is enabled
};

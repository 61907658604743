import { RequiredFields } from "@utils/utils";
import { useEffect, useState } from "react";

import {
  Button,
  Divider,
  ExistingFileInterface,
  FileInstance,
  FileUploadAndParse,
  Select,
  SelectItem,
} from "@fronterahealth/frontera-ui-components";

import { FileTypeEnum } from "@api/graphql/types-and-hooks";
import { FileRow } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/EvaluationUploadFiles";
import { groupInterviewsByType } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/Interviews/utils";
import {
  INTERVIEW_TYPE_TYPE,
  interviewTypeMap,
} from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/constants";
import {
  useGetFileParseStatusRetriever,
  useGetRemoveFileCallback,
  useGetReportFileUploadCompleteCallback,
  useGetS3BucketUploadCallback,
  useGetS3BucketUrlRetriever,
} from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/hooks";

interface InterviewsSelectionProps {
  reportDoneCallback: (bool: boolean) => void;
  existingInterviewsTypes: string[];
  existingInterviews: ExistingFileInterface[];
}

export const InterviewsSelection: React.FC<InterviewsSelectionProps> = ({
  reportDoneCallback,
  existingInterviewsTypes,
  existingInterviews,
}) => {
  const [selectedInterviewType, setSelectedInterviewType] = useState<RequiredFields<SelectItem, "id"> | null>(null);
  const [fileStatuses, setFileStatuses] = useState<FileInstance[]>([]);

  useEffect(() => {
    if (existingInterviews?.length) {
      const interviewMetadata = JSON.parse(existingInterviews[0].metadata);
      const interviewType = interviewMetadata.interview_type as INTERVIEW_TYPE_TYPE;
      setSelectedInterviewType({ primary: interviewTypeMap[interviewType], id: interviewType });
    }
  }, [existingInterviews]);

  useEffect(() => {
    if (
      fileStatuses.length > 0 &&
      fileStatuses.every((file) => file.fileStatus === "done" || file.fileStatus === "parsing")
    ) {
      reportDoneCallback(true);
    }
  }, [JSON.stringify(fileStatuses), existingInterviews]);

  const interviewTypeLocked = !!existingInterviews?.length || fileStatuses.length > 0;

  const S3BucketUrlRetriever = useGetS3BucketUrlRetriever({
    /* NOTE: Bring this back when we do tagging of interview types */
    metadata: { interview_type: selectedInterviewType?.id },
  });
  const reportFileUploadCompleteCallback = useGetReportFileUploadCompleteCallback();
  const S3BucketUploadCallback = useGetS3BucketUploadCallback();
  const fileParseStatusRetriever = useGetFileParseStatusRetriever();
  const removeFileCallback = useGetRemoveFileCallback();

  const showUploader = selectedInterviewType;
  const fileKind: FileTypeEnum = FileTypeEnum.Interview;

  return (
    <div className="flex flex-col">
      <Select
        hintText="Optional"
        title={"Interview Type"}
        disabled={interviewTypeLocked}
        items={Object.entries(interviewTypeMap)
          .map(([id, primary]) => ({ id, primary }))
          .filter((item) => !existingInterviewsTypes.includes(item?.id ?? ""))}
        placeholderText={"Select Interview Type"}
        selected={selectedInterviewType}
        setSelected={(item) => {
          setSelectedInterviewType({
            primary: item.primary,
            id: item.id || "<missing-id>",
          });
        }}
      />
      {showUploader ? (
        <FileUploadAndParse
          key={selectedInterviewType.id}
          fileKind={fileKind}
          title={"Video / Audio Recordings"}
          supportedFileTypes={["mp4", "mpeg"]}
          maxSize={"2GB"}
          existingFiles={existingInterviews.length ? existingInterviews : undefined}
          S3BucketUrlRetriever={S3BucketUrlRetriever}
          S3BucketUploadCallback={S3BucketUploadCallback}
          reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
          fileParseStatusRetriever={fileParseStatusRetriever}
          removeFileCallback={removeFileCallback}
          updateFileStatusCallback={setFileStatuses}
        />
      ) : null}
    </div>
  );
};

interface InterviewsProps {
  existingInterviews: ExistingFileInterface[];
}
export const Interviews: React.FC<InterviewsProps> = ({ existingInterviews }) => {
  const [numberOfInterviews, setNumberOfInterviews] = useState<number>(1);
  const [readyForNewInterview, setReadyForNewInterview] = useState<boolean>(false);
  const existingInterviewsByType = groupInterviewsByType(existingInterviews);
  const existingInterviewsTypes = Object.keys(existingInterviewsByType);
  useEffect(() => {
    if (existingInterviews.length) {
      setNumberOfInterviews(existingInterviewsTypes.length);
      if (existingInterviews.every((a) => a.fileStatus === "done" || a.fileStatus === "parsing")) {
        setReadyForNewInterview(true);
      }
    } else {
      setReadyForNewInterview(false);
    }
  }, [existingInterviews.length]);

  const newInterviewsCount = numberOfInterviews - (existingInterviewsTypes.length || 0);

  if (existingInterviews) {
    return (
      <FileRow title="Interview" subtitle="Parent, Child, Providers, Family Members, etc">
        {/* RENDER ANY EXISTING INTERVIEWS */}
        {Object.entries(existingInterviewsByType)
          .sort(([typeA], [typeB]) => (typeA > typeB ? 1 : -1))
          .map(([, interviews], index) => {
            return (
              <div key={index} className="flex flex-col">
                <InterviewsSelection
                  key={JSON.stringify(interviews)}
                  existingInterviews={interviews}
                  reportDoneCallback={setReadyForNewInterview}
                  existingInterviewsTypes={existingInterviewsTypes}
                />
                {numberOfInterviews > 1 && index !== numberOfInterviews - 1 ? <Divider /> : null}
              </div>
            );
          })}

        {/* RENDER NEWLY UPLOADED INTERVIEWS */}
        {Array(newInterviewsCount < 0 ? 0 : newInterviewsCount)
          .fill(0)
          .map((_, index) => {
            return (
              <div key={index} className="flex flex-col">
                <InterviewsSelection
                  existingInterviews={[]}
                  reportDoneCallback={setReadyForNewInterview}
                  existingInterviewsTypes={existingInterviewsTypes}
                />
                {numberOfInterviews > 1 && index !== numberOfInterviews - 1 ? <Divider /> : null}
              </div>
            );
          })}

        {/* RENDER BUTTON TO ADD NEW ONES */}
        {readyForNewInterview && numberOfInterviews === existingInterviewsTypes.length ? (
          <Button
            className="self-end"
            appearance="link"
            text={"Add Another"}
            onClick={() => {
              setNumberOfInterviews((num) => num + 1);
            }}
          />
        ) : null}
      </FileRow>
    );
  }
  return (
    <FileRow title="Interview" subtitle="Parent, Child, Providers, Family Members, etc">
      {Array(numberOfInterviews)
        .fill(0)
        .map((_, index) => {
          return (
            <div key={index} className="flex flex-col">
              <InterviewsSelection
                existingInterviews={[]}
                reportDoneCallback={setReadyForNewInterview}
                existingInterviewsTypes={existingInterviewsTypes}
              />
              {numberOfInterviews > 1 && index !== numberOfInterviews - 1 ? <Divider /> : null}
            </div>
          );
        })}
      {readyForNewInterview ? (
        <Button
          className="self-end"
          appearance="link"
          text={"Add Another"}
          onClick={() => {
            setNumberOfInterviews((num) => num + 1);
          }}
        />
      ) : null}
    </FileRow>
  );
};

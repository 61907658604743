import { PlusIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

import { Button, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  CreateGoalBankLongTermGoalsInput,
  GoalTypeEnums,
  useCreateGoalBankLongTermGoalsMutation,
} from "@api/graphql/types-and-hooks";
import { GoalBankGoalsFormConfig, Goals } from "@components/GoalsBank/constants";
import { renderGoalBankFormDialog } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

interface GoalBankLongTermGoalsAddNewFormProps {
  folderId: string;
  refetchGoals: () => void;
  formType: Goals;
  hasGoals: boolean;
}

export const GoalBankLongTermGoalsAddNewForm: React.FC<GoalBankLongTermGoalsAddNewFormProps> = ({
  folderId,
  refetchGoals,
  formType,
  hasGoals = false,
}) => {
  const [open, setOpen] = useState(false);
  const config = GoalBankGoalsFormConfig[formType];
  const createLongTermGoal = useCreateGoalBankLongTermGoalsMutation({});
  const { formState, RegisteredFormInput, RegisteredFormTextArea, RegisteredFormSelected, reset, onSubmit } =
    useFormUtils<CreateGoalBankLongTermGoalsInput>({
      defaultValues: config.defaultValues,
      mutationFn: async (params) => {
        createLongTermGoal.mutateAsync(
          {
            input: {
              longtermGoalsData: params.longtermGoalsData.map((goal) => ({
                ...goal,
                goalType: convertDBString(goal.goalType) as GoalTypeEnums,
              })),
              folderId: folderId || params.folderId,
            },
          },
          {
            onSuccess: async () => {
              await refetchGoals();
              notifySuccess(`Successfully Created ${config.title}`);
              setOpen(false);
              reset();
            },
            onError: async (error: unknown) => {
              console.error(`Error when saving ${config.title}`, error);
              setOpen(true);
            },
          },
        );
      },
    });

  const dialog = renderGoalBankFormDialog({
    title: config.title,
    openForm: open,
    setOpenForm: setOpen,
    onSubmit: async () => {
      await onSubmit();
    },
    children: (
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormInput formKey="longtermGoalsData.0.goalName" formState={formState} label="Goal Name" required />
        <RegisteredFormSelected
          formKey="longtermGoalsData.0.goalType"
          required
          formState={formState}
          items={
            Object.values(GoalTypeEnums).map((o) => ({
              primary: convertReadableString(o),
            })) as SelectItem[]
          }
          title={"Goal Type"}
          placeholderText={""}
        />
        <RegisteredFormTextArea
          required={false}
          rows={4}
          formKey="longtermGoalsData.0.description"
          formState={formState}
          label="Description"
        />
      </FormContainer>
    ),
  });

  return (
    <>
      {hasGoals ? (
        <div
          onClick={() => setOpen(true)}
          className="group cursor-pointer content-center items-center justify-center rounded-lg border border-dashed border-limestone-200 px-6 hover:border-globemallow-600"
        >
          <div className="flex items-center justify-center">
            <PlusIcon className="size-6 text-globemallow-600 group-hover:text-globemallow-500" />
            <Button
              text="Add New"
              className="pl-1 text-base group-hover:text-globemallow-500"
              appearance="text"
              buttonAction="action"
              onClick={(e) => {
                if (e) {
                  e.stopPropagation();
                }
                setOpen(true);
              }}
            />
          </div>
          {dialog}
        </div>
      ) : (
        <>
          <Button appearance="primary" buttonAction="action" text={"+ Add New"} onClick={() => setOpen(true)} />
          {dialog}
        </>
      )}
    </>
  );
};

import { useCallback, useEffect } from "react";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  ApiLongTermGoalBankGoalTypeChoices,
  GoalTypeEnums,
  UpdateGoalBankLongTermGoalInput,
  useUpdateGoalBankLongTermGoalMutation,
} from "@api/graphql/types-and-hooks";
import { renderGoalBankFormDialog } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

export interface LongTermGoalBankFormProps {
  id: string;
  goalName: string;
  goalType: ApiLongTermGoalBankGoalTypeChoices | GoalTypeEnums;
  description?: string | null;
}

interface GoalBankLongTermGoalsEditFormProps {
  refetchGoals: () => void;
  selectedGoalBankLongTermGoal: LongTermGoalBankFormProps;
  openEditForm: boolean;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GoalBankLongTermGoalsEditForm: React.FC<GoalBankLongTermGoalsEditFormProps> = ({
  refetchGoals,
  selectedGoalBankLongTermGoal,
  openEditForm,
  setOpenEditForm,
}) => {
  const updateLongTermGoal = useUpdateGoalBankLongTermGoalMutation({});
  const handleSubmit = useCallback(
    async (params: UpdateGoalBankLongTermGoalInput) => {
      try {
        await updateLongTermGoal.mutateAsync({
          input: {
            description: params?.description,
            goalName: params?.goalName,
            goalType: convertDBString(params?.goalType) as GoalTypeEnums,
            goalBankLongTermId: selectedGoalBankLongTermGoal.id,
          },
        });
        refetchGoals();
        notifySuccess("Successfully Updated Long Term Goal");
        setOpenEditForm(false);
      } catch (error) {
        console.error("Error when saving Long Term Goal", error);
      }
    },
    [updateLongTermGoal, refetchGoals, selectedGoalBankLongTermGoal, setOpenEditForm],
  );

  const { formState, RegisteredFormInput, RegisteredFormTextArea, RegisteredFormSelected, reset, onSubmit } =
    useFormUtils<UpdateGoalBankLongTermGoalInput>({
      mutationFn: handleSubmit,
    });

  useEffect(() => {
    if (selectedGoalBankLongTermGoal) {
      reset({
        goalName: selectedGoalBankLongTermGoal.goalName,
        description: selectedGoalBankLongTermGoal.description,
        goalType: convertReadableString(selectedGoalBankLongTermGoal?.goalType) as GoalTypeEnums,
        goalBankLongTermId: selectedGoalBankLongTermGoal.id,
      });
    }
  }, [reset, selectedGoalBankLongTermGoal]);

  const dialog = renderGoalBankFormDialog({
    title: "Edit Long Term Goal",
    openForm: openEditForm,
    setOpenForm: setOpenEditForm,
    onSubmit: async () => {
      await onSubmit();
    },
    children: (
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormInput formKey="goalName" formState={formState} label="Goal Name" />
        <RegisteredFormSelected
          formKey="goalType"
          required
          formState={formState}
          items={
            Object.values(GoalTypeEnums).map((o) => ({
              primary: convertReadableString(o),
            })) as SelectItem[]
          }
          title={"Goal Type"}
          placeholderText={""}
        />
        <RegisteredFormTextArea
          required={false}
          rows={4}
          formKey="description"
          formState={formState}
          label="Description"
        />
      </FormContainer>
    ),
  });

  return <>{dialog}</>;
};

import { PlusIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

import { Button, Paragraph, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  CreateGoalBankShortTermGoalsInput,
  ShortTermTimelineEstimationEnums,
  useCreateGoalBankShortTermGoalsMutation,
} from "@api/graphql/types-and-hooks";
import { GoalBankGoalsFormConfig, Goals } from "@components/GoalsBank/constants";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { renderGoalBankFormDialog } from "@components/GoalsBank/utils";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifySuccess } from "@components/notifications/notifications";

interface GoalBankShortTermGoalsAddNewFormProps {
  folderId: string;
  refetchGoals: () => void;
  formType: Goals;
  hasGoals: boolean;
}

export const GoalBankShortTermGoalsAddNewForm: React.FC<GoalBankShortTermGoalsAddNewFormProps> = ({
  folderId,
  refetchGoals,
  formType,
  hasGoals = false,
}) => {
  const [open, setOpen] = useState(false);
  const config = GoalBankGoalsFormConfig[formType];
  const createShortTermGoal = useCreateGoalBankShortTermGoalsMutation({});
  const { longTermGoals } = useGetLongTermGoals(folderId);

  const { formState, RegisteredFormInput, RegisteredFormTextArea, RegisteredFormSelected, reset, onSubmit } =
    useFormUtils<CreateGoalBankShortTermGoalsInput>({
      defaultValues: config.defaultValues,
      mutationFn: async (params) => {
        createShortTermGoal.mutateAsync(
          {
            input: {
              shorttermGoalsData: params.shorttermGoalsData.map((goal) => ({
                ...goal,
                timelineEstimationValue: goal.timelineEstimationValue ? Number(goal.timelineEstimationValue) : null,
                timelineEstimationType: goal.timelineEstimationType
                  ? (convertDBString(goal.timelineEstimationType) as ShortTermTimelineEstimationEnums)
                  : null,
              })),
              folderId: folderId || params.folderId,
            },
          },
          {
            onSuccess: async () => {
              await refetchGoals();
              notifySuccess(`Successfully Created ${config.title}`);
              setOpen(false);
              reset();
            },
            onError: async (error: unknown) => {
              console.error(`Error when saving ${config.title}`, error);
              setOpen(true);
            },
          },
        );
      },
    });

  const dialog = renderGoalBankFormDialog({
    title: config.title,
    openForm: open,
    setOpenForm: setOpen,
    onSubmit: async () => {
      await onSubmit();
    },
    children: (
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormInput formKey="shorttermGoalsData.0.goalName" formState={formState} label="Goal Name" />
        <RegisteredFormSelected
          required={true}
          formKey="shorttermGoalsData.0.longTermGoalBankId"
          formState={formState}
          items={
            longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
              return {
                id: edge?.node?.id,
                primary: edge?.node?.goalName,
                secondary: convertReadableString(edge?.node?.goalType),
              };
            }) as SelectItem[]
          }
          title="Long Term Goal"
          placeholderText={"Select Long Term Goal"}
        />

        <RegisteredFormTextArea
          required={false}
          rows={4}
          formKey="shorttermGoalsData.0.description"
          formState={formState}
          label="Description"
        />
        {/* // Note: Commenting this out for now because it might come back in the future  */}
        {/* <RegisteredFormInput
          required={false}
          formKey="shorttermGoalsData.0.medicalRationale"
          formState={formState}
          label="Medical Rationale"
        /> */}
        {/* <RegisteredFormInput
          required={false}
          formKey="shorttermGoalsData.0.programGoal"
          formState={formState}
          label="Program Goal"
        /> */}
        <RegisteredFormInput
          required={false}
          formKey="shorttermGoalsData.0.masteryCriteria"
          formState={formState}
          label="Mastery Criteria"
        />
        <Paragraph displayType="loud" colorType="primary">
          Expected Mastery Date
        </Paragraph>
        <div className="w-full flex items-center justify-start mt-2 gap-x-4">
          <RegisteredFormSelected
            required={false}
            formKey="shorttermGoalsData.0.timelineEstimationValue"
            formState={formState}
            items={
              Array.from({ length: 60 }, (_, index) => ({
                primary: `${index + 1}`,
              })) as unknown as SelectItem[]
            }
            title="Duration"
            placeholderText={"Select Duration"}
            className="w-full z-50"
          />
          <RegisteredFormSelected
            required={false}
            formKey="shorttermGoalsData.0.timelineEstimationType"
            formState={formState}
            items={
              Object.values(ShortTermTimelineEstimationEnums).map((o) => ({
                primary: convertReadableString(o),
              })) as SelectItem[]
            }
            title="Time Period"
            className="w-full z-50"
            placeholderText={"Select Time Period"}
          />
        </div>
      </FormContainer>
    ),
  });

  return (
    <>
      {hasGoals ? (
        <div
          onClick={() => setOpen(true)}
          className="group cursor-pointer content-center items-center justify-center rounded-lg border border-dashed border-limestone-200 px-6 hover:border-globemallow-600"
        >
          <div className="flex items-center justify-center">
            <PlusIcon className="size-6 text-globemallow-600 group-hover:text-globemallow-500" />
            <Button
              text="Add New"
              className="pl-1 text-base group-hover:text-globemallow-500"
              appearance="text"
              buttonAction="action"
              onClick={(e) => {
                if (e) {
                  e.stopPropagation();
                }
                setOpen(true);
              }}
            />
          </div>
          {dialog}
        </div>
      ) : (
        <>
          <Button appearance="primary" buttonAction="action" text={"+ Add New"} onClick={() => setOpen(true)} />
          {dialog}
        </>
      )}
    </>
  );
};

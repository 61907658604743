import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  UpdateProviderLearnersMutationVariables,
  useProviderByIdQuery,
  useUpdateProviderLearnersMutation,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAdminData } from "@providers/AdminDataProvider";
import { useProvidersData } from "@providers/ProvidersDataProvider";

export const ProviderClients: React.FC = () => {
  const { providerId } = useParams();
  const { learnersQuery } = useAdminData();
  const { providersQuery } = useProvidersData();

  const providerDetailsQuery = useProviderByIdQuery(
    { providerId: providerId ? providerId : "" },
    {
      queryKey: ["provider-by-id", providerId],
      enabled: !!providerId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch } = providerDetailsQuery;

  const updateProviderLearnersMutation = useUpdateProviderLearnersMutation({});

  const { data: updateData } = updateProviderLearnersMutation;

  const { formState, onSubmit, RegisteredCheckboxList, reset } = useFormUtils<UpdateProviderLearnersMutationVariables>({
    mutationFn: async (params) => {
      await updateProviderLearnersMutation.mutateAsync({
        learners: params.learners,
        providerId: providerId ? providerId : "",
      });
      await refetch();
      await providersQuery.refetch();
    },
  });

  useEffect(() => {
    if (data && data.providerById?.providermetadata) {
      const learnersData = data.providerById?.learnerproviderSet.edges;
      reset({
        learners: learnersData.length ? learnersData.map((item) => item?.node?.learner.id) : [],
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (updateData?.updateProviderLearners) {
      if (updateData?.updateProviderLearners.status) {
        notifySuccess(updateData.updateProviderLearners?.message ? updateData.updateProviderLearners?.message : "");
      } else {
        notifyError(updateData.updateProviderLearners?.message ? updateData.updateProviderLearners?.message : "");
      }
    }
  }, [updateData]);

  const availableLearners = learnersQuery?.data?.learner?.length
    ? learnersQuery.data.learner.map((learner) => ({
        id: learner.id,
        title: `${learner.learnermetadata?.firstName} ${learner.learnermetadata?.lastName}`,
      }))
    : [];

  return (
    <div className="flex w-full flex-col items-start">
      <FormContainer onSubmit={onSubmit}>
        <DoubleColumnContainer>
          <RegisteredCheckboxList
            required={false}
            formState={formState}
            orientation="vertical"
            formKey="learners"
            title={"Authorise Clients"}
            // @ts-ignore: Ignoring the compiler and risking bugs because: CheckboxItem[]
            items={availableLearners}
          />
        </DoubleColumnContainer>
        <div className="mt-6">
          <SubmitButton isLoading={formState.isSubmitting} buttonText="Update" />
        </div>
      </FormContainer>
    </div>
  );
};

import { FieldValues, FormState, Path, UseFormRegister } from "react-hook-form";

import { CheckboxButtonList, CheckboxList, CheckboxListProps } from "@fronterahealth/frontera-ui-components";

interface Props<T extends FieldValues> extends Omit<CheckboxListProps, "name" | "legend" | "subtitle" | "ref"> {
  register: UseFormRegister<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  required?: boolean;
  buttonVersion?: boolean;
}

export const FormCheckBoxList = <T extends FieldValues>({
  formState,
  formKey,
  register,

  required = true,
  title,
  buttonVersion,
  ...props
}: Props<T>) => {
  const registerOutput = register(formKey, {
    required: {
      value: required,
      message: `${title} is required`,
    },
  });
  if (buttonVersion) {
    return <CheckboxButtonList title={title} legend={`${title} choice`} {...registerOutput} {...props} />;
  }
  return <CheckboxList title={title} legend={`${title} choice`} {...registerOutput} {...props} />;
};

import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { FolderIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";

import { Button, Folder, FolderExplorer, GoalBankCard, GoalBankPanel } from "@fronterahealth/frontera-ui-components";

import {
  useDeleteGoalBankLongTermGoalMutation,
  useDeleteGoalBankShortTermGoalMutation,
  useDeleteGoalBankTargetMutation,
} from "@api/graphql/types-and-hooks";
import { GoalBankLongTermGoalsAddNewForm } from "@components/GoalsBank/SavedGoals/LongTermGoals/GoalBankLongTermGoalsAddNewForm";
import {
  GoalBankLongTermGoalsEditForm,
  LongTermGoalBankFormProps,
} from "@components/GoalsBank/SavedGoals/LongTermGoals/GoalBankLongTermGoalsEditForm";
import { MoveLongTermGoalToFolder } from "@components/GoalsBank/SavedGoals/LongTermGoals/GoalBankLongTermGoalsMoveToFolder";
import { GoalBankShortTermGoalsAddNewForm } from "@components/GoalsBank/SavedGoals/ShortTermGoals/GoalBankShortTermGoalsAddNewForm";
import {
  GoalBankShortTermGoalsEditForm,
  ShortTermGoalBankFormProps,
} from "@components/GoalsBank/SavedGoals/ShortTermGoals/GoalBankShortTermGoalsEditForm";
import { MoveShortTermGoalToFolder } from "@components/GoalsBank/SavedGoals/ShortTermGoals/GoalBankShortTermGoalsMoveToFolder";
import { GoalBankTargetsAddNewForm } from "@components/GoalsBank/SavedGoals/Targets/GoalBankTargetsGoalsAddNewForm";
import {
  GoalBankTargetsEditForm,
  TargetFormProps,
} from "@components/GoalsBank/SavedGoals/Targets/GoalBankTargetsGoalsEditForm";
import { MoveTargetGoalToFolder } from "@components/GoalsBank/SavedGoals/Targets/GoalBankTargetsMoveToFolder";
import { Goals } from "@components/GoalsBank/constants";
import { DeleteFolderDialog } from "@components/GoalsBank/dialogs/DeleteFolderDialog";
import { useGetLongTermGoals } from "@components/GoalsBank/hooks/useGetLongTermGoals";
import { useGetShortTermGoals } from "@components/GoalsBank/hooks/useGetShortTermGoals";
import { useGetTargets } from "@components/GoalsBank/hooks/useGetTargets";
import { useGoalBankFolders } from "@components/GoalsBank/hooks/useGoalBankFolders";
import {
  findFolder,
  getFolderSelections,
  getOrgFolderSection,
  getTabsToDisplay,
  getUserFolderSection,
} from "@components/GoalsBank/utils";
import { convertReadableString } from "@components/forms/utils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";

export const SavedGoalsBank = () => {
  const { user } = useAuth0();
  const orgName = user![config.auth0Audience + "/orgName"] || "";

  const [selectedTab, setSelectedTab] = useState<Goals>(Goals.LONG_TERM_GOALS);
  const [selectedFolderId, setSelectedFolderId] = useState<string>("");
  const [selectedGoal, setSelectedGoal] = useState<
    LongTermGoalBankFormProps | ShortTermGoalBankFormProps | TargetFormProps | null
  >(null);

  const { userFolders, orgFolders, createNewFolder, deleteFolder, editFolder } = useGoalBankFolders();
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);

  const { mutateAsync: deleteLongTermGoal } = useDeleteGoalBankLongTermGoalMutation({});
  const { mutateAsync: deleteShortTermGoal } = useDeleteGoalBankShortTermGoalMutation({});
  const { mutateAsync: deleteTarget } = useDeleteGoalBankTargetMutation({});

  const { longTermGoals, refetchLtg, ltgIsLoading } = useGetLongTermGoals(selectedFolderId);
  const { shortTermGoals, refetchStg, stgIsLoading } = useGetShortTermGoals(selectedFolderId);
  const { targets, refetchTargets, targetsIsLoading } = useGetTargets(selectedFolderId);

  const [isLTGMoveToFolderDialogOpen, setLTGMoveToFolderDialogOpen] = useState<boolean>(false);
  const [isSTGMoveToFolderDialogOpen, setSTGMoveToFolderDialogOpen] = useState<boolean>(false);
  const [isTargetMoveToFolderDialogOpen, setTargetMoveToFolderDialogOpen] = useState<boolean>(false);

  const displayLongTermGoals = useMemo(() => {
    if (selectedTab !== Goals.LONG_TERM_GOALS) return [];

    return (
      longTermGoals?.getGoalBankLongTermGoals?.edges.map((edge) => {
        const goal = edge?.node;
        if (!goal) return null;

        return (
          <GoalBankCard
            key={goal.id}
            id={goal.id ?? ""}
            title={goal.goalName ?? ""}
            subtitle={convertReadableString(goal.goalType) ?? ""}
            body={goal.description ?? ""}
            onClick={() => {
              setSelectedGoal(goal as LongTermGoalBankFormProps);
              setOpenEditForm(true);
            }}
            settings={{
              menuItems: [
                {
                  label: "Edit",
                  Icon: PencilIcon,
                  callback: () => {
                    setSelectedGoal(goal as LongTermGoalBankFormProps);
                    setOpenEditForm(true);
                  },
                },
                {
                  label: "Move to Folder",
                  Icon: FolderIcon,
                  callback: () => {
                    setSelectedGoal(goal as LongTermGoalBankFormProps);
                    setLTGMoveToFolderDialogOpen(true);
                  },
                },
                {
                  label: "Delete",
                  Icon: TrashIcon,
                  textColorClassName: "text-red-700",
                  callback: async () => {
                    const goalId = goal.id;
                    try {
                      await deleteLongTermGoal({
                        input: {
                          folderId: selectedFolderId,
                          goalBankLongTermId: goalId,
                        },
                      });
                      notifySuccess("Successfully Deleted Long Term Goal");
                      await refetchLtg();
                    } catch (err) {
                      console.error("Failed To Delete Long Term Goal", err);
                      notifyError("Failed To Delete Long Term Goal");
                    }
                  },
                },
              ],
              openDirection: "to-bottom-left",
            }}
          />
        );
      }) || []
    );
  }, [selectedTab, longTermGoals, setSelectedGoal, setOpenEditForm]);

  const displayShortTermGoals = useMemo(() => {
    if (selectedTab !== Goals.SHORT_TERM_GOALS) return [];

    return (
      shortTermGoals?.getGoalBankShortTermGoals?.edges.map((edge) => {
        const goal = edge?.node;
        if (!goal) return null;

        return (
          <GoalBankCard
            key={goal.id}
            id={goal.id ?? ""}
            title={goal.goalName ?? ""}
            subtitle={goal.longTermGoalBank?.goalName ?? ""}
            body={goal.description ?? ""}
            onClick={() => {
              setSelectedGoal(goal as ShortTermGoalBankFormProps);
              setOpenEditForm(true);
            }}
            settings={{
              menuItems: [
                {
                  label: "Edit",
                  Icon: PencilIcon,
                  callback: () => {
                    setSelectedGoal(goal as ShortTermGoalBankFormProps);
                    setOpenEditForm(true);
                  },
                },
                {
                  label: "Move to Folder",
                  Icon: FolderIcon,
                  callback: () => {
                    setSelectedGoal(goal as ShortTermGoalBankFormProps);
                    setSTGMoveToFolderDialogOpen(true);
                  },
                },
                {
                  label: "Delete",
                  Icon: TrashIcon,
                  textColorClassName: "text-red-700",
                  callback: async () => {
                    const goalId = goal.id;
                    try {
                      await deleteShortTermGoal({
                        input: {
                          folderId: selectedFolderId,
                          goalBankShortTermId: goalId,
                        },
                      });
                      notifySuccess("Successfully Deleted Short Term Goal");
                      await refetchStg();
                    } catch (err) {
                      console.error("Failed To Delete Short Term Goal", err);
                      notifyError("Failed To Delete Short Term Goal");
                    }
                  },
                },
              ],
              openDirection: "to-bottom-left",
            }}
          />
        );
      }) || []
    );
  }, [selectedTab, shortTermGoals, setSelectedGoal, setOpenEditForm]);

  const displayTargets = useMemo(() => {
    if (selectedTab !== Goals.TARGETS) return [];

    return (
      targets?.getGoalBankTargets?.edges.map((edge) => {
        const target = edge?.node;
        if (!target) return null;

        return (
          <GoalBankCard
            key={target.id}
            id={target.id ?? ""}
            title={target.targetName ?? ""}
            subtitle={target.shortTermGoalBank?.goalName ?? ""}
            body={target.targetDescription ?? ""}
            onClick={() => {
              setSelectedGoal(target as TargetFormProps);
              setOpenEditForm(true);
            }}
            settings={{
              menuItems: [
                {
                  label: "Edit",
                  Icon: PencilIcon,
                  callback: () => {
                    setSelectedGoal(target as TargetFormProps);
                    setOpenEditForm(true);
                  },
                },
                {
                  label: "Move to Folder",
                  Icon: FolderIcon,
                  callback: () => {
                    setSelectedGoal(target as TargetFormProps);
                    setTargetMoveToFolderDialogOpen(true);
                  },
                },
                {
                  label: "Delete",
                  Icon: TrashIcon,
                  textColorClassName: "text-red-700",
                  callback: async () => {
                    const targetId = target.id;
                    try {
                      await deleteTarget({
                        input: {
                          folderId: selectedFolderId,
                          goalBankTargetId: targetId,
                        },
                      });
                      notifySuccess("Successfully Deleted Target");
                      await refetchTargets();
                    } catch (err) {
                      console.error("Failed To Delete Target", err);
                      notifyError("Failed To Delete Target");
                    }
                  },
                },
              ],
              openDirection: "to-bottom-left",
            }}
          />
        );
      }) || []
    );
  }, [selectedTab, targets, setSelectedGoal, setOpenEditForm]);
  const [deleteFolderId, setDeleteFolderId] = useState<string>("");
  const userFoldersSection = getUserFolderSection(userFolders);
  const orgFoldersSection = getOrgFolderSection(orgFolders, orgName);
  const [openDeleteFolderDialog, setDeleteFolderDialogOpen] = useState(false);

  const onSelectedFolder = (folder: Folder) => {
    setSelectedFolderId(folder.id);
  };

  const formTypeMapping = {
    [Goals.LONG_TERM_GOALS]: {
      refetch: refetchLtg,
      form: GoalBankLongTermGoalsAddNewForm,
      header: "No Goals Here Yet",
      subHeader: 'Click "Add New" to begin organizing your goals here.',
    },
    [Goals.SHORT_TERM_GOALS]: {
      refetch: refetchStg,
      form: GoalBankShortTermGoalsAddNewForm,
      header: "No Goals Here Yet",
      subHeader: 'Click "Add New" to begin organizing your goals here.',
    },
    [Goals.TARGETS]: {
      refetch: refetchTargets,
      form: GoalBankTargetsAddNewForm,
      header: "No Targets Here Yet",
      subHeader: 'Click "Add New" to begin organizing your targets here.',
    },
  };
  const goalsToDisplay = [displayLongTermGoals, displayShortTermGoals, displayTargets].flat();

  const showDeleteFolderDialog = (folderId: string) => {
    setDeleteFolderId(folderId);
    setDeleteFolderDialogOpen(true);
  };

  return (
    <>
      <FolderExplorer
        selectedFolderId={selectedFolderId}
        onDeleteFolder={(folderId: string) => {
          showDeleteFolderDialog(folderId);
        }}
        onEditFolder={(id: string, newValue: string) =>
          editFolder(findFolder(id, userFolders.concat(orgFolders)), newValue)
        }
        onCreateFolder={async (folderName: string, folderType: string) => {
          const newFolder = await createNewFolder(folderName, folderType);
          setSelectedFolderId(newFolder.id);
        }}
        onSelectedFolder={onSelectedFolder}
        title="Saved Goals"
        folderSections={orgName ? [userFoldersSection, orgFoldersSection] : [userFoldersSection]}
        modalButton={<Button id="saved-goals-btn" appearance="secondary" text="Saved Goals" />}
        panel={
          <GoalBankPanel
            addNewButton={() => {
              const FormComponent = formTypeMapping[selectedTab].form;
              const refetchGoals = formTypeMapping[selectedTab].refetch;
              return (
                <FormComponent
                  folderId={selectedFolderId}
                  refetchGoals={() => {
                    if (refetchGoals) refetchGoals();
                  }}
                  formType={selectedTab}
                  hasGoals={goalsToDisplay.length > 0}
                />
              );
            }}
            isLoading={stgIsLoading || ltgIsLoading || targetsIsLoading}
            key={userFolders.length + orgFolders.length}
            noFolders={!userFolders.length && !orgFolders.length}
            goalsToDisplay={goalsToDisplay}
            goalsTabs={getTabsToDisplay(selectedTab, setSelectedTab)}
            onCreateFolder={createNewFolder}
            noGoalsEmptyState={{
              header: formTypeMapping[selectedTab].header,
              subHeader: formTypeMapping[selectedTab].subHeader,
            }}
          ></GoalBankPanel>
        }
      >
        {openEditForm && selectedGoal && selectedTab === Goals.LONG_TERM_GOALS && (
          <GoalBankLongTermGoalsEditForm
            openEditForm={openEditForm}
            setOpenEditForm={setOpenEditForm}
            refetchGoals={refetchLtg}
            selectedGoalBankLongTermGoal={selectedGoal as LongTermGoalBankFormProps}
          />
        )}
        {openEditForm && selectedGoal && selectedTab === Goals.SHORT_TERM_GOALS && (
          <GoalBankShortTermGoalsEditForm
            key={selectedFolderId}
            folderId={selectedFolderId}
            openEditForm={openEditForm}
            setOpenEditForm={setOpenEditForm}
            refetchGoals={refetchStg}
            selectedGoalBankShortTermGoal={selectedGoal as ShortTermGoalBankFormProps}
          />
        )}
        {openEditForm && selectedGoal && selectedTab === Goals.TARGETS && (
          <GoalBankTargetsEditForm
            key={selectedFolderId}
            folderId={selectedFolderId}
            openEditForm={openEditForm}
            setOpenEditForm={setOpenEditForm}
            refetchGoals={refetchTargets}
            selectedGoalBankTarget={selectedGoal as TargetFormProps}
          />
        )}
        <MoveLongTermGoalToFolder
          open={isLTGMoveToFolderDialogOpen}
          currentFolderId={selectedFolderId}
          goalBankLongTermId={selectedGoal?.id ?? undefined}
          foldersToSelectFrom={getFolderSelections(userFoldersSection, orgFoldersSection)}
          onCloseClick={() => setLTGMoveToFolderDialogOpen(false)}
          refetch={refetchLtg}
        />
        <MoveShortTermGoalToFolder
          open={isSTGMoveToFolderDialogOpen}
          currentFolderId={selectedFolderId}
          goalBankShortTermId={selectedGoal?.id ?? undefined}
          foldersSelection={getFolderSelections(userFoldersSection, orgFoldersSection)}
          onCloseClick={() => {
            setSTGMoveToFolderDialogOpen(false);
          }}
          refetch={refetchStg}
        />
        <MoveTargetGoalToFolder
          open={isTargetMoveToFolderDialogOpen}
          currentFolderId={selectedFolderId}
          goalBankTargetId={selectedGoal?.id ?? undefined}
          foldersSelection={getFolderSelections(userFoldersSection, orgFoldersSection)}
          onCloseClick={() => setTargetMoveToFolderDialogOpen(false)}
          refetch={refetchTargets}
        />
        <DeleteFolderDialog
          open={openDeleteFolderDialog}
          onCancelClick={() => {
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
          onDeleteClick={() => {
            if (deleteFolderId == selectedFolderId) {
              setSelectedFolderId("");
            }
            deleteFolder(findFolder(deleteFolderId, userFolders.concat(orgFolders)));
            setDeleteFolderId("");
            setDeleteFolderDialogOpen(false);
          }}
        />
      </FolderExplorer>
    </>
  );
};

import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Paragraph } from "@fronterahealth/frontera-ui-components";

import {
  DevelopmentMilestonesQuestionType,
  LearnerDevelopmentalMileStonesQuery,
  useLearnerDevelopmentalMileStonesQuery,
} from "@api/graphql/types-and-hooks";
import { FormContainer, FormSpacer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { QuestionData, convertOnboardingQuestionListToQuestionMap } from "@components/forms/utils";
import {
  RadioButtonValueMappings,
  developmentalMilestonesQuestionsMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/DevelopmentalMilestone/DevelopmentalMilestonesQuestions.languageMapping";
import { generateOnetimeLateItemsWithLabel } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const DevelopmentalMilestone: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();

  const customQueryKey = pathname.includes("all") ? "/development/developmental-milestones" : undefined;

  const config = useGetQueryConfig<LearnerDevelopmentalMileStonesQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerDevelopmentalMileStonesQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormRadioInput, reset } = useFormUtils<{
    [key in DevelopmentMilestonesQuestionType]: string;
  }>({
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  useEffect(() => {
    if (data) {
      const developmentalMilestoneQuestions = data.learnerById?.onboardingQuestionsForLearner.edges.map((edge) => ({
        ...edge?.node,
      }));

      const formattedQuestions = convertOnboardingQuestionListToQuestionMap(
        developmentalMilestoneQuestions as QuestionData[],
      );

      reset(formattedQuestions);
    }
  }, [data, reset]);

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        <div className="flex-col">
          <Paragraph>Please estimate if the age of attainment was on time or late</Paragraph>
          <FormSpacer />
          {Object.values(DevelopmentMilestonesQuestionType).map((questionType) => {
            return (
              <RegisteredFormRadioInput
                key={questionType}
                items={generateOnetimeLateItemsWithLabel(RadioButtonValueMappings[questionType], chosenLanguage)}
                title={developmentalMilestonesQuestionsMapping[questionType][chosenLanguage]}
                disabled
                // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                formKey={`${questionType}`}
                formState={formState}
              />
            );
          })}
        </div>
      </FormContainer>
    </div>
  );
};

import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { Divider, Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

import { LearnerEducationQuery, useLearnerEducationQuery } from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { transformQueryData } from "@components/forms/utils";
import {
  LearnerEducationData,
  OtherServicesHistoryType,
  TheraputicServicesType,
  educationMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/Education/educationMapping";
import { YesNoRadioItems } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

type LearnerEducationMetaDataType = NonNullable<
  NonNullable<NonNullable<LearnerEducationQuery["learnerById"]>["learnerEducation"]>["learnerEducationMetaData"]
>;

type LearnerEducationType = NonNullable<
  NonNullable<NonNullable<LearnerEducationQuery["learnerById"]>["learnerEducation"]>
>;

export const extractEducationData = (queryData: LearnerEducationQuery) => {
  const learnerOtherServicesHistory: OtherServicesHistoryType[] =
    queryData.learnerById?.learnerOtherServicesHistory?.edges &&
    queryData.learnerById?.learnerOtherServicesHistory?.edges?.length
      ? queryData.learnerById.learnerOtherServicesHistory?.edges?.map((edge) => ({
          service: edge?.node?.service || "",
          serviceHours: edge?.node?.serviceHours || "",
          serviceProviderName: edge?.node?.serviceProviderName || "",
          serviceStartDate: edge?.node?.serviceStartDate || "",
          serviceEndDate: edge?.node?.serviceEndDate || "",
        }))
      : [];
  const learnerTheraputicServicesInSchool: TheraputicServicesType[] =
    queryData.learnerById?.learnerTheraputicServicesInSchool?.edges &&
    queryData.learnerById?.learnerTheraputicServicesInSchool?.edges?.length
      ? queryData.learnerById.learnerTheraputicServicesInSchool?.edges?.map((edge) => ({
          service: edge?.node?.service || "",
          serviceHours: edge?.node?.serviceHours || "",
        }))
      : [];
  const converted: LearnerEducationData = {
    learnerEducation: { ...queryData.learnerById?.learnerEducation } as unknown as LearnerEducationType,
    learnerEducationMetaData: {
      ...queryData.learnerById?.learnerEducation?.learnerEducationMetaData,
    } as unknown as LearnerEducationMetaDataType,
    learnerOtherServicesHistory,
    learnerTheraputicServicesInSchool,
  };
  const transformed = transformQueryData(converted, {
    "learnerEducation.specialEducation": (value) => (value ? "yes" : "no"),
  });
  return transformed;
};

export const Education: React.FC = () => {
  const { clientId } = useParams();
  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/development/education" : undefined;

  const config = useGetQueryConfig<LearnerEducationQuery>(customQueryKey);

  const learnerDetailsQuery = useLearnerEducationQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching: formLoading } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormInput, RegisteredFormRadioInput, reset, control, RegisteredFormTextArea } =
    useFormUtils<LearnerEducationData>({
      mutationFn: async () => {
        await Promise.resolve();
      },
      displayOnlyMode: pathname.includes("all"),
    });

  const { fields: therapeuticServicesFields } = useFieldArray({
    control,
    name: "learnerTheraputicServicesInSchool",
  });

  const { fields: otherHistoricalServicesFields } = useFieldArray({
    control,
    name: "learnerOtherServicesHistory",
  });

  useEffect(() => {
    if (data) {
      reset(extractEducationData(data));
    }
  }, [data, reset]);

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        <DoubleColumnContainer>
          <RegisteredFormInput
            label={educationMapping.learnerEducation.nameOfSchool[chosenLanguage]}
            readOnly
            formState={formState}
            formKey={`learnerEducation.nameOfSchool`}
            isLoading={formLoading}
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            label={educationMapping.learnerEducation.grade[chosenLanguage]}
            readOnly
            formState={formState}
            formKey={`learnerEducation.grade`}
            isLoading={formLoading}
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormInput
            label={educationMapping.learnerEducation.educationSetting[chosenLanguage]}
            readOnly
            formState={formState}
            formKey={`learnerEducation.educationSetting`}
            isLoading={formLoading}
          />
        </DoubleColumnContainer>
        <DoubleColumnContainer>
          <RegisteredFormRadioInput
            title={educationMapping.learnerEducation.specialEducation[chosenLanguage]}
            disabled
            items={YesNoRadioItems}
            formState={formState}
            formKey={`learnerEducation.specialEducation`}
          />
        </DoubleColumnContainer>
        <Heading type="h4" className="my-4">
          Therapeutic services in school
        </Heading>

        {therapeuticServicesFields.map((field, index) => {
          return (
            <div className="flex-col" key={field.id}>
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center">
                  <Paragraph colorType="primary">Therapeutic Service {index + 1}</Paragraph>
                </div>
              </div>

              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={educationMapping.learnerTheraputicServicesInSchool.service?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                  formKey={`learnerTheraputicServicesInSchool[${index}].service`}
                  isLoading={formLoading}
                />
                <RegisteredFormInput
                  label={educationMapping.learnerTheraputicServicesInSchool.serviceHours?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                  formKey={`learnerTheraputicServicesInSchool[${index}].serviceHours`}
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>

              <Divider />
            </div>
          );
        })}
        <Heading type="h4" className="my-4">
          History of Other Services
        </Heading>

        {otherHistoricalServicesFields.map((field, index) => {
          return (
            <div className="flex-col" key={field.id}>
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center">
                  <Paragraph>Other Historical Service {index + 1}</Paragraph>
                </div>
              </div>

              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={educationMapping.learnerOtherServicesHistory.service?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                  formKey={`learnerOtherServicesHistory[${index}].service`}
                  isLoading={formLoading}
                />
                <RegisteredFormInput
                  label={educationMapping.learnerOtherServicesHistory.serviceHours?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                  formKey={`learnerOtherServicesHistory[${index}].serviceHours`}
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>

              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={educationMapping.learnerOtherServicesHistory.serviceStartDate?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                  formKey={`learnerOtherServicesHistory[${index}].serviceStartDate`}
                  isLoading={formLoading}
                  type="date"
                />
                <RegisteredFormInput
                  label={educationMapping.learnerOtherServicesHistory.serviceEndDate?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                  formKey={`learnerOtherServicesHistory[${index}].serviceEndDate`}
                  isLoading={formLoading}
                  type="date"
                />
              </DoubleColumnContainer>
              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={educationMapping.learnerOtherServicesHistory.serviceProviderName?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                  formKey={`learnerOtherServicesHistory[${index}].serviceProviderName`}
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>

              <Divider />
            </div>
          );
        })}

        <RegisteredFormTextArea
          // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
          label={educationMapping.learnerEducationMetaData.currentGrades[chosenLanguage]}
          readOnly
          formState={formState}
          formKey={`learnerEducationMetaData.currentGrades`}
        />
        <RegisteredFormTextArea
          label={educationMapping.learnerEducationMetaData.academicStrengthsAndWeaknesses[chosenLanguage]}
          readOnly
          formState={formState}
          formKey={`learnerEducationMetaData.academicStrengthsAndWeaknesses`}
        />
        <RegisteredFormTextArea
          label={educationMapping.learnerEducationMetaData.schoolBehaviorProblems[chosenLanguage]}
          readOnly
          formState={formState}
          formKey={`learnerEducationMetaData.schoolBehaviorProblems`}
        />
      </FormContainer>
    </div>
  );
};

import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Heading } from "@fronterahealth/frontera-ui-components";

import {
  LearnerBirthHistoryQuery,
  LearnerBirthHistoryType,
  useLearnerBirthHistoryQuery,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { transformQueryData } from "@components/forms/utils";
import {
  BirthHistoryDataType,
  learnerBirthHistoryLanguageMap,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Health/BirthHistory/BirthHistory.languageMapping";
import { YesNoRadioItems } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const extractBirthHistoryData = (queryData: LearnerBirthHistoryQuery) => {
  const convertedBirthHistory = transformQueryData(queryData, {
    "learnerById.learnerbirthhistory.separatedFromParentFirst24Months": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.clientHasCurrentCounselor": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.clientHasMedicalDiagnoses": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.clientGiDifficulties": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.normalFullTermPregnancy": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.pregnancyComplications": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.prematureBirth": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.cesareanBirth": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.difficultLaborDelivery": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.overdueBirth": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.breathingDifficulty": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.suckingDifficulty": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.touchResponseDifficulty": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.pregnancyInfections": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.pregnancyPreEclampsia": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.pregnancyHypertension": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.pregnancyFevers": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.gestationalDiabetes": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.prescriptionDrugsUsed": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.alcoholUsed": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.recreationalDrugsUsed": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.tobaccoUsed": (value) => (value ? "yes" : "no"),
    "learnerById.learnerbirthhistory.hospitalizedForHealthConcernsAtBirth": (value) => (value ? "yes" : "no"),
  }) as unknown as LearnerBirthHistoryQuery;
  return convertedBirthHistory.learnerById?.learnerbirthhistory as LearnerBirthHistoryType;
};
export const BirthHistory: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/health/birth-history" : undefined;

  const config = useGetQueryConfig<LearnerBirthHistoryQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerBirthHistoryQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching: formLoading } = learnerDetailsQuery;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormRadioInput,
    RegisteredFormTextArea,
    reset,

    watch,
  } = useFormUtils<BirthHistoryDataType>({
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  useEffect(() => {
    if (data) {
      const converted = extractBirthHistoryData(data);

      reset({ ...converted });
    }
  }, [data, reset]);

  // @ts-ignore: Ignoring the compiler and risking bugs because: We are hacking booleans and radio inputs
  const clientSeparatedFromEitherParent = watch("separatedFromParentFirst24Months") === "yes";

  // @ts-ignore: Ignoring the compiler and risking bugs because: We are hacking booleans and radio inputs
  const prescriptionDrugsOrMedications = watch("prescriptionDrugsUsed") === "yes";

  // @ts-ignore: Ignoring the compiler and risking bugs because: We are hacking booleans and radio inputs
  const childHospitalized = watch("hospitalizedForHealthConcernsAtBirth") === "yes";

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        <div className="flex-col">
          <RegisteredFormTextArea
            label={learnerBirthHistoryLanguageMap["pregnancyDeliveryConcerns"][chosenLanguage]}
            readOnly
            formKey={`pregnancyDeliveryConcerns`}
            formState={formState}
          />

          <Heading type="h4" className="my-4">
            Weight at birth
          </Heading>

          <DoubleColumnContainer>
            <RegisteredFormInput
              label={learnerBirthHistoryLanguageMap["birthWeightInPounds"][chosenLanguage]}
              readOnly
              formState={formState}
              formKey="birthWeightInPounds"
              isLoading={formLoading}
            />
            <RegisteredFormInput
              label={learnerBirthHistoryLanguageMap["birthWeightInOunces"][chosenLanguage]}
              readOnly
              formState={formState}
              formKey="birthWeightInOunces"
              isLoading={formLoading}
            />
          </DoubleColumnContainer>

          <RegisteredFormTextArea
            label={
              learnerBirthHistoryLanguageMap["clientLikeAsBaby"]
                ? learnerBirthHistoryLanguageMap["clientLikeAsBaby"][chosenLanguage]
                : ""
            }
            readOnly
            formKey="clientLikeAsBaby"
            formState={formState}
          />
          <RegisteredFormTextArea
            label={
              learnerBirthHistoryLanguageMap["babyTemperament"]
                ? learnerBirthHistoryLanguageMap["babyTemperament"][chosenLanguage]
                : ""
            }
            readOnly
            formKey="babyTemperament"
            formState={formState}
          />

          <RegisteredFormRadioInput
            title={learnerBirthHistoryLanguageMap["separatedFromParentFirst24Months"][chosenLanguage]}
            disabled
            items={YesNoRadioItems}
            formKey="separatedFromParentFirst24Months"
            formState={formState}
          />
          {clientSeparatedFromEitherParent && (
            <RegisteredFormTextArea
              label={
                learnerBirthHistoryLanguageMap["separatedFromParentFirst24MonthsExplanation"]
                  ? learnerBirthHistoryLanguageMap["separatedFromParentFirst24MonthsExplanation"][chosenLanguage]
                  : ""
              }
              readOnly
              formKey="separatedFromParentFirst24MonthsExplanation"
              formState={formState}
            />
          )}

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["normalFullTermPregnancy"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="normalFullTermPregnancy"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["pregnancyComplications"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="pregnancyComplications"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["prematureBirth"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="prematureBirth"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["cesareanBirth"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="cesareanBirth"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["difficultLaborDelivery"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="difficultLaborDelivery"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["overdueBirth"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="overdueBirth"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["breathingDifficulty"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="breathingDifficulty"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["suckingDifficulty"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="suckingDifficulty"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["touchResponseDifficulty"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="touchResponseDifficulty"
              formState={formState}
            />
          </DoubleColumnContainer>

          <Heading className="mb-4" type="h4">
            Illnesses during pregnancy
          </Heading>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["pregnancyInfections"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="pregnancyInfections"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["pregnancyPreEclampsia"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="pregnancyPreEclampsia"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["pregnancyHypertension"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="pregnancyHypertension"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["pregnancyFevers"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="pregnancyFevers"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["gestationalDiabetes"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="gestationalDiabetes"
              formState={formState}
            />
          </DoubleColumnContainer>

          <RegisteredFormInput
            label={learnerBirthHistoryLanguageMap["otherPregnancyIllnesses"][chosenLanguage]}
            readOnly
            formState={formState}
            formKey="otherPregnancyIllnesses"
            isLoading={formLoading}
          />

          <Heading type="h4" className="mb-4">
            Were any of the following used during pregnancy
          </Heading>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["prescriptionDrugsUsed"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="prescriptionDrugsUsed"
              formState={formState}
            />
          </DoubleColumnContainer>
          {prescriptionDrugsOrMedications && (
            <RegisteredFormTextArea
              label={
                learnerBirthHistoryLanguageMap["prescriptionList"]
                  ? learnerBirthHistoryLanguageMap["prescriptionList"][chosenLanguage]
                  : ""
              }
              readOnly
              formKey="prescriptionList"
              formState={formState}
            />
          )}

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["alcoholUsed"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="alcoholUsed"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["recreationalDrugsUsed"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="recreationalDrugsUsed"
              formState={formState}
            />
          </DoubleColumnContainer>
          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["tobaccoUsed"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="tobaccoUsed"
              formState={formState}
            />
          </DoubleColumnContainer>

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={learnerBirthHistoryLanguageMap["hospitalizedForHealthConcernsAtBirth"][chosenLanguage]}
              disabled
              items={YesNoRadioItems}
              formKey="hospitalizedForHealthConcernsAtBirth"
              formState={formState}
            />
          </DoubleColumnContainer>

          {childHospitalized && (
            <RegisteredFormTextArea
              label={
                learnerBirthHistoryLanguageMap["hospitalizedForHealthConcernsAtBirthExplanatiion"]
                  ? learnerBirthHistoryLanguageMap["hospitalizedForHealthConcernsAtBirthExplanatiion"][chosenLanguage]
                  : ""
              }
              readOnly
              formKey="hospitalizedForHealthConcernsAtBirthExplanatiion"
              formState={formState}
            />
          )}
        </div>
      </FormContainer>
    </div>
  );
};

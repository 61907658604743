import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  PlayPauseIcon,
} from "@heroicons/react/24/solid";
import { isBefore, isFuture } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { Heading, Small, TabNavigation } from "@fronterahealth/frontera-ui-components";

import { ApiOnboardingFormStatusStatusChoices, useLearnerByIdQuery } from "@api/graphql/types-and-hooks";
import { IntakeType, StageType } from "@components/ClientTable/ClientTable";
import "@components/notifications/notifications.css";
import { numberOfOnboardingSectionsCompleted } from "@routes/ClientDashboard/helpers";

const clientDetailsNavigationTabs = [
  "details",
  "availability",
  "insurance",
  "caregivers",
  "EHR-data",
  "files",
  "forms",
];

const intakeToIcon: { [key in IntakeType]: React.ForwardRefExoticComponent<object> } = {
  Awaiting: ClockIcon,
  Complete: CheckCircleIcon,
  "In Progress": PencilIcon,
  Started: PlayPauseIcon,
};

const stageToIcon: { [key in StageType]: React.ForwardRefExoticComponent<object> } = {
  "Assessment Complete": CheckCircleIcon,
  "Assessment Pending": ClockIcon,
  "Assessment Waitlist": ExclamationTriangleIcon,
  "Diagnosis Pending": ClockIcon,
  "Diagnosis Waitlist": ExclamationTriangleIcon,
};

const tabToName = (t: string) =>
  t
    .split("-")
    .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
    .join(" ");

export const getStage = (
  hasPreviousDiagnosis: boolean,
  diagnosisDate: Date | null,
  assessmentDate: Date | null,
): StageType => {
  const currentDate = new Date();

  if (!hasPreviousDiagnosis && !diagnosisDate) {
    return "Diagnosis Waitlist"; // No previous diagnosis and no diagnosis date, client is on the diagnosis waitlist
  }

  if (!hasPreviousDiagnosis && diagnosisDate && isFuture(diagnosisDate)) {
    return "Diagnosis Pending"; // Diagnosis date is in the future and no previous diagnosis
  }

  if ((hasPreviousDiagnosis && !assessmentDate) || (diagnosisDate && isBefore(diagnosisDate, currentDate))) {
    return "Assessment Waitlist"; // Previous diagnosis exists but no assessment date, or diagnosis date has passed
  }

  if (hasPreviousDiagnosis && assessmentDate && isFuture(assessmentDate)) {
    return "Assessment Pending"; // Previous diagnosis exists and Assessment date is in the future
  }

  if (hasPreviousDiagnosis && assessmentDate && isBefore(assessmentDate, currentDate)) {
    return "Assessment Complete"; // Previous diagnosis exists and Assessment date has passed
  }

  return "Diagnosis Waitlist"; // Default to diagnosis waitlist
};

export const ClientDetails: React.FC = () => {
  const { pathname, state } = useLocation();
  const [clientName, setClientName] = useState<string>("");
  const [clientIntake, setClientIntake] = useState<IntakeType | null>(null);
  const [clientIntakePagesCompleted, setClientIntakePagesCompleted] = useState<number>(0);
  const [clientStage, setClientStage] = useState<StageType | null>(null);
  const stage = (state?.stage as StageType) || "Assessment Complete";
  const intake = (state?.intake as IntakeType) || "In Progress";
  const IntakeIcon = intakeToIcon[intake];
  const StageIcon = stageToIcon[stage];

  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data } = learnerDetailsQuery;

  useEffect(() => {
    if (data && data.learnerById) {
      const intakePages = data.learnerById.formStatusForLearner.edges.map((edge) => edge?.node);
      const intakeFinished = intakePages.find(
        (node) => node?.status === ApiOnboardingFormStatusStatusChoices.Completed && node.formName === "submit",
      );

      const intakeInProgress =
        intakePages.filter((page) => page?.status === ApiOnboardingFormStatusStatusChoices.Completed).length > 0;

      const intakeStarted = !!data?.learnerById?.relationshipSet?.edges?.[0]?.node?.caregiver.auth0User?.auth0id;
      const diagnosisAppointmentDate = data?.learnerById?.learnermetadata?.diagnosisAppointmentDate;
      const assessmentAppointmentDate = data?.learnerById?.learnermetadata?.assessmentAppointmentDate;
      const intakePagesCompleted = numberOfOnboardingSectionsCompleted(intakePages || []);
      const intake: IntakeType = intakeFinished
        ? "Complete"
        : intakeInProgress
          ? "In Progress"
          : intakeStarted
            ? "Started"
            : "Awaiting";
      const hasPreviousDiagnosis = data?.learnerById?.learnermetadata?.hasPreviousDiagnosis || false;
      const stage: StageType = getStage(hasPreviousDiagnosis, diagnosisAppointmentDate, assessmentAppointmentDate);

      setClientName(`${data.learnerById?.learnermetadata?.firstName} ${data.learnerById?.learnermetadata?.lastName}`);
      setClientStage(stage);
      setClientIntake(intake);
      setClientIntakePagesCompleted(intakePagesCompleted);
    }
  }, [data]);

  return (
    <div className="w-full">
      <Heading type="h1">{clientName}</Heading>
      <div className="my-4 flex items-center">
        {/* @ts-ignore: Ignoring the compiler and risking bugs because: the typings need work here but have been working */}
        <StageIcon className="mr-1 h-4 w-4 text-text-secondary" />
        <Small className="mr-4">{clientStage}</Small>
        {/* @ts-ignore: Ignoring the compiler and risking bugs because: the typings need work here but have been working */}
        <IntakeIcon className="mr-1 h-4 w-4 text-text-secondary" />
        <Small>
          Intake {clientIntake} {clientIntake === "In Progress" ? `(${clientIntakePagesCompleted} of 10)` : ""}
        </Small>
      </div>
      <TabNavigation
        tabs={clientDetailsNavigationTabs.map((tab) => ({
          current: pathname.split("/")[pathname.split("/").length - 1] === tab,
          type: "component",
          name: tab,
          Component: (
            <Link to={tab} state={state}>
              {tabToName(tab)}
            </Link>
          ),
        }))}
      />
      <div className="py-8">
        <Outlet />
      </div>
    </div>
  );
};

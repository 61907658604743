import React, { PropsWithChildren } from "react";

import { Button, Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

interface ErrorProps extends PropsWithChildren {
  redirect?: boolean;
  to?: string;
  is404?: boolean;
}

export const Error: React.FC<ErrorProps> = ({ children, to = "/", redirect = true, is404 = false }) => {
  return (
    <div className="bg-inherit">
      <div className="flex flex-col items-center h-screen pt-16 bg-inherit">
        <Heading type="h1">{is404 ? "404" : "403"}</Heading>
        <Heading type="h4" className="mt-4">
          {is404
            ? "Oops, the page you are looking for does not exist."
            : "Oops, you don't have permission to access this page."}
        </Heading>

        <Paragraph displayType="normal" colorType="secondary" className="text-center mb-4">
          You can return to the homepage, or if you think something is broken you may report a problem.
        </Paragraph>

        <div className="flex space-x-2 pt-6">
          {redirect ? (
            <Button
              appearance="primary"
              text="Return to Homepage"
              className="px-6"
              onClick={() => (window.location.href = to)}
            />
          ) : (
            <></>
          )}
          <Button
            appearance="secondary"
            className="px-6"
            text="Report a Problem"
            onClick={() => (window.location.href = "mailto:support@frontera.health")}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

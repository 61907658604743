import { GenderApiValues, GenderMappingToApi, GenderMappingToUi, GenderUiValues } from "@utils/constants";

import { ApiCaregiverMetadataGenderChoices, ApiLearnerMetadataGenderChoices } from "@api/graphql/types-and-hooks";
import { LanguageObjectType } from "@components/forms/interfaces";
import { LANGUAGE_KEYS } from "@internationalization/index";

export const getGenderValue = (
  value: GenderApiValues | null | undefined | ApiLearnerMetadataGenderChoices | ApiCaregiverMetadataGenderChoices,
) => {
  const genderValue = value === "M" || value === "F" ? value : value?.toLowerCase();
  if (genderValue) return GenderMappingToUi[genderValue as GenderApiValues];

  return null;
};

export const setGenderValue = (value: GenderUiValues | null | undefined) => {
  if (value) return GenderMappingToApi[value as GenderUiValues];
  return null;
};
export const YesNoRadioItems = [
  { id: "yes", title: "Yes" },
  { id: "no", title: "No" },
];

export const YesNoLanguageMapping = {
  yes: {
    en: "Yes",
    es: "Si",
  },
  no: {
    en: "No",
    es: "No",
  },
};

export const YesNoRadioItemsWithLang = (chosenLanguage: LANGUAGE_KEYS) => [
  { id: "yes", title: YesNoLanguageMapping["yes"][chosenLanguage] },
  { id: "no", title: YesNoLanguageMapping["no"][chosenLanguage] },
];

export const RELATIONSHIP_TO_CLIENT_OPTIONS = ["Father", "Mother", "Sibling", "Guardian", "Other"] as const;
export type RelationshipToClientType = (typeof RELATIONSHIP_TO_CLIENT_OPTIONS)[number];

export const NeverPreviouslyOngoingLanguageMapping = {
  never: {
    en: "Never",
    es: "Nunca",
  },
  previously: {
    en: "Previously",
    es: "Anteriormente",
  },
  ongoing: {
    en: "Ongoing",
    es: "En curso",
  },
};

export const NeverPreviouslyOngoingItems = (chosenLanguage: LANGUAGE_KEYS) => [
  { id: "never", title: NeverPreviouslyOngoingLanguageMapping["never"][chosenLanguage] },
  { id: "previously", title: NeverPreviouslyOngoingLanguageMapping["previously"][chosenLanguage] },
  { id: "ongoing", title: NeverPreviouslyOngoingLanguageMapping["ongoing"][chosenLanguage] },
];

export const NeverOrRarelySometimesOftenAlmostAlwaysLanguageMapping = {
  never: {
    en: "Never or rarely",
    es: "Nunca o rara vez",
  },
  sometimes: {
    en: "Sometimes",
    es: "A veces",
  },
  often: {
    en: "Often",
    es: "A menudo",
  },
  almostAlways: {
    en: "Almost always",
    es: "Casi siempre",
  },
};

export const NeverOrRarelySometimesOftenAlmostAlwaysItems = (chosenLanguage: LANGUAGE_KEYS) => [
  { id: "never", title: NeverOrRarelySometimesOftenAlmostAlwaysLanguageMapping["never"][chosenLanguage] },
  { id: "sometimes", title: NeverOrRarelySometimesOftenAlmostAlwaysLanguageMapping["sometimes"][chosenLanguage] },
  { id: "often", title: NeverOrRarelySometimesOftenAlmostAlwaysLanguageMapping["often"][chosenLanguage] },
  { id: "almostAlways", title: NeverOrRarelySometimesOftenAlmostAlwaysLanguageMapping["almostAlways"][chosenLanguage] },
];

export const OntimeLateItems = [
  { id: "ontime", title: "On time" },
  { id: "late", title: "Late" },
];

const OntimeLateNotApplicableItemsMapping: { [key in "Not Applicable" | "Late" | "On time"]: LanguageObjectType } = {
  Late: {
    en: "Late",
    es: "Tarde",
  },
  "Not Applicable": {
    en: "Not Applicable",
    es: "No aplica",
  },
  "On time": {
    en: "On Time",
    es: "A tiempo",
  },
};

export const generateOnetimeLateItemsWithLabel = (label: string, chosenLanguage: LANGUAGE_KEYS) => [
  { id: "ontime", title: `${OntimeLateNotApplicableItemsMapping["On time"][chosenLanguage]} (${label})` },
  { id: "late", title: OntimeLateNotApplicableItemsMapping["Late"][chosenLanguage] },
  { id: "na", title: OntimeLateNotApplicableItemsMapping["Not Applicable"][chosenLanguage] },
];

// Function to extract path
export const extractPath = (fullPath: string): string => {
  // Split the fullPath by '/'
  const pathParts = fullPath.split("/");

  // Find the index of 'forms' in the pathParts array
  const formsIndex = pathParts.indexOf("forms");

  // Extract the path from the formsIndex onwards
  const extractedPath = pathParts.slice(formsIndex).join("/");

  return extractedPath;
};

export const TraumaHistoryChoicesMapping = {
  physicalAbuse: {
    en: "Physical Abuse",
    es: "Abuso Físico",
  },
  sexualAbuse: {
    en: "Sexual Abuse",
    es: "Abuso Sexual",
  },
  domesticViolenceExposure: {
    en: "Domestic Violence Exposure",
    es: "Exposición a Violencia Doméstica",
  },
  otherViolence: {
    en: "Other Violence",
    es: "Otra Violencia",
  },
};

export const traumaHistoryChoices = (chosenLanguage: LANGUAGE_KEYS) => [
  { id: "physicalAbuse", title: TraumaHistoryChoicesMapping["physicalAbuse"][chosenLanguage] },
  { id: "sexualAbuse", title: TraumaHistoryChoicesMapping["sexualAbuse"][chosenLanguage] },
  { id: "domesticViolenceExposure", title: TraumaHistoryChoicesMapping["domesticViolenceExposure"][chosenLanguage] },
  { id: "otherViolence", title: TraumaHistoryChoicesMapping["otherViolence"][chosenLanguage] },
];

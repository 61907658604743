import { Paragraph } from "@fronterahealth/frontera-ui-components";

import { SupportedContentType } from "@components/SectionWidget/SectionWidget";
import imageSvg from "@components/SectionWidget/image.svg";
import tableSvg from "@components/SectionWidget/table.svg";
import textSvg from "@components/SectionWidget/text.svg";

interface ContentBlockCardProps {
  typeName: SupportedContentType;
  onClick: () => void;
}

export const ContentBlockCard: React.FC<ContentBlockCardProps> = ({ typeName, onClick }) => {
  return (
    <div
      className="w-28 mx-2 h-full p-2 outline outline-1 outline-limestone-100 hover:outline-globemallow-500 hover:outline-2 rounded-md justify-center cursor-pointer flex items-center flex-col transition-all hover:transition-all"
      onClick={onClick}
    >
      {typeName === "Image" ? (
        <img src={imageSvg} className="rounded-md h-16 " />
      ) : typeName === "Text" ? (
        <img src={textSvg} className="rounded-md h-16 " />
      ) : (
        <img src={tableSvg} className="rounded-md h-16 " />
      )}
      <Paragraph displayType="loud" colorType="primary" className="text-center mt-4">
        {typeName}
      </Paragraph>
    </div>
  );
};

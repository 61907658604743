import { LanguageMappingInterface } from "src/components/forms/interfaces";

import { LearnerEducationQuery } from "@api/graphql/types-and-hooks";

export type TheraputicServicesType = {
  service?: string;
  serviceHours?: string;
};

export type OtherServicesHistoryType = {
  service?: string;
  serviceHours?: string;
  serviceProviderName?: string;
  serviceStartDate?: string;
  serviceEndDate?: string;
};

type LearnerEducationMetaDataType = NonNullable<
  NonNullable<NonNullable<LearnerEducationQuery["learnerById"]>["learnerEducation"]>["learnerEducationMetaData"]
>;

type LearnerEducationType = NonNullable<
  NonNullable<NonNullable<LearnerEducationQuery["learnerById"]>["learnerEducation"]>
>;
export interface LearnerEducationData {
  learnerEducation: Omit<LearnerEducationType, "id" | "createdDate" | "learner" | "lastUpdatedDate">;
  learnerEducationMetaData: Omit<
    LearnerEducationMetaDataType,
    "id" | "createdDate" | "learnerEducation" | "lastUpdatedDate"
  >;

  learnerTheraputicServicesInSchool: TheraputicServicesType[];
  learnerOtherServicesHistory: OtherServicesHistoryType[];
}

export const educationMapping: LanguageMappingInterface<LearnerEducationData> = {
  learnerEducation: {
    nameOfSchool: {
      en: "Name of School",
      es: "Nombre de la escuela",
    },
    grade: {
      en: "Grade",
      es: "Grado",
    },
    educationSetting: {
      en: "Education Setting",
      es: "Configuración educativa",
    },
    specialEducation: {
      en: "Special Education",
      es: "Educación especial",
    },
  },
  learnerEducationMetaData: {
    currentGrades: {
      en: "Current Grades",
      es: "Calificaciones actuales",
    },
    academicStrengthsAndWeaknesses: {
      en: "Academic Strengths and Weaknesses",
      es: "Fortalezas y debilidades académicas",
    },
    schoolBehaviorProblems: {
      en: "School Behavior Problems",
      es: "Problemas de comportamiento escolar",
    },
  },
  learnerOtherServicesHistory: {
    serviceStartDate: {
      en: "Start Date of Service",
      es: "Fecha de inicio del servicio",
    },
    serviceEndDate: {
      en: "End Date of Service",
      es: "Fecha de finalización del servicio",
    },
    serviceHours: {
      en: "Hours",
      es: "Horas",
    },
    serviceProviderName: {
      en: "Name of Provider",
      es: "Nombre del proveedor",
    },
    service: {
      en: "Service",
      es: "Servicio",
    },
  },
  learnerTheraputicServicesInSchool: {
    serviceHours: {
      en: "Hours",
      es: "Horas",
    },
    service: {
      en: "Service",
      es: "Servicio",
    },
  },
};

import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

import { TabNavigation, VideoPlayer, VideoPlayerHandle } from "@fronterahealth/frontera-ui-components";

import { useGetSessionMediaStreamQuery } from "@api/graphql/types-and-hooks";
import { notifyError } from "@components/notifications/notifications";

const sessionDetailsNavigationTabs = ["Social Engagement"];
const tabToName = (t: string) =>
  t
    .split("-")
    .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
    .join(" ");

interface SessionDetailsProps {}
export interface sessionDetailOutletContext {
  videoDuration: number;
  handleSeekTo: (time: number, endTime: number) => void;
  handleStartToPlay: (time: number) => void;

  mediaLoading: boolean;
  videoUrl: string;
}

export const SessionDetails: React.FC<SessionDetailsProps> = ({}) => {
  const [videoDuration, setVideoDuration] = useState(0);

  const { pathname } = useLocation();

  const { sessionId } = useParams();
  const videoPlayerRef = useRef<VideoPlayerHandle>(null);
  const { data, isFetching, error } = useGetSessionMediaStreamQuery(
    { sessionId: sessionId || "" },
    {
      queryKey: ["getSessionMediaStream"],
      enabled: sessionId !== undefined,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (error) {
      notifyError("No Media Found For This Session.");
    }
  }, [error]);

  const dashUrl = data?.getSessionMediaStream?.dashUrl || "";

  const handleSeekTo = (startTime: number, endTime: number) => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.playInRange(startTime, endTime);
    }
  };

  const handleStartToPlay = (time: number) => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.seekTo(time);
    }
  };

  return (
    <div className="w-full">
      {isFetching ? (
        <div className="flex flex-row text-white items-center justify-center h-96 bg-black border rounded-lg shadow-md">
          Media Loading...
        </div>
      ) : (
        <div className="mt-8 flow-root w-full bg-white rounded-lg">
          <VideoPlayer
            ref={videoPlayerRef}
            src={dashUrl}
            onDuration={(duration: number) => {
              console.debug("Video duration", duration);
              setVideoDuration(Math.round(duration));
            }}
            className="h-96"
          />
        </div>
      )}
      <TabNavigation
        tabs={sessionDetailsNavigationTabs.map((tab) => ({
          current: pathname.split("/")[pathname.split("/").length - 1] === tab,
          type: "component",
          name: tab,
          Component: <Link to={tab}>{tabToName(tab)}</Link>,
        }))}
      />
      <div className="py-4">
        <Outlet
          context={{
            videoDuration,
            handleSeekTo,
            mediaLoading: isFetching,
            videoUrl: dashUrl,
            handleStartToPlay,
          }}
        />
      </div>
    </div>
  );
};

import { XMarkIcon } from "@heroicons/react/24/outline";

import {
  BucketInformation,
  CreateFileInformation,
  FileUploadAndParse,
  Paragraph,
  ParsingInformation,
  TableBuilderDynamic,
  UploadFileInformation,
  WYSIWYG,
} from "@fronterahealth/frontera-ui-components";
import {
  PopoverMenu,
  PopoverMenuItem,
} from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

import { ApiReportSectionFieldsFieldTypeChoices, ReportSectionFieldsType } from "@api/graphql/types-and-hooks";
import { BespokeInputField } from "@components/FieldComponent/BespokeInputField";

interface FieldBlockCardProps {
  field: ReportSectionFieldsType;
  isSaving: boolean;
  onSave: (content: string) => Promise<unknown>;
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  S3BucketUrlRetriever: (params: CreateFileInformation) => Promise<BucketInformation>;
  S3BucketUploadCallback: (params: UploadFileInformation) => Promise<void>;
  reportFileUploadCompleteCallback: (fileId: string) => Promise<void>;
  fileParseStatusRetriever: (fileId: string) => Promise<ParsingInformation>;
  removeFileCallback: (fileId: string) => Promise<void>;
  actionButtonMenu?: PopoverMenuItem[];
}

export const FieldBlockCard: React.FC<FieldBlockCardProps> = ({
  field,
  isSaving,
  onSave,
  permissions,
  S3BucketUrlRetriever,
  reportFileUploadCompleteCallback,
  S3BucketUploadCallback,
  fileParseStatusRetriever,
  removeFileCallback,
  actionButtonMenu,
}) => {
  if (field?.fieldType === ApiReportSectionFieldsFieldTypeChoices.Wysiwyg) {
    return (
      <div className={`${permissions.canEdit ? "-mt-4 -mb-4" : " -mb-4"}`}>
        <WYSIWYG
          isSaving={isSaving}
          onSave={async ({ content }) => {
            await onSave(content);
          }}
          autoSave={true}
          existingContent={field.content || ""}
          key={field.id}
          title={""}
          placeholder={!permissions.canEdit ? "" : "Fill me in"}
          displayOnlyMode={!permissions.canEdit}
          showTitle={false}
          actionMenuItems={actionButtonMenu}
        />
      </div>
    );
  }
  if (field?.fieldType === ApiReportSectionFieldsFieldTypeChoices.Table) {
    return (
      <div className={`${permissions.canEdit ? "-mt-4" : "-mt-6"} pr-8 overflow-x-auto`}>
        <TableBuilderDynamic
          tableSaving={isSaving}
          onSave={async (datum) => {
            await onSave(JSON.stringify(datum));
          }}
          tableId={field.id}
          jsonDatum={JSON.parse(field?.content || `{"headers":["Enter Header 1", "Enter Header 2"],"data":[]}`)}
          title=""
          displayOnlyMode={!permissions.canEdit}
          actionMenuItems={actionButtonMenu}
          submenuOpenDirection="left"
        />
      </div>
    );
  }
  if (field?.fieldType === ApiReportSectionFieldsFieldTypeChoices.Image) {
    const renderImage = () => (
      <div
        className={`flex justify-center ${permissions.canEdit ? "" : "mt-2"} items-center justify-center rounded-lg border border-dashed border-limestone-200 px-6 hover:bg-limestone-50`}
      >
        <div className="rounded-xl">
          <img
            src={field.imageSignedUrl || ""}
            alt="Invalid URL"
            className={`max-w-full h-48 object-contain h-48 p-2 rounded-xl`}
          />
          {permissions.canEdit && (
            <XMarkIcon
              onClick={async () => await onSave("")}
              className="h-6 w-6 absolute top-2 right-2 cursor-pointer rounded-md border border-limestone-200 bg-white p-1 text-text-secondary transition-all hover:bg-red-200"
            />
          )}
        </div>
      </div>
    );

    const renderFileUpload = () => (
      <div className="-my-2">
        <FileUploadAndParse
          key={JSON.stringify(field.content)}
          fileKind={field.id}
          existingFiles={[]}
          title=""
          singleFileUpload
          supportedFileTypes={["png", "jpg", "gif"]}
          maxSize={"100MB"}
          S3BucketUrlRetriever={S3BucketUrlRetriever}
          S3BucketUploadCallback={S3BucketUploadCallback}
          reportFileUploadCompleteCallback={reportFileUploadCompleteCallback}
          fileParseStatusRetriever={fileParseStatusRetriever}
          removeFileCallback={removeFileCallback}
        />
      </div>
    );

    return (
      <div className="group relative pr-6">
        <div data-testid="image-input-container">
          <div className="relative">
            {field.imageSignedUrl ? renderImage() : permissions.canEdit && renderFileUpload()}
          </div>
        </div>
        <div
          data-testid="image-actions-panel"
          className={`group absolute -right-1 ${field.imageSignedUrl ? "top-0" : "top-2"}  flex-col gap-y-4 ${permissions.canEdit ? "opacity-0 group-hover:opacity-100" : "pointer-events-none opacity-0"} transition-all`}
        >
          <PopoverMenu mode="secondary" menuItems={actionButtonMenu ?? []} openDirection="to-bottom-left" />
        </div>
      </div>
    );
  }

  if (field?.fieldType === ApiReportSectionFieldsFieldTypeChoices.SimpleText) {
    return (
      <div className={`${permissions.canEdit ? "-mt-8 -mb-6" : ""}`}>
        <BespokeInputField
          isSaving={isSaving}
          onSave={async ({ content }: { content: string }) => {
            await onSave(content);
          }}
          existingContent={field.content || ""}
          placeholder="Fill me in"
          inputType={field.bespokeType === "date" ? "date" : "text"}
          displayOnlyMode={!permissions.canEdit}
        />
      </div>
    );
  }

  return (
    <>
      {permissions.canEdit ? (
        <div className="p-2 border hover:border-gray-500 rounded-md justify-center">
          <div className="rounded-md bg-gray-50"></div>
          <Paragraph displayType="loud" colorType="primary" className="text-center">
            {field?.fieldType} field type is not supported yet.
          </Paragraph>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

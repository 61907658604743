export enum GenderUiValues {
  "Agender" = "Agender",
  "Girl/woman" = "Girl/woman",
  "Genderqueer or genderfluid" = "Genderqueer or genderfluid",
  "Gender identity is not listed" = "Gender identity is not listed",
  "Boy/man" = "Boy/man",
  "Non-binary" = "Non-binary",
  "Prefer not to disclose" = "Prefer not to disclose",
  "Questioning or unsure" = "Questioning or unsure",
}

export enum GenderApiValues {
  Agender = "agender",
  F = "F",
  GenderqueerOrGenderfluid = "genderqueer_or_genderfluid",
  GenderNotListed = "gender_not_listed",
  M = "M",
  NonBinary = "non_binary",
  PreferNotToDisclose = "prefer_not_to_disclose",
  QuestioningOrUnsure = "questioning_or_unsure",
}

export const GenderMappingToUi = {
  [GenderApiValues.Agender]: "Agender",
  [GenderApiValues.F]: "Girl/woman",
  [GenderApiValues.GenderqueerOrGenderfluid]: "Genderqueer or genderfluid",
  [GenderApiValues.GenderNotListed]: "Gender identity is not listed",
  [GenderApiValues.M]: "Boy/man",
  [GenderApiValues.NonBinary]: "Non-binary",
  [GenderApiValues.PreferNotToDisclose]: "Prefer not to disclose",
  [GenderApiValues.QuestioningOrUnsure]: "Questioning or unsure",
};

export const GenderMappingToApi = {
  [GenderUiValues["Agender"]]: GenderApiValues.Agender,
  [GenderUiValues["Girl/woman"]]: GenderApiValues.F,
  [GenderUiValues["Genderqueer or genderfluid"]]: GenderApiValues.GenderqueerOrGenderfluid,
  [GenderUiValues["Gender identity is not listed"]]: GenderApiValues.GenderNotListed,
  [GenderUiValues["Boy/man"]]: GenderApiValues.M,
  [GenderUiValues["Non-binary"]]: GenderApiValues.NonBinary,
  [GenderUiValues["Prefer not to disclose"]]: GenderApiValues.PreferNotToDisclose,
  [GenderUiValues["Questioning or unsure"]]: GenderApiValues.QuestioningOrUnsure,
};

export type LinkName =
  | "client-details"
  | "general-details"
  | "emergency-contact"
  | "home-environment"
  | "guardian-details"
  | "living-conditions"
  | "insurance"
  | "health"
  | "medical-history"
  | "birth-history"
  | "development"
  | "education"
  | "early-intervention-services"
  | "developmental-milestones"
  | "behaviors"
  | "general"
  | "movement-&-play"
  | "sensory"
  | "sleep"
  | "diet"
  | "interests"
  | "communication"
  | "verbal-communication"
  | "non-verbal-communication"
  | "tendencies"
  | "attention"
  | "defiance"
  | "aggression"
  | "mood"
  | "odd-ideas"
  | "attachment"
  | "trauma"
  | "somatic"
  | "language"
  | "social-skills"
  | "executive-functions"
  | "strengths"
  | "schedule"
  | "additional-details";

export type Link = {
  name: LinkName;
  // TODO: The display for a route should not be static but be part of our internationalization pattern
  sublinks?: Link[];
};

export const links: Link[] = [
  {
    name: "client-details",
    sublinks: [
      {
        name: "general-details",
      },
      {
        name: "emergency-contact",
      },
    ],
  },
  {
    name: "home-environment",
    sublinks: [
      {
        name: "guardian-details",
      },
      {
        name: "living-conditions",
      },
    ],
  },
  {
    name: "insurance",
  },
  {
    name: "health",
    sublinks: [
      {
        name: "medical-history",
      },
      {
        name: "birth-history",
      },
    ],
  },
  {
    name: "development",
    sublinks: [
      {
        name: "education",
      },
      {
        name: "early-intervention-services",
      },
      {
        name: "developmental-milestones",
      },
    ],
  },
  {
    name: "behaviors",
    sublinks: [
      {
        name: "general",
      },
      {
        name: "movement-&-play",
      },
      {
        name: "sensory",
      },
      {
        name: "sleep",
      },
      {
        name: "diet",
      },
      {
        name: "interests",
      },
    ],
  },
  {
    name: "communication",
    sublinks: [
      {
        name: "verbal-communication",
      },
      {
        name: "non-verbal-communication",
      },
    ],
  },
  {
    name: "tendencies",
    sublinks: [
      {
        name: "attention",
      },
      {
        name: "defiance",
      },
      {
        name: "aggression",
      },
      {
        name: "mood",
      },
      {
        name: "odd-ideas",
      },
      {
        name: "attachment",
      },
      {
        name: "trauma",
      },
      {
        name: "somatic",
      },
      {
        name: "language",
      },
      {
        name: "social-skills",
      },
      {
        name: "executive-functions",
      },
      {
        name: "strengths",
      },
    ],
  },
  {
    name: "schedule",
  },
  {
    name: "additional-details",
  },
];

export type AllRoutes =
  | "all"
  | "client-details"
  | "client-details/general-details"
  | "client-details/emergency-contact"
  | "home-environment"
  | "home-environment/guardian-details"
  | "home-environment/living-conditions"
  | "insurance"
  | "health"
  | "health/medical-history"
  | "health/birth-history"
  | "development"
  | "development/education"
  | "development/early-intervention-services"
  | "development/developmental-milestones"
  | "behaviors"
  | "behaviors/general"
  | "behaviors/movement-&-play"
  | "behaviors/sensory"
  | "behaviors/sleep"
  | "behaviors/diet"
  | "behaviors/interests"
  | "communication"
  | "communication/verbal-communication"
  | "communication/non-verbal-communication"
  | "tendencies"
  | "tendencies/attention"
  | "tendencies/defiance"
  | "tendencies/aggression"
  | "tendencies/mood"
  | "tendencies/odd-ideas"
  | "tendencies/attachment"
  | "tendencies/trauma"
  | "tendencies/somatic"
  | "tendencies/language"
  | "tendencies/social-skills"
  | "tendencies/executive-functions"
  | "tendencies/strengths"
  | "schedule"
  | "additional-details";

import { AcceptedFileTypes, ExistingFileInterface, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  ApiLearnerFileFileKindChoices,
  AssessmentToolType,
  LearnerFileAssessmentTemplateTypeEnums,
} from "@api/graphql/types-and-hooks";
import { convertReadableString } from "@components/forms/utils";

export const groupAssessmentsByType = (assessments: (ExistingFileInterface & { category?: string })[]) => {
  return assessments.reduce(
    (acc, assessment) => {
      const assessmentType = assessment.fileKind as ApiLearnerFileFileKindChoices;

      if (acc[assessmentType]?.length) {
        acc[assessmentType]?.push(assessment);
      } else {
        acc[assessmentType] = [assessment];
      }
      return acc;
    },
    {} as { [key in ApiLearnerFileFileKindChoices]?: (ExistingFileInterface & { category?: string })[] },
  );
};

export const getFileSourceByReportType = (
  assessmentReportTypes: AssessmentToolType[],
  selectedAssessmentReportType: SelectItem,
): SelectItem[] => {
  const reportTypes = assessmentReportTypes.filter((reportType) => reportType.id === selectedAssessmentReportType.id);
  if (reportTypes.at(0)?.supportedFileSource?.length) {
    return (
      reportTypes.at(0)?.supportedFileSource?.map((fileSource) => {
        return {
          primary: convertReadableString(fileSource?.toString()),
          id: fileSource,
        } as SelectItem;
      }) ?? []
    );
  } else {
    return [
      {
        primary: convertReadableString(reportTypes.at(0)?.supportedFileSource?.toString()),
        id: reportTypes.at(0)?.supportedFileSource,
      } as SelectItem,
    ];
  }
};

export const getMultipleFileUploadByReportType = (
  assessmentReportTypes: AssessmentToolType[],
  selectedAssessmentReportType: SelectItem,
): boolean => {
  const reportType: AssessmentToolType | undefined = assessmentReportTypes
    .filter((reportType) => reportType.id === selectedAssessmentReportType.id)
    .at(0);
  return reportType?.multipleFileUpload ?? false;
};

export const getSupportedFileTypesByFileSourceType = (
  sourceType: LearnerFileAssessmentTemplateTypeEnums,
): AcceptedFileTypes[] => {
  const xlsxType = "xlsx" as AcceptedFileTypes;
  const pdfType = "pdf" as AcceptedFileTypes;
  switch (sourceType) {
    case LearnerFileAssessmentTemplateTypeEnums.FronteraHealthTemplate:
    case LearnerFileAssessmentTemplateTypeEnums.CustomTemplate:
      return [xlsxType];
    case LearnerFileAssessmentTemplateTypeEnums.OfficialScoringFile:
      return [pdfType];
    default:
      return [];
  }
};

export const createEvaluationAssessmentCategory = (category: string): SelectItem => {
  const parsedCategory = category?.split("', '");
  return {
    id: parsedCategory?.at(0)?.replace(/["'\(\)]/g, ""),
    primary: parsedCategory?.at(1)?.replace(/["'\(\)]/g, ""),
  } as SelectItem;
};

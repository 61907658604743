import { capitalizeString, getTimeByShift } from "@utils/utils";

import { ApiLearnerAvailabilityDayChoices, ApiLearnerAvailabilityTimeOfDayChoices } from "@api/graphql/types-and-hooks";

export const NEAREST_LOCATIONS_OPTIONS = [
  "Clovis",
  "Hobbs",
  "Las Cruces",
  "Pueblo, CO",
  "Roswell",
  "South Valley",
  "Other",
] as const;

export const REFERRAL_SOURCE_OPTIONS = [
  "Pediatrician",
  "Specialist Doctor",
  "School Counselor",
  "Insurance Company",
  "Family Member or Friend",
  "Support Group or Community Organization",
  "Online Search or Social Media",
  "Other",
] as const;

export const PSYCHOLOGIST_OPTIONS = [
  "Stacey Graziano",
  "Jared Gonzales",
  "Karlen Moore",
  "Duranda Cosette Montanez",
  "Marion Zahn",
  "Angela Heads",
] as const;

export const BCBA_OPTIONS = [
  "Yvonne Salazar",
  "Angelica Gutierrez",
  "Caitlin Young",
  "Heather Davis",
  "Jennifer Amaya",
  "Juanita McPherson",
  "Monica Eason",
  "Mrunalini Rath",
  "Sumaira Kausar",
  "Tara Calcanis",
  "Abigail Adams",
  "Maria Fernanda Garcia",
  "Airel Lufkin",
  "Joshua Levine",
  "Gregory Mancil",
  "Wendy Nebbia",
  "Jason Julia",
  "Francis Kamen",
  "Lucia Margaret",
  "Ryan Feeback",
  "Joni Highsmith",
  "Charlene Asuncion",
  "Trisha Iannotta-Bieszczad",
  "Annelisa Machado",
] as const;

export const AvailabilityTimes = Object.values(ApiLearnerAvailabilityTimeOfDayChoices).map((choice) => ({
  id: choice,
  title: capitalizeString(choice),
  secondaryTitle: getTimeByShift(choice),
}));

export const AvailabilityDays = Object.values(ApiLearnerAvailabilityDayChoices);

export const SMSAgreementItem = [
  {
    id: "yes",
    title:
      "This caregiver consents to receiving automated messages from MECA therapies/Frontera Health regarding updates to their services. ",
  },
];

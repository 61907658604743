import { TendenciesQuestionType } from "@api/graphql/types-and-hooks";
import { LanguageObjectType } from "@components/forms/interfaces";

export const tendenciesQuestionsMapping: {
  [key in TendenciesQuestionType]: LanguageObjectType;
} = {
  HAS_DIFFICULTY_PAYING_ATTENTION_OR_MAKES_CARELESS_MISTAKES: {
    en: "Has difficulty paying attention or makes careless mistakes",
    es: "Tiene dificultades para prestar atención o comete errores descuidados",
  },
  IS_EASILY_DISTRACTED: {
    en: "Is easily distracted",
    es: "Se distrae fácilmente",
  },
  IS_ON_THE_GO_OR_ACTS_AS_IF_DRIVEN_BY_A_MOTOR: {
    en: "Is on the go or acts as if driven by a motor",
    es: "Está en movimiento o actúa como si estuviera impulsado por un motor",
  },
  RUSHES_THROUGH_TASKS_OR_GIVES_UP_EASILY: {
    en: "Rushes through tasks or gives up easily",
    es: "Apresura las tareas o se rinde fácilmente",
  },
  CANT_SIT_STILL: {
    en: "Can't sit still",
    es: "No puede quedarse quieto/a",
  },
  AVOIDS_TASKS_THAT_TAKE_A_LOT_OF_EFFORT: {
    en: "Avoids tasks that take a lot of effort",
    es: "Evita las tareas que requieren mucho esfuerzo",
  },
  DOES_NOT_SEEM_TO_LISTEN_WHEN_SPOKEN_TO_DIRECTLY: {
    en: "Does not seem to listen when spoken to directly",
    es: "No parece escuchar cuando se le habla directamente",
  },
  HAS_MANY_THOUGHTS_AT_ONCE: {
    en: "Has many thoughts at once",
    es: "Tiene muchos pensamientos a la vez",
  },
  ACTS_WITHOUT_THINKING: {
    en: "Acts without thinking",
    es: "Actúa sin pensar",
  },
  LOSES_TEMPER_OR_TEMPER_TANTRUMS: {
    en: "Loses temper or temper tantrums",
    es: "Pierde el temperamento o tiene berrinches",
  },
  ARGUES_WITH_ADULTS: {
    en: "Argues with adults",
    es: "Discute con los adultos",
  },
  DEFIANT_REFUSES_WHAT_YOU_TELL_THEM_TO_DO: {
    en: "Defiant, refuses what you tell them to do",
    es: "Desafiante, se niega a hacer lo que se le dice",
  },
  BLAMES_OTHERS_FOR_MISBEHAVIOR: {
    en: "Blames others for misbehavior",
    es: "Echa la culpa a otros por su mal comportamiento",
  },
  IS_ANGRY: {
    en: "Is angry",
    es: "Está enojado/a",
  },
  DOES_NOT_APOLOGIZE_FOR_POOR_BEHAVIOR: {
    en: "Does not apologize for poor behavior",
    es: "No se disculpa por su mal comportamiento",
  },
  HAS_AN_EXPLOSIVE_TEMPER_OR_HAS_RAGE_REACTIONS: {
    en: "Has an explosive temper or has rage reactions",
    es: "Tiene un temperamento explosivo o reacciones de ira",
  },
  FREQUENT_CONFLICTS_WITH_OTHER_PEOPLE_IN_THE_HOME: {
    en: "Frequent conflicts with other people in the home",
    es: "Conflictos frecuentes con otras personas en el hogar",
  },
  BULLIES_THREATENS_OR_INTIMIDATES_OTHERS: {
    en: "Bullies, threatens, or intimidates others",
    es: "Acosa, amenaza o intimida a otros",
  },
  PHYSICAL_FIGHTS: {
    en: "Physical fights",
    es: "Peleas físicas",
  },
  CRUELTY_TO_ANIMALS: {
    en: "Cruelty to animals",
    es: "Crueldad hacia los animales",
  },
  HAS_DESTROYED_OTHERS_PROPERTY: {
    en: "Has destroyed others' property",
    es: "Ha destruido la propiedad de otros",
  },
  HAS_STARTED_FIRES: {
    en: "Has started fires",
    es: "Ha iniciado incendios",
  },
  LIES_TO_GET_THINGS_OR_AVOID_RESPONSIBILITY: {
    en: "Lies to get things or avoid responsibility",
    es: "Miente para conseguir cosas o evitar responsabilidades",
  },
  CLIENT_HAVE_HISTORY: {
    en: "Does the client have a history of",
    es: "¿El cliente tiene un historial de",
  },
  HAS_STOLEN_THINGS_FROM_OTHERS: {
    en: "Has stolen things from others",
    es: "Ha robado cosas de otros",
  },
  GANG_INVOLVEMENT: {
    en: "Gang involvement",
    es: "Involucramiento en pandillas",
  },
  SUBSTANCE_ABUSE: {
    en: "Substance abuse",
    es: "Abuso de sustancias",
  },
  IS_SAD_OR_IRRITATED_MOST_OF_THE_DAY: {
    en: "Is sad or irritated most of the day",
    es: "Está triste o irritado/a la mayor parte del día",
  },
  SHOWS_LITTLE_INTEREST_IN_PLEASURABLE_ACTIVITIES: {
    en: "Shows little interest in pleasurable activities",
    es: "Muestra poco interés en actividades placenteras",
  },
  TALKS_ABOUT_KILLING_THEMSELVES_OR_NOT_WANTING_TO_LIVE: {
    en: "Talks about killing themselves or not wanting to live",
    es: "Habla sobre matarse a sí mismos o no querer vivir",
  },
  HAS_LOW_ENERGY_OR_IS_TIRED_FOR_NO_APPARENT_REASON: {
    en: "Has low energy or is tired for no apparent reason",
    es: "Tiene poca energía o está cansado/a sin motivo aparente",
  },
  IS_VERY_TENSE_WORRIED_OR_ANXIOUS: {
    en: "Is very tense, worried, or anxious",
    es: "Está muy tenso/a, preocupado/a o ansioso/a",
  },
  DISPLAYS_ABRUPT_RAPID_MOOD_SWINGS: {
    en: "Displays abrupt, rapid mood swings",
    es: "Muestra cambios de humor abruptos y rápidos",
  },
  SOMETIMES_IS_SO_WOUND_UP_OR_HYPER_THAT_THEY_DO_NOT_SLEEP_FOR_A_DAY_OR_TWO: {
    en: "Sometimes is so wound up or hyper that they do not sleep for a day or two",
    es: "A veces está tan excitado/a o hiperactivo/a que no duerme durante uno o dos días",
  },
  HEARS_VOICES_ESPECIALLY_TELLING_THEM_TO_DO_THINGS: {
    en: "Hears voices, especially telling them to do things",
    es: "Oye voces, especialmente diciéndoles que hagan cosas",
  },
  HAS_EXTREMELY_STRANGE_OR_ILLOGICAL_IDEAS: {
    en: "Has extremely strange or illogical ideas",
    es: "Tiene ideas extremadamente extrañas o ilógicas",
  },
  TALKS_TO_IMAGINARY_PEOPLE: {
    en: "Talks to imaginary people",
    es: "Habla con personas imaginarias",
  },
  HAS_COMPULSIVE_BEHAVIORS: {
    en: "Has compulsive behaviors",
    es: "Tiene comportamientos compulsivos",
  },
  IS_HYPERSENSITIVE_OR_HYPOSENSITIVE: {
    en: "Is hypersensitive or hyposensitive",
    es: "Es hipersensible o hiposensible",
  },
  CUTS_ON_SELF_OR_ENGAGES_IN_OTHER_TYPES_OF_SELF_HARM: {
    en: "Cuts on self or engages in other types of self-harm",
    es: "Se corta a sí mismo/a o se involucra en otros tipos de autolesiones",
  },
  SHOWS_LITTLE_OR_NOT_INTEREST_IN_OTHER_PEOPLES_FEELINGS: {
    en: "Shows little or not interest in other people's feelings",
    es: "Muestra poco o ningún interés en los sentimientos de otras personas",
  },
  DOES_NOT_TRUST_ANYONE: {
    en: "Does not trust anyone",
    es: "No confía en nadie",
  },
  HAS_NO_FEAR_OR_STRANGERS_WILL_GO_WITH_ANYONE: {
    en: "Has no fear or strangers, will go with anyone",
    es: "No tiene miedo a los extraños, irá con cualquier persona",
  },
  POOR_EYE_CONTACT_EVEN_IF_NOT_IN_TROUBLE: {
    en: "Poor eye contact, even if not in trouble",
    es: "Poca contacto visual, incluso si no está en problemas",
  },
  RELATES_TO_OTHERS_ON_ONLY_A_SUPERFICIAL_LEVEL: {
    en: "Relates to others on only a superficial level",
    es: "Se relaciona con los demás solo a nivel superficial",
  },
  SHOWS_LITTLE_AFFECTION_DOES_NOT_ACCEPT_AFFECTION_FROM_OTHERS: {
    en: "Shows little affection, does not accept affection from others",
    es: "Muestra poco afecto, no acepta afecto de los demás",
  },
  HYPERVIGILANT_ALWAYS_WATCHING_OTHERS: {
    en: "Hypervigilant, always watching others",
    es: "Hipervigilante, siempre observando a los demás",
  },
  VERY_MANIPULATIVE: {
    en: "Very manipulative",
    es: "Muy manipulador/a",
  },
  LACK_OF_REMORSE_OR_CONSCIENCE: {
    en: "Lack of remorse or conscience",
    es: "Falta de remordimiento o conciencia",
  },
  SEEMS_TO_HAVE_A_LACK_OF_PURPOSE: {
    en: "Seems to have a lack of purpose",
    es: "Parece tener una falta de propósito",
  },
  CANNOT_GET_UPSETTING_THOUGHTS_OR_MEMORIES_OUT_OF_THEIR_MIND: {
    en: "Cannot get upsetting thoughts or memories out of their mind",
    es: "No puede sacar de su mente pensamientos o recuerdos perturbadores",
  },
  STARES_EASILY: {
    en: "Stares easily",
    es: "Mira fijamente fácilmente",
  },
  SEEMS_EMOTIONALLY_NUMB: {
    en: "Seems emotionally numb",
    es: "Parece emocionalmente insensible",
  },
  DISSOCIATES_SEEMS_TO_BE_OFF_IN_THEIR_OWN_WORLD: {
    en: "Dissociates, seems to be off in their own world",
    es: "Se disocia, parece estar en su propio mundo",
  },
  REPORTS_TRAUMA_DREAMS: {
    en: "Reports trauma dreams",
    es: "Informa sueños traumáticos",
  },
  REENACTS_TRAUMA_THROUGH_PLAY_OR_ACTS_OUT_SEXUALLY_OR_AGGRESSIVELY: {
    en: "Reenacts trauma through play or acts out sexually or aggressively",
    es: "Reinterpreta el trauma a través del juego o actúa sexual o agresivamente",
  },
  COMPLAINS_OF_HEADACHES_STOMACHACHES_OR_BODILY_PAINS: {
    en: "Complains of headaches, stomachaches, or bodily pains",
    es: "Se queja de dolores de cabeza, dolores de estómago o dolores corporales",
  },
  HAS_DIFFICULTY_FALLING_ASLEEP: {
    en: "Has difficulty falling asleep",
    es: "Tiene dificultades para conciliar el sueño",
  },
  HAS_BAD_DREAMS_OR_NIGHT_TERRORS: {
    en: "Has bad dreams or night terrors",
    es: "Tiene malos sueños o terrores nocturnos",
  },
  WAKES_FREQUENTLY_IN_THE_NIGHT_AND_HAS_TROUBLE_GETTING_BACK_TO_SLEEP: {
    en: "Wakes frequently in the night and has trouble getting back to sleep",
    es: "Se despierta frecuentemente en la noche y tiene dificultades para volver a dormir",
  },
  POOR_APPETITE: {
    en: "Poor appetite",
    es: "Poco apetito",
  },
  EATS_TOO_MUCH_OR_EATS_POORLY: {
    en: "Eats too much or eats poorly",
    es: "Come demasiado o come mal",
  },
  WETS_OR_SOILS_THE_BED: {
    en: "Wets or soils the bed",
    es: "Moja o ensucia la cama",
  },
  WETS_OR_SOILS_SELF_DURING_DAYTIME_HOURS: {
    en: "Wets or soils self during daytime hours",
    es: "Moja o ensucia su ropa durante el día",
  },
  ARTICULATION_PROBLEMS_DIFFICULT_TO_UNDERSTAND_SPEECH: {
    en: "Articulation problems, difficult to understand speech",
    es: "Problemas de articulación, difícil de entender el habla",
  },
  HAS_DIFFICULTY_EXPRESSING_THOUGHTS_AND_OR_FEELINGS: {
    en: "Has difficulty expressing thoughts and/or feelings",
    es: "Tiene dificultades para expresar pensamientos y/o sentimientos",
  },
  HAS_DIFFICULTY_UNDERSTANDING_WHAT_IS_BEING_SAID: {
    en: "Has difficulty understanding what is being said",
    es: "Tiene dificultades para entender lo que se dice",
  },
  HAS_FEW_OR_NO_FRIENDS: {
    en: "Has few or no friends",
    es: "Tiene pocos o ningún amigo",
  },
  HAS_DIFFICULTY_ACCURATELY_UNDERSTANDING_SOCIAL_AND_COMMUNICATION_CUES: {
    en: "Has difficulty accurately understanding social and communication cues",
    es: "Tiene dificultades para entender con precisión las señales sociales y de comunicación",
  },
  INTERRUPTS_OTHERS: {
    en: "Interrupts others",
    es: "Interrumpe a los demás",
  },
  DOES_NOT_LISTEN_VERY_WELL_TO_OTHER_PEOPLES_POINT_OF_VIEW_OR_PERSPECTIVE: {
    en: "Does not listen very well to other people's point of view or perspective",
    es: "No escucha muy bien el punto de vista o la perspectiva de otras personas",
  },
  DIFFICULTY_HANDLING_TRANSITIONS: {
    en: "Difficulty handling transitions",
    es: "Dificultad para manejar transiciones",
  },
  DIFFICULTY_HANDLING_CHANGES_IN_ROUTINE: {
    en: "Difficulty handling changes in routine",
    es: "Dificultad para manejar cambios en la rutina",
  },
  DOESNT_START_TASKS_WITHOUT_A_LOT_OF_PROMPTING: {
    en: "Doesn't start tasks without a lot of prompting",
    es: "No comienza las tareas sin mucha insistencia",
  },
  DIFFICULTY_FOLLOWING_MULTI_STEP_DIRECTIONS: {
    en: "Difficulty following multi-step directions",
    es: "Dificultad para seguir instrucciones de varios pasos",
  },
  DIFFICULTY_WITH_ORGANIZING_TASKS_OR_SCHOOL_WORK: {
    en: "Difficulty with organizing tasks or school work",
    es: "Dificultad para organizar tareas o trabajo escolar",
  },
  DIFFICULTY_DEALING_WITH_FRUSTRATION_IN_COMPLETING_TASKS: {
    en: "Difficulty dealing with frustration in completing tasks",
    es: "Dificultad para lidiar con la frustración al completar tareas",
  },
  DIFFICULTY_ADJUSTING_TO_UNFORESEEN_PROBLEMS_OR_UNCLEAR_DIRECTIONS: {
    en: "Difficulty adjusting to unforeseen problems or unclear directions",
    es: "Dificultad para adaptarse a problemas imprevistos o direcciones poco claras",
  },
  TROUBLE_COMPLETING_TASKS: {
    en: "Trouble completing tasks",
    es: "Problemas para completar tareas",
  },
  FAILS_TO_UNDERSTAND_HOW_HIS_OR_HER_BEHAVIOR_AFFECTS_OTHERS: {
    en: "Fails to understand how his or her behavior affects others",
    es: "No comprende cómo su comportamiento afecta a los demás",
  },
  FAILS_TO_LEARN_FROM_HIS_OR_HER_EXPERIENCE_SUCCESS_OR_FAILURE: {
    en: "Fails to learn from his or her experience, success, or failure",
    es: "No aprende de su experiencia, éxito o fracaso",
  },
  HELPS_WITH_CHORES_AROUND_THE_HOUSE: {
    en: "Helps with chores around the house",
    es: "Ayuda con las tareas de la casa",
  },
  LIKES_SCHOOL: {
    en: "Likes school",
    es: "Le gusta la escuela",
  },
  COMPLETES_HOMEWORK_WITH_MINIMAL_REMINDERS: {
    en: "Completes homework with minimal reminders",
    es: "Completa la tarea con recordatorios mínimos",
  },
  GETS_ALONG_WELL_WITH_FAMILY_MEMBERS: {
    en: "Gets along well with family members",
    es: "Se lleva bien con los miembros de la familia",
  },
  IS_ARTISTIC_CREATIVE_OR_MUSICAL: {
    en: "Is artistic, creative, or musical",
    es: "Es artístico, creativo o musical",
  },
  LIKES_ANIMALS: {
    en: "Likes animals",
    es: "Le gustan los animales",
  },
  HAS_GOOD_MANNERS: {
    en: "Has good manners",
    es: "Tiene buenos modales",
  },
  APOLOGIZES_WHEN_HURTS_SOMEONES_FEELINGS: {
    en: "Apologizes when hurts someone's feelings",
    es: "Se disculpa cuando lastima los sentimientos de alguien",
  },
  IS_AFFECTIONATE: {
    en: "Is affectionate",
    es: "Es cariñoso/a",
  },
  SENSE_OF_HUMOR: {
    en: "Sense of humor",
    es: "Sentido del humor",
  },
  ATTENTION_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  DEFIANCE_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  AGGRESSION_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  MOOD_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  ODD_IDEAS_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  ATTACHMENT_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  TRAUMA_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  SOMATIC_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  LANGUAGE_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  SOCIAL_SKILLS_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  EXECUTIVE_FUNCTIONS_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
  STRENGTHS_COMMENT: {
    en: "Comment",
    es: "Comentario",
  },
};

import {
  ArrowPathIcon,
  CheckIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  SparklesIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PropsWithChildren, useState } from "react";

import { Button, Paragraph } from "@fronterahealth/frontera-ui-components";
import {
  PopoverMenu,
  PopoverMenuItem,
} from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

import { ReportSectionFieldTypeEnums } from "@api/graphql/types-and-hooks";
import { AddContentMenu } from "@components/SectionWidget/AddContentMenu";

type IconName = "document";

export type SupportedContentType = "Text" | "Image" | "Table" | "Input";
export interface ContentTypeAndBespokeType {
  contentType: ReportSectionFieldTypeEnums;
  bespokeType: string;
}

export const getContentTypeAndBespokeType = (type: SupportedContentType): ContentTypeAndBespokeType | null => {
  switch (type) {
    case "Text":
      return { contentType: ReportSectionFieldTypeEnums.Wysiwyg, bespokeType: "" };
    case "Table":
      return { contentType: ReportSectionFieldTypeEnums.Table, bespokeType: "" };
    case "Image":
      return { contentType: ReportSectionFieldTypeEnums.Image, bespokeType: "" };
    case "Input":
      return { contentType: ReportSectionFieldTypeEnums.Bespoke, bespokeType: "text" };
    default:
      return null;
  }
};

interface SectionWidgetProps extends PropsWithChildren {
  sectionType: "regular" | "recommendation";
  sectionData: {
    heading: string;
    icon: IconName;
  };
  supportedContentTypes: SupportedContentType[];
  onAddContent: (contentTypeAndBespokeType: ContentTypeAndBespokeType, newRankOrder: number) => Promise<unknown>;
  onEditSection: (newHeading: string) => Promise<unknown>;
  setIsInLineEditMode: (value: boolean) => void;
  permissions: {
    canEdit: boolean;
    canDelete: boolean;
  };
  placeholder: string;
  id: string;
  actionButtonMenu: PopoverMenuItem[];
  showAddContent?: boolean;
  allowInLineEdit?: boolean;
  isInLineEditMode?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  hasFields?: boolean;
}

// Icon mapping for dynamic icons
const iconMapping: { [key in IconName]: JSX.Element } = {
  document: <DocumentTextIcon className="h-6 w-6" />,
};

export const SectionWidget: React.FC<SectionWidgetProps> = ({
  sectionType,
  sectionData,
  onAddContent,
  onEditSection,
  permissions,
  supportedContentTypes,
  placeholder,
  id,
  children,
  showAddContent = true,
  allowInLineEdit = false,
  setIsInLineEditMode,
  isInLineEditMode = false,
  actionButtonMenu,
  isLoading = false,
  isError = false,
  hasFields,
}) => {
  const { heading, icon } = sectionData;
  const [isEditing, setIsEditing] = useState(false);
  const [newHeading, setNewHeading] = useState(heading);
  const [tempSuccessShowing, setTempSuccessShowing] = useState<boolean>(false);
  const [errorShowing, setErrorShowing] = useState<boolean>(false);

  const handleSave = async () => {
    try {
      setTempSuccessShowing(true);
      await onEditSection(newHeading);
      setTempSuccessShowing(false);
      setIsEditing(false);
    } catch (err) {
      console.error(`Error when saving section heading`, err);
      setErrorShowing(true);
    }
  };

  const handleHeadingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewHeading(e.target.value);
  };

  const showInput = (isEditing && permissions.canEdit) || (!permissions.canEdit && isInLineEditMode && isEditing);

  return (
    <section
      data-testid={`report-section-${sectionData.heading}`}
      className={`px-8 py-6 mb-4 pageBreak rounded-3xl ${isLoading ? "border-2 border-blurple-300 " : isError ? "border-2 border-red-300" : ""} bg-white`}
      id={id}
    >
      <div className={`flex  items-center justify-between ${isEditing ? "pb-6" : ""}`}>
        <div
          data-testid="section-title-row"
          className="flex items-center relative group w-full"
          id={`${id}-${heading.replace(/\s+/g, "-")}`} // replacing all the spaces with "-"
          data-sticky-nav-header
        >
          <span className="border p-2 rounded mr-2">{iconMapping[icon]}</span>
          {showInput ? (
            <div className="w-full">
              <input
                className="peer block w-full border-0 py-1.5 text-text-primary placeholder-limestone-200 ring-inset ring-limestone-200 placeholder:text-limestone-400 sm:text-sm sm:leading-6 focus:ring-0 focus:outline-none cursor-text"
                type="text"
                defaultValue={newHeading}
                placeholder={placeholder}
                onChange={handleHeadingChange}
                autoFocus
              />
              <div className="absolute -bottom-8 left-16 flex items-center gap-x-2">
                <XMarkIcon
                  onClick={() => {
                    setErrorShowing(false);
                    setTempSuccessShowing(false);
                    setNewHeading(heading);
                    setIsEditing(false);
                  }}
                  className="h-6 w-6 cursor-pointer rounded-md border border-limestone-200 bg-transparent p-1 text-text-secondary transition-all hover:bg-limestone-50"
                />
                <div
                  className={`flex h-6 w-6 items-center justify-center rounded-md border ${
                    tempSuccessShowing
                      ? "border-green-700 bg-green-100"
                      : errorShowing
                        ? "border-red-700 bg-red-100"
                        : "border-limestone-200"
                  }`}
                >
                  {tempSuccessShowing ? (
                    <ArrowPathIcon className="h-6 w-6 animate-spin p-1 text-text-secondary" />
                  ) : (
                    <>
                      {errorShowing ? (
                        <ExclamationTriangleIcon className="h-6 w-6 p-1 text-red-700" />
                      ) : (
                        <CheckIcon
                          data-testid="save-section-title-button"
                          onClick={handleSave}
                          className={`h-6 w-6 p-1 cursor-pointer ${
                            tempSuccessShowing ? "text-green-700" : "text-text-secondary"
                          }`}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <Paragraph
                displayType="loud"
                colorType="primary"
                data-dynamic-section
                data-testid="section-title-input-paragraph"
                className={`${(permissions.canEdit || isInLineEditMode) && !isLoading ? "cursor-pointer" : ""}`}
                onClick={() => (permissions.canEdit || isInLineEditMode) && !isLoading && setIsEditing(true)}
              >
                {newHeading}
              </Paragraph>
              {isLoading ? (
                <div className="flex items-center space-x-1  text-blurple-500 ml-4">
                  <SparklesIcon className="h-6 w-6" />
                  <span>AI is generating this section...</span>
                </div>
              ) : null}
              {isError ? (
                <div className="flex items-center space-x-1  text-red-500 ml-4">
                  <ExclamationTriangleIcon className="h-6 w-6" />
                  <span>Section encountered an error with generating </span>
                </div>
              ) : null}
              {(permissions.canEdit || isInLineEditMode) && !isLoading && (
                <PencilIcon
                  onClick={() => setIsEditing(true)}
                  className="h-6 w-6 ml-1 cursor-pointer rounded-md border border-limestone-200 p-1 text-text-secondary opacity-0 group-hover:opacity-100"
                />
              )}
            </>
          )}
        </div>

        {permissions.canEdit && !isInLineEditMode && !isLoading && (
          <PopoverMenu openDirection="to-bottom-left" menuItems={actionButtonMenu} />
        )}

        {allowInLineEdit && (
          <Button
            onClick={() => setIsInLineEditMode(!isInLineEditMode)}
            appearance="link"
            text={isInLineEditMode ? "Done Editing" : "Edit"}
            className="ml-2"
          />
        )}
      </div>

      {/* Add Blocks */}
      {permissions.canEdit && showAddContent && !isLoading ? (
        <AddContentMenu
          sectionId={id}
          sectionType={sectionType}
          onAddContent={onAddContent}
          newRankOrder={1}
          supportedContentTypes={supportedContentTypes}
        />
      ) : (
        <></>
      )}

      {/* Content Blocks */}
      <div
        className={`${isLoading && hasFields ? "pointer-events-none opacity-20" : isLoading ? "pointer-events-none" : ""}`}
      >
        {hasFields && isLoading ? (
          children
        ) : isLoading && !hasFields ? (
          <div className="flex flex-col items-start space-y-4 py-4">
            <span
              className={`h-[24px] w-[240px]  block  animate-pulse rounded-md bg-limestone-100 transition-opacity opacity-100`}
            />
            <span
              className={`h-[180px] w-full  block  animate-pulse rounded-md bg-limestone-100 transition-opacity opacity-100`}
            />
          </div>
        ) : (
          children
        )}
      </div>
    </section>
  );
};

import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  ChatBubbleBottomCenterIcon,
  CheckBadgeIcon,
  LifebuoyIcon,
  MinusCircleIcon,
  PhoneIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { HeroIconType } from "@utils/utils";

import { AssessmentReportFieldsEnums } from "@api/graphql/types-and-hooks";
import { AssessmentReportHeadingType } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/ReportSummaryPart2";

export const fieldEnumToHumanLabelMapping: { [key in AssessmentReportFieldsEnums]: string } = {
  [AssessmentReportFieldsEnums.DevelopmentalDisabilityDiagnosis]: "Developmental Disability Diagnosis",
  [AssessmentReportFieldsEnums.DateOfDiagnosis]: "Date of Diagnosis",
  [AssessmentReportFieldsEnums.DateAndTimeOfAssessment]: "Date and Time of Assessment",
  [AssessmentReportFieldsEnums.DateOfReportWriteUp]: "Date of Report Write-up",
  [AssessmentReportFieldsEnums.InsuranceName]: "Insurance Name",
  [AssessmentReportFieldsEnums.MemberId]: "Member ID",
  [AssessmentReportFieldsEnums.LocationOfServices]: "Location of Services",
  [AssessmentReportFieldsEnums.BcbaCompletingAssessmentAndReportWriteUp]:
    "BCBA completing Assessment and Report Write-up",
  [AssessmentReportFieldsEnums.StatementOfConfidentiality]: "Statement of Confidentiality",
  [AssessmentReportFieldsEnums.DiagnosticInformation]: "Diagnostic Information",
  [AssessmentReportFieldsEnums.ReasonForReferral]: "Reason for referral",
  [AssessmentReportFieldsEnums.DevelopmentalHistory]: "Developmental History",
  [AssessmentReportFieldsEnums.FamilyHistoryOrInformation]: "Family History / Information",
  [AssessmentReportFieldsEnums.OtherMedicalDiagnosesOfClient]: "Other medical diagnoses of client",
  [AssessmentReportFieldsEnums.MedicationPrescribed]: "Medication Prescribed",
  [AssessmentReportFieldsEnums.AllergiesOrDietaryRestrictions]: "Allergies or dietary restrictions",
  [AssessmentReportFieldsEnums.FamilyHistoryOfDiagnosis]: "Family history of diagnosis: (Maternal/Paternal/Siblings)",
  [AssessmentReportFieldsEnums.OtherHealthConcernsOfClient]: "Other health concerns of client",
  [AssessmentReportFieldsEnums.NameOfSchool]: "Name of School",
  [AssessmentReportFieldsEnums.GradeAndEducationalSetting]: "Grade and educational setting",
  [AssessmentReportFieldsEnums.IepOrBipInSchool]: "IEP/BIP in school",
  [AssessmentReportFieldsEnums.TherapeuticServicesInSchoolAndHours]: "Therapeutic services in school and hours",
  [AssessmentReportFieldsEnums.TherapyServiceHoursNameOfProviderDates]:
    "Chart: Therapy/Service, Hours of Services Provided, Name of Service Provider, Dates of Services Provided",
  [AssessmentReportFieldsEnums.AssessmentsOrInformationUsed]: "Assessments/Information Used",
  [AssessmentReportFieldsEnums.AssessmentWriteUp]: "Assessment Write Up",
  [AssessmentReportFieldsEnums.ClinicalObservationDuringAssessment]: "Clinical Observation During Assessment",
  [AssessmentReportFieldsEnums.LearnerStrengths]: "Learner Strengths",
  [AssessmentReportFieldsEnums.PreferredItems]: "Preferred Items",
  [AssessmentReportFieldsEnums.KnownAversions]: "Known aversions",
  [AssessmentReportFieldsEnums.QabfOrNarrativeFunctionalAssessment]: "QABF/Narrative functional assessment",
  [AssessmentReportFieldsEnums.AssessmentSummary]: "Assessment Summary",
  [AssessmentReportFieldsEnums.BehaviorInterventionPlanBip]: "Behavior Intervention Plan (BIP)",
  [AssessmentReportFieldsEnums.CoordinationOfCare]: "Coordination of Care", // Added based on pattern
  [AssessmentReportFieldsEnums.ProgramRecommendations]: "Treatment Recommendations/Services Requests",
  [AssessmentReportFieldsEnums.MedicalNecessity]: "Medical Necessity", // Added based on pattern
  [AssessmentReportFieldsEnums.TitrationPlan]: "Titration Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.DischargePlan]: "Discharge Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.RiskEvaluation]: "Risk Evaluation", // Added
  [AssessmentReportFieldsEnums.EmergencyPlan]: "Emergency Plan", // Added based on pattern
  [AssessmentReportFieldsEnums.ProgramContactsForClient]: "Program Contacts For Client", // Added based on pattern
  [AssessmentReportFieldsEnums.References]: "References", // Added based on pattern
  [AssessmentReportFieldsEnums.AbaBackgroundInSchool]: "ABA Background In School", // Added based on pattern
  [AssessmentReportFieldsEnums.BehaviorReductionSummary]: "Summary",
  [AssessmentReportFieldsEnums.ReplacementBehaviorSummary]: "Summary",
  [AssessmentReportFieldsEnums.SkillAcquisitionSummary]: "Summary",
  [AssessmentReportFieldsEnums.ParentCaregiverSummary]: "Summary",
  [AssessmentReportFieldsEnums.ReportSignature]: "Signature",
};

export const headerToIconMapping: {
  [key in AssessmentReportHeadingType]: HeroIconType;
} = {
  "Behavior Reduction Goals": ArrowTrendingDownIcon,
  "Replacement Behavior Goals": ArrowPathIcon,
  "Skill Acquisition Goals": ArrowTrendingUpIcon,
  "Parent / Caregiver Goals": UsersIcon,
  "Coordination of Care": ChatBubbleBottomCenterIcon,
  "Program Recommendations": CheckBadgeIcon,
  "Service Reduction and Termination": MinusCircleIcon,
  "Risk Evaluation & Emergency Plan": LifebuoyIcon,
  "Program Contacts for Clients": PhoneIcon,
  References: ArchiveBoxIcon,
};

export const headerToListOfFieldsMapping: { [key in AssessmentReportHeadingType]: AssessmentReportFieldsEnums[] } = {
  "Behavior Reduction Goals": [AssessmentReportFieldsEnums.BehaviorReductionSummary],
  "Replacement Behavior Goals": [AssessmentReportFieldsEnums.ReplacementBehaviorSummary],
  "Skill Acquisition Goals": [AssessmentReportFieldsEnums.SkillAcquisitionSummary],
  "Parent / Caregiver Goals": [AssessmentReportFieldsEnums.ParentCaregiverSummary],
  "Coordination of Care": [AssessmentReportFieldsEnums.CoordinationOfCare],
  "Program Recommendations": [AssessmentReportFieldsEnums.ProgramRecommendations],
  "Service Reduction and Termination": [
    AssessmentReportFieldsEnums.TitrationPlan,
    AssessmentReportFieldsEnums.DischargePlan,
    AssessmentReportFieldsEnums.MedicalNecessity,
  ],
  "Risk Evaluation & Emergency Plan": [
    AssessmentReportFieldsEnums.RiskEvaluation,
    AssessmentReportFieldsEnums.EmergencyPlan,
  ],
  "Program Contacts for Clients": [AssessmentReportFieldsEnums.ProgramContactsForClient],
  References: [AssessmentReportFieldsEnums.References],
};

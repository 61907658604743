import { AI_GENERATED } from "@fronterahealth/frontera-ui-components";

import { useGoalBankLongTermGoalsQuery } from "@api/graphql/types-and-hooks";

export const useGetLongTermGoals = (selectedFolderId: string) => {
  const {
    data: longTermGoals,
    refetch: refetchLtg,
    isLoading: ltgIsLoading,
  } = useGoalBankLongTermGoalsQuery(
    { folderId: selectedFolderId },
    {
      queryKey: ["long-term-goals-" + selectedFolderId],
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      refetchInterval: 200000,
      enabled: !!selectedFolderId && selectedFolderId !== AI_GENERATED,
    },
  );

  return {
    longTermGoals,
    refetchLtg,
    ltgIsLoading,
  };
};

import { DocumentIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";

import { Button, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

export const INTAKE_VALUES = ["Complete", "In Progress", "Awaiting", "Started"] as const;
export type IntakeType = (typeof INTAKE_VALUES)[number];

export interface ConsentData {
  name: string;
  size: number;
  type: string;
  lastUpdatedDate: Date | null;
  signedURL: string;
}

interface ConsentFilesTableProps {
  data: ConsentData[];
  isLoading: boolean;
  onDownload: (data: ConsentData) => void;
}

const bytesToMB = (bytes: number): string => {
  const bytesInMB = 1024 * 1024; // 1 MB = 1,048,576 bytes
  const mb = bytes / bytesInMB;
  return `${mb.toFixed(2)}mb`;
};

export const ConsentFilesTable: React.FC<ConsentFilesTableProps> = ({ data, isLoading, onDownload }) => {
  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full border-collapse border-0">
            <thead>
              <tr className="grid grid-cols-5 gap-5 mb-2 p-2 bg-limestone-100 rounded-lg ">
                <th scope="col" className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-limestone-900 sm:pl-6">
                  Name
                </th>
                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-limestone-900">
                  Size
                </th>
                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-limestone-900">
                  Type
                </th>
                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-limestone-900">
                  Last Updated
                </th>
                <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-limestone-900"></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={4} className="text-center py-4">
                    <div className="loader">Loading...</div> {/* Customize the loader as needed */}
                  </td>
                </tr>
              ) : data.length ? (
                data.map((form) => (
                  <tr
                    key={form.name}
                    className="grid grid-cols-5 gap-5 mb-2 p-2 bg-white border border-limestone-300 rounded-lg "
                  >
                    <td className="py-2 px-3 text-sm font-medium text-limestone-900 flex items-center">
                      <DocumentIcon className="mr-2 h-4 w-4" /> {form.name}
                    </td>
                    <td className="py-2 px-3 text-sm font-medium text-limestone-900 flex items-center">
                      {bytesToMB(form.size)}
                    </td>
                    <td className="py-2 px-3 text-sm font-medium text-limestone-900 flex items-center">{form.type}</td>
                    <td className="py-2 px-3 text-sm text-limestone-500 flex items-center">
                      {form.lastUpdatedDate ? format(new Date(form.lastUpdatedDate), "MM/dd/yyyy h:mm a") : ""}
                    </td>
                    <td className="py-2 px-3 text-sm font-medium text-limestone-900 flex items-end justify-end">
                      <Button
                        text="Download"
                        appearance="link"
                        onClick={() => onDownload(form)}
                        style={{ padding: 2 }}
                        className="max-h-5"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <div className="py-5 flex flex-col align-middle bg-white border border-limestone-300 rounded-lg text-center">
                  <Paragraph displayType="loud">No Files to Show</Paragraph>
                  <Small>Agreements and documents added to this client will show up here.</Small>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { BehaviorsQuestionType, LearnerBehaviorsQuery, useLearnerBehaviorsQuery } from "@api/graphql/types-and-hooks";
import { FormSpacer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { QuestionData, convertOnboardingQuestionListToQuestionMap } from "@components/forms/utils";
import {
  BehaviorsQuestionGroupings,
  BehaviorsRoutes,
  customQuestionConfig,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Behaviors/BehaviorsQuestionGroupings";
import { behaviorsQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Behaviors/BehaviorsQuestions.languageMapping";
import { extractPath } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const Behaviors: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/behaviors" : undefined;

  const subpage = pathname.split("/").pop();
  const extractedPath = extractPath(pathname);

  const questionPageOrigin = pathname.includes("all") ? undefined : `behaviors/${subpage}`;

  const questionsForThisPage = BehaviorsQuestionGroupings[extractedPath as BehaviorsRoutes];

  const config = useGetQueryConfig<LearnerBehaviorsQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerBehaviorsQuery(
    { learnerId: clientId ? clientId : "", questionPageOrigin },
    config,
  );
  const { data } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormTextArea, RegisteredFormRadioInput, reset } = useFormUtils<{
    [key in BehaviorsQuestionType]: string;
  }>({
    // TODO: Needs to be better tested, documented
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  useEffect(() => {
    if (data) {
      const behaviorQuestions = data.learnerById?.onboardingQuestionsForLearner.edges
        .filter((question) => question?.node?.questionPageOrigin?.includes("behaviors"))
        .map((edge) => ({
          ...edge?.node,
        }));

      const formattedQuestions = convertOnboardingQuestionListToQuestionMap(behaviorQuestions as QuestionData[]);

      reset(formattedQuestions);
    }
  }, [data, reset]);

  return (
    <form onSubmit={onSubmit} className="flex min-h-[500px] flex-col justify-between px-2" noValidate>
      <div className="flex-col">
        {Object.values(BehaviorsQuestionType)
          .filter((q) => questionsForThisPage.includes(q))
          .map((q) => {
            const customConfig = customQuestionConfig[q];
            if (customConfig) {
              switch (customConfig.type) {
                case "radio":
                  return (
                    <>
                      {q === BehaviorsQuestionType.PretendPlay && <FormSpacer />}
                      <RegisteredFormRadioInput
                        title={behaviorsQuestionsMapping[q][chosenLanguage]}
                        items={customConfig.items(chosenLanguage)}
                        // @ts-ignore: Ignoring the compiler and risking bugs because: Question Type has a unique pattern
                        formKey={`${q}`}
                        formState={formState}
                        disabled
                      />
                      {q === BehaviorsQuestionType.OverFocusedOnSpecificParts && <FormSpacer />}
                    </>
                  );
              }
            }
            return (
              <RegisteredFormTextArea
                key={q}
                readOnly
                label={behaviorsQuestionsMapping[q][chosenLanguage]}
                // @ts-ignore: Ignoring the compiler and risking bugs because: Question Type has a unique pattern
                formKey={`${q}`}
                formState={formState}
              />
            );
          })}
      </div>
    </form>
  );
};

import { FieldValues, FormState, Path, UseFormRegister } from "react-hook-form";

import { TextArea, TextAreaProps } from "@fronterahealth/frontera-ui-components";

import { getErrorFromFormKey } from "@components/forms/utils";

interface Props<T extends FieldValues> extends Omit<TextAreaProps, "name" | "ref"> {
  register: UseFormRegister<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  pattern?: RegExp;
  required?: boolean;
}

export const FormTextArea = <T extends FieldValues>({
  formState,
  formKey,
  register,
  pattern,
  required = true,
  label,
  ...props
}: Props<T>) => {
  const errorMessage = getErrorFromFormKey(formKey, formState.errors, undefined);
  return (
    <TextArea
      label={label}
      errorMessage={errorMessage}
      hintText={required ? undefined : "Optional"}
      inputRequired={required}
      {...register(formKey, {
        required: {
          value: required,
          message: `${label} is required`,
        },
      })}
      {...props}
    />
  );
};

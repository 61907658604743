import { useAuth0 } from "@auth0/auth0-react";
import { config } from "@config/config";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { PropsWithChildren } from "react";

import { Heading } from "@fronterahealth/frontera-ui-components";

export const FeatureFlagProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, isLoading } = useAuth0();

  if (isLoading || !user) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <Heading type="h2" className="text-limestone-400">
          Loading...
        </Heading>
      </div>
    );
  }
  const orgName = `${user![config.auth0Audience + "/orgName"] ?? "no-org"}`;

  return (
    <LDProvider
      clientSideID={import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID}
      reactOptions={{ useCamelCaseFlagKeys: false }}
      context={{
        kind: "user",
        key: user?.sub,
        name: user?.name,
        email: user?.email,
        custom: {
          orgName,
          sub: user?.sub,
        },
      }}
    >
      {children}
    </LDProvider>
  );
};

import { nonNullable } from "@utils/utils";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { FileInstance, Heading, Small } from "@fronterahealth/frontera-ui-components";

import {
  ApiLearnerFileFileKindChoices,
  ApiLearnerFileStatusChoices,
  ApiReportSectionsAiGeneratedStatusChoices,
  AssessmentToolType,
  ReportPageLocationEnums,
  ReportTypeEnums,
  useAssessmentToolsQuery,
} from "@api/graphql/types-and-hooks";
import { REASONS_TO_BE_OPEN, ValidationNoticeDialog } from "@components/ValidationNoticeDialog/ValidationNoticeDialog";
import { notifyError } from "@components/notifications/notifications";
import { FooterButtonRow } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/FooterButtonRow";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/EvaluationDetails/Components/helper";
import { Assessments } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/Assessments/Assessments";
import { ClinicalNotes } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/ClinicalNotes";
import { ConfirmGenerationDialog } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/ConfirmGenerationDialog/ConfirmGenerationDialog";
import { Interviews } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/Interviews/Interviews";
import { useEvaluationData } from "@providers/EvaluationProvider";

interface FileRowProps extends PropsWithChildren {
  title: string;
  subtitle: string;
  isLast?: boolean;
  showTemplateDownloadLink?: boolean;
}
export const FileRow: React.FC<FileRowProps> = ({ title, subtitle, showTemplateDownloadLink, children }) => {
  return (
    <div className="my-0 flex w-full flex-col items-end">
      <div className="grid w-full grid-cols-8 items-start">
        <div className="col-span-3 flex flex-col">
          <Heading type="h4">{title}</Heading>
          <Small className="max-w-56">{subtitle}</Small>
          {showTemplateDownloadLink ? (
            <Small displayType="normal" className="max-w-56 mt-4">
              For best results, use our scoring <br /> templates found{" "}
              <span
                onClick={() => window.open("/downloads", "_blank")}
                className="bg-transparent text-interaction-primary cursor-pointer"
              >
                here
              </span>
            </Small>
          ) : (
            <></>
          )}
        </div>
        <div className="col-span-5 flex flex-col rounded-xl border border-limestone-100 bg-white p-6">{children}</div>
      </div>
      {/* {isLast ? null : <Divider className="w-full" />} */}
    </div>
  );
};

export const ApiStatusToFileStatus: { [key in ApiLearnerFileStatusChoices]: FileInstance["fileStatus"] } = {
  [ApiLearnerFileStatusChoices.Uploaded]: "done",
  [ApiLearnerFileStatusChoices.Pending]: "pending",
  [ApiLearnerFileStatusChoices.ProcessError]: "parsing-failed",
  [ApiLearnerFileStatusChoices.ProcessSuccess]: "done",
  [ApiLearnerFileStatusChoices.Processing]: "parsing",
};

export const EvaluationUploadFiles: React.FC = () => {
  const { evaluationQuery } = useEvaluationData();
  const go = useNavigate();

  const files = evaluationQuery.data?.getReports?.edges?.[0]?.node?.reportFiles.edges?.map((edge) => edge?.node);
  const { refetch } = evaluationQuery;
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.DiagnosisEvaluation });
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];

  const filesForAssessment = files?.filter((file) => file?.status !== "PENDING")?.filter(nonNullable);

  /* NOTE: Current product ask (as of 9/24/24) is to remove this from the page */
  // const existingIntakeCall = filesForAssessment
  //   ?.filter((file) => file.fileKind === ApiLearnerFileFileKindChoices.IntakeCall)
  //   ?.pop();

  const existingInterviews = filesForAssessment?.filter(
    (file) => file.fileKind === ApiLearnerFileFileKindChoices.Interview,
  );

  const existingAssessments = filesForAssessment?.filter((file) =>
    supportedAssessmentsList.map((a) => a.id).includes(file.fileKind),
  );

  const existingClinicalNotes = filesForAssessment?.filter((file) =>
    [ApiLearnerFileFileKindChoices.ClinicalNotes].includes(file.fileKind),
  );

  const [promptDialogOpen, setPromptDialogOpen] = React.useState<boolean>(false);
  const [validationDialogOpen, setValidationDialogOpen] = React.useState<REASONS_TO_BE_OPEN | null>(null);

  const nextPage: FilterSectionSubRoute = "report-part-1";
  const nextPageFilteredSections = useGetFilteredPageSectionsByLocation(nextPage);

  const nextPageAlreadyGenerated = nextPageFilteredSections.some(
    (section) =>
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending ||
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted,
  );

  const handlePrimaryButtonAction = async () => {
    if (nextPageAlreadyGenerated) {
      go(`../${nextPage}`);
      return;
    }
    try {
      const updatedFiles = (await refetch()).data?.getReports?.edges?.[0]?.node?.reportFiles.edges?.map(
        (edge) => edge?.node,
      );
      const filesForAssessment = updatedFiles?.filter((file) => file?.status !== "PENDING")?.filter(nonNullable);

      const existingAssessments = filesForAssessment?.filter((file) =>
        supportedAssessmentsList.map((a) => a.id).includes(file.fileKind),
      );

      const parsingFiles = existingAssessments?.filter(
        (file) => file.status === ApiLearnerFileStatusChoices.Processing,
      );

      const errorFiles = existingAssessments?.filter(
        (file) => file.status === ApiLearnerFileStatusChoices.ProcessError,
      );

      if (existingAssessments?.length === 0) {
        setValidationDialogOpen("MISSING_FILES");
        return;
      }

      if (parsingFiles && parsingFiles.length > 0) {
        setValidationDialogOpen("FILES_VALIDATING");
        return;
      }

      if (errorFiles && errorFiles.length > 0) {
        setValidationDialogOpen("PARSING_FAILED");
        return;
      }
      setPromptDialogOpen(true);
    } catch (error) {
      console.error("Assessment file retrieval error: ", error);
      notifyError("Assessment file retrieval error");
    }
  };

  return (
    <div className="flex w-full flex-col gap-y-8 ">
      <ValidationNoticeDialog reasonToBeOpen={validationDialogOpen} closeDialog={() => setValidationDialogOpen(null)} />
      <ConfirmGenerationDialog
        type={ReportPageLocationEnums.ReportPart_1}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      {/* NOTE: Current product ask (as of 9/24/24) is to remove this from the page */}
      {/* <IntakeCall
        existingIntakeCall={
          existingIntakeCall
            ? {
                fileName: existingIntakeCall.originalFileName || "<missing-file-name>",
                fileSize: existingIntakeCall.fileSize,
                fileStatus: ApiStatusToFileStatus[existingIntakeCall.status || ApiLearnerFileStatusChoices.Pending],
                metadata: existingIntakeCall.metadata,
                fileKind: existingIntakeCall.fileKind || "<missing-file-kind>",
                fileId: existingIntakeCall.id,
              }
            : undefined
        }
      /> */}
      <Interviews
        existingInterviews={
          existingInterviews?.map((existingInterview) => ({
            fileName: existingInterview.originalFileName || "<missing-file-name>",
            fileSize: existingInterview.fileSize,
            fileStatus: ApiStatusToFileStatus[existingInterview.status || ApiLearnerFileStatusChoices.Pending],
            metadata: existingInterview.metadata,
            fileKind: existingInterview.fileKind || "<missing-file-kind>",
            fileId: existingInterview.id,
          })) || []
        }
      />
      <Assessments
        existingAssessments={
          existingAssessments?.map((a) => ({
            fileName: a.originalFileName,
            fileSize: a.fileSize,
            fileStatus: ApiStatusToFileStatus[a?.status || ApiLearnerFileStatusChoices.Pending],
            metadata: a.metadata,
            fileKind: a.fileKind,
            fileId: a.id,
            assessmentTemplateType: a.assessmentTemplateType,
            category: supportedAssessmentsList.find((sa) => sa.id === a.fileKind)?.categories?.[0] || "",
          })) || []
        }
      />
      <ClinicalNotes
        existingClinicalNotes={
          existingClinicalNotes?.map((a) => ({
            fileName: a.originalFileName,
            fileSize: a.fileSize,
            fileStatus: ApiStatusToFileStatus[a?.status || ApiLearnerFileStatusChoices.Pending],
            metadata: a.metadata,
            fileKind: a.fileKind,
            fileId: a.id,
          })) || []
        }
      />
      <FooterButtonRow
        primaryButtonTitle="Next"
        primaryButtonDisabled={evaluationQuery.isFetching}
        primaryButtonAction={handlePrimaryButtonAction}
        skipNavigate
      />
    </div>
  );
};

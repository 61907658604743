import { FieldValues, FormState, Path, UseFormRegister } from "react-hook-form";

import { Input, InputProps } from "@fronterahealth/frontera-ui-components/Input/Input";

import { LanguageMappingInterface } from "@components/forms/interfaces";
import { getErrorFromFormKey, getLabelFromFormKey } from "@components/forms/utils";

export function getErrorFromFormState<T extends FieldValues>(formKey: Path<T>, formState: FormState<T>): string {
  if (formKey.includes(".")) {
    const periodCount = formKey.match(/\./g)?.length;
    if (periodCount === 1) {
      const [fk, sk] = formKey.split(".");

      // @ts-ignore: Ignoring the compiler and risking bugs because: Admin input validation is not polished yet
      const labelBase = formState.errors?.[fk]?.[sk]?.message || ("" as string);
      return labelBase;
    } else return "missing-error-message";
  } else {
    return formState.errors[formKey]?.message?.toString() || "";
  }
}

interface Props<T extends FieldValues> extends Omit<InputProps, "name" | "pattern" | "ref"> {
  register: UseFormRegister<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  mapping?: LanguageMappingInterface<T>;
  pattern?: RegExp;
  required?: boolean;
  customErrorMessage?: string;
}

export const FormInput = <T extends FieldValues>({
  formState,
  formKey,
  register,
  pattern,
  mapping,
  label,
  required = true,
  ...props
}: Props<T>) => {
  const inputLabel = mapping ? getLabelFromFormKey(formKey, mapping, "en") : label;
  const errorMessage = getErrorFromFormKey(formKey, formState.errors, props.customErrorMessage);
  return (
    <Input
      label={inputLabel}
      hintText={required ? undefined : "Optional"}
      inputRequired={required}
      inputSize="full"
      {...register(formKey, {
        required: {
          value: required,
          message: `${label} is required`,
        },

        pattern: pattern
          ? {
              value: pattern,
              message: `${label} must match pattern above`,
            }
          : undefined,
      })}
      errorMessage={errorMessage}
      {...props}
    />
  );
};

import { ExclamationTriangleIcon as ExclamationTriangleIconOutline } from "@heroicons/react/24/outline";
import { ArrowPathIcon, CheckIcon, ExclamationTriangleIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { formatDateTime } from "@utils/utils";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";

import { Input, Paragraph, Select, SelectItem, Small } from "@fronterahealth/frontera-ui-components";

import { convertDBString, convertReadableString } from "@components/forms/utils";

export type InputType = "text" | "date" | "datetime-local" | "select";

interface OnSaveParams {
  content: string;
}

export interface SmartInputFieldProps {
  title: string;
  placeholder?: string;
  existingContent?: string;
  onSave: (params: OnSaveParams) => Promise<void>;
  isSaving: boolean;
  displayOnlyMode?: boolean;
  inputType: InputType;
  items?: SelectItem[];
}

export const SmartInputField: React.FC<SmartInputFieldProps> = ({
  title,
  placeholder,
  existingContent,
  onSave,
  isSaving,
  displayOnlyMode,
  inputType,
  items,
}) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string | undefined>(existingContent);
  const [tempSuccessShowing, setTempSuccessShowing] = useState<boolean>(false);
  const [errorShowing, setErrorShowing] = useState<boolean>(false);

  useEffect(() => {
    if (existingContent) {
      setInputValue(existingContent);
    }
  }, [existingContent]);

  const handleSave = async () => {
    try {
      await onSave({ content: inputValue || "" });
      setTempSuccessShowing(true);
      setTimeout(() => {
        setTempSuccessShowing(false);
        setReadOnly(true);
      }, 1000);
    } catch (err) {
      console.error(`Error when saving Smart Input Field (title: ${title})`, err);
      setErrorShowing(true);
    }
  };

  if (displayOnlyMode) {
    return (
      <div className="mb-4 mt-4 flex flex-col">
        <label className="block">
          <Small className="">{title}</Small>
        </label>
        <Paragraph displayType="normal" colorType="primary">
          {inputValue}
        </Paragraph>
      </div>
    );
  }

  const getFormatedValue = (content: string): string => {
    switch (inputType) {
      case "date":
        const formattedDate = parseISO(content);
        return format(formattedDate, "MM/dd/yyyy");
      case "datetime-local":
        return formatDateTime(content as unknown as Date);
      case "select":
        return convertReadableString(content);
      default:
        return content;
    }
  };

  return (
    <div className="my-8 flex flex-col">
      <Small className="mb-1">{title}</Small>
      <div className="group relative pr-6">
        <div
          data-testid="smart-input-container"
          className={`relative flex flex-col items-center justify-center rounded-md transition-all  ${readOnly && inputValue !== "" ? "border border-limestone-200 hover:border-limestone-100 p-2" : ""}`}
        >
          <div onClick={() => setReadOnly(false)} className="hover:cursor-pointer w-full bg-white">
            {readOnly ? (
              <>
                {inputValue === "" ? (
                  <>
                    <div className="border border-limestone-200 hover:border-limestone-100 rounded-lg p-2">
                      <div className="border border-globemallow-400 bg-globemallow-50 rounded p-2">
                        <Paragraph displayType="normal" colorType="primary">
                          <>
                            <ExclamationTriangleIconOutline className="h-6 w-6 text-globemallow-500 p-1 inline mr-2" />
                            We were unable to populate this field from the supplied files. Please add it manually.
                          </>
                        </Paragraph>
                      </div>
                    </div>
                  </>
                ) : (
                  <Paragraph displayType="normal" colorType="primary">
                    {getFormatedValue(inputValue || "")}
                  </Paragraph>
                )}
              </>
            ) : (
              <>
                {inputType === "text" || inputType === "date" || inputType === "datetime-local" ? (
                  <Input
                    name=""
                    type={inputType}
                    value={inputValue}
                    label={""}
                    onChange={(e) => setInputValue(e.target.value)}
                    inputSize="full"
                    className="-mb-7 -mt-2"
                    placeholder={placeholder}
                  />
                ) : inputType === "select" && items ? (
                  <Select
                    title={""}
                    items={items}
                    placeholderText={"Select"}
                    selected={{ primary: convertReadableString(inputValue || "") }}
                    setSelected={(e) => setInputValue(convertDBString(e.primary))}
                    className="-mb-7 -mt-2"
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <div
            data-testid="smart-save-cancel-panel"
            className={`${readOnly ? "sr-only opacity-0" : "opacity-100"} absolute -bottom-8 right-0 flex items-center gap-x-2`}
          >
            <XMarkIcon
              data-testid="x-icon"
              onClick={() => {
                setReadOnly(true);
                setErrorShowing(false);
                setTempSuccessShowing(false);
              }}
              className="h-6 w-6 cursor-pointer rounded-md border border-limestone-200 bg-transparent p-1 text-text-secondary transition-all hover:bg-limestone-50 group-hover:visible"
            />
            <div
              className={`transition-all ${tempSuccessShowing ? "bg-green-100" : errorShowing ? "bg-red-100" : ""} mr-2 flex h-6 w-6 items-center justify-center rounded-md border ${tempSuccessShowing ? "border-green-700" : errorShowing ? "border-red-700" : "border-limestone-200"} hover:bg-limestone-50 group-hover:visible`}
            >
              {isSaving ? (
                <ArrowPathIcon
                  data-testid="spinner-icon"
                  className="h-6 w-6 animate-spin cursor-pointer p-1 text-text-secondary transition-all"
                />
              ) : (
                <div className="relative">
                  {errorShowing ? (
                    <ExclamationTriangleIcon
                      data-testid="error-icon"
                      className={`h-6 w-6 cursor-pointer ${errorShowing ? "text-red-700" : "text-text-secondary"} p-1 transition-all`}
                    />
                  ) : (
                    <CheckIcon
                      data-testid="check-icon"
                      onClick={handleSave}
                      className={`h-6 w-6 cursor-pointer ${tempSuccessShowing ? "text-green-700" : "text-text-secondary"} p-1 transition-all`}
                    />
                  )}
                  <span
                    className={`absolute -bottom-4 -left-8 whitespace-nowrap text-[10px] ${tempSuccessShowing ? "opacity-70" : "opacity-0"} text-green-700`}
                  >
                    field saved
                  </span>
                  <span
                    className={`absolute -bottom-4 -left-8 whitespace-nowrap text-[10px] ${errorShowing ? "opacity-70" : "opacity-0"} text-red-700`}
                  >
                    error saving
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          data-testid="smart-actions-panel"
          className={`group absolute -right-1 top-0 flex-col gap-y-4 ${readOnly && !displayOnlyMode ? "opacity-0 group-hover:opacity-100" : "pointer-events-none opacity-0"} transition-all`}
        >
          <PencilIcon
            data-testid="pencil-icon"
            onClick={() => setReadOnly(false)}
            className="h-6 w-6 cursor-pointer rounded-md border border-limestone-200 bg-transparent p-1 text-text-secondary transition-all hover:bg-limestone-50"
          />
        </div>
      </div>
    </div>
  );
};

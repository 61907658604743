/**
 *
 * This file contains the logical grouping of which Tendency questions belong to which page
 *
 * At the data layer, these are all just LearnerOnboardingQuestions with question keys that belong
 * to a massive Enum on the backend / Python Graphene side. We wanted to use one mutation on the backend side
 * (instead of having 10 different mutations for each Tendency type) and because GraphQL currently does not allow
 * Input Union types (https://github.com/graphql/graphql-spec/issues/488) we couldn't separate each enum into a separate one
 * based on the form page it belonged to
 *
 * Therefore, ALL tendencies question_keys are in one Enum = the TendenciesQuestionType - and the responsibility therefore lies
 * on the UI and the following mapping files to dictate which questions will fall on which page
 */
import { CommunicationQuestionType } from "@api/graphql/types-and-hooks";
import { LanguageObjectType } from "@components/forms/interfaces";

export type CommunicationRoutes =
  | "forms/intake_form/all"
  | "forms/intake_form/communication/verbal-communication"
  | "forms/intake_form/communication/non-verbal-communication";

const communicationSubSections = [
  "early-development",
  "current-communication",
  "receptive-language",
  "understanding-nonverbal-communication",
  "use-of-non-verbal-communication",
  "social-functioning",
  "social-interest",
  "social-skill",
  "understanding-of-social-conventions",
  "understanding-of-social-situations",
  "knowing-difference-between-friend-and-acquaintance",
] as const;

export type CommunicationSubSections = (typeof communicationSubSections)[number];

export const CommunicationSubSectionLanguageMapping: { [key in CommunicationSubSections]: LanguageObjectType } = {
  "early-development": {
    en: "Early Development",
    es: "Desarrollo Temprano",
  },
  "current-communication": {
    en: "Current Communication",
    es: "Comunicación Actual",
  },
  "receptive-language": {
    en: "Receptive Language",
    es: "Lenguaje Receptivo",
  },
  "understanding-nonverbal-communication": {
    en: "Understanding of Nonverbal Communication",
    es: "Comprensión de la Comunicación No Verbal",
  },
  "use-of-non-verbal-communication": {
    en: "Use of Non-Verbal Communication",
    es: "Uso de la Comunicación No Verbal",
  },
  "social-functioning": {
    en: "Social Functioning",
    es: "Funcionamiento Social",
  },
  "social-interest": {
    en: "Social Interest",
    es: "Interés Social",
  },
  "social-skill": {
    en: "Social Skill",
    es: "Habilidad Social",
  },
  "understanding-of-social-conventions": {
    en: "Understanding of Social Conventions",
    es: "Comprensión de las Convenciones Sociales",
  },
  "understanding-of-social-situations": {
    en: "Understanding of Social Situations",
    es: "Comprensión de las Situaciones Sociales",
  },
  "knowing-difference-between-friend-and-acquaintance": {
    en: "Knowing Difference Between a Friend and Acquaintance",
    es: "Conocer la Diferencia Entre un Amigo y un Conocido",
  },
};

export const CommunicationQuestionGroupings: {
  [key in CommunicationRoutes]: { [key in CommunicationSubSections]?: CommunicationQuestionType[] };
} = {
  "forms/intake_form/all": {
    "early-development": [
      CommunicationQuestionType.HowClientLetYouKnowWanted,
      CommunicationQuestionType.UsedHandAsTool,
      CommunicationQuestionType.TookHandAndLeadToWantedItem,
      CommunicationQuestionType.PlacedHandOnSomethingToGetManipulation,
      CommunicationQuestionType.DifferentOddLanguageUseEarlyOn,
    ],
    "current-communication": [
      CommunicationQuestionType.ExpressiveCommunication,
      CommunicationQuestionType.CommunicationChallenges,
      CommunicationQuestionType.OffWordsOrPhrases,
      CommunicationQuestionType.RepetitivePhrases,
      CommunicationQuestionType.QuotingScripting,
      CommunicationQuestionType.Neologisms,
      CommunicationQuestionType.IdiosyncraticPhrasesWords,
    ],
    "receptive-language": [
      CommunicationQuestionType.UnderstandingWhatOthersSay,
      CommunicationQuestionType.UnderstandDirectionInstructions,
      CommunicationQuestionType.FigurativeLanguageSlang,
    ],
    "understanding-nonverbal-communication": [CommunicationQuestionType.PickUpOnSocialCues],
    "use-of-non-verbal-communication": [
      CommunicationQuestionType.UseOfEyeContact,
      CommunicationQuestionType.UseOfFacialExpressions,
      CommunicationQuestionType.Gestures,
    ],
    "social-functioning": [CommunicationQuestionType.InteractingWithOthers],
    "social-interest": [CommunicationQuestionType.SocialInterest, CommunicationQuestionType.EffortsToBeWithOthers],
    "social-skill": [
      CommunicationQuestionType.InteractingWithOthersDescription,
      CommunicationQuestionType.UnderstandingPersonalSpace,
    ],
    "understanding-of-social-conventions": [
      CommunicationQuestionType.StrangerDangerOrOverlyFamiliar,
      CommunicationQuestionType.ActingDifferentlyInDifferentSituations,
    ],
    "understanding-of-social-situations": [
      CommunicationQuestionType.InterpretingBehaviorsAndWordsOfOthersCorrectly,
      CommunicationQuestionType.KnowingWhenOthersAreTakingAdvantage,
      CommunicationQuestionType.UnderstandingAccidentsAndJokes,
    ],
    "knowing-difference-between-friend-and-acquaintance": [
      CommunicationQuestionType.SeeSituationsFromDifferentPerspectives,
      CommunicationQuestionType.KnowImpactsOfActionsWords,
      CommunicationQuestionType.KnowingWhenOffensiveCommentWasMade,
    ],
  },
  "forms/intake_form/communication/verbal-communication": {
    "early-development": [
      CommunicationQuestionType.HowClientLetYouKnowWanted,
      CommunicationQuestionType.UsedHandAsTool,
      CommunicationQuestionType.TookHandAndLeadToWantedItem,
      CommunicationQuestionType.PlacedHandOnSomethingToGetManipulation,
      CommunicationQuestionType.DifferentOddLanguageUseEarlyOn,
    ],
    "current-communication": [
      CommunicationQuestionType.ExpressiveCommunication,
      CommunicationQuestionType.CommunicationChallenges,
      CommunicationQuestionType.OffWordsOrPhrases,
      CommunicationQuestionType.RepetitivePhrases,
      CommunicationQuestionType.QuotingScripting,
      CommunicationQuestionType.Neologisms,
      CommunicationQuestionType.IdiosyncraticPhrasesWords,
    ],
    "receptive-language": [
      CommunicationQuestionType.UnderstandingWhatOthersSay,
      CommunicationQuestionType.UnderstandDirectionInstructions,
      CommunicationQuestionType.FigurativeLanguageSlang,
    ],
  },
  "forms/intake_form/communication/non-verbal-communication": {
    "understanding-nonverbal-communication": [CommunicationQuestionType.PickUpOnSocialCues],
    "use-of-non-verbal-communication": [
      CommunicationQuestionType.UseOfEyeContact,
      CommunicationQuestionType.UseOfFacialExpressions,
      CommunicationQuestionType.Gestures,
    ],
    "social-functioning": [CommunicationQuestionType.InteractingWithOthers],
    "social-interest": [CommunicationQuestionType.SocialInterest, CommunicationQuestionType.EffortsToBeWithOthers],
    "social-skill": [
      CommunicationQuestionType.InteractingWithOthersDescription,
      CommunicationQuestionType.UnderstandingPersonalSpace,
    ],
    "understanding-of-social-conventions": [
      CommunicationQuestionType.StrangerDangerOrOverlyFamiliar,
      CommunicationQuestionType.ActingDifferentlyInDifferentSituations,
    ],
    "understanding-of-social-situations": [
      CommunicationQuestionType.InterpretingBehaviorsAndWordsOfOthersCorrectly,
      CommunicationQuestionType.KnowingWhenOthersAreTakingAdvantage,
      CommunicationQuestionType.UnderstandingAccidentsAndJokes,
    ],
    "knowing-difference-between-friend-and-acquaintance": [
      CommunicationQuestionType.SeeSituationsFromDifferentPerspectives,
      CommunicationQuestionType.KnowImpactsOfActionsWords,
      CommunicationQuestionType.KnowingWhenOffensiveCommentWasMade,
    ],
  },
};

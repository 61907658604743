import { InsuranceDetailsType } from "@api/graphql/types-and-hooks";
import { LanguageMappingInterface } from "@components/forms/interfaces";

export type InsuranceDetailsDataType = NonNullable<
  Omit<
    InsuranceDetailsType,
    | "id"
    | "createdDate"
    | "learner"
    | "caregiver"
    | "frontOfCardLocation"
    | "backOfCardLocation"
    | "inNetwork"
    | "lastUpdatedDate"
    | "organization"
  >
>;
export type InsuranceDataType = {
  insuranceDetails: Array<InsuranceDetailsDataType>;
};
export const insuranceMapping: LanguageMappingInterface<InsuranceDataType> = {
  insuranceDetails: {
    insuranceType: {
      en: "Insurance Type",
      es: "Tipo de Seguro",
    },
    insurer: {
      en: "Insurer",
      es: "Aseguradora",
    },
    idNumber: {
      en: "ID Number",
      es: "Número de Identificación",
    },
    groupName: {
      en: "Group Name",
      es: "Nombre del Grupo",
    },
    signedUrlOfCardFront: {
      en: "Front of Card Image",
      es: "Imagen del Frente de la Tarjeta",
    },
    signedUrlOfCardBack: {
      en: "Back of Card Image",
      es: "Imagen del Dorso de la Tarjeta",
    },
    claimsAddress: {
      en: "Claims Address Line 1",
      es: "Línea 1 de dirección de reclamos",
    },
    claimsAddressLine2: {
      en: "Claims Address Line 2",
      es: "Línea 2 de dirección de reclamos",
    },
    city: {
      en: "City",
      es: "Ciudad",
    },
    state: {
      en: "State",
      es: "Estado",
    },
    zip: {
      en: "ZIP Code",
      es: "Código Postal",
    },
    phoneNumber: {
      en: "Phone Number",
      es: "Número de Teléfono",
    },
    isPrimaryInsurance: {
      en: "Primary Insurance",
      es: "Seguro Primario",
    },
    insurancePolicyHolder: {
      en: "Primary Insurance Policy Holder",
      es: "Titular de la Póliza de Seguro Principal",
    },
    policyHolderBirthDate: {
      en: "Policy Holder Date of Birth",
      es: "Fecha de Nacimiento del Titular de la ",
    },
  },
};

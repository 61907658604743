import { format } from "date-fns";

import { Badge, BadgeProps } from "@fronterahealth/frontera-ui-components";

export const INTAKE_VALUES = ["Complete", "In Progress", "Awaiting", "Started"] as const;
export type IntakeType = (typeof INTAKE_VALUES)[number];

export interface Form {
  name: string;
  dateCompleted: Date | null;
  status: IntakeType;
}

const BadgeSettingFromStatus: { [key in IntakeType]: BadgeProps["appearance"] } = {
  Complete: "success",
  "In Progress": "neutral",
  Started: "neutral",
  Awaiting: "warn",
};

interface ClientDetailsFormsTableProps {
  forms: Form[];
  onRowClick: (form: Form) => void;
}

export const ClientDetailsFormsTable: React.FC<ClientDetailsFormsTableProps> = ({ forms, onRowClick }) => {
  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Form
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date Completed
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {forms.map((form) => (
                  <tr key={form.name} onClick={() => onRowClick(form)} className=" hover:bg-limestone-100">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {form.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {form.dateCompleted ? format(form.dateCompleted, "mm/dd/yyyy") : ""}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <Badge appearance={BadgeSettingFromStatus[form.status]} text={form.status} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

import { capitalizeString } from "@utils/utils";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Divider, Heading, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  ApiInsuranceDetailsInNetworkChoices,
  ApiInsuranceDetailsInsuranceTypeChoices,
  ApiInsuranceDetailsInsurerChoices,
  CaregiverInput,
  CreateLearnerCaregiverMutationVariables,
  useCreateLearnerCaregiverMutation,
  useGetCaregiversDataQuery,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertDBString, convertReadableString } from "@components/forms/utils";
import { notifyError } from "@components/notifications/notifications";
import {
  AvailabilityDays,
  AvailabilityTimes,
  BCBA_OPTIONS,
  NEAREST_LOCATIONS_OPTIONS,
  PSYCHOLOGIST_OPTIONS,
  REFERRAL_SOURCE_OPTIONS,
  SMSAgreementItem,
} from "@pages/NewClient/NewClient.interfaces";
import { useAdminData } from "@providers/AdminDataProvider";

type POSSIBLE_ERROR_CODES = "LEARNER_ALREADY_EXISTS" | "CAREGIVER_ALREADY_EXISTS";

type AdditionalProperty = {
  isExistingCaregiver: string;
};

const errorCodeToHumanMapping: { [key in POSSIBLE_ERROR_CODES]: (msg: string) => string } = {
  LEARNER_ALREADY_EXISTS: (msg: string) =>
    `This Client already exists in the system. Trying creating a different one or editing "${msg}" from the table`,
  CAREGIVER_ALREADY_EXISTS: (msg: string) =>
    `This Caregiver already exists in the system. Trying creating a different one or editing the client for "${msg}" from the table`,
};

const getErrorCodesFromGraphqlError = (errorObj: unknown | null | undefined): POSSIBLE_ERROR_CODES[] => {
  return (
    // NOTE: The shape of GraphQL errors can be all over the place, and so this is how it is coming off the wire now
    //
    // TODO: Fix this
    // @ts-ignore: Ignoring the compiler and risking bugs because: This isn't typed correctly but this does need improvement
    errorObj?.response?.errors?.filter((e) => e?.extensions?.error_code).map((e) => e?.extensions?.error_code) || []
  );
};

export const NewClient: React.FC = () => {
  const { learnersQuery } = useAdminData();
  const createLearnerCaregiver = useCreateLearnerCaregiverMutation({
    onSuccess: async () => {
      learnersQuery.refetch();
    },
  });
  const go = useNavigate();

  const { data, isLoading } = useGetCaregiversDataQuery(
    {},
    {
      queryKey: ["getCaregiversDataQuery"],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormRadioInput,
    RegisteredFormSelected,
    RegisteredCheckboxList,
    RegisteredPhoneNumberInput,
    watch,
    setValue,
    unregister,
  } = useFormUtils<CreateLearnerCaregiverMutationVariables & AdditionalProperty>({
    /**
     * We are complicating the mutationFn here and pulling off form data from the native
     * event (instead of relying on React Hook Form's data management) because the data structure
     * for "availability" and how we're rendering the input are fairly difficult to reconcile
     *
     * `availability` as per the data type is an array of {day: string, timeOfDay:string} objects
     * Any time React Hook Form sees an array of objects, it assumes the user will submit 0 or more
     * and expects you to use the append/fields setup from useFieldArray(). In our case, we don't actually
     * want to allow the user to additionally add an extra form field for every availability combo
     *
     * Instead, how we're rendering the input for this is a list of 4-option checkbox rows for each day of the week
     * Because of this, we do some hackery here to get the data from how it's rendered to how it needs to be submitted
     */
    mutationFn: async (params, e) => {
      // @ts-ignore: Ignoring the compiler and risking bugs because: FormData is not type correctly here
      const formData = new FormData(e?.nativeEvent?.target);
      const slots = Array.from(formData.entries())
        .filter(([name]) => name.includes("availability"))
        // this split on "." works because our formKey for these Form inputs look like this
        // `availability.${day}` and the value is "morning" | "afternoon"  | etc
        .map(([name, value]) => ({
          day: name.split(".").pop()?.toUpperCase() || "",
          timeOfDay: (value as string).toUpperCase(),
        }));

      const { learner, insurance, caregiver } = params;

      learner.diagnosisAppointmentDate === "" && delete learner.diagnosisAppointmentDate;
      learner.assessmentAppointmentDate === "" && delete learner.assessmentAppointmentDate;

      // @ts-ignore: Ignoring the compiler and risking bugs because: see above
      learner.hasPreviousDiagnosis === "yes"
        ? (learner.hasPreviousDiagnosis = true)
        : (learner.hasPreviousDiagnosis = false);

      const newParams = {
        learner,
        insurance: {
          ...insurance,
          insuranceType: insurance?.insuranceType ? convertDBString(insurance.insuranceType) : null,
          insurer: insurance?.insurer ? convertDBString(insurance.insurer) : null,
          inNetwork: insurance?.inNetwork ? convertDBString(insurance?.inNetwork) : null,
        },
        caregiver: {
          ...caregiver,
          smsAgreement: caregiver.smsAgreement === ("yes" as unknown as boolean),
        },
        availability: { slots },
      };
      await createLearnerCaregiver!.mutateAsync(newParams as CreateLearnerCaregiverMutationVariables);
      go("/clients");
    },
    defaultValues: {
      learner: {
        // @ts-ignore: Ignoring the compiler and risking bugs because: see above
        hasPreviousDiagnosis: "no",
      },
      isExistingCaregiver: "no",
    },
  });

  // @ts-ignore: Ignoring the compiler and risking bugs because: see above
  const clientHasPreviousDiagnosis = watch("learner.hasPreviousDiagnosis") === "yes";

  useEffect(() => {
    const submitErrors = getErrorCodesFromGraphqlError(createLearnerCaregiver?.error);
    let errorThrown = false;
    submitErrors.forEach((errorType) => {
      switch (errorType) {
        case "CAREGIVER_ALREADY_EXISTS": {
          const { firstName, lastName } = createLearnerCaregiver?.variables?.caregiver || {};
          const msg = errorCodeToHumanMapping[errorType](`${firstName} ${lastName}`);
          notifyError(msg);
          errorThrown = true;
          return;
        }
        case "LEARNER_ALREADY_EXISTS": {
          const { firstName, lastName } = createLearnerCaregiver?.variables?.learner || {};
          const msg = errorCodeToHumanMapping[errorType](`${firstName} ${lastName}`);
          notifyError(msg);
          errorThrown = true;
          return;
        }
        default: {
          notifyError("Something went wrong, please try again later");
        }
      }
    });

    if (submitErrors.filter((e) => !!e).length === 0 && createLearnerCaregiver?.error && !errorThrown) {
      notifyError("Something went wrong, please try again later");
    }
  }, [createLearnerCaregiver?.error]);

  const caregivers = data?.caregivers?.edges.length ? data.caregivers.edges.map((item) => ({ ...item?.node })) : [];

  const isExistingCaregiver = watch("isExistingCaregiver");

  const selectedCaregiverID = isExistingCaregiver === "yes" ? watch("caregiver.caregiverId") : null;
  useEffect(() => {
    if (selectedCaregiverID) {
      const caregiver = caregivers.find((caregiver) => caregiver.id === selectedCaregiverID);
      if (caregiver) {
        setValue("caregiver", {
          caregiverId: caregiver.id,
          firstName: caregiver.caregivermetadata?.firstName,
          lastName: caregiver.caregivermetadata?.lastName,
          email: caregiver.caregivermetadata?.email,
          nearestLocation: caregiver.caregivermetadata?.nearestLocation,
          phoneNumber: caregiver.caregivermetadata?.phoneNumber1,
          referralSource: caregiver.caregivermetadata?.referralSource,
          smsAgreement: caregiver.caregivermetadata?.smsAgreement ? "yes" : "no",
        } as unknown as CaregiverInput);
      }
    }
    if (!selectedCaregiverID) {
      unregister("caregiver.caregiverId", { keepDefaultValue: true });
    }
  }, [selectedCaregiverID, unregister]);

  return (
    <FormContainer onSubmit={(e) => onSubmit(e)}>
      <div className="mt-10 flex w-full flex-col">
        <div className="flex w-full items-start">
          <div className="grid w-full grid-cols-5 ">
            <div className="col-span-2 flex flex-col">
              <Heading type="h2">Client Details</Heading>
            </div>
            <div className="col-span-3 flex flex-col">
              <div className="gap-x-4  lg:grid lg:grid-cols-2">
                <RegisteredFormInput
                  formKey="learner.firstName"
                  inputSize="full"
                  formState={formState}
                  label="First Name"
                />
                <RegisteredFormInput
                  formKey="learner.lastName"
                  inputSize="full"
                  formState={formState}
                  label="Last Name"
                />
              </div>

              <div className="gap-x-4 lg:grid lg:grid-cols-2">
                <RegisteredFormInput
                  formKey="learner.birthDate"
                  inputSize="full"
                  type="date"
                  formState={formState}
                  label="Date of Birth"
                />
                <div />
              </div>

              <div className="gap-x-4 lg:grid lg:grid-cols-2">
                <RegisteredFormRadioInput
                  formState={formState}
                  formKey="learner.hasPreviousDiagnosis"
                  title="Has Previous Diagnosis"
                  items={[
                    {
                      id: "no",
                      title: "No",
                    },
                    {
                      id: "yes",
                      title: "Yes",
                    },
                  ]}
                />
                <div />
              </div>

              <div className="gap-x-4 lg:grid lg:grid-cols-2">
                {clientHasPreviousDiagnosis ? (
                  <>
                    <RegisteredFormInput
                      formKey="learner.assessmentAppointmentDate"
                      inputSize="full"
                      type="datetime-local"
                      formState={formState}
                      required={false}
                      label="Assessment Appointment Date"
                    />

                    <RegisteredFormSelected
                      formKey="learner.assessmentBcba"
                      formState={formState}
                      placeholderText={""}
                      title="BCBA for Assessment"
                      required={false}
                      errorMessage={formState.errors.learner?.assessmentBcba?.message}
                      items={BCBA_OPTIONS.map((o) => ({ primary: o }))}
                    />
                  </>
                ) : (
                  <>
                    <RegisteredFormInput
                      formKey="learner.diagnosisAppointmentDate"
                      inputSize="full"
                      required={false}
                      type="datetime-local"
                      formState={formState}
                      label="Diagnosis Appointment Date"
                    />

                    <RegisteredFormSelected
                      formKey="learner.diagnosisPsychologist"
                      formState={formState}
                      placeholderText={""}
                      required={false}
                      // required={!clientHasPreviousDiagnosis}
                      title="Psychologist for Diagnosis"
                      errorMessage={formState.errors.learner?.diagnosisPsychologist?.message}
                      items={PSYCHOLOGIST_OPTIONS.map((o) => ({ primary: o }))}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="mt-10 flex w-full items-start">
          <div className="grid w-full grid-cols-5">
            <div className="col-span-2 flex flex-col">
              <Heading type="h2">Caregiver Details</Heading>
            </div>
            <div className="col-span-3 flex flex-col">
              <DoubleColumnContainer>
                <RegisteredFormRadioInput
                  formState={formState}
                  formKey="isExistingCaregiver"
                  title="Existing Caregiver?"
                  items={[
                    {
                      id: "yes",
                      title: "Yes",
                    },
                    {
                      id: "no",
                      title: "No",
                    },
                  ]}
                />
              </DoubleColumnContainer>
              {isExistingCaregiver === "yes" && (
                <RegisteredFormSelected
                  formKey="caregiver.caregiverId"
                  formState={formState}
                  placeholderText={""}
                  title="Select Existing Caregiver"
                  isLoading={isLoading}
                  items={
                    caregivers
                      .sort((a, b) =>
                        (a.caregivermetadata?.firstName || "") > (b.caregivermetadata?.firstName || "") ? 1 : -1,
                      )
                      .map((o) => ({
                        primary: `${o?.caregivermetadata?.firstName} ${o?.caregivermetadata?.lastName}`,
                        secondary: o.caregivermetadata?.email,
                        id: o?.id,
                      })) as unknown as SelectItem[]
                  }
                />
              )}
              <div className="gap-x-4  lg:grid lg:grid-cols-2">
                <RegisteredFormInput
                  formKey="caregiver.firstName"
                  inputSize="full"
                  formState={formState}
                  label="First Name"
                  readOnly={selectedCaregiverID !== null && selectedCaregiverID !== undefined}
                />
                <RegisteredFormInput
                  formKey="caregiver.lastName"
                  inputSize="full"
                  formState={formState}
                  label="Last Name"
                  readOnly={selectedCaregiverID !== null && selectedCaregiverID !== undefined}
                />
              </div>

              <div className="gap-x-4 lg:grid lg:grid-cols-2">
                <RegisteredFormInput
                  formKey="caregiver.email"
                  inputSize="full"
                  helpText="This may be used for login. Ensure this is correct"
                  formState={formState}
                  label="Email Address"
                  readOnly={selectedCaregiverID !== null && selectedCaregiverID !== undefined}
                />
                <div />
              </div>

              <div className="gap-x-4 lg:grid lg:grid-cols-2">
                <RegisteredPhoneNumberInput
                  formKey="caregiver.phoneNumber"
                  formState={formState}
                  defaultCountry="US"
                  label="Phone Number"
                  readOnly={selectedCaregiverID !== null && selectedCaregiverID !== undefined}
                  required={true}
                />
                <div />
              </div>

              <div className="gap-x-4 lg:grid lg:grid-cols-2">
                <RegisteredFormSelected
                  formKey="caregiver.nearestLocation"
                  formState={formState}
                  placeholderText={""}
                  title="Nearest Location"
                  errorMessage={formState.errors.caregiver?.nearestLocation?.message}
                  items={NEAREST_LOCATIONS_OPTIONS.map((o) => ({ primary: o }))}
                  readOnly={selectedCaregiverID !== null && selectedCaregiverID !== undefined}
                />
                <div />
              </div>

              <div className="gap-x-4 lg:grid lg:grid-cols-2">
                <RegisteredFormSelected
                  formKey="caregiver.referralSource"
                  formState={formState}
                  placeholderText={""}
                  required={false}
                  title="Referral Source"
                  items={REFERRAL_SOURCE_OPTIONS.map((o) => ({ primary: o }))}
                  readOnly={selectedCaregiverID !== null && selectedCaregiverID !== undefined}
                />
                <div />
              </div>
              <div className="gap-x-4 lg:grid lg:grid-cols-2">
                <RegisteredCheckboxList
                  formKey="caregiver.smsAgreement"
                  formState={formState}
                  required={false}
                  title="SMS Agreement"
                  items={SMSAgreementItem}
                  readOnly={selectedCaregiverID !== null && selectedCaregiverID !== undefined}
                />
                <div />
              </div>
            </div>
          </div>
        </div>

        <Divider />
        <div className="mt-10 flex w-full items-start">
          <div className="flex w-full items-start">
            <div className="grid w-full grid-cols-5">
              <Heading className="col-span-2" type="h2">
                Insurance
              </Heading>
              <div className="col-span-3 flex flex-col">
                <div className="gap-x-4 lg:grid lg:grid-cols-2">
                  <RegisteredFormSelected
                    formKey={`insurance.insuranceType`}
                    title="Insurance Type"
                    formState={formState}
                    errorMessage={formState.errors.insurance?.insuranceType?.message}
                    items={Object.values(ApiInsuranceDetailsInsuranceTypeChoices).map((o) => ({
                      primary: convertReadableString(o),
                    }))}
                    placeholderText={""}
                  />
                  <div />
                </div>

                <div className="gap-x-4 lg:grid lg:grid-cols-2">
                  <RegisteredFormSelected
                    formKey={`insurance.insurer`}
                    title="Insurer"
                    errorMessage={formState.errors.insurance?.insurer?.message}
                    formState={formState}
                    items={Object.values(ApiInsuranceDetailsInsurerChoices).map((o) => ({
                      primary: convertReadableString(o),
                    }))}
                    placeholderText={""}
                  />
                  <div />

                  <RegisteredFormRadioInput
                    formState={formState}
                    formKey="insurance.inNetwork"
                    title="In-Network"
                    items={Object.keys(ApiInsuranceDetailsInNetworkChoices).map((o) => {
                      return {
                        title: convertReadableString(o),
                        id: o,
                      };
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />
        <div className="mt-10 flex w-full items-start">
          <div className="flex w-full items-start">
            <div className="grid w-full grid-cols-5">
              <Heading className="col-span-2" type="h2">
                Availability
              </Heading>
              <div className="col-span-3 flex flex-col">
                {AvailabilityDays.map((day) => {
                  const formattedDay = capitalizeString(day);
                  return (
                    <RegisteredCheckboxList
                      key={formattedDay}
                      // @ts-ignore: Ignoring the compiler and risking bugs because: We are overriding how the React Hook Form typings work
                      formKey={`availability.${day}`}
                      title={formattedDay}
                      required={false}
                      legend={formattedDay}
                      orientation="horizontal"
                      items={AvailabilityTimes}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <SubmitButton isLoading={formState.isSubmitting} buttonText="Save & Continue" />
      </div>
    </FormContainer>
  );
};

import { faker } from "@faker-js/faker";
import { GenderApiValues } from "@utils/constants";
import { getGenderValue, setGenderValue } from "@utils/helpers";
import { formatDateYYYY_MM_DD } from "@utils/utils";
import { format } from "date-fns";
import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Heading, SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  AddReportClientDetailsMutationVariables,
  useAddReportClientDetailsMutation,
  useUpdateReportClientDetailsMutation,
} from "@api/graphql/types-and-hooks";
import { DevOnlyWrapper } from "@components/DevOnlyWrapper/DevOnlyWrapper";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { EvaluationSubRoute, getNextRoute } from "@pages/EvaluationDetails/EvaluationDetails";
import { FooterButtonRow } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/FooterButtonRow";
import { useEvaluationData } from "@providers/EvaluationProvider";

interface RowProps extends PropsWithChildren {
  title: string;
  isLast?: boolean;
}
const Row: React.FC<RowProps> = ({ title, children }) => {
  return (
    <div className="py-6 flex w-full flex-col items-end">
      <div className="grid w-full grid-cols-8 items-start">
        <div className="col-span-3 flex flex-col">
          <Heading type="h4">{title}</Heading>
        </div>
        <div className="col-span-5 flex flex-col bg-white px-8 py-6 rounded-3xl">{children}</div>
      </div>
    </div>
  );
};

export const EvaluationClientDetails: React.FC = () => {
  const { evaluationQuery } = useEvaluationData();

  const evaluation = evaluationQuery?.data?.getReports?.edges[0]?.node;
  const learnerId = evaluation?.learner?.id || null;
  const go = useNavigate();
  const { pathname } = useLocation();

  const addEvaluationClientDetailsMutation = useAddReportClientDetailsMutation({});
  const updateEvaluationClientDetailsMutation = useUpdateReportClientDetailsMutation({});

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as EvaluationSubRoute) || ("upload-files" as EvaluationSubRoute);
  const nextRoute = getNextRoute(currentRoute);

  const { isPending: saveCallLoading } = addEvaluationClientDetailsMutation;
  const { isPending: updateCallLoading } = updateEvaluationClientDetailsMutation;

  const { onSubmit, formState, RegisteredFormInput, RegisteredFormSelected, reset, setValue, trigger, watch } =
    useFormUtils<AddReportClientDetailsMutationVariables>({
      mutationFn: async (params) => {
        if (!learnerId) {
          await addEvaluationClientDetailsMutation.mutateAsync(
            {
              reportId: evaluation?.id ?? "<missing-evaluation-id>",
              learnerCaregiverMinimalInput: {
                ...params.learnerCaregiverMinimalInput,
                gender: setGenderValue(params.learnerCaregiverMinimalInput.gender),
              },
            },
            {
              onSuccess: async () => {
                await evaluationQuery.refetch();
                notifySuccess("Successfully Created Client");
                go(`../${nextRoute}`);
              },
              onError: async (error) => {
                console.error("Error creating client", error);
                notifyError("Error Creating Client");
              },
            },
          );
        } else {
          await updateEvaluationClientDetailsMutation.mutateAsync(
            {
              reportId: evaluation?.id ?? "<missing-evaluation-id>",
              learnerCaregiverMinimalInput: {
                ...params.learnerCaregiverMinimalInput,
                gender: setGenderValue(params.learnerCaregiverMinimalInput.gender),
              },
            },
            {
              onSuccess: async () => {
                await evaluationQuery.refetch();
                notifySuccess("Successfully Updated Client Details");
                go(`../${nextRoute}`);
              },
              onError: async (error) => {
                console.error("Error updating client details", error);
                notifyError("Error Updating Client Details");
              },
            },
          );
        }
      },
    });

  useEffect(() => {
    if (evaluation) {
      const formattedGender = getGenderValue(evaluation?.reportClientDetails?.gender) || "";
      reset({
        learnerCaregiverMinimalInput: {
          clientFirstName: evaluation?.reportClientDetails?.clientFirstName,
          clientLastName: evaluation?.reportClientDetails?.clientLastName || "",
          birthDate: evaluation?.reportClientDetails?.birthDate,
          gender: formattedGender,
          specifyGender: evaluation?.reportClientDetails?.specifyGender || "",
          address: evaluation?.reportClientDetails?.address || "",
          addressLine2: evaluation?.reportClientDetails?.addressLine2,
          city: evaluation?.reportClientDetails?.city,
          state: evaluation?.reportClientDetails?.state,
          zip: evaluation?.reportClientDetails?.zip,
          caregiverFirstName: evaluation?.reportClientDetails?.guardianFirstName,
          caregiverLastName: evaluation?.reportClientDetails?.guardianLastName || "",
        },
      });
    } else {
      reset({ learnerCaregiverMinimalInput: {} });
    }
  }, [evaluation]);

  const genderWatch = watch("learnerCaregiverMinimalInput.gender");

  const isReadOnly = false;
  const isPending = saveCallLoading || updateCallLoading;

  return (
    <div className="flex w-full flex-col ">
      <FormContainer onSubmit={onSubmit}>
        <Row title="Client Details">
          {!isReadOnly ? (
            <DevOnlyWrapper>
              <div className="my-4 flex items-center">
                <Button
                  appearance="link"
                  className="mr-2"
                  text="Fill Fields (dev only)"
                  onClick={() => {
                    setValue("learnerCaregiverMinimalInput.clientFirstName", `(fake) ${faker.person.firstName()}`);
                    setValue("learnerCaregiverMinimalInput.clientLastName", `(fake) ${faker.person.lastName()}`);
                    setValue(`learnerCaregiverMinimalInput.birthDate`, format(faker.date.birthdate(), "yyyy-MM-dd"), {
                      shouldValidate: true,
                    });
                    setValue(
                      "learnerCaregiverMinimalInput.gender",
                      `${faker.helpers.arrayElement(["Boy/man", "Girl/woman"])}`,
                    );
                    setValue("learnerCaregiverMinimalInput.address", `${faker.location.streetAddress()}`);
                    setValue("learnerCaregiverMinimalInput.addressLine2", `${faker.location.secondaryAddress()}`);
                    setValue("learnerCaregiverMinimalInput.city", `${faker.location.city()}`);
                    setValue("learnerCaregiverMinimalInput.state", `${faker.location.state()}`);
                    setValue("learnerCaregiverMinimalInput.zip", `${faker.location.zipCode()}`);
                    setValue("learnerCaregiverMinimalInput.caregiverFirstName", `(fake) ${faker.person.firstName()}`);
                    setValue("learnerCaregiverMinimalInput.caregiverLastName", `(fake) ${faker.person.lastName()}`);
                    trigger();
                  }}
                />
                <Button
                  appearance="link"
                  text="Clear Fields (dev only)"
                  onClick={() => {
                    reset();
                    trigger();
                  }}
                />
              </div>
            </DevOnlyWrapper>
          ) : null}
          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.clientFirstName"
                inputSize="full"
                formState={formState}
                label="Client’s First Name"
                readOnly={isReadOnly}
              />
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.clientLastName"
                inputSize="full"
                formState={formState}
                label="Client's Last Name"
                readOnly={isReadOnly}
              />
            </div>
          </div>
          <div className="gap-x-4 lg:grid lg:grid-cols-2">
            <RegisteredFormInput
              formKey="learnerCaregiverMinimalInput.birthDate"
              inputSize="full"
              type="date"
              formState={formState}
              max={formatDateYYYY_MM_DD(new Date())}
              label="Date of Birth"
              readOnly={isReadOnly}
            />
            <div />
          </div>
          {isReadOnly ? (
            <DoubleColumnContainer>
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.gender"
                formState={formState}
                label="Gender"
                readOnly
                inputSize="full"
              />
              {genderWatch === "Gender identity is not listed" && (
                <RegisteredFormInput
                  formKey="learnerCaregiverMinimalInput.specifyGender"
                  formState={formState}
                  required={false}
                  label="Specify Gender"
                  readOnly
                  inputSize="full"
                />
              )}
            </DoubleColumnContainer>
          ) : (
            <DoubleColumnContainer>
              <RegisteredFormSelected
                formKey="learnerCaregiverMinimalInput.gender"
                formState={formState}
                placeholderText={""}
                title="Gender"
                readOnly={isReadOnly}
                items={
                  Object.values(GenderApiValues).map((o) => ({
                    primary: getGenderValue(o),
                  })) as SelectItem[]
                }
              />
              {genderWatch === "Gender identity is not listed" && (
                <RegisteredFormInput
                  formKey="learnerCaregiverMinimalInput.specifyGender"
                  formState={formState}
                  required={false}
                  label="Specify Gender"
                  readOnly={isReadOnly}
                />
              )}
            </DoubleColumnContainer>
          )}
          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.address"
                inputSize="full"
                formState={formState}
                label="Address Line 1"
                readOnly={isReadOnly}
              />
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.addressLine2"
                inputSize="full"
                required={false}
                formState={formState}
                label="Address Line 2"
                readOnly={isReadOnly}
              />
            </div>
          </div>
          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.city"
                inputSize="full"
                formState={formState}
                label="City"
                readOnly={isReadOnly}
              />

              <div className="gap-x-4  lg:grid lg:grid-cols-2">
                <RegisteredFormInput
                  formKey="learnerCaregiverMinimalInput.state"
                  inputSize="full"
                  formState={formState}
                  label="State / Province"
                  readOnly={isReadOnly}
                />
                <RegisteredFormInput
                  formKey="learnerCaregiverMinimalInput.zip"
                  inputSize="full"
                  formState={formState}
                  label="ZIP / Postal Code"
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>

          <div className="col-span-3 flex flex-col">
            <div className="gap-x-4  lg:grid lg:grid-cols-2">
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.caregiverFirstName"
                inputSize="full"
                formState={formState}
                label="Guardian’s First Name"
                readOnly={isReadOnly}
              />
              <RegisteredFormInput
                formKey="learnerCaregiverMinimalInput.caregiverLastName"
                inputSize="full"
                formState={formState}
                label="Guardian’s Last Name"
                readOnly={isReadOnly}
              />
            </div>
          </div>
        </Row>

        <FooterButtonRow primaryButtonTitle={"Continue"} primaryButtonType="submit" primaryButtonLoading={isPending} />
      </FormContainer>
    </div>
  );
};

import { PropsWithChildren } from "react";

/**
 * Desktop: Each child takes up 1/2 of available width
 * Mobile: Each Takes up the full width on it's own row
 * @param
 * @returns
 */
export const DoubleColumnContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="gap-x-4 lg:grid lg:grid-cols-2">
      {children}
      <div />
    </div>
  );
};

/**
 * Desktop: Each child takes up 1/2 of available width
 * Mobile: Each Takes up the full width on it's own row
 * @param
 * @returns
 */
export const TripleColumnHeader: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="gap-x-4 lg:grid lg:grid-cols-3">{children}</div>;
};

export const FormSpacer = () => {
  return <div className="min-h-12" />;
};

interface FormContainerProps extends PropsWithChildren, React.FormHTMLAttributes<HTMLFormElement> {
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
}
export const FormContainer: React.FC<FormContainerProps> = ({ onSubmit, children, ...props }) => {
  return (
    <form onSubmit={onSubmit} className="flex w-full flex-col justify-start" {...props}>
      {children}
    </form>
  );
};

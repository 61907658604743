// src/CustomTooltip.tsx
import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/outline";
import {
  HandThumbDownIcon as SolidThummbDownIcon,
  HandThumbUpIcon as SolidThummbUpIcon,
} from "@heroicons/react/24/solid";
import { formatSecondsToHHMMSS } from "@utils/utils";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";

import { Button } from "@fronterahealth/frontera-ui-components";

import { ApiSessionEngagementInsightInsightTypeChoices } from "@api/graphql/types-and-hooks";
import { AiFeedbackDialog } from "@components/AiFeedbackDialog/AiFeedbackDialog";

interface GraphTooltipProps {
  engagement: number;
  engagementType: string;
  handleSeekTo?: (time: number, endTime: number) => void;
  isTooltipInteracting: boolean;
  setIsTooltipInteracting: (val: boolean) => void;
  handleCloseTooltip?: (val: boolean) => void;
  insight?: string;
  timeInSeconds: number;
  endTimeInSeconds: number;
}

export const GraphTooltip: React.FC<GraphTooltipProps> = ({
  handleSeekTo,
  isTooltipInteracting,
  setIsTooltipInteracting,
  handleCloseTooltip,
  insight,
  timeInSeconds,
  endTimeInSeconds,
  engagementType,
}) => {
  const { sessionId } = useParams();

  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState<boolean>(false);

  const [aiFeedbackVote, setAiFeedbackVote] = useState<boolean>(false);

  const [aiFeedbackDialogOpen, setAiFeedbackDialogOpen] = useState<boolean>(false);

  const onAiFeedbackVote = (vote: boolean) => {
    setAiFeedbackVote(vote);
    setAiFeedbackDialogOpen(true);
  };

  return (
    <div className="bg-white py-4 px-4 rounded-lg shadow-card-shadow w-96 grid gap-2 z-20">
      {aiFeedbackDialogOpen && (
        <AiFeedbackDialog
          isDialogOpen={aiFeedbackDialogOpen}
          setDialogOpen={setAiFeedbackDialogOpen}
          positiveFeedback={aiFeedbackVote}
          objectType="sessionAnalyzer"
          time={formatSecondsToHHMMSS(timeInSeconds)}
          objectId={sessionId || null}
          handleSubmittedFeedback={() => {
            setIsFeedbackSubmitted(true);
          }}
        />
      )}

      <div className="flex flex-row justify-between items-center">
        <p className="flex text-limestone-600 text-sm">{`${engagementType === ApiSessionEngagementInsightInsightTypeChoices.Disengagement ? "Disengagement" : "Engagement"}`}</p>
        <Button
          text={`${formatSecondsToHHMMSS(timeInSeconds)}-${formatSecondsToHHMMSS(endTimeInSeconds)}`}
          appearance="link"
          className="text-sm font-normal"
          onClick={() => (handleSeekTo ? handleSeekTo(timeInSeconds, endTimeInSeconds) : {})}
        />
      </div>
      <div className="flex text-limestone-900 text-sm text-wrap">{insight}</div>

      <div className="flex flex-row justify-center items-center relative">
        <Button
          text={isTooltipInteracting ? "Close" : "Click to interact"}
          appearance="link"
          onClick={() => {
            setIsTooltipInteracting(!isTooltipInteracting);
            if (isTooltipInteracting && handleCloseTooltip) handleCloseTooltip(false);
          }}
        />
        {isTooltipInteracting && (
          <div className="absolute right-2 p-2 rounded-lg border-limestone-100 border flex flex-row justify-between items-center">
            {isFeedbackSubmitted && aiFeedbackVote ? (
              <SolidThummbUpIcon
                className="h-5 w-5 mr-3 text-limestone-500 cursor-pointer"
                onClick={() => onAiFeedbackVote(true)}
              />
            ) : (
              <HandThumbUpIcon
                className="h-5 w-5 mr-3 text-limestone-500 cursor-pointer"
                onClick={() => onAiFeedbackVote(true)}
              />
            )}
            {isFeedbackSubmitted && !aiFeedbackVote ? (
              <SolidThummbDownIcon
                className="h-5 w-5 mr-3 text-limestone-500 cursor-pointer"
                onClick={() => onAiFeedbackVote(true)}
              />
            ) : (
              <HandThumbDownIcon
                className="h-5 w-5 text-limestone-500 cursor-pointer"
                onClick={() => onAiFeedbackVote(false)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const renderTooltip = (props: GraphTooltipProps) => {
  const container = document.createElement("div");
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(<GraphTooltip {...props} />, container);
  const htmlString = container.innerHTML;
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.unmountComponentAtNode(container);
  return htmlString;
};

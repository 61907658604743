import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Heading } from "@fronterahealth/frontera-ui-components";

import {
  CommunicationQuestionType,
  LearnerCommunicationQuery,
  useLearnerCommunicationQuery,
} from "@api/graphql/types-and-hooks";
import { useFormUtils } from "@components/forms/useFormUtils";
import { QuestionData, convertOnboardingQuestionListToQuestionMap } from "@components/forms/utils";
import {
  CommunicationQuestionGroupings,
  CommunicationRoutes,
  CommunicationSubSectionLanguageMapping,
  CommunicationSubSections,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Communication/CommunicationQuestionGroupings";
import { communicationQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Communication/CommunicationQuestions.languageMapping";
import { extractPath } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const Communication: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();

  const subpage = pathname.split("/").pop();
  const extractedPath = extractPath(pathname);

  const customQueryKey = pathname.includes("all") ? "/communication" : undefined;

  const questionPageOrigin = pathname.includes("all") ? undefined : `communication/${subpage}`;

  const config = useGetQueryConfig<LearnerCommunicationQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerCommunicationQuery(
    { learnerId: clientId ? clientId : "", questionPageOrigin },
    config,
  );
  const { data } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormTextArea, reset } = useFormUtils<{
    [key in CommunicationQuestionType]: string;
  }>({
    // TODO: Needs to be better tested, documented
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  useEffect(() => {
    if (data) {
      const communicationQuestions = data.learnerById?.onboardingQuestionsForLearner.edges
        .filter((question) => question?.node?.questionPageOrigin?.includes("communication"))
        .map((edge) => ({
          ...edge?.node,
        }));

      const formattedQuestions = convertOnboardingQuestionListToQuestionMap(communicationQuestions as QuestionData[]);

      reset(formattedQuestions);
    }
  }, [data, reset]);

  return (
    <form onSubmit={onSubmit} className="flex min-h-[500px] flex-col justify-between px-2" noValidate>
      <div className="flex-col">
        {Object.entries(CommunicationQuestionGroupings[extractedPath as CommunicationRoutes]).map(
          ([subsection, questionArray]) => {
            return (
              <div key={subsection} className="flex flex-col">
                <Heading type="h3" className="my-4">
                  {CommunicationSubSectionLanguageMapping[subsection as CommunicationSubSections][chosenLanguage]}
                </Heading>
                {questionArray.map((q) => {
                  return (
                    <RegisteredFormTextArea
                      key={q}
                      label={communicationQuestionsMapping[q][chosenLanguage]}
                      // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                      formKey={`${q}`}
                      formState={formState}
                      readOnly
                    />
                  );
                })}
              </div>
            );
          },
        )}
      </div>
    </form>
  );
};

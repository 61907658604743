import { FeatureFlags } from "@utils/FeatureFlags/generated-flags";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ApiReportSectionsAiGeneratedStatusChoices, ReportPageLocationEnums } from "@api/graphql/types-and-hooks";
import { SupportedContentType } from "@components/SectionWidget/SectionWidget";
import { AddSection } from "@pages/EvaluationDetails/Components/AddSection";
import { SectionWidgetWrapper } from "@pages/EvaluationDetails/Components/SectionWidgetWrapper";
import {
  FilterSectionSubRoute,
  useGetFilteredPageSectionsByLocation,
} from "@pages/EvaluationDetails/Components/helper";
import { getNextRoute } from "@pages/EvaluationDetails/EvaluationDetails";
import { ConfirmGenerationDialog } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/EvaluationUploadFiles/ConfirmGenerationDialog/ConfirmGenerationDialog";
import { FooterButtonRow } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/FooterButtonRow";
import { StickyNav } from "@pages/EvaluationDetails/EvaluationDetailsSubPages/StickyNav/StickyNav";

const pageLocation: FilterSectionSubRoute = "report-part-2";

export const EvaluationReportPart2: React.FC = () => {
  const filteredSections = useGetFilteredPageSectionsByLocation(pageLocation);
  const go = useNavigate();
  const flags = useFlags<FeatureFlags>();

  const [promptDialogOpen, setPromptDialogOpen] = React.useState<boolean>(false);

  const nextPage = getNextRoute(pageLocation) as FilterSectionSubRoute;
  const nextPageFilteredSections = useGetFilteredPageSectionsByLocation(nextPage);
  const nextPageAlreadyGenerated = nextPageFilteredSections.some(
    (section) =>
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending ||
      section.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPredicted,
  );

  const handlePrimaryButtonAction = async () => {
    if (!nextPageAlreadyGenerated) setPromptDialogOpen(true);
    else go(`../${nextPage}`);
  };

  const supportedContentTypes = ["Text", "Table"] as SupportedContentType[];
  if (flags["upload-images-component"]) {
    supportedContentTypes.push("Image");
  }

  return (
    <div className="flex w-full flex-col pb-6">
      <ConfirmGenerationDialog
        type={ReportPageLocationEnums.Conclusions}
        promptDialogOpen={promptDialogOpen}
        setPromptDialogOpen={setPromptDialogOpen}
      />
      <div className="grid grid-cols-4 gap-x-1">
        <div className={`col-span-3 flex w-full flex-col gap-y-4`}>
          <AddSection newRankOrder={1} defaultNewSectionTitle="Enter Section Title" pageLocation={pageLocation} />
          {filteredSections.map((section) => (
            <div key={section.id}>
              <SectionWidgetWrapper
                section={section}
                permissions={{
                  canDelete: true,
                  canEdit: true,
                }}
                supportedContentTypes={supportedContentTypes}
              />
              <AddSection
                newRankOrder={section.rankOrder + 1}
                defaultNewSectionTitle="Enter Section Title"
                pageLocation={pageLocation}
              />
            </div>
          ))}
        </div>

        <div className="flex w-full flex-col pl-8">
          <StickyNav state={filteredSections} />
        </div>
      </div>
      <FooterButtonRow primaryButtonTitle="Next" primaryButtonAction={handlePrimaryButtonAction} skipNavigate />
    </div>
  );
};

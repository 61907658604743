import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { Button, Heading } from "@fronterahealth/frontera-ui-components";

import {
  ApiReportReportStatusChoices,
  ApiReportSectionsAiGeneratedStatusChoices,
  AssessmentToolType,
  GetReportsQuery,
  ReportSectionsType,
  ReportType,
  ReportTypeEnums,
  useAssessmentToolsQuery,
  useGetReportsQuery,
} from "@api/graphql/types-and-hooks";
import { Evaluation, EvaluationsTable, StatusType } from "@components/EvaluationsReportsTable/EvaluationsReportsTable";
import { NewEvaluationDialog } from "@components/NewEvaluationDialog/NewEvaluationDialog";
import { RecommendationsBank } from "@components/RecommendationsBank/RecommendationsBank";
import "@components/notifications/notifications.css";

export const getNumberOfPagesDone = (report: ReportType, supportedAssessmentsList: AssessmentToolType[]): number => {
  let pagesDone = 1;

  const completedFiles =
    report?.reportFiles?.edges?.map((edge) => edge?.node)?.filter((file) => file?.status !== "PENDING") ?? [];

  const assessmentIds = new Set(supportedAssessmentsList.map((assessment) => assessment.id));
  const existingAssessmentsFound = completedFiles.some((file) => assessmentIds.has(file?.fileKind));

  if (report?.reportClientDetails) pagesDone = 2;
  if (existingAssessmentsFound) pagesDone = 2;
  else return pagesDone;

  const getFilteredSections = (location: string) =>
    report.reportSections.edges.map((edge) => edge?.node).filter((section) => section?.pageLocation === location);

  const isSectionStatus = (sections: ReportSectionsType[], status: string): boolean =>
    sections.some((section) => section?.aiGeneratedStatus === status);

  const checkAndSetPagesDone = (pageLocation: string, step: number): boolean => {
    const sections = getFilteredSections(pageLocation);
    if (!sections.length) return false;

    if (
      isSectionStatus(
        sections as ReportSectionsType[],
        ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
      )
    ) {
      pagesDone = step;
      return true;
    }

    pagesDone = step;
    return false;
  };

  if (checkAndSetPagesDone("report-part-1", 3)) return pagesDone;
  if (checkAndSetPagesDone("report-part-2", 4)) return pagesDone;
  if (checkAndSetPagesDone("conclusions", 5)) return pagesDone;
  if (checkAndSetPagesDone("recommendations", 6)) return pagesDone;

  return pagesDone;
};

const getStatusFromReport = (report: ReportType, pagesDone: number): StatusType => {
  const reportStatus = report?.reportStatus;

  const getFilteredSections = (location: string) =>
    report.reportSections.edges.map((edge) => edge?.node).filter((section) => section?.pageLocation === location);

  const isSectionErrored = (sections: ReportSectionsType[]): boolean =>
    sections.some((section) => section?.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.Failure);

  const isSectionGenerating = (sections: ReportSectionsType[]): boolean =>
    sections.some(
      (section) => section?.aiGeneratedStatus === ApiReportSectionsAiGeneratedStatusChoices.SectionPredictionPending,
    );

  if (reportStatus === ApiReportReportStatusChoices.Completed) {
    return "Completed";
  }

  switch (pagesDone) {
    case 2:
      return "At Upload Files";
    case 3:
      return isSectionGenerating(getFilteredSections("report-part-1") as ReportSectionsType[])
        ? "Generating Report Page 1"
        : isSectionErrored(getFilteredSections("report-part-1") as ReportSectionsType[])
          ? "Error on Report Page 1"
          : "At Report Page 1";
    case 4:
      return isSectionGenerating(getFilteredSections("report-part-2") as ReportSectionsType[])
        ? "Generating Report Page 2"
        : isSectionErrored(getFilteredSections("report-part-2") as ReportSectionsType[])
          ? "Error on Report Page 2"
          : "At Report Page 2";
    case 5:
      return isSectionGenerating(getFilteredSections("conclusions") as ReportSectionsType[])
        ? "Generating Conclusions"
        : isSectionErrored(getFilteredSections("conclusions") as ReportSectionsType[])
          ? "Error on Conclusions"
          : "At Conclusions";
    case 6:
      return isSectionGenerating(getFilteredSections("recommendations") as ReportSectionsType[])
        ? "Generating Recommendations"
        : "At Recommendations";
    default:
      return "Client Details Needed";
  }
};

const mapReportsToEvaluation = (
  evaluations: GetReportsQuery["getReports"],
  supportedAssessmentsList: AssessmentToolType[],
): Evaluation[] => {
  if (!evaluations?.edges.length) return [];
  return evaluations?.edges
    .map((edge) => {
      const node = edge?.node;
      if (!node) return null;

      const clientFirstName = node?.reportClientDetails?.clientFirstName || "N/A";
      const clientLastName = node?.reportClientDetails?.clientLastName || "";
      const clientName = `${clientFirstName} ${clientLastName}`;
      const pagesDone = getNumberOfPagesDone(node as ReportType, supportedAssessmentsList);
      const status = getStatusFromReport(node as ReportType, pagesDone);

      const report: Evaluation = {
        id: node?.id,
        clientName,
        dateCreated: new Date(node?.createdDate),
        lastUpdated: new Date(node?.lastUpdatedDate),
        status,
        pagesDone,
      };

      return report;
    })
    .filter(Boolean) as Evaluation[];
};

export const Evaluations: React.FC = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const go = useNavigate();
  const { pathname } = useLocation();
  const reportType = ReportTypeEnums.DiagnosisEvaluation;
  const previousEvaluationsPath = useRef<string>("");

  const getEvaluationsQuery = useGetReportsQuery(
    { records: 100, reportType: reportType },
    {
      queryKey: ["get-evaluations"],
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: "always",
      staleTime: Infinity,
    },
  );

  const { data, refetch, isLoading } = getEvaluationsQuery;
  const response = useAssessmentToolsQuery({ reportType: ReportTypeEnums.DiagnosisEvaluation });
  const supportedAssessmentsList: AssessmentToolType[] =
    response.data?.assessmentTools?.filter((item) => !!item).filter((tool) => tool.parsingSupported) ?? [];
  const tableData = mapReportsToEvaluation(data?.getReports, supportedAssessmentsList);

  useEffect(() => {
    const evaluationsPath = pathname.split("/evaluations")?.pop() || "";
    if (evaluationsPath === "" && previousEvaluationsPath.current.length) {
      refetch();
    }
    previousEvaluationsPath.current = evaluationsPath;
  }, [pathname]);

  return (
    <div className="mx-auto flex w-full flex-col content-between items-center lg:flex-col lg:items-start">
      <div className="mt-4 flex w-full items-center justify-between">
        {pathname === "/evaluations" ? (
          <>
            <Heading className="" type="h1">
              <Heading type="h1">Evaluations</Heading>
            </Heading>
            <div className="flex items-center space-x-2">
              <RecommendationsBank />
              <Button
                text="New Evaluation"
                appearance="primary"
                onClick={() => {
                  setDialogOpen(true);
                }}
              />
              <NewEvaluationDialog isDialogOpen={isDialogOpen} setDialogOpen={(open) => setDialogOpen(open)} />
            </div>
          </>
        ) : null}
      </div>

      {pathname === "/evaluations" ? (
        <EvaluationsTable
          data={tableData}
          refetch={getEvaluationsQuery.refetch}
          loading={isLoading}
          onRowClick={(row) => go(`${row.id}`)}
        />
      ) : (
        <Outlet />
      )}
    </div>
  );
};

import React from "react";

import { Dialog, Paragraph } from "@fronterahealth/frontera-ui-components";

export type REASONS_TO_BE_OPEN = "MISSING_FILES" | "PARSING_FAILED" | "FILES_VALIDATING";

const reasonsToBeOpenMessageMapping: { [key in REASONS_TO_BE_OPEN]: string } = {
  FILES_VALIDATING:
    "One or more files are currently validating, please wait for them to finish validation before continuing. This may take several minutes.",
  PARSING_FAILED: "Failed to parse certain files. Please remove the incorrect files and upload new ones.",
  MISSING_FILES: "Please upload required assessments in order to continue with report generation.",
};

interface ValidationNoticeDialogProps {
  reasonToBeOpen: REASONS_TO_BE_OPEN | null;
  closeDialog: () => void;
}
export const ValidationNoticeDialog: React.FC<ValidationNoticeDialogProps> = ({ reasonToBeOpen, closeDialog }) => {
  return (
    <Dialog
      size="lg"
      open={!!reasonToBeOpen}
      setOpen={() => closeDialog()}
      title={`Notice`}
      primaryButton={{
        text: "Close",
        onClick: async () => closeDialog(),
      }}
      secondaryButton={undefined}
    >
      {reasonToBeOpen ? (
        <Paragraph displayType="normal" colorType="secondary">
          {reasonsToBeOpenMessageMapping[reasonToBeOpen]}
        </Paragraph>
      ) : null}
    </Dialog>
  );
};

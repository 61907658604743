import { useAuth0 } from "@auth0/auth0-react";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import { Heading } from "@fronterahealth/frontera-ui-components";

import { useCreateCheckoutSessionMutation } from "@api/graphql/types-and-hooks";
import { notifySuccess } from "@components/notifications/notifications";
import { useScrollToAnchor } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/StickyNav/useScrollToAnchor";
import { useAdminData } from "@providers/AdminDataProvider";
import { BillingTable } from "@routes/AccountSettings/BillingTable";

const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;
const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;

export const UserSettings = () => {
  const { user } = useAuth0();
  const [clientSecret, setClientSecret] = useState("");
  // const { email, given_name, family_name } = user || {};
  const createCheckoutSession = useCreateCheckoutSessionMutation({});
  const [searchParams] = useSearchParams();

  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

  useEffect(() => {
    const createIntent = async () => {
      const res = await createCheckoutSession.mutateAsync({
        redirectOrigin: window.location.origin,
      });

      const { clientSecret: setupClientSecret } = res.createCheckoutSession as {
        clientSecret: string;
      };
      setClientSecret(setupClientSecret);
    };

    if (user) {
      createIntent();
    }
  }, []);

  const { assessmentCreditsRemaining } = useAdminData();

  useEffect(() => {
    if (searchParams.get("redirect_status") === "succeeded") {
      notifySuccess("Payment method updates successfully");
    }
  }, []);
  useScrollToAnchor();

  const changePassword = useCallback(async () => {
    const options = {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        client_id: AUTH0_CLIENT_ID,
        email: user?.email,
        connection: "Username-Password-Authentication",
      }),
    };

    const response = await fetch(`https://${AUTH0_DOMAIN}/dbconnections/change_password`, options);

    const { error, status } = (await response) as { error?: string; status: number };

    if (error) {
      console.error(error);
      return;
    }
    if (status === 200) {
      notifySuccess("Password reset email was sent to your inbox");
    }
  }, [user]);
  const isOauthUser = user?.sub?.includes("google-oauth2");

  return (
    <div>
      <Heading type="h1" className="mb-16">
        Account
      </Heading>
      {/* TODO: Create custom claim to get user name from metadata
      <div className="flex justify-between mb-4">
        <Heading type="h4">Account Details</Heading>
        <div className="bg-white rounded-3xl p-8 w-2/3">
          <div className="flex justify-between">
            <Input disabled name="firstName" label="First Name" inputSize="sm" defaultValue={given_name} />
            <Input disabled name="lastName" label="Last Name" inputSize="sm" defaultValue={family_name} />
          </div>
          <Input disabled name="email" label="Email" type="email" inputSize="sm" defaultValue={email} />
        </div>
      </div> */}
      {!isOauthUser && (
        <div className="flex justify-between mb-4">
          <Heading type="h4">Security</Heading>
          <div className="bg-white rounded-3xl p-8 w-2/3">
            <p className="mb-4 font-semibold text-limestone-900">Password</p>
            <p className="text-limestone-600">We'll email you a link to change your password</p>
            <div className="text-[#16803c] cursor-pointer" onClick={changePassword}>
              Change Password
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between mb-4">
        <Heading type="h4">Billing Details</Heading>
        <div className="bg-white rounded-3xl p-8 w-2/3">
          <div className="mb-8">
            <p className="mb-4 font-semibold text-limestone-900">Your Plan</p>
            <p>
              {assessmentCreditsRemaining} Credit{assessmentCreditsRemaining != 1 && "s"}
            </p>
            <Link className="text-[#16803c]" to="/products">
              Purchase Credits
            </Link>
          </div>
          <div className="mb-8" id="payment-method">
            {clientSecret && (
              <div>
                <p className="mb-4 font-semibold text-limestone-900">Payment Method</p>
                <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              </div>
            )}
          </div>
          <div id="payment-method">
            <p className="mb-4 font-semibold text-limestone-900">Billing History</p>
            <BillingTable />
          </div>
        </div>
      </div>
    </div>
  );
};

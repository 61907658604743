import { BehaviorsQuestionType } from "@api/graphql/types-and-hooks";
import { LanguageObjectType } from "@components/forms/interfaces";

export const behaviorsQuestionsMapping: {
  [key in BehaviorsQuestionType]: LanguageObjectType;
} = {
  WHEN_FIRST_CONCERNED: {
    en: "When did you first become concerned about the client's development?",
    es: "¿Cuándo te preocupaste por primera vez por el desarrollo del cliente?",
  },
  WALKING_TALKING_TOILET_TRAINING: {
    en: "How is the client with walking, talking and toilet training?",
    es: "¿Cómo le va al cliente con caminar, hablar y el entrenamiento para ir al baño?",
  },
  MOST_CONCERNED_BEHAVIORS: {
    en: "What client behaviors are you most concerned with?",
    es: "¿Con qué comportamientos del cliente estás más preocupado?",
  },
  BEHAVIOR_TREND_RECENT_WEEKS: {
    en: "Is the client's behavior tending to get better or worse in recent weeks? Why?",
    es: "¿La conducta del cliente tiende a mejorar o empeorar en las últimas semanas? ¿Por qué?",
  },
  EFFECTIVE_BEHAVIOR_STRATEGIES: {
    en: "What behavioral or parenting strategies seem to work best? Why?",
    es: "¿Qué estrategias de comportamiento o crianza parecen funcionar mejor? ¿Por qué?",
  },
  SIGNS_OF_TANTRUMS_OR_AGGRESSION: {
    en: "Does the client exhibit signs of tantrums or aggression?",
    es: "¿El cliente muestra signos de berrinches o agresión?",
  },
  SIGNS_OF_SELF_INJURY: {
    en: "Does the client exhibit signs of self injury?",
    es: "¿El cliente muestra signos de autolesión?",
  },
  REPETITIVE_MOVEMENTS: {
    en: "Does the client ever move in a way that seems repetitive?",
    es: "¿El cliente se mueve de manera repetitiva?",
  },
  ODD_OR_STRANGE_MOVEMENTS: {
    en: "Do they have any movements that seem odd or strange to you?",
    es: "¿Tiene algún movimiento que te parezca extraño o raro?",
  },
  PLAY_DESCRIPTION: {
    en: "What was/is their play like? What do they like to play with?",
    es: "¿Cómo era/es su juego? ¿Con qué le gusta jugar?",
  },
  PRETEND_PLAY: {
    en: "Pretend Play",
    es: "Juego de simulación",
  },
  PLAY_WITH_OTHERS: {
    en: "Play with others",
    es: "Jugar con otros",
  },
  LINING_OBJECTS_UP: {
    en: "Lining objects up or playing in a specific manner",
    es: "Alinear objetos o jugar de una manera específica",
  },
  PLAYING_WITH_TOY_AS_INTENDED: {
    en: "Playing with the toy as intended",
    es: "Jugar con el juguete según lo previsto",
  },
  OVER_FOCUSED_ON_SPECIFIC_PARTS: {
    en: "Getting over focused on specific parts, toys or objects?",
    es: "¿Se enfoca demasiado en partes, juguetes u objetos específicos?",
  },
  BIG_CHANGES_VS_LITTLE_CHANGES: {
    en: "Is the client rigid when it comes to big changes vs little changes?",
    es: "¿Es rígido el cliente cuando se trata de cambios grandes vs pequeños?",
  },
  RIGID_ROUTINES: {
    en: "Is the client rigid when it comes to routines?",
    es: "¿Es rígido el cliente cuando se trata de rutinas?",
  },
  INSISTS_ON_CERTAIN_THINGS: {
    en: "Does the client insist on certain things being the same way or doing things in a certain way?",
    es: "¿Insiste el cliente en que ciertas cosas sean de la misma manera o haga las cosas de cierta manera?",
  },
  RIGID_CHANGING_MIND_OR_PERSPECTIVES: {
    en: "Is the client rigid when it comes to changing their mind or thinking from different perspectives?",
    es: "¿Es rígido el cliente cuando se trata de cambiar de opinión o de pensar desde diferentes perspectivas?",
  },
  IRRITATED_BY_SOUNDS_SMELLS_FEELS: {
    en: "Does the client ever get really irritated by certain things, like how something sounds, smells or feels?",
    es: "¿El cliente se irrita mucho por ciertas cosas, como el sonido, el olor o el tacto de algo?",
  },
  OVERSENSITIVE_TO_SENSORY_STIMULI: {
    en: "Does it ever seem like the client is oversensitive to these things?",
    es: "¿Alguna vez parece que el cliente es demasiado sensible a estas cosas?",
  },
  SEEKING_SPECIFIC_SENSORY_EXPERIENCES: {
    en: "Does the client ever go after certain experiences, like smelling lots of things, feeling things or listening to things?",
    es: "¿El cliente busca experiencias sensoriales específicas, como oler muchas cosas, sentir cosas o escuchar cosas?",
  },
  VISUAL_INSPECTION: {
    en: "Does the client ever exhibit usual visual inspection habits, such a looking out of the corners of their eyes or holding objects up close to their face?",
    es: "¿El cliente muestra hábitos de inspección visual habituales, como mirar desde las esquinas de sus ojos o sostener objetos cerca de su rostro?",
  },
  MOVEMENT_ACTIVITIES: {
    en: "Does the client ever exhibit usual movement activities?",
    es: "¿El cliente muestra actividades de movimiento habituales?",
  },
  TROUBLE_FALLING_OR_STAYING_ASLEEP: {
    en: "Does the client have trouble falling asleep or staying asleep?",
    es: "¿El cliente tiene problemas para conciliar el sueño o mantenerse dormido?",
  },
  SLEEP_TROUBLE_START: {
    en: "If so, when did that start?",
    es: "Si es así, ¿cuándo comenzó eso?",
  },
  FAVORITE_THINGS: {
    en: "What are the client's favorite things to do?",
    es: "¿Cuáles son las cosas favoritas del cliente?",
  },
  FAVORITE_FOOD_DRINKS: {
    en: "Please list some of the client's favorite foods/drinks",
    es: "Por favor, enumera algunas de las comidas y bebidas favoritas del cliente.",
  },
  FAVORITE_TOYS_AND_PLAY_ITEMS: {
    en: "Please list some of the client's favorite toys and play items",
    es: "Por favor, enumere algunos de los juguetes y artículos de juego favoritos del cliente.",
  },
  FAVORITE_TECHNOLOGY: {
    en: "Please list some of the client's favorite technology items (iPad, Nintendo, etc.)",
    es: "Por favor, enumere algunos de los artículos tecnológicos favoritos del cliente (iPad, Nintendo, etc.).",
  },
  FAVORITE_SOCIAL_ACTIVITY: {
    en: "Please list some of the client's favorite social activities",
    es: "Por favor, enumere algunas de las actividades sociales favoritas del cliente.",
  },
  REPEATED_ACTIVITIES: {
    en: "Is there anything they do repeatedly or repetitively?",
    es: "¿Hay algo que hagan repetidamente o de forma repetitiva?",
  },
  EXCESSIVE_ACTIVITIES: {
    en: "Is there anything the client spends an excessive amount of time doing?",
    es: "¿Hay algo en lo que el cliente pasa una cantidad excesiva de tiempo?",
  },
  REPEATED_TV_PROGRAMS: {
    en: "Does the client repetitively replay any specific tv programs/videos? What is re-played and how often? What do they do when they are watching TV/video?",
    es: "¿El cliente repite de forma repetitiva algún programa de televisión/video específico? ¿Qué se reproduce y con qué frecuencia? ¿Qué hacen cuando están viendo televisión/video?",
  },
  TYPICAL_INTERESTS_FOR_AGE: {
    en: "Does is seem like their interest in these programs are typical for their age?",
    es: "¿Parece que su interés en estos programas es típico para su edad?",
  },
  ODD_INTERESTS_FOR_AGE: {
    en: "Has the client ever had any interests that seemed odd for their age?",
    es: "¿El cliente ha tenido intereses que parecían extraños para su edad?",
  },
  IS_PICKY_OR_RESTRICTIVE_DIET: {
    en: "Is the client picky, or sometimes restrictive with eating?",
    es: "¿El cliente es quisquilloso o a veces restrictivo con la comida?",
  },
};

import { EyeIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Badge, BadgeProps, Table } from "@fronterahealth/frontera-ui-components";

export const INTAKE_VALUES = ["Complete", "In Progress", "Started", "Awaiting"] as const;
export type IntakeType = (typeof INTAKE_VALUES)[number];
export const STAGE_VALUES = [
  "Diagnosis Waitlist",
  "Diagnosis Pending",
  "Assessment Waitlist",
  "Assessment Pending",
  "Assessment Complete",
] as const;

const BadgeSettingFromIntake: { [key in IntakeType]: BadgeProps["appearance"] } = {
  Complete: "success",
  Awaiting: "warn",
  "In Progress": "neutral",
  Started: "neutral",
};

export type StageType = (typeof STAGE_VALUES)[number];

export interface FronteraClient {
  id: string;
  name: string;
  caregiver: string;
  intake: IntakeType;
  intakePagesCompleted: number;
  created: Date;
  nextAppointment: Date;
  stage: StageType;
  nearestLocation: string;
}

interface ClientTableProps {
  clients: (FronteraClient | null)[];
  loading?: boolean;
  onRowClick: (client: FronteraClient) => void;
}

export const ClientTable: React.FC<ClientTableProps> = ({ clients, loading, onRowClick }) => {
  const go = useNavigate();
  return (
    <div className="mt-8 flow-root w-full">
      <Table
        onRowClick={onRowClick}
        defaultItemsPerPage={10}
        showPagination
        loading={loading}
        showSearch
        inputClassName="w-96"
        itemsPerPageOptions={[5, 10, 20, 50]}
        actions={[
          {
            label: "View",
            Icon: EyeIcon,
            callback: (client) => {
              go(`/clients/${client.id}`, {
                state: {
                  id: client.id,
                  name: client.name,
                  intake: client.intake,
                  stage: client.stage,
                },
              });
            },
          },
        ]}
        data={(clients.filter((client) => client !== null) as FronteraClient[]).sort((clientA, clientB) =>
          clientB.created > clientA.created ? 1 : -1,
        )}
        columns={[
          { dataIndex: "name", key: "name", title: "Name" },
          {
            dataIndex: "caregiver",
            key: "caregiver",
            title: "Caregiver",
          },
          {
            dataIndex: "intake",
            key: "intake",
            title: "Intake",
            render: (value, record) => (
              <div className="min-w-36">
                <Badge
                  appearance={BadgeSettingFromIntake[value as IntakeType]}
                  text={`${value}${value === "In Progress" ? ` (${record.intakePagesCompleted} of 10)` : ""}`}
                />
              </div>
            ),
          },
          {
            dataIndex: "created",
            key: "created",
            title: "Created",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
          {
            dataIndex: "nextAppointment",
            key: "nextAppointment",
            title: "Next Appointment",
            render: (value) => (value ? format(value, "MM/dd/yyyy") : ""),
          },
          {
            dataIndex: "nearestLocation",
            key: "nearestLocation",
            title: "Nearest Clinic",
          },
        ]}
      />
    </div>
  );
};

import {
  GoalTypeEnums,
  useCreateGoalBankLongTermGoalsMutation,
  useCreateGoalBankShortTermGoalsMutation,
  useCreateGoalBankTargetsMutation,
} from "@api/graphql/types-and-hooks";

export enum Goals {
  LONG_TERM_GOALS = "Long Term Goals",
  SHORT_TERM_GOALS = "Short Term Goals",
  TARGETS = "Targets",
}
export const GoalBankGoalsFormConfig = {
  [Goals.LONG_TERM_GOALS]: {
    title: "New Long Term Goal",
    mutation: useCreateGoalBankLongTermGoalsMutation,
    defaultValues: {
      folderId: "",
      longtermGoalsData: [
        {
          goalName: "",
          description: "",
          goalType: GoalTypeEnums.BehaviorReduction,
        },
      ],
    },
  },
  [Goals.SHORT_TERM_GOALS]: {
    title: "New Short Term Goal",
    mutation: useCreateGoalBankShortTermGoalsMutation,
    defaultValues: {
      folderId: "",
      shorttermGoalsData: [
        {
          goalName: "",
          description: "",
          medicalRationale: "",
          programGoal: "",
          masteryCriteria: "",
          timelineEstimationType: null,
          timelineEstimationValue: null,
        },
      ],
    },
  },
  [Goals.TARGETS]: {
    title: "New Target",
    mutation: useCreateGoalBankTargetsMutation,
    defaultValues: {
      folderId: "",
      goalBankTargetData: [
        {
          goalName: "",
          description: "",
          medicalRationale: "",
          programGoal: "",
          masteryCriteria: "",
          timelineEstimationType: null,
          timelineEstimationValue: null,
        },
      ],
    },
  },
};
export enum FolderManagementAction {
  MOVE,
  SAVE,
}

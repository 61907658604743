import { AI_GENERATED, Dialog, Folder, FolderProps } from "@fronterahealth/frontera-ui-components";

export const getUserFolderSection = (folders: Folder[]): FolderProps => {
  return {
    folderSectionName: "My Folders",
    folders,
  };
};

export const getOrgFolderSection = (folders: Folder[], orgName: string): FolderProps => {
  return {
    folderSectionName: orgName + "'s Folders",
    folders,
  };
};

export const getAiGeneratedFolderSection = (): FolderProps => {
  return {
    folderSectionName: AI_GENERATED,
    folders: [{ name: "Suggested", id: AI_GENERATED, type: AI_GENERATED } as Folder],
  };
};

export const findFolder = (folderId: string, folders: Folder[]): Folder => {
  return folders.find((folder) => folder.id === folderId) ?? { id: "", name: "", type: "" };
};

interface RenderRecommendationBankFormDialogProps {
  title: string;
  openForm: boolean;
  setOpenForm: (open: boolean) => void;
  onSubmit: () => void;
  children: React.ReactNode;
}

export const renderRecommendationBankFormDialog: React.FC<RenderRecommendationBankFormDialogProps> = ({
  title,
  openForm,
  setOpenForm,
  onSubmit,
  children,
}) => {
  return (
    <Dialog
      title={title}
      size="2xl"
      overflowBehavior="yAuto"
      open={openForm}
      setOpen={setOpenForm}
      primaryButton={{
        text: "Save",
        onClick: onSubmit,
        type: "submit",
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: () => setOpenForm(false),
      }}
    >
      {children}
    </Dialog>
  );
};

import { addYears, differenceInMonths, differenceInYears, format } from "date-fns";
import { GraphQLError } from "graphql";

import { ApiLearnerAvailabilityTimeOfDayChoices } from "@api/graphql/types-and-hooks";
import { useAdminData } from "@providers/AdminDataProvider";

export const capitalizeString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const getTimeByShift = (shiftChoice: ApiLearnerAvailabilityTimeOfDayChoices): string => {
  switch (shiftChoice) {
    case "MORNING":
      return "8:00am - 12:00pm";
    case "AFTERNOON":
      return "12:00pm - 3:00pm";
    case "MIDDAY":
      return "3:00pm - 5:30pm";
    case "EVENING":
      return "5:30pm - 7:30pm";
    default:
      return "";
  }
};

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export const convertHeadersToObject = (headers: HeadersInit): Record<string, string> => {
  const result: Record<string, string> = {};

  if (Array.isArray(headers)) {
    headers.forEach(([key, value]) => {
      result[key] = value;
    });
  } else if (headers instanceof Headers) {
    headers.forEach((value, key) => {
      result[key] = value;
    });
  } else {
    Object.assign(result, headers);
  }

  return result;
};

export const getDifferenceInYearsAndMonths = (startDate: Date, endDate: Date): string => {
  const years = differenceInYears(endDate, startDate);
  const adjustedStartDate = addYears(startDate, years);
  const months = differenceInMonths(endDate, adjustedStartDate);

  return `${years} years ${months ? `${months} months` : ""}`;
};

export const formatDateTime = (datetime: Date): string => {
  try {
    let formattedDate = format(datetime, "MM/dd/yyyy h:mm a");
    formattedDate = formattedDate.replace("AM", "A.M.").replace("PM", "P.M.");
    return formattedDate;
  } catch (error) {
    return "<not-valid-date>";
  }
};

export const formatDate = (datetime: Date): string => {
  try {
    const formattedDate = format(datetime, "MM/dd/yyyy");

    return formattedDate;
  } catch (error) {
    return "<not-valid-date>";
  }
};

export const formatDateYYYY_MM_DD = (datetime: Date): string => {
  try {
    const formattedDate = format(datetime, "yyyy-MM-dd");

    return formattedDate;
  } catch (error) {
    return "<not-valid-date>";
  }
};

export const formatDurationToHrMin = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours}hr ${minutes}min`;
};

export const formatSecondsToHHMMSS = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  const padToTwoDigits = (num: number): string => num.toString().padStart(2, "0");

  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}:${padToTwoDigits(remainingSeconds)}`;
};

export const isAuthorised = (list1: string[]): boolean | null => {
  const { roles } = useAdminData();
  if (roles.length) {
    const set1 = new Set(roles);
    for (const item of list1) {
      if (set1.has(item)) {
        return true;
      }
    }
    return false;
  }
  return false;
};

export type HeroIconType = React.ForwardRefExoticComponent<
  Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & React.RefAttributes<SVGSVGElement>
>;

export const getEnvFromHostname = (hostname: string): "dev" | "prod" | "local" | "staging" | "unknown-env" => {
  switch (hostname) {
    case "portal.dev.fronterahealth.com":
      return "dev";
    case "portal.staging.fronterahealth.com":
      return "staging";
    case "portal.fronterahealth.com":
      return "prod";
    case "localhost":
      return "local";
    default:
      // console.error("Unexpected hostname, this should not happen");
      return "unknown-env";
  }
};

export const getTracingUrlFromHostname = (
  hostname: string,
):
  | "https://dev.us-west-2.foundational.frontera.health/graphql/"
  | "https://prod.us-west-2.foundational.frontera.health/graphql/"
  | "https://staging.us-west-2.foundational.frontera.health/graphql/"
  | undefined => {
  switch (hostname) {
    case "portal.dev.fronterahealth.com":
      return "https://dev.us-west-2.foundational.frontera.health/graphql/";
    case "portal.staging.fronterahealth.com":
      return "https://staging.us-west-2.foundational.frontera.health/graphql/";
    case "portal.fronterahealth.com":
      return "https://prod.us-west-2.foundational.frontera.health/graphql/";
    case "localhost":
      return undefined;
    default:
      return undefined;
  }
};

const getCookieDomainFromHostname = (
  hostname: string,
):
  | ".dev.fronterahealth.com"
  | ".staging.fronterahealth.com"
  | ".fronterahealth.com"
  | ".local.fronterahealth.com"
  | ".unknown-cookie.fronterahealth.com" => {
  switch (hostname) {
    case "portal.dev.fronterahealth.com":
      return ".dev.fronterahealth.com";
    case "portal.staging.fronterahealth.com":
      return ".staging.fronterahealth.com";
    case "portal.fronterahealth.com":
      return ".fronterahealth.com";
    case "localhost":
      return ".local.fronterahealth.com";
    default:
      console.error("Unexpected hostname, this should not happen");
      return ".unknown-cookie.fronterahealth.com";
  }
};

export const APP_ENV = getEnvFromHostname(window.location.hostname);
export const tracingUrl = getTracingUrlFromHostname(window.location.hostname);
export const cookieDomain = getCookieDomainFromHostname(window.location.hostname);

// Helper type
export type RequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

export const graphqlErrorHandler = (error: GraphQLError) => {
  const errorMessage = error.message;
  const jsonStartIndex = errorMessage.indexOf("{");
  const jsonString = errorMessage.substring(jsonStartIndex);
  const errorData = JSON.parse(jsonString);
  interface GraphQLError {
    message: string;
  }

  const errorMessages = errorData.response.errors.map((err: GraphQLError) => err.message);

  return errorMessages;
};

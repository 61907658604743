import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { Divider, Heading } from "@fronterahealth/frontera-ui-components";

import { LearnerInsuranceQuery, useLearnerInsuranceQuery } from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer, FormSpacer, TripleColumnHeader } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { convertReadableString } from "@components/forms/utils";
import {
  InsuranceDataType,
  insuranceMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Insurance/insuranceMapping";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

type ImageObject = {
  frontOfImage: string;
  backOfImage: string;
};

export const extractInsuranceData = (queryData: LearnerInsuranceQuery) => {
  const insuranceDetails = {
    insuranceDetails: queryData.learnerById?.insuranceForLearner?.edges
      ? queryData.learnerById?.insuranceForLearner?.edges?.map((edge) => ({
          ...edge?.node,
          insurer: edge?.node?.insurer ? convertReadableString(edge?.node?.insurer) : "",
          insuranceType: edge?.node?.insuranceType ? convertReadableString(edge?.node?.insuranceType) : "",
        }))
      : [],
  } as unknown as InsuranceDataType;
  return insuranceDetails;
};

export const Insurance: React.FC = () => {
  const { clientId } = useParams();
  const { chosenLanguage } = useInternationalization();

  const [existingInsuranceImagePaths, setExistingInsuranceImagePaths] = useState<ImageObject[]>([
    {
      backOfImage: "",
      frontOfImage: "",
    },
  ]);

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/insurance" : undefined;

  const config = useGetQueryConfig<LearnerInsuranceQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerInsuranceQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching: formLoading } = learnerDetailsQuery;

  const { formState, onSubmit, RegisteredFormInput, RegisteredFormFileUpload, reset, control } =
    useFormUtils<InsuranceDataType>({
      mutationFn: async () => {
        await Promise.resolve();
      },
      displayOnlyMode: pathname.includes("all"),
    });

  const { fields } = useFieldArray({
    control,
    name: "insuranceDetails",
  });
  useEffect(() => {
    if (data) {
      const insurance = data.learnerById?.insuranceForLearner.edges || [];
      const images: ImageObject[] = insurance.map((item) => ({
        frontOfImage: item?.node?.signedUrlOfCardFront || "",
        backOfImage: item?.node?.signedUrlOfCardBack || "",
      }));
      setExistingInsuranceImagePaths(images);

      const converted = extractInsuranceData(data);
      reset(converted);
    }
  }, [data, reset]);

  return (
    <div>
      <FormContainer onSubmit={onSubmit} noValidate>
        <div className="flex-col">
          {fields.map((field, index) => {
            return (
              <div className="flex-col" key={field.id}>
                <div className="flex w-full items-center justify-start">
                  <Heading type="h3" className="my-4">
                    {index === 0 ? "Primary Insurance" : "Secondary Insurance"}
                  </Heading>
                </div>

                {/* Easiest way to send in the guardianId back in is to include it in the form but not display the input */}

                <DoubleColumnContainer>
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.insuranceType[chosenLanguage]}
                    formKey={`insuranceDetails.${index}.insuranceType`}
                    formState={formState}
                    isLoading={formLoading}
                    readOnly
                  />
                </DoubleColumnContainer>
                <DoubleColumnContainer>
                  <RegisteredFormInput
                    key={field.id}
                    formKey={`insuranceDetails.${index}.insurer`}
                    formState={formState}
                    label={insuranceMapping.insuranceDetails.insurer[chosenLanguage]}
                    isLoading={formLoading}
                    readOnly
                  />
                </DoubleColumnContainer>

                <DoubleColumnContainer>
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.idNumber?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    formKey={`insuranceDetails.${index}.idNumber`}
                    isLoading={formLoading}
                  />
                </DoubleColumnContainer>
                <DoubleColumnContainer>
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.groupName?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    formKey={`insuranceDetails.${index}.groupName`}
                    isLoading={formLoading}
                  />
                </DoubleColumnContainer>

                <RegisteredFormFileUpload
                  title={insuranceMapping.insuranceDetails.signedUrlOfCardFront?.[chosenLanguage] ?? ""}
                  maxSize="1GB"
                  existingImagePath={existingInsuranceImagePaths ? existingInsuranceImagePaths[index].frontOfImage : ""}
                  supportedFileTypes={["PNG,JPG"]}
                  formKey={`insuranceDetails.${index}.signedUrlOfCardFront`}
                  formState={formState}
                  formNoValidate
                  disabled
                />
                <RegisteredFormFileUpload
                  title={insuranceMapping.insuranceDetails.signedUrlOfCardBack?.[chosenLanguage] ?? ""}
                  maxSize="1GB"
                  supportedFileTypes={["PNG,JPG"]}
                  existingImagePath={existingInsuranceImagePaths ? existingInsuranceImagePaths[index].backOfImage : ""}
                  formKey={`insuranceDetails.${index}.signedUrlOfCardBack`}
                  formState={formState}
                  disabled
                />
                <FormSpacer />

                <DoubleColumnContainer>
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.claimsAddress?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    formKey={`insuranceDetails.${index}.claimsAddress`}
                    isLoading={formLoading}
                  />
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.claimsAddressLine2?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    isLoading={formLoading}
                    formKey={`insuranceDetails.${index}.claimsAddressLine2`}
                    required={false}
                  />
                </DoubleColumnContainer>

                <TripleColumnHeader>
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.city?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    formKey={`insuranceDetails.${index}.city`}
                    isLoading={formLoading}
                  />
                  <RegisteredFormInput
                    formState={formState}
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.state?.[chosenLanguage] ?? ""}
                    readOnly
                    formKey={`insuranceDetails.${index}.state`}
                    isLoading={formLoading}
                  />
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.zip?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    formKey={`insuranceDetails.${index}.zip`}
                    isLoading={formLoading}
                  />
                </TripleColumnHeader>

                <DoubleColumnContainer>
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.phoneNumber?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    formKey={`insuranceDetails.${index}.phoneNumber`}
                    isLoading={formLoading}
                  />
                </DoubleColumnContainer>

                <DoubleColumnContainer>
                  <RegisteredFormInput
                    key={field.id}
                    label={insuranceMapping.insuranceDetails.insurancePolicyHolder?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    formKey={`insuranceDetails.${index}.insurancePolicyHolder`}
                    isLoading={formLoading}
                  />
                  <RegisteredFormInput
                    type="date"
                    key={field.id}
                    // @ts-ignore: Ignoring the compiler and risking bugs because: has any type
                    label={insuranceMapping.insuranceDetails.policyHolderBirthDate?.[chosenLanguage] ?? ""}
                    readOnly
                    formState={formState}
                    formKey={`insuranceDetails.${index}.policyHolderBirthDate`}
                    isLoading={formLoading}
                  />
                </DoubleColumnContainer>
                <Divider />
              </div>
            );
          })}
        </div>
      </FormContainer>
    </div>
  );
};

import { LANGUAGE_KEYS } from "@internationalization/index";
import { LinkName, links } from "@pages/ClientDetailsSubPages/IntakeForm/Links/config";
import { routeLabelMappings } from "@pages/ClientDetailsSubPages/IntakeForm/Links/routeLabelMappings";

export const displayToRoute = (d: string) => d.toLowerCase().split(" ").join("-");

export const getPageTitleFromName = (name: LinkName, language: LANGUAGE_KEYS) => {
  return routeLabelMappings[name][language];
};

export const getCurrentPageTitle = (path: string) => {
  const [routePrimary, routeSecondary] = path.split("/");
  const currentLinkObject = links.find((l) => l.name === routePrimary);

  if (currentLinkObject?.sublinks) {
    const currentSublinkObject = currentLinkObject.sublinks.find((sl) => sl.name === routeSecondary);

    if (currentSublinkObject) {
      return routeLabelMappings[currentSublinkObject.name]["en"];
    }
  }
  if (currentLinkObject) {
    return routeLabelMappings[currentLinkObject.name]["en"];
  }
  throw new Error("Could not find a route link");
};

export const getNextLinkRoute = (route: string) => {
  const [, routePrimary, routeSecondary] = route.split("/");
  const currentLinkObject = links.find((l) => l.name === routePrimary);
  // assuming we find a link...
  if (currentLinkObject) {
    // if our current link has sublinks and we are at one of them...
    if (currentLinkObject.sublinks && routeSecondary) {
      // find which sublink we are currently sitting at
      const currentSublinkObject = currentLinkObject.sublinks.find((sl) => sl.name === routeSecondary);

      if (currentSublinkObject) {
        const indexInSublinks = currentLinkObject.sublinks.indexOf(currentSublinkObject);
        // if we are not sitting at the last sublink, return the next sublink in the list
        if (currentLinkObject.sublinks.length > indexInSublinks + 1) {
          return currentLinkObject.sublinks[indexInSublinks + 1].name;
        }
      }
    }

    const indexInLinks = links.indexOf(currentLinkObject);
    // if we are not sitting at the last link, return the next link in the list
    if (links.length > indexInLinks + 1) {
      const nextLink = links[indexInLinks + 1];
      if (nextLink.sublinks) {
        return `../${nextLink.name}/${nextLink.sublinks[0].name}`;
      }
      return `../${links[indexInLinks + 1].name}`;
    }
  }
  throw new Error("Failed to find a link in our list, this should not happen");
};

// TODO: Write tests around this
export const getPreviousLinkRoute = (route: string) => {
  const [, routePrimary, routeSecondary] = route.split("/");
  const currentLinkObject = links.find((l) => l.name === routePrimary);
  // assuming we find a link...
  if (currentLinkObject) {
    // if our current link has sublinks and we are at one of them...
    if (currentLinkObject.sublinks && routeSecondary) {
      // find which sublink we are currently sitting at
      const currentSublinkObject = currentLinkObject.sublinks.find((sl) => sl.name === routeSecondary);

      if (currentSublinkObject) {
        const indexInSublinks = currentLinkObject.sublinks.indexOf(currentSublinkObject);
        // if we are not sitting at the first sublink, return the previous one
        if (indexInSublinks - 1 >= 0) {
          return currentLinkObject.sublinks[indexInSublinks - 1].name;
        }
      }
    }

    const indexInLinks = links.indexOf(currentLinkObject);
    // if we are not sitting at the first link, return the previous
    if (indexInLinks - 1 >= 0) {
      const nextLink = links[indexInLinks - 1];
      if (nextLink.sublinks) {
        return `../${nextLink.name}/${nextLink.sublinks[nextLink.sublinks.length - 1].name}`;
      }
      return `../${links[indexInLinks - 1].name}`;
    }
  }
  return null;
};

export const nth = function (d: number) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

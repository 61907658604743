import { LearnerEmergencyContactQuery } from "@api/graphql/types-and-hooks";
import { LanguageMappingInterface } from "@components/forms/interfaces";

export type EmergencyContactType = NonNullable<
  NonNullable<NonNullable<LearnerEmergencyContactQuery["learnerById"]>["learneremergencycontact"]>
>;
export const emergencyContactMapping: LanguageMappingInterface<EmergencyContactType> = {
  firstName: {
    en: "First Name",
    es: "Nombre",
  },
  lastName: {
    en: "Last Name",
    es: "Apellido",
  },
  phoneNumber: {
    en: "Phone Number",
    es: "Nombre Preferido",
  },
  relationshipToClient: {
    en: "Relationship to Client",
    es: "Fecha de nacimiento",
  },
};

import { HeroIconType, capitalizeString } from "@utils/utils";
import React, { PropsWithChildren, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { Heading, HeadingProps, Paragraph, Small } from "@fronterahealth/frontera-ui-components";

import { useScrollToAnchor } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/StickyNav/useScrollToAnchor";

// TODO: To return to at some point when we have time to cleanup this component
// const useActiveHeadingObserver = (headings: string[], state: object, isScolling?: boolean) => {
//   const navigate = useNavigate();
//   const [activeId, setActiveId] = useState<string | null>(null);

//   useEffect(() => {
//     if (isScolling) return;
//     const handleScroll = debounce(() => {
//       let found = false;

//       headings.forEach((heading) => {
//         const element = document.getElementById(heading);
//         if (element && !found) {
//           const bounding = element.getBoundingClientRect();
//           if (bounding.top >= 0 && bounding.top <= window.innerHeight / 2) {
//             setActiveId(heading);
//             found = true;
//           }
//         }
//       });
//     }, 0);

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, [headings, isScolling]);

//   useEffect(() => {
//     if (activeId) {
//       navigate(`#${activeId}`, { replace: true, state });
//     }
//   }, [activeId, navigate]);

//   return activeId;
// };

// export const useActiveObserver = () => {
//   const [activeHeadings, setActiveHeadings] = useState<(string | null)[]>([]);
//   useEffect(() => {
//     const observer = new IntersectionObserver((entries) => {
//       entries.forEach((entry) => {
//         const id = entry.target.getAttribute("id");
//         if (entry.intersectionRatio > 0) {
//           setActiveHeadings((ah) => Array.from(new Set([...ah, id])));
//           //   document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.add("active");
//         } else {
//           setActiveHeadings((ah) => ah.filter((a) => a !== id));
//           //   document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.remove("active");
//         }
//       });
//     });

//     // Track all sections that have an `id` applied
//     document.querySelectorAll("section[id]").forEach((section) => {
//       observer.observe(section);
//     });
//   }, []);
//   return activeHeadings;
// };

interface Props {
  state: object;
  headingType: HeadingProps["type"];
}
export const StickyNav: React.FC<Props> = ({ state, headingType }) => {
  const { hash } = useLocation();
  const [headings, setHeadings] = React.useState<string[]>([]);

  useEffect(() => {
    const _headings = Array.from(document.querySelectorAll(`${headingType}[data-sticky-nav-header]`)).map((h) => h.id);
    setHeadings(_headings);
  }, [headingType]);

  useScrollToAnchor();
  // useActiveHeadingObserver(headings, state, isScrolling);

  return (
    <nav className="sticky top-[138px] flex flex-col items-start ">
      <Paragraph className="mb-4">On this page</Paragraph>

      <div className="flex flex-col items-start max-h-[calc(100vh-260px)] overflow-y-scroll ">
        {headings.map((heading) => {
          const active = hash.replace("#", "") === heading;
          const headingLabel = heading
            .split("-")
            .map((h) => capitalizeString(h))
            .join(" ");

          return (
            <Link to={`#${heading}`} key={heading} state={state}>
              <div
                className={`transition-all cursor-pointer ${active ? "border-l-2" : "border-l"}   py-2 pl-2 hover:border-interaction-primary  ${active ? "border-interaction-primary" : "border-limestone-100"} `}
              >
                <Small
                  className={`transition-all hover:text-text-primary ${active ? "-mr-1" : ""}`}
                  displayType={active ? "loud" : "normal"}
                  colorType={active ? "primary" : "secondary"}
                >
                  {headingLabel}
                </Small>
              </div>
            </Link>
          );
        })}
      </div>
    </nav>
  );
};

interface HeadingSectionProps extends PropsWithChildren {
  id: string;
  label: string;
  Icon: HeroIconType;
  isLast?: boolean;
  isDisplayOnly?: boolean;
  description?: string;
}
export const HeadingSection: React.FC<HeadingSectionProps> = ({
  children,
  id,
  label,
  Icon,
  description,
  isDisplayOnly,
}) => {
  return (
    <section
      id={id}
      key={id}
      className={`${isDisplayOnly ? "bg-transparent" : "bg-white border-limestone-100 border"}   p-6 rounded-xl`}
    >
      <div className="flex w-full flex-col">
        <div className="flex items-center mb-4 ">
          {isDisplayOnly ? null : (
            <div className="mr-2 h-10 w-10 border-globemallow-100 border rounded-md p-1 flex items-center justify-center">
              <Icon className="h-6 w-6 text-text-primary" />
            </div>
          )}
          <div>
            <Heading id={id} type="h4" data-sticky-nav-header>
              {label}
            </Heading>
            {description && (
              <Small displayType="normal" colorType="secondary">
                {description}
              </Small>
            )}
          </div>
        </div>
      </div>
      {children}
    </section>
  );
};

import { LanguageObjectType } from "src/components/forms/interfaces";

import { DevelopmentMilestonesQuestionType } from "@api/graphql/types-and-hooks";

export const RadioButtonValueMappings: { [key in DevelopmentMilestonesQuestionType]: string } = {
  SAID_FIRST_WORD: "12 months",
  PLAYED_PEEK_A_BOO: "12 months",
  CRAWLS_AND_STANDS_BRIEFLY_BY_SELF: "12 months",
  WALK_WITHOUT_HELP: "12 months",
  SAID_FIRST_TWO_WORD_SENTENCE: "18 months",
  TOILET_TRAINED: "24 months",
  RIDE_A_TRICYCLE: "30 months",
  NAMES_AT_LEAST_ONE_COLOR: "3 years",
  USED_THREE_TO_FIVE_WORD_SENTENCES: "3 years",
  USED_FIVE_TO_SIX_WORD_SENTENCES: "4 years",
  UNDERSTOOD_SAME_AND_DIFFERENT: "4 years",
  DRAWS_PEOPLE_WITH_TWO_TO_FOUR_BODY_PARTS: "4 years",
  CAN_COUNT_10_OR_MORE_OBJECTS: "5 years",
  PRINT_SOME_LETTERS: "5 years",
  DRESS_AND_UNDRESS_WITHOUT_HELP: "5 years",
  ABLE_TO_USE_A_FORK_AND_KNIFE_TO_EAT: "5 years",
};

export const developmentalMilestonesQuestionsMapping: {
  [key in DevelopmentMilestonesQuestionType]: LanguageObjectType;
} = {
  SAID_FIRST_WORD: {
    en: "Said First Word",
    es: "Dijo la primera palabra",
  },
  PLAYED_PEEK_A_BOO: {
    en: "Played Peek-A-Boo",
    es: "Jugó a cucú tras",
  },
  CRAWLS_AND_STANDS_BRIEFLY_BY_SELF: {
    en: "Crawls and Stands Briefly By Self",
    es: "Gatea y se queda de pie brevemente por sí mismo",
  },
  WALK_WITHOUT_HELP: {
    en: "Walk Without Help",
    es: "Camina sin ayuda",
  },
  SAID_FIRST_TWO_WORD_SENTENCE: {
    en: "Said First Two Word Sentence",
    es: "Dijo la primera frase de dos palabras",
  },
  TOILET_TRAINED: {
    en: "Toilet Trained",
    es: "Entrenado/a en el uso del baño",
  },
  RIDE_A_TRICYCLE: {
    en: "Ride A Tricycle",
    es: "Monta en triciclo",
  },
  NAMES_AT_LEAST_ONE_COLOR: {
    en: "Names At Least One Color",
    es: "Nombra al menos un color",
  },
  USED_THREE_TO_FIVE_WORD_SENTENCES: {
    en: "Used Three To Five Word Sentences",
    es: "Usa frases de tres a cinco palabras",
  },
  USED_FIVE_TO_SIX_WORD_SENTENCES: {
    en: "Used Five To Six Word Sentences",
    es: "Usa frases de cinco a seis palabras",
  },
  UNDERSTOOD_SAME_AND_DIFFERENT: {
    en: "Understood Same And Different",
    es: "Entiende lo mismo y lo diferente",
  },
  DRAWS_PEOPLE_WITH_TWO_TO_FOUR_BODY_PARTS: {
    en: "Draws People With Two To Four Body Parts",
    es: "Dibuja personas con dos a cuatro partes del cuerpo",
  },
  CAN_COUNT_10_OR_MORE_OBJECTS: {
    en: "Can Count 10 Or More Objects",
    es: "Puede contar 10 o más objetos",
  },
  PRINT_SOME_LETTERS: {
    en: "Print Some Letters",
    es: "Escribe algunas letras",
  },
  DRESS_AND_UNDRESS_WITHOUT_HELP: {
    en: "Dress And Undress Without Help",
    es: "Se viste y se desviste sin ayuda",
  },
  ABLE_TO_USE_A_FORK_AND_KNIFE_TO_EAT: {
    en: "Able To Use A Fork And Knife To Eat",
    es: "Puede usar un tenedor y un cuchillo para comer",
  },
};

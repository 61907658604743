import { FieldValues, FormState, Path, UseFormRegister } from "react-hook-form";

import { FileUpload, FileUploadProps } from "@fronterahealth/frontera-ui-components";

interface Props<T extends FieldValues> extends Omit<FileUploadProps, "name" | "ref"> {
  register: UseFormRegister<T>;
  formState: FormState<T>;
  formKey: Path<T>;
  required?: boolean;
}

export const FormFileUpload = <T extends FieldValues>({
  formState,
  formKey,
  register,
  required = true,
  title,
  ...props
}: Props<T>) => {
  return (
    <FileUpload
      title={title}
      errorMessage={formState.errors[formKey]?.message?.toString()}
      {...register(formKey, {
        required: {
          value: required,
          message: `${title} is required`,
        },
      })}
      {...props}
    />
  );
};

import { AI_GENERATED } from "@fronterahealth/frontera-ui-components";

import { useGoalBankTargetsQuery } from "@api/graphql/types-and-hooks";

export const useGetTargets = (selectedFolderId: string) => {
  const {
    data: targets,
    refetch: refetchTargets,
    isLoading: targetsIsLoading,
  } = useGoalBankTargetsQuery(
    { folderId: selectedFolderId },
    {
      queryKey: ["targets-" + selectedFolderId],
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      refetchInterval: 200000,
      enabled: !!selectedFolderId && selectedFolderId !== AI_GENERATED,
    },
  );

  return {
    targets,
    refetchTargets,
    targetsIsLoading,
  };
};

import { format } from "date-fns";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Breadcrumb, BreadcrumbLink, Heading, SkeletonLoader } from "@fronterahealth/frontera-ui-components";

import { useLearnersRecentSessionQuery, useTherapySessionByIdQuery } from "@api/graphql/types-and-hooks";
import { SessionAnalyzerClientType, transformSessionLearnersIntoClients } from "@pages/SessionAnalyzer/DataHelpers";
import { SessionLearners } from "@pages/SessionAnalyzer/SessionLearners/SessionLearners";

const pathNameToBreadCrumbLinks = (
  pathname: string,
  clients: SessionAnalyzerClientType[],
  sessionName: string | null,
): BreadcrumbLink[] => {
  const links = pathname.slice(1).split("/");

  return links.map((link, index) => {
    const decodedLink = decodeURI(link);
    const clientName = clients?.find((item) => item.learnerId === decodedLink)?.name || "";
    const isLastLink = index === 2;
    let linkName = "";

    if (index === 0) {
      linkName = "Session Analyzer";
    } else if (index === 1) {
      linkName = clientName;
    } else if (isLastLink) {
      linkName = sessionName || "";
    }

    return {
      name: linkName,
      current: true,
      type: "component",
      Component: (
        <Link to={`/${links.slice(0, index + 1).join("/")}${isLastLink ? "/socialEngagement" : ""}`}>{linkName}</Link>
      ),
    };
  });
};

export const SessionAnalyzer: React.FC = () => {
  const { sessionId } = useParams();

  const { pathname } = useLocation();

  const go = useNavigate();

  const { data, isFetching } = useLearnersRecentSessionQuery(
    {},
    {
      queryKey: ["learnersRecentSession"],
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: sessionData } = useTherapySessionByIdQuery(
    { sessionId: sessionId || "" },
    {
      queryKey: ["therapySessionById", sessionId],
      enabled: Boolean(sessionId),
    },
  );

  const clients = data ? transformSessionLearnersIntoClients(data) : [];

  const breadcrumbLinks = pathNameToBreadCrumbLinks(
    pathname,
    clients,
    sessionData?.therapySessionById?.lastUpdatedDate
      ? `Session ${format(sessionData?.therapySessionById?.lastUpdatedDate, "MM/dd/yyyy ")}`
      : null,
  );

  const filteredBreadcrumbsLinks = breadcrumbLinks.filter((item) => item.name !== "");
  const headingName =
    filteredBreadcrumbsLinks.length > 0
      ? filteredBreadcrumbsLinks[filteredBreadcrumbsLinks.length - 1].name
      : "Clients";
  const loadHeading = headingName !== "Session Analyzer" && headingName !== "Clients" && isFetching;

  return (
    <>
      <div className="mx-auto flex w-full max-w-5xl flex-col content-between items-center lg:flex-col lg:items-start">
        {pathname !== "/session-analyzer" ? (
          <SkeletonLoader loading={isFetching}>
            <Breadcrumb
              links={filteredBreadcrumbsLinks}
              homeLink={{
                name: "Home",
                Component: <Link to={`/session-analyzer`}>Home</Link>,
                current: false,
                type: "component",
              }}
            />
          </SkeletonLoader>
        ) : null}
        <div className="mt-4 flex w-full items-center justify-between">
          <SkeletonLoader loading={loadHeading}>
            <Heading type="h1">{headingName}</Heading>
          </SkeletonLoader>
        </div>

        {pathname === "/session-analyzer" ? (
          <SessionLearners
            clients={clients || []}
            loading={isFetching}
            onRowClick={(data: SessionAnalyzerClientType) => {
              go(`/session-analyzer/${data.learnerId}`);
            }}
          />
        ) : (
          <Outlet />
        )}
      </div>
    </>
  );
};

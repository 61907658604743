import React from "react";

const PolygonShape: React.FC = () => {
  return (
    <div className="relative">
      <svg
        className="absolute inset-y-5 -right-9 w-16 bottom-0"
        width="40"
        height="37.07"
        viewBox="0 0 40 37.07"
        xmlns="http://www.w3.org/2000/svg"
        style={{ opacity: 1 }}
      >
        {/* Half hexagon shape with direct rotation applied to points */}
        <polygon points="40,0 20,0 0,18.535 20,37.07 40,37.07" fill="#E77757" />
      </svg>
    </div>
  );
};

export default PolygonShape;

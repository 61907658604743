export type AllRoutes =
  | "/clients"
  | "/providers"
  | "/assessment-reports"
  | "/assessment-reports?trigger-walkthrough=true"
  | "/downloads"
  | "/session-analyzer"
  | "/evaluations";

export type roleType =
  | "bcba"
  | "admin"
  | "frontera-internal"
  | "rbt"
  | "assessment-bcba"
  | "super_admin"
  | "psychologist";

type RoleMapping = {
  [key in AllRoutes]: roleType[];
};

export const roleMapping: RoleMapping = {
  "/clients": ["admin"],
  "/providers": ["admin"],
  "/assessment-reports": ["assessment-bcba"],
  "/assessment-reports?trigger-walkthrough=true": ["assessment-bcba"],
  "/downloads": ["admin", "assessment-bcba", "psychologist"],
  "/session-analyzer": ["admin"],
  "/evaluations": ["psychologist"],
};

import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { APP_ENV, graphqlErrorHandler } from "@utils/utils";
import { GraphQLError } from "graphql";
import yaml from "js-yaml";
import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Dialog, Paragraph, Small, TextArea } from "@fronterahealth/frontera-ui-components";

import {
  AiPredictionOfEnum,
  AiReportPredictionOfEnum,
  ApiAssessmentAiLtgPredictionStatusChoices,
  ApiAssessmentAiReport1PredictionStatusChoices,
  ApiAssessmentAiReport2PredictionStatusChoices,
  ApiAssessmentAiStgPredictionStatusChoices,
  ApiAssessmentAiTargetPredictionStatusChoices,
  AssessmentType,
  useStartAssessmentGoalsPredictionsMutation,
  useStartAssessmentReportFieldsPredictionMutation,
} from "@api/graphql/types-and-hooks";
import { notifyError } from "@components/notifications/notifications";
import { AssessmentReportSubRoute, getNextRoute } from "@pages/AssessmentReportDetails/AssessmentReportDetails";
import {
  GenerationPages,
  GenerationTypeLabelMapping,
} from "@pages/AssessmentReportDetails/AssessmentReportSubPages/GenerationErrorPage";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

interface ConfirmGenerationContentProps {
  type: GenerationPages;
  promptDialogOpen: boolean;
  setPromptDialogOpen: (bool: boolean) => void;
  onboardingData?: string;
}

const checkIsPredictionGenerating = (type: GenerationPages, assessmentReport: AssessmentType): boolean => {
  switch (type) {
    case AiReportPredictionOfEnum.AssessmentReport_1:
      return (
        assessmentReport.aiReport1PredictionStatus ===
        ApiAssessmentAiReport1PredictionStatusChoices.ReportFieldPredictionPending
      );

    case AiPredictionOfEnum.LongTermGoal:
      return assessmentReport.aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices.GoalPredictionPending;

    case AiPredictionOfEnum.ShortTermGoal:
      return (
        assessmentReport.aiStgPredictionStatus === ApiAssessmentAiStgPredictionStatusChoices.GoalPredictionPending ||
        assessmentReport.aiTargetPredictionStatus === ApiAssessmentAiTargetPredictionStatusChoices.GoalPredictionPending
      );

    case AiReportPredictionOfEnum.AssessmentReport_2:
      return (
        assessmentReport.aiReport2PredictionStatus ===
        ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPending
      );

    default:
      return false;
  }
};

const checkIsPredictionAvailable = (type: GenerationPages, assessmentReport: AssessmentType): boolean => {
  switch (type) {
    case AiReportPredictionOfEnum.AssessmentReport_1:
      return (
        assessmentReport.aiReport1PredictionStatus ===
        ApiAssessmentAiReport1PredictionStatusChoices.ReportFieldPredictionPredicted
      );

    case AiPredictionOfEnum.LongTermGoal:
      return (
        assessmentReport.aiLtgPredictionStatus === ApiAssessmentAiLtgPredictionStatusChoices.GoalPredictionPredicted
      );

    case AiPredictionOfEnum.ShortTermGoal:
      return (
        assessmentReport.aiStgPredictionStatus === ApiAssessmentAiStgPredictionStatusChoices.GoalPredictionPredicted &&
        assessmentReport.aiTargetPredictionStatus ===
          ApiAssessmentAiTargetPredictionStatusChoices.GoalPredictionPredicted
      );

    case AiReportPredictionOfEnum.AssessmentReport_2:
      return (
        assessmentReport.aiReport2PredictionStatus ===
        ApiAssessmentAiReport2PredictionStatusChoices.ReportFieldPredictionPredicted
      );

    default:
      return false;
  }
};

export const ConfirmGenerationDialog: React.FC<ConfirmGenerationContentProps> = ({
  type,
  promptDialogOpen,
  setPromptDialogOpen,
  onboardingData,
}) => {
  const { pathname } = useLocation();
  const go = useNavigate();
  const { assessmentId } = useParams();
  const { assessmentReport } = useAssessmentBuilderData();

  const isPredictionGenerating = checkIsPredictionGenerating(type, assessmentReport);
  const isPredictionAvailable = checkIsPredictionAvailable(type, assessmentReport);

  const startAssessmentReportFieldsPredictionMutation = useStartAssessmentReportFieldsPredictionMutation({
    mutationKey: ["start-assessment-report-fields-prediction", type],
  });

  const startAssessmentGoalsPredictionsMutation = useStartAssessmentGoalsPredictionsMutation({
    mutationKey: ["start-assessment-goals-prediction", type],
  });

  const [promptContent, setPromptContent] = React.useState<string>("");
  const [isValidYaml, setIsValidYaml] = React.useState<boolean>(true);
  const [showNotice, setShowNotice] = React.useState<boolean>(isPredictionAvailable || false);

  const currentRoute =
    (pathname.split("#")?.pop()?.split("/").pop() as AssessmentReportSubRoute) ||
    ("upload-files" as AssessmentReportSubRoute);
  const nextRoute = getNextRoute(currentRoute);

  useEffect(() => {
    if (promptContent) {
      try {
        yaml.load(promptContent);
        setIsValidYaml(true);
      } catch (err) {
        setIsValidYaml(false);
      }
    } else {
      setIsValidYaml(true);
    }
  }, [promptContent]);

  const generationTypeLabel = GenerationTypeLabelMapping[type];

  const isReport1and2 =
    type === AiReportPredictionOfEnum.AssessmentReport_1 || type === AiReportPredictionOfEnum.AssessmentReport_2;

  const handleDialogClick = useCallback(async () => {
    if (isPredictionGenerating) {
      setPromptDialogOpen(false);
    } else if (showNotice && (APP_ENV === "dev" || APP_ENV === "local")) {
      setShowNotice(false);
    } else {
      if (
        type === AiReportPredictionOfEnum.AssessmentReport_1 ||
        type === AiReportPredictionOfEnum.AssessmentReport_2
      ) {
        try {
          const prompts = promptContent;
          await startAssessmentReportFieldsPredictionMutation.mutateAsync({
            assessmentId: assessmentId || "<missing-assessment-id>",
            prompts,
            onboardingData,
            predictionOfReport: type as AiReportPredictionOfEnum,
          });
          go(`../${nextRoute}`);
        } catch (err) {
          const errorMessages = graphqlErrorHandler(err as GraphQLError);
          console.error(`Failed to kickoff ${generationTypeLabel} Generation`, err);
          for (const errorMessage of errorMessages) {
            notifyError(errorMessage);
          }
          return;
        }
      } else if (type === AiPredictionOfEnum.LongTermGoal || type === AiPredictionOfEnum.ShortTermGoal) {
        try {
          const prompts = promptContent;
          await startAssessmentGoalsPredictionsMutation.mutateAsync({
            assessmentId: assessmentId || "<missing-assessment-id>",
            prompts,
            predictionOf: type as AiPredictionOfEnum,
          });
          go(`../${nextRoute}`);
        } catch (err) {
          console.error(`Failed to kickoff ${generationTypeLabel} Generation`, err);
          notifyError(`Something went wrong kicking off ${generationTypeLabel} Generation`);
        }
      }
    }
  }, [showNotice]);

  return (
    <Dialog
      size="xl"
      open={promptDialogOpen}
      setOpen={setPromptDialogOpen}
      title={showNotice || isPredictionGenerating ? "Notice" : `Confirm ${generationTypeLabel} Generation`}
      primaryButton={{
        text:
          startAssessmentReportFieldsPredictionMutation.isPending || startAssessmentGoalsPredictionsMutation.isPending
            ? "Generating..."
            : showNotice
              ? "Yes, Continue"
              : isPredictionGenerating
                ? "Okay"
                : "Generate",
        buttonAction: showNotice && isReport1and2 ? "destructive" : "regular",
        onClick: handleDialogClick,
        disabled:
          !isValidYaml ||
          startAssessmentReportFieldsPredictionMutation.isPending ||
          startAssessmentGoalsPredictionsMutation.isPending,
        className: "px-6",
      }}
      secondaryButton={
        !showNotice && isPredictionGenerating
          ? undefined
          : {
              text: showNotice ? "No, Cancel" : "Cancel",
              onClick: () => {
                if (showNotice) {
                  setPromptDialogOpen(false);
                } else {
                  setPromptDialogOpen(false);
                  if (isPredictionAvailable) setShowNotice(true);
                }
              },
              className: "px-6 hover:no-underline",
            }
      }
    >
      {showNotice ? (
        isReport1and2 ? (
          <>
            <Paragraph displayType="normal" colorType="secondary">
              You are about to resubmit a previous step. This will cause the upcoming pages of your assessment report to
              be regenerated. Any changes you previously made to these pages will be overwritten.
            </Paragraph>
            <Paragraph displayType="normal" colorType="secondary" className="my-4">
              Would you like to proceed?
            </Paragraph>
            <div className="p-4 bg-red-50">
              <Paragraph displayType="loud" className="inline !text-red-500">
                <ExclamationTriangleIcon className="h-5 w-5 text-red-500 inline mr-2" />
                Warning:{" "}
              </Paragraph>
              <Paragraph displayType="normal" colorType="secondary" className="inline !text-red-500">
                &nbsp; This action is not reversible
              </Paragraph>
            </div>
          </>
        ) : (
          <Paragraph displayType="normal" colorType="secondary">
            You are about to regenerate the next page of your report. Would you like to proceed?
          </Paragraph>
        )
      ) : isPredictionGenerating ? (
        <>
          <Paragraph displayType="normal" colorType="secondary">
            Content for another step is currently being generated. This may take a few moments. Please wait until the
            process is complete before proceeding.
          </Paragraph>
        </>
      ) : APP_ENV === "dev" || APP_ENV === "local" ? (
        <>
          <Small displayType="loud">Enter your custom set of prompts below</Small>
          <TextArea label="" value={promptContent} onChange={(e) => setPromptContent(e.currentTarget.value)} />

          {isValidYaml ? (
            <div className="flex items-center gap-x-2">
              <CheckCircleIcon className="h-6 w-6 text-green-700" />
              <Small>Prompt YAML is Valid</Small>
            </div>
          ) : (
            <div className="flex items-center gap-x-2">
              <ExclamationCircleIcon className="h-6 w-6 text-yellow-700" />
              <Small>Prompt YAML is NOT valid</Small>
            </div>
          )}
        </>
      ) : (
        <Small>
          By clicking "Generate", you will be kicking off the {generationTypeLabel} generation step. This step will take
          several minutes. While {generationTypeLabel} generation is happening, you will not be able to edit your
          report.
        </Small>
      )}
    </Dialog>
  );
};

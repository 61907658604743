import React, { PropsWithChildren, ReactNode } from "react";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";

interface NoDataCardProps extends PropsWithChildren {
  title: string;
  description?: ReactNode;
}

export const NoDataCard: React.FC<NoDataCardProps> = ({ title, description, children }) => {
  return (
    <div className="border border-gray-200 rounded-md text-center bg-limestone-50 p-4 gap-8 mb-2">
      <div>
        <Paragraph colorType="primary" displayType="loud">
          {title}
        </Paragraph>
        <Small displayType="normal" colorType="secondary">
          {description}
        </Small>
      </div>
      {children}
    </div>
  );
};

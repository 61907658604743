import { format } from "date-fns";
import { useState } from "react";

import { Column, Table } from "@fronterahealth/frontera-ui-components";
import { classNames } from "@fronterahealth/frontera-ui-components/utils/utils";

import { useBillingDetailsQuery } from "@api/graphql/types-and-hooks";

interface PurchaseItem {
  quantity: string;
  createdDate?: string;
  amount: string;
  userStripeAccount?: {
    pendingAssessment: number;
  };
  invoiceUrl: string;
  transactionType: "CREDIT";
}

interface CreditItem {
  quantity: number;
  createdDate?: string | Date;
  amount?: string; // Add optional amount property
  invoiceUrl?: string; // Add optional invoiceUrl property
  userStripeAccount?: {
    pendingAssessment: number;
  };
  transactionType: "DEBIT";
}

type ColumnItem = PurchaseItem | CreditItem | null;

const purchasesColumns: Column<ColumnItem>[] = [
  {
    dataIndex: "quantity" as never,
    key: "item",
    title: "Item",
    render: (value: string | number | Date | { pendingAssessment: number } | undefined) => {
      if (typeof value === "object" && "pendingAssessment" in value) {
        return (
          <div className="font-semibold">
            {value.pendingAssessment} Credit{value.pendingAssessment !== 1 ? "s" : ""}
          </div>
        );
      }
      return (
        <div className="font-semibold">
          {String(value)} Credit{value !== 1 ? "s" : ""}
        </div>
      );
    },
  },
  {
    dataIndex: "createdDate" as never,
    key: "date",
    title: "Date",
    render: (value: string | number | Date | { pendingAssessment: number } | undefined) => (
      <div className="text-limestone-600">{format(value as string | number | Date, "MM/dd/yyyy") || ""}</div>
    ),
  },
  {
    dataIndex: "amount" as never,
    key: "amount",
    title: "Price",
    render: (value) => <div className="text-limestone-600">${value}</div>,
  },
];

const creditsColumns: Column<ColumnItem>[] = [
  {
    dataIndex: "quantity" as never,
    key: "credits",
    title: "Credits Used",
    render: (value: string | number | Date | { pendingAssessment: number } | undefined) =>
      typeof value === "number" ? (
        <div className="font-semibold">
          {value} Credit{value !== 1 ? "s" : ""}
        </div>
      ) : (
        ""
      ),
  },
  {
    dataIndex: "createdDate" as never,
    key: "date",
    title: "Date",
    render: (value: string | number | Date | { pendingAssessment: number } | undefined) =>
      value ? <div className="text-limestone-600">{format(value as string | number | Date, "MM/dd/yyyy")}</div> : "",
  },
];

export const BillingTable = () => {
  const [selected, setBilling] = useState("purchases");

  const { data: billingData, error, isFetching, isLoading } = useBillingDetailsQuery();

  const transactions = billingData?.getUserBillingDetails?.edges.map((edge) => edge?.node) as (
    | PurchaseItem
    | CreditItem
    | null
    | undefined
  )[];

  if (error) {
    console.error(error);
  }

  if (isFetching) {
    return <div>Loading...</div>;
  }

  const transactionCategories: { [key: string]: (ColumnItem | null)[] | undefined } = {
    credits: transactions?.filter((transaction) => transaction?.transactionType === "DEBIT") as (CreditItem | null)[],
    purchases: transactions?.filter(
      (transaction) => transaction?.transactionType === "CREDIT",
    ) as (PurchaseItem | null)[],
  };

  const selectBilling = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setBilling(target.innerText === "Purchases" ? "purchases" : "credits");
  };

  return (
    <div className="w-full bg-white mt-8 rounded-lg">
      <div className="flex ">
        <div
          className={classNames(
            selected === "purchases" ? "border-b-2 border-[#16803c]" : "",
            "mr-4 cursor-pointer pb-4",
          )}
          onClick={selectBilling}
        >
          Purchases
        </div>
        <div
          className={classNames(selected === "credits" ? "border-b-2 border-[#16803c]" : "", "cursor-pointer pb-4")}
          onClick={selectBilling}
        >
          Credit Usage
        </div>
      </div>
      <Table
        showPagination
        loading={isLoading}
        defaultItemsPerPage={5}
        inputClassName="w-96"
        itemsPerPageOptions={[5, 10, 20, 50]}
        data={transactionCategories[selected] || []}
        columns={selected === "purchases" ? purchasesColumns : creditsColumns}
      />
    </div>
  );
};

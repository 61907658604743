import { GenderApiValues } from "@utils/constants";
import { getGenderValue, setGenderValue } from "@utils/helpers";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import {
  UpdateProviderMutationVariables,
  useProviderByIdQuery,
  useUpdateProviderMutation,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useProvidersData } from "@providers/ProvidersDataProvider";

export const ProviderDetailsDetails: React.FC = () => {
  const { providerId } = useParams();
  const { providersQuery } = useProvidersData();

  const providerDetailsQuery = useProviderByIdQuery(
    { providerId: providerId ? providerId : "" },
    {
      queryKey: ["provider-by-id", providerId],
      enabled: !!providerId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch, isFetching } = providerDetailsQuery;

  const updateProviderDetailsMutation = useUpdateProviderMutation({});

  const { data: updateData } = updateProviderDetailsMutation;

  const { formState, onSubmit, RegisteredFormInput, RegisteredFormSelected, reset, watch } =
    useFormUtils<UpdateProviderMutationVariables>({
      mutationFn: async (params) => {
        await updateProviderDetailsMutation.mutateAsync({
          provider: {
            ...params.provider,
            gender: setGenderValue(params.provider.gender),
          },
          providerId: providerId ? providerId : "",
        });
        await refetch();
        await providersQuery.refetch();
      },
    });

  useEffect(() => {
    if (data && data.providerById?.providermetadata) {
      const learnersData = data.providerById?.learnerproviderSet.edges;
      const providerData = data.providerById?.providermetadata;

      reset({
        provider: {
          firstName: providerData?.firstName,
          lastName: providerData?.lastName,
          gender: getGenderValue(providerData?.gender) || "",
          specifyGender: providerData?.specifyGender || "",
          learners: learnersData.length ? learnersData.map((item) => item?.node?.learner.id) : [],
        },
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (updateData?.updateProvider) {
      if (updateData?.updateProvider.status) {
        notifySuccess(updateData.updateProvider?.message ? updateData.updateProvider?.message : "");
      } else {
        notifyError(updateData.updateProvider?.message ? updateData.updateProvider?.message : "");
      }
    }
  }, [updateData]);

  const genderWatch = watch("provider.gender");

  const formLoading = isFetching;

  return (
    <div className="flex w-full flex-col items-start">
      <FormContainer onSubmit={onSubmit}>
        <DoubleColumnContainer>
          <RegisteredFormInput
            isLoading={formLoading}
            formKey="provider.firstName"
            inputSize="full"
            formState={formState}
            label="First Name"
          />
          <RegisteredFormInput formKey="provider.lastName" inputSize="full" formState={formState} label="Last Name" />
        </DoubleColumnContainer>

        <DoubleColumnContainer>
          <RegisteredFormSelected
            isLoading={formLoading}
            formKey="provider.gender"
            formState={formState}
            placeholderText={""}
            errorMessage={formState.errors.provider?.gender?.message?.toString()}
            title="Gender"
            items={
              Object.values(GenderApiValues).map((o) => ({
                primary: getGenderValue(o),
              })) as SelectItem[]
            }
          />
          {genderWatch === "Gender identity is not listed" && (
            <RegisteredFormInput
              formKey="provider.specifyGender"
              formState={formState}
              isLoading={formLoading}
              required={false}
              label="Specify Gender"
            />
          )}
        </DoubleColumnContainer>
        <div className="mt-6">
          <SubmitButton isLoading={formState.isSubmitting} buttonText="Update" />
        </div>
      </FormContainer>
    </div>
  );
};

import { useParams } from "react-router-dom";

import { SelectItem } from "@fronterahealth/frontera-ui-components";

import { AssessmentReportFieldsEnums, useAddUpdateAssessmentFieldValuesMutation } from "@api/graphql/types-and-hooks";
import { InputType, SmartInputField } from "@components/SmartInputField/SmartInputField";
import { AssessmentReportHeadingType } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/ReportSummaryPart1";
import { fieldEnumToHumanLabelMapping } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/assessmentFieldEnumMapping";

interface SmartInputFieldWrapperProps {
  section: AssessmentReportHeadingType;
  field: AssessmentReportFieldsEnums;
  existingContent?: string | null;
  behaviorIndex?: number | null;
  onValueSave?: () => void;
  inputType: InputType;
  items?: SelectItem[];
  displayOnlyMode?: boolean;
}

export const SmartInputFieldWrapper: React.FC<SmartInputFieldWrapperProps> = ({
  field,
  section,
  existingContent,
  behaviorIndex,
  onValueSave,
  inputType,
  items,
  displayOnlyMode,
}) => {
  const { assessmentId } = useParams();
  const addUpdateAssessmentFieldValuesMutation = useAddUpdateAssessmentFieldValuesMutation({});
  const { isPending } = addUpdateAssessmentFieldValuesMutation;
  const isSaving = isPending;

  const handleSave = async ({ content }: { content: string }) => {
    await addUpdateAssessmentFieldValuesMutation.mutateAsync({
      assessmentId: assessmentId || "<missing-id>",
      assessmentFieldDetail: {
        fieldKey: field,
        fieldValue: content,
        behaviorIndex: behaviorIndex,
      },
    });
    if (section === "BIP" && onValueSave) {
      onValueSave();
    }
  };

  return (
    <SmartInputField
      isSaving={isSaving}
      onSave={handleSave}
      existingContent={existingContent || ""}
      key={`${section}-${field}`}
      title={fieldEnumToHumanLabelMapping[field]}
      placeholder="Fill me in"
      inputType={inputType}
      items={items}
      displayOnlyMode={displayOnlyMode}
    />
  );
};

import { getDifferenceInYearsAndMonths } from "@utils/utils";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import { camelCase, snakeCase } from "lodash";
import PizZip from "pizzip";
import React, { useEffect, useState } from "react";

import { Button, CheckboxList, Dialog, Paragraph } from "@fronterahealth/frontera-ui-components";

import { useGetLongTermShortTermGoalsQuery } from "@api/graphql/types-and-hooks";
import {
  ExportWithTemplateProps,
  FieldMap,
  GoalTypeData,
  MappedGoal,
  SignatureType,
  createGoalTypeSummary,
  markdownToDocxtemplater,
} from "@components/PDFGenerator/docxTemplateUtils";
import { generateConfidentialityNotice } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart1/helpers";
import { LTG_STG_Targets_To_Overview } from "@pages/AssessmentReportDetails/AssessmentReportSubPages/ReportSummaries/ReportSummaryPart2/ReportSummaryPart2";

// Commented out sections are those that do not use a WYSWYG field
const fieldsWithCustomMarkdown = [
  "ABA_BACKGROUND_IN_SCHOOL",
  "ALLERGIES_OR_DIETARY_RESTRICTIONS",
  "ASSESSMENT_SUMMARY",
  "ASSESSMENT_WRITE_UP",
  "ASSESSMENTS_OR_INFORMATION_USED",
  // "BCBA_COMPLETING_ASSESSMENT_AND_REPORT_WRITE_UP",
  "BEHAVIOR_INTERVENTION_PLAN_BIP",
  // "BEHAVIOR_REDUCTION_SUMMARY",
  "CLINICAL_OBSERVATION_DURING_ASSESSMENT",
  "COORDINATION_OF_CARE",
  // "DATE_AND_TIME_OF_ASSESSMENT",
  // "DATE_OF_DIAGNOSIS",
  // "DATE_OF_REPORT_WRITE_UP",
  // "DEVELOPMENTAL_DISABILITY_DIAGNOSIS",
  "DEVELOPMENTAL_HISTORY",
  "DIAGNOSTIC_INFORMATION",
  "DISCHARGE_PLAN",
  "EMERGENCY_PLAN",
  "FAMILY_HISTORY_OF_DIAGNOSIS",
  "FAMILY_HISTORY_OR_INFORMATION",
  "GRADE_AND_EDUCATIONAL_SETTING",
  "IEP_OR_BIP_IN_SCHOOL",
  // "INSURANCE_NAME",
  "KNOWN_AVERSIONS",
  "LEARNER_STRENGTHS",
  // "LOCATION_OF_SERVICES",
  "MEDICAL_NECESSITY",
  "MEDICATION_PRESCRIBED",
  // "MEMBER_ID",
  "NAME_OF_SCHOOL",
  "OTHER_HEALTH_CONCERNS_OF_CLIENT",
  "OTHER_MEDICAL_DIAGNOSES_OF_CLIENT",
  // "PARENT_CAREGIVER_SUMMARY",
  "PREFERRED_ITEMS",
  "PROGRAM_CONTACTS_FOR_CLIENT",
  "PROGRAM_RECOMMENDATIONS",
  "QABF_OR_NARRATIVE_FUNCTIONAL_ASSESSMENT",
  "REASON_FOR_REFERRAL",
  // "REPLACEMENT_BEHAVIOR_SUMMARY",
  // "REPORT_SIGNATURE",
  "RISK_EVALUATION",
  // "SKILL_ACQUISITION_SUMMARY",
  "THERAPEUTIC_SERVICES_IN_SCHOOL_AND_HOURS",
  "TITRATION_PLAN",
];
interface FormData {
  [key: string]: boolean;
}

const initialSelectedData: FormData = {
  "reportItems-ltg": true,
  "reportItems-stg": true,
  "reportItems-targets": true,
};

interface BIPTargetBehavior {
  title: string;
  value: string;
}

interface BIPStructure {
  targetBehaviors: BIPTargetBehavior[];
}

const ExportWithTemplate: React.FC<ExportWithTemplateProps> = ({ assessmentData }) => {
  const learnerId = assessmentData?.learner?.id || "<missing-learner-id>";
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<FormData>(initialSelectedData);
  const { data: longTermShortTermTargets } = useGetLongTermShortTermGoalsQuery(
    { assessmentId: assessmentData.id ? assessmentData.id : "<missing-assessmentId>" },
    {
      queryKey: ["get-long-term-short-term-goals-query", learnerId],
      enabled: !!assessmentData && !!learnerId,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const goalItems = LTG_STG_Targets_To_Overview(longTermShortTermTargets);

  const exportToDocx = async (selectedData: FormData) => {
    const {
      assessmentClientDetails,
      assessmentReportFields,
      assessmentHistoryOfServices,
      assessmentTreatmentRecommendationService,
    } = assessmentData;
    const clientName = `${assessmentClientDetails?.clientFirstName} ${assessmentClientDetails?.clientLastName}` || "";
    const age = getDifferenceInYearsAndMonths(assessmentClientDetails?.birthDate, assessmentData?.createdDate);
    const fieldMap: FieldMap = {
      clientName: clientName,
      guardianName: `${assessmentClientDetails?.guardianFirstName} ${assessmentClientDetails?.guardianLastName}`,
      address: `${assessmentClientDetails?.address}${assessmentClientDetails?.addressLine2 ? "\n" + assessmentClientDetails.addressLine2 : ""}`,
      cityStateZip: `${assessmentClientDetails?.city}, ${assessmentClientDetails?.state} ${assessmentClientDetails?.zip}`,
      dateOfBirth: assessmentClientDetails?.birthDate,
      age: age || "",
      statementOfConfidentiality:
        assessmentClientDetails?.clientFirstName &&
        generateConfidentialityNotice(assessmentClientDetails?.clientFirstName),
      currentDate: new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      showLtg: selectedData["reportItems-ltg"],
      showStg: selectedData["reportItems-stg"],
      showTargets: selectedData["reportItems-targets"],
    };

    const behaviorInterventionPlanBip: BIPStructure = {
      targetBehaviors: [],
    };
    let targetCounter = 1;

    assessmentReportFields.edges.forEach(async (edge) => {
      if (edge?.node) {
        const camelCaseKey = camelCase(edge.node.fieldKey);
        if (edge.node.fieldKey === "BEHAVIOR_INTERVENTION_PLAN_BIP") {
          const convertedValue = markdownToDocxtemplater(edge.node.value);
          behaviorInterventionPlanBip.targetBehaviors.push({
            title: `Target Behavior ${targetCounter}`,
            value: convertedValue,
          });

          targetCounter++;
        } else if (fieldsWithCustomMarkdown.includes(edge.node.fieldKey)) {
          const xmlConvert = markdownToDocxtemplater(edge.node.value);
          fieldMap[camelCaseKey] = xmlConvert;
        } else {
          fieldMap[camelCaseKey] = edge.node.value;
        }
      }
    });

    const historyOfServices = assessmentHistoryOfServices.edges.map((edge) => {
      if (edge?.node) {
        return {
          hours: edge.node.hours,
          service: edge.node.service,
          location: edge.node.location,
          provider: edge.node.provider,
        };
      }
    });

    const treatmentRecommendations = assessmentTreatmentRecommendationService.edges.map((edge) => {
      if (edge?.node) {
        return {
          codeNumber: edge.node.codeNumber,
          service: edge.node.service,
          hoursRequested: edge.node.hoursRequested,
          frequency: edge.node.frequency,
          totalUnitsPerAuth: edge.node.totalUnitsPerAuth,
        };
      }
    });

    const structuredGoals = createGoalTypeSummary(goalItems as MappedGoal[]);
    const goalsList: GoalTypeData[] = Object.entries(structuredGoals).map(([goalType, { goals, formattedType }]) => {
      let summaryKey = `${camelCase(goalType)}Summary`;
      if (goalType === "PARENT_CAREGIVER_INVOLVEMENT") {
        summaryKey = "parentCaregiverSummary";
      }
      const goalTypeData: GoalTypeData = {
        goalType,
        formattedGoalType: formattedType,
        summary: (fieldMap[summaryKey] as string) || "",
        goals,
      };

      return goalTypeData;
    });

    // Process signature
    if (fieldMap.reportSignature) {
      const reportSignature = JSON.parse(fieldMap.reportSignature as string) as SignatureType;
      fieldMap.signatureName = (reportSignature as SignatureType).signature || "";
      fieldMap.signatureDate = new Date((reportSignature as SignatureType).date).toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } else {
      fieldMap.signatureName = "";
      fieldMap.signatureDate = "";
    }

    fetch("/assessment_report_docx_template.docx")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.arrayBuffer();
      })
      .then((buffer) => {
        const zip = new PizZip(buffer);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        const templateData = {
          ...fieldMap,
          goalsList,
          behaviorInterventionPlanBip: behaviorInterventionPlanBip.targetBehaviors,
          historyOfServices,
          treatmentRecommendations,
        };
        doc.setData(templateData);

        try {
          doc.render();
        } catch (error) {
          console.error(JSON.stringify({ error: error }));
          return;
        }
        const blob = doc.getZip().generate({ type: "blob" });
        saveAs(blob, `${snakeCase(clientName)}_assessment_report.docx`);
      })
      .catch((error) => console.error("Error fetching template:", error));
  };

  const resetSelectedData = () => {
    setSelectedData(initialSelectedData);
  };

  useEffect(() => {
    if (!open) {
      setSelectedData(initialSelectedData);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    resetSelectedData();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setSelectedData((prevData) => ({
      ...prevData,
      [id]: checked,
    }));
  };

  return (
    <>
      <Button text="Export" id="report-export-btn" appearance="primary" onClick={() => setOpen(true)} />
      <Dialog
        title="Report Settings"
        open={open}
        setOpen={(isOpen) => {
          if (!isOpen) {
            handleClose();
          }
        }}
        primaryButton={{
          text: "Apply",
          onClick: () => {
            setOpen(false);
            exportToDocx(selectedData);
          },
        }}
        secondaryButton={{ text: "Cancel", onClick: handleClose }}
        size="lg"
      >
        <CheckboxList
          name="reportItems"
          legend="Report Items"
          title="Choose the items to include in your exported report."
          onChange={handleChange}
          items={[
            {
              id: "ltg",
              title: "Long Term Goals",
              defaultChecked: initialSelectedData["reportItems-ltg"],
            },
            {
              id: "stg",
              title: "Short Term Goals",
              defaultChecked: initialSelectedData["reportItems-stg"],
            },
            {
              id: "targets",
              title: "Targets",
              defaultChecked: initialSelectedData["reportItems-targets"],
            },
          ]}
        />
        <Paragraph displayType="normal" colorType="secondary">
          Note: These items will still be available for review and editing within the tool.
        </Paragraph>
      </Dialog>
    </>
  );
};

export default ExportWithTemplate;

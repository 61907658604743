import { Dialog } from "@fronterahealth/frontera-ui-components";

interface DeleteFolderDialogProps {
  onDeleteClick: () => void;
  onCancelClick: () => void;
  open: boolean;
}

export const DeleteFolderDialog: React.FC<DeleteFolderDialogProps> = ({ onDeleteClick, onCancelClick, open }) => {
  return (
    <Dialog
      size="xl"
      showCloseButton={false}
      title="Delete Folder"
      open={open}
      setOpen={() => {}}
      primaryButton={{
        text: "Yes, Delete",
        onClick: onDeleteClick,
        type: "submit",
        buttonAction: "destructive",
      }}
      secondaryButton={{
        text: "No, Cancel",
        onClick: onCancelClick,
      }}
    >
      <div className="py-2 text-text-primary text-base">
        Are you sure you want to delete this folder? You will lose all goals within the folder. This action cannot be
        undone.
      </div>
    </Dialog>
  );
};

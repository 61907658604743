import { useState } from "react";

import { Badge, BadgeProps, Select, SelectItem, Table } from "@fronterahealth/frontera-ui-components";

import { ApiTherapySessionSessionStatusChoices } from "@api/graphql/types-and-hooks";
import { convertReadableString } from "@components/forms/utils";
import { SessionAnalyzerClientType } from "@pages/SessionAnalyzer/DataHelpers";

export const BadgeSettingForStatus: { [key in ApiTherapySessionSessionStatusChoices]: BadgeProps["appearance"] } = {
  COMPLETED: "success",
  RECORDING: "warn",
  FAILED: "error",
  PROCESSING: "ai",
  SCHEDULED: "neutral",
  UPLOADING: "warn",
};

export interface SessionAnalyzerTypeProps {
  clients: (SessionAnalyzerClientType | null)[];
  onRowClick: (client: SessionAnalyzerClientType) => void;
  loading?: boolean;
}

export const SessionLearners: React.FC<SessionAnalyzerTypeProps> = ({ clients, onRowClick, loading }) => {
  const [sessionStatus, setSessionStatus] = useState<SelectItem | undefined>();

  return (
    <div className="mt-8 flow-root w-full bg-white rounded-lg">
      <Table
        loading={loading}
        data={
          (sessionStatus && clients
            ? clients.filter(
                (item) => item?.status && item?.status.toLowerCase() === sessionStatus?.primary.toLowerCase(),
              )
            : clients) as unknown as SessionAnalyzerClientType[]
        }
        columns={[
          { dataIndex: "name", key: "name", title: "Client Name" },
          { dataIndex: "technician", key: "technician", title: "Technicians" },

          { dataIndex: "lastSession", key: "lastSession", title: "Last Session" },
          {
            dataIndex: "status",
            key: "status",
            title: "Status",
            render: (value) => (
              <div className="min-w-36">
                <Badge
                  appearance={BadgeSettingForStatus[value as ApiTherapySessionSessionStatusChoices]}
                  text={convertReadableString(value)}
                />
              </div>
            ),
          },

          { dataIndex: "engagement", key: "engagement", title: "Engagement" },
        ]}
        showSearch
        showPagination
        defaultItemsPerPage={10}
        onRowClick={onRowClick}
        headerContent={
          <div className="flex flex-row align-center">
            <Select
              items={Object.values(ApiTherapySessionSessionStatusChoices).map((o) => ({
                primary: convertReadableString(o),
              }))}
              actionItem={{
                label: "Clear",
                onClick: () => {
                  setSessionStatus(undefined);
                },
              }}
              title=""
              placeholderText=" Session Status "
              selected={sessionStatus}
              setSelected={setSessionStatus}
              formNoValidate
            />
          </div>
        }
      />
    </div>
  );
};

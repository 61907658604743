import { useParams } from "react-router-dom";

import { useGetLearnerConsentDetailsQuery } from "@api/graphql/types-and-hooks";
import { ConsentData, ConsentFilesTable } from "@components/ConsentFilesTable/ConsentFilesTable";
import { convertReadableString } from "@components/forms/utils";

export const ClientDetailsFiles: React.FC = () => {
  const { clientId } = useParams();
  const { data, isLoading } = useGetLearnerConsentDetailsQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-consents", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const consentData: ConsentData[] =
    data?.learnerById?.learnerconsentfilesSet?.edges?.map((item) => ({
      name: convertReadableString(item?.node?.consentType || ""),
      type: "PDF",
      size: item?.node?.fileSizeInBytes as unknown as number,
      lastUpdatedDate: item?.node?.lastUpdatedDate ? new Date(item?.node?.lastUpdatedDate) : null,
      signedURL: item?.node?.signedConsentFileUrl || "",
    })) || [];

  return (
    <>
      <ConsentFilesTable data={consentData} onDownload={(row) => window.open(row.signedURL)} isLoading={isLoading} />
    </>
  );
};

import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Paragraph } from "@fronterahealth/frontera-ui-components";

import {
  BehaviorsQuestionType,
  LearnerTendenciesQuery,
  TendenciesQuestionType,
  useLearnerTendenciesQuery,
} from "@api/graphql/types-and-hooks";
import { useFormUtils } from "@components/forms/useFormUtils";
import {
  QuestionData,
  commaSeparatedDataToQuestionsmap,
  convertOnboardingQuestionListToQuestionMap,
} from "@components/forms/utils";
import {
  TendenciesQuestionGroupings,
  TendencyRoutes,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Tendencies/TendenciesQuestionGroupings";
import { tendenciesQuestionsMapping } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Tendencies/TendenciesQuestions.languageMapping";
import {
  NeverOrRarelySometimesOftenAlmostAlwaysItems,
  extractPath,
  traumaHistoryChoices,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const Tendencies: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();

  const subpage = pathname.split("/").pop();
  const extractedPath = extractPath(pathname);
  const customQueryKey = pathname.includes("all") ? "tendencies" : undefined;

  const questionPageOrigin = pathname.includes("all") ? undefined : `tendencies/${subpage}`;
  const questionsForThisPage = TendenciesQuestionGroupings[extractedPath as TendencyRoutes];

  const config = useGetQueryConfig<LearnerTendenciesQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerTendenciesQuery(
    { learnerId: clientId ? clientId : "", questionPageOrigin },
    config,
  );
  const { data } = learnerDetailsQuery;

  const {
    formState,
    onSubmit,
    RegisteredFormTextArea,
    RegisteredCheckboxList,
    RegisteredFormRadioInput,
    reset,
    watch,
  } = useFormUtils<{
    [key in BehaviorsQuestionType]: string;
  }>({
    // TODO: Needs to be better tested, documented
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  useEffect(() => {
    if (data) {
      const behaviorQuestions = data.learnerById?.onboardingQuestionsForLearner.edges
        .filter((question) => question?.node?.questionPageOrigin?.includes("tendencies"))
        .map((edge) => ({
          ...edge?.node,
        }));

      const formattedQuestions = convertOnboardingQuestionListToQuestionMap(behaviorQuestions as QuestionData[]);
      const seperatedAnswers =
        subpage === "trauma" ? commaSeparatedDataToQuestionsmap(formattedQuestions) : formattedQuestions;

      reset(seperatedAnswers);
    }
  }, [data, reset]);

  const clientHasTraumaHistory = // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
    watch(`${TendenciesQuestionType.ClientHaveHistory}`)?.length;

  return (
    <form onSubmit={onSubmit} className="flex min-h-[500px] flex-col justify-between px-2" noValidate>
      <div className="flex-col">
        <Paragraph className="mb-4">
          Select which rating best describes the client's behavior during the past month
        </Paragraph>

        {subpage === "trauma" ? (
          <RegisteredCheckboxList
            // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
            formKey={`${TendenciesQuestionType.ClientHaveHistory}`}
            title={tendenciesQuestionsMapping[TendenciesQuestionType.ClientHaveHistory][chosenLanguage]}
            required={false}
            legend={tendenciesQuestionsMapping[TendenciesQuestionType.ClientHaveHistory][chosenLanguage]}
            orientation="horizontal"
            items={traumaHistoryChoices(chosenLanguage)}
            disabled
          />
        ) : null}
        {Object.values(TendenciesQuestionType)
          .filter((q) => questionsForThisPage.includes(q))
          .filter((q) => q !== TendenciesQuestionType.ClientHaveHistory)
          .map((questionType) => {
            return (
              <>
                {(subpage === "trauma" && clientHasTraumaHistory) || subpage !== "trauma" ? (
                  <>
                    {questionType.includes("COMMENT") ? (
                      <div className="afterPageBreak">
                        <RegisteredFormTextArea
                          formState={formState}
                          required={false}
                          label={
                            tendenciesQuestionsMapping[questionType][chosenLanguage] +
                            ` (${questionType.split("_")[0].toLowerCase()})`
                          }
                          // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                          formKey={`${questionType}`}
                          readOnly
                        />
                      </div>
                    ) : (
                      <RegisteredFormRadioInput
                        key={questionType}
                        items={NeverOrRarelySometimesOftenAlmostAlwaysItems(chosenLanguage)}
                        title={tendenciesQuestionsMapping[questionType][chosenLanguage]}
                        // @ts-ignore: Ignoring the compiler and risking bugs because: See comment above
                        formKey={`${questionType}`}
                        formState={formState}
                        disabled
                      />
                    )}
                  </>
                ) : null}
              </>
            );
          })}
      </div>
    </form>
  );
};

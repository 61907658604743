import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  LearnerEarlyInterventionServicesQuery,
  useLearnerEarlyInterventionServicesQuery,
} from "@api/graphql/types-and-hooks";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { transformQueryData } from "@components/forms/utils";
import {
  LearnerEarlyInterventionServicesDataType,
  earlyInterventionServicesMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/Development/EarlyInterventionServices/earlyInterventionServicesMapping";
import { YesNoRadioItems } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

type LearnerEarlyInterventionServicesType = NonNullable<
  NonNullable<LearnerEarlyInterventionServicesQuery["learnerById"]>["learnerEarlyInterventionServices"]
>;

export const extractEarlyInterventionServicesData = (queryData: LearnerEarlyInterventionServicesQuery) => {
  const converted = transformQueryData(
    { ...(queryData?.learnerById?.learnerEarlyInterventionServices || {}) },
    {
      clientEarlyInterventionEnrollment: (value) => (value ? "yes" : "no"),
    },
  ) as unknown as LearnerEarlyInterventionServicesType;
  return converted;
};

export const EarlyInterventionServices: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/development/early-intervention-services" : undefined;

  const config = useGetQueryConfig<LearnerEarlyInterventionServicesQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerEarlyInterventionServicesQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data } = learnerDetailsQuery;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormRadioInput,
    RegisteredFormTextArea,
    reset,

    watch,
  } = useFormUtils<LearnerEarlyInterventionServicesDataType>({
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  useEffect(() => {
    if (data && data.learnerById) {
      const converted = extractEarlyInterventionServicesData(data);
      reset(converted);
    }
  }, [data, reset]);

  // @ts-ignore: Ignoring the compiler and risking bugs because: We are hacking booleans and radio inputs
  const clientEnrolledInEarlyInterventionServices = watch("clientEarlyInterventionEnrollment") === "yes";

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        <div className="flex-col">
          <RegisteredFormRadioInput
            title={earlyInterventionServicesMapping.clientEarlyInterventionEnrollment[chosenLanguage]}
            disabled
            items={YesNoRadioItems}
            formState={formState}
            formKey="clientEarlyInterventionEnrollment"
          />
          {clientEnrolledInEarlyInterventionServices && (
            <>
              <RegisteredFormInput
                label={
                  earlyInterventionServicesMapping.earlyInterventionStartAge
                    ? earlyInterventionServicesMapping.earlyInterventionStartAge[chosenLanguage]
                    : ""
                }
                readOnly
                formState={formState}
                formKey={`earlyInterventionStartAge`}
              />
              <RegisteredFormTextArea
                label={
                  earlyInterventionServicesMapping.dismissedOrTransferredToPreschool
                    ? earlyInterventionServicesMapping.dismissedOrTransferredToPreschool[chosenLanguage]
                    : ""
                }
                readOnly
                formState={formState}
                formKey="dismissedOrTransferredToPreschool"
              />
              <RegisteredFormTextArea
                label={
                  earlyInterventionServicesMapping.specialEducationOrOutsideServices
                    ? earlyInterventionServicesMapping.specialEducationOrOutsideServices[chosenLanguage]
                    : ""
                }
                readOnly
                formState={formState}
                formKey="specialEducationOrOutsideServices"
              />
            </>
          )}
        </div>
      </FormContainer>
    </div>
  );
};

import {
  LearnerMedicalHistoryQuery,
  LearnerMedicationsType,
  LearnerOnboardingQuestionsType,
} from "@api/graphql/types-and-hooks";
import { LanguageMappingInterface } from "@components/forms/interfaces";

export type OnboardingQuestion = NonNullable<
  Omit<LearnerOnboardingQuestionsType, "id" | "createdDate" | "learner" | "lastUpdatedDate" | "organization">
>;

export type Medication = NonNullable<
  Omit<LearnerMedicationsType, "id" | "createdDate" | "learner" | "lastUpdatedDate" | "organization">
>;

export type MedicalHistoryMetadata = NonNullable<
  NonNullable<NonNullable<LearnerMedicalHistoryQuery["learnerById"]>["learnermedicalhistorymetadata"]>
>;

export type MedicalHistory = NonNullable<
  NonNullable<NonNullable<LearnerMedicalHistoryQuery["learnerById"]>["medicalHistoryForLearner"]>
>;
export type MedicalHistoryDataType = {
  onboardingQuestionsForLearner: OnboardingQuestion;
  medicationsForLearner: Medication[];
  learnermedicalhistorymetadata: MedicalHistoryMetadata;
  medicalHistoryForLearner: MedicalHistory;
};
export const medicalHistoryMapping: LanguageMappingInterface<MedicalHistoryDataType> = {
  medicalHistoryForLearner: {
    primaryCareProvider: { en: "Primary Care Provider", es: "Proveedor de Atención Primaria" },
    address: { en: "Address Line 1", es: "Dirección Línea 1" },
    addressLine2: { en: "Address Line 2", es: "Dirección Línea 2" },
    city: { en: "City", es: "Ciudad" },
    state: { en: "State", es: "Estado" },
    zip: { en: "ZIP", es: "Código Postal" },
  },
  learnermedicalhistorymetadata: {
    clientHasBeenHospitalized: {
      en: "Has the client ever been hospitalized in a psychiatric hospital?",
      es: "¿Ha estado hospitalizado/a alguna vez en un hospital psiquiátrico?",
    },
    clientHospitalizedDetails: {
      en: "When did that occur, and why?",
      es: "Detalles de la hospitalización en el hospital psiquiátrico",
    },
    clientHasCurrentCounselor: {
      en: "Does the client currently have a counselor or therapist?",
      es: "¿Tiene el cliente actualmente un consejero o terapeuta?",
    },
    clientCurrentCounselorName: { en: "Counselor or Therapist Name", es: "Nombre del consejero o terapeuta" },
    clientCurrentCounselorPhone: {
      en: "Counselor or Therapist Phone Number",
      es: "Número de teléfono del consejero o terapeuta",
    },
    clientThoughtsForParticipatingCounseling: {
      en: "What does your child say (if anything) about participating in counseling? ",
      es: "Participación del niño/a en consejería",
    },
    clientHasMedicalDiagnoses: {
      en: "Does the client have other medical diagnoses?",
      es: "¿Tiene el cliente otros diagnósticos médicos?",
    },
    clientMedicalDiagnosesDetails: {
      en: "Please describe",
      es: "Descripción de otros diagnósticos médicos",
    },
    sideEffectsOfEmotionalBehaviourMedication: {
      en: "If the client takes medication for behavior or emotional issues, how is it working? List any adverse side effects.",
      es: "Si el cliente toma medicamentos para problemas emocionales o de conducta, ¿cómo está funcionando? Enumere los efectos secundarios adversos.",
    },
    allergiesOrDietaryRestrictions: {
      en: "Allergies or Dietary Restrictions",
      es: "Alergias o restricciones dietéticas",
    },
    familyHistoryOfDiagnoses: { en: "Family History of Diagnoses", es: "Historial familiar de diagnósticos" },
    hospitalizationsOrSurgeries: {
      en: "Hospitalizations or Surgeries",
      es: "Hospitalizaciones o cirugías",
    },
    clientOverallHealth: { en: "How is the client’s overall health?", es: "¿Cómo es la salud general del cliente?" },
    clientOtherHealthConcerns: { en: "Other Health Concerns", es: "Otras preocupaciones de salud" },
    clientGiDifficulties: {
      en: "Do the client have chronic difficulties with constipation or diarrhea?",
      es: "¿Tiene el cliente dificultades crónicas con estreñimiento o diarrea?",
    },
  },

  medicationsForLearner: {
    name: {
      en: "Name",
      es: "",
    },
    dosage: {
      en: "Dosage",
      es: "",
    },
    prescriber: {
      en: "Prescriber",
      es: "",
    },
  },

  onboardingQuestionsForLearner: {
    answer: {
      en: "",
      es: "",
    },
    questionKey: {
      en: "",
      es: "",
    },
    questionPageOrigin: {
      en: "",
      es: "",
    },
  },
};

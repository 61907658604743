import { RectangleStackIcon } from "@heroicons/react/24/outline";
import React, { PropsWithChildren } from "react";

import { Paragraph, Small } from "@fronterahealth/frontera-ui-components";
import { PopoverMenu } from "@fronterahealth/frontera-ui-components/PopoverActionMenu/PopoverActionMenu";

import { ShortTermGoalType, useRemoveShortTermGoalMutation } from "@api/graphql/types-and-hooks";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { useAssessmentBuilderData } from "@providers/AssessmentBuilderProvider";

interface ShortTermGoalCardProps extends PropsWithChildren {
  onClick: () => void;
  goalObject: ShortTermGoalType;
  refetchGoals: () => Promise<void>;
  onSaveToFolder?: () => void;
}

export const ShortTermGoalCard: React.FC<ShortTermGoalCardProps> = ({
  goalObject,
  onClick,
  children,
  refetchGoals,
  onSaveToFolder,
}) => {
  const { assessmentReport } = useAssessmentBuilderData();
  const learnerId = assessmentReport?.learner?.id || "<missing-learner-id>";
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const removeShortTermGoalMutation = useRemoveShortTermGoalMutation({});

  return (
    <div>
      <div
        onClick={onClick}
        className="hover:cursor-pointer hover:border-limestone-300  border transition-all rounded-md z-0 shadow p-4 pb-4 bg-white"
      >
        <div className="flex items-center justify-between mb-4">
          <div>
            <Paragraph displayType="loud" colorType="primary">
              <RectangleStackIcon className="mr-2 h-4 w-4 inline-block" />
              {goalObject?.goalName}
            </Paragraph>
          </div>

          <PopoverMenu
            openDirection="to-left"
            menuItems={[
              {
                label: "Edit",
                callback: () => onClick(),
              },

              {
                label: "Save to Folder...",
                callback: () => {
                  if (onSaveToFolder) onSaveToFolder();
                },
              },

              {
                label: deleteLoading ? "Deleting..." : "Delete",
                callback: async () => {
                  setDeleteLoading(true);
                  try {
                    await removeShortTermGoalMutation.mutateAsync({
                      learner: learnerId,
                      shortTermGoalId: goalObject.id,
                    });
                    notifySuccess("Successfully Deleted Short Term Goal");
                    await refetchGoals();
                    setDeleteLoading(false);
                  } catch (err) {
                    console.error("Failed To Delete Short Term Goal", err);
                    notifyError("Failed To Delete Short Term Goal");
                  }
                },
              },
            ]}
          />
        </div>
        <Small displayType="normal">{goalObject?.description}</Small>
        {children}
      </div>
    </div>
  );
};

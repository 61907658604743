import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { Divider, Heading, Paragraph } from "@fronterahealth/frontera-ui-components";

import {
  ApiLearnerLivingConditionsClientHouseholdParentCountChoices,
  LearnerLivingConditionsQuery,
  useLearnerLivingConditionsQuery,
} from "@api/graphql/types-and-hooks";
import { DoubleColumnContainer, FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { transformQueryData } from "@components/forms/utils";
import {
  LearnerIndividualsThatLiveInHomeDataType,
  LearnerLivingConditionDataType,
  LivingConditionDataType,
  livingConditionsMapping,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/HomeEnvironment/Mappings/livingConditionsMapping";
import {
  RELATIONSHIP_TO_CLIENT_OPTIONS,
  YesNoRadioItems,
} from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/helpers";
import { useGetQueryConfig } from "@pages/ClientDetailsSubPages/IntakeForm/IntakeFormSubPages/useGetQueryConfig";
import { useInternationalization } from "@providers/InternationalizationProvider";

export const extractLivingConditionData = (queryData: LearnerLivingConditionsQuery) => {
  const transformed = transformQueryData(queryData, {
    "learnerById.learnerlivingconditions.clientInFosterCare": (value) => (value ? "yes" : "no"),
    "learnerById.learnerlivingconditions.clientLivesBetweenParentsAndGrandparents": (value) => (value ? "yes" : "no"),
  });

  const converted: LivingConditionDataType = {
    learnerindividualsthatliveinhomeSet: transformed?.learnerById?.learnerindividualsthatliveinhomeSet?.edges?.map(
      (edge) => edge?.node,
    ) as unknown as Array<LearnerIndividualsThatLiveInHomeDataType>,
    learnerlivingconditions: transformed.learnerById
      ?.learnerlivingconditions as unknown as LearnerLivingConditionDataType,
  };
  return converted;
};

export const LivingConditions: React.FC = () => {
  const { clientId } = useParams();

  const { chosenLanguage } = useInternationalization();

  const { pathname } = useLocation();
  const customQueryKey = pathname.includes("all") ? "/home-environment/living-conditions" : undefined;

  const config = useGetQueryConfig<LearnerLivingConditionsQuery>(customQueryKey);
  const learnerDetailsQuery = useLearnerLivingConditionsQuery({ learnerId: clientId ? clientId : "" }, config);
  const { data, isFetching: formLoading } = learnerDetailsQuery;

  const {
    formState,
    onSubmit,
    RegisteredFormInput,
    RegisteredFormRadioInput,
    RegisteredFormSelected,
    RegisteredFormTextArea,
    reset,
    watch,
    control,
  } = useFormUtils<LivingConditionDataType>({
    mutationFn: async () => {
      await Promise.resolve();
    },
    displayOnlyMode: pathname.includes("all"),
  });

  const { fields } = useFieldArray({
    control,
    name: "learnerindividualsthatliveinhomeSet",
  });
  // @ts-ignore: Ignoring the compiler and risking bugs because: We are hacking booleans and radio inputs
  const clientInFosterCare = watch("learnerlivingconditions.clientInFosterCare") === "yes";
  const clientLivesInSingleParentHousehold =
    watch("learnerlivingconditions.clientHouseholdParentCount") ===
    ApiLearnerLivingConditionsClientHouseholdParentCountChoices.OneParent;

  useEffect(() => {
    if (data) {
      const converted = extractLivingConditionData(data);
      reset(converted);
    }
  }, [data, reset]);

  return (
    <div className="flex flex-col">
      <FormContainer onSubmit={onSubmit} noValidate>
        <div className="flex-col">
          <Paragraph className="my-4">
            Please list any <i>additional</i> Individuals that live in the home
          </Paragraph>

          <div className="flex-col">
            {fields.map((field, index) => {
              // return <FormInput<IGuardianDetailsInput> register={register} mapping={mapping} {...props} />;
              return (
                <div className="flex-col" key={field.id}>
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <Heading type="h3" className="my-4">
                        Individual {index + 1}
                      </Heading>
                    </div>
                  </div>

                  <DoubleColumnContainer>
                    <RegisteredFormInput
                      label={livingConditionsMapping.learnerindividualsthatliveinhomeSet.name[chosenLanguage]}
                      key={field.id}
                      formState={formState}
                      formKey={`learnerindividualsthatliveinhomeSet.${index}.name`}
                      isLoading={formLoading}
                      readOnly
                    />
                  </DoubleColumnContainer>
                  <DoubleColumnContainer>
                    <RegisteredFormInput
                      key={field.id}
                      label={livingConditionsMapping.learnerindividualsthatliveinhomeSet.age[chosenLanguage]}
                      formState={formState}
                      formKey={`learnerindividualsthatliveinhomeSet.${index}.age`}
                      isLoading={formLoading}
                      readOnly
                    />
                  </DoubleColumnContainer>

                  <DoubleColumnContainer>
                    <RegisteredFormSelected
                      title={
                        livingConditionsMapping.learnerindividualsthatliveinhomeSet.relationshipToClient[chosenLanguage]
                      }
                      formKey={`learnerindividualsthatliveinhomeSet.${index}.relationshipToClient`}
                      formState={formState}
                      items={RELATIONSHIP_TO_CLIENT_OPTIONS.map((o) => ({ primary: o }))}
                      placeholderText={""}
                      isLoading={formLoading}
                      readOnly
                    />
                  </DoubleColumnContainer>
                  <Divider />
                </div>
              );
            })}
          </div>

          <RegisteredFormTextArea
            label={
              livingConditionsMapping.learnerlivingconditions.delaysOrDiagnosesInClientSiblings?.[chosenLanguage] ?? ""
            }
            readOnly
            formKey="learnerlivingconditions.delaysOrDiagnosesInClientSiblings"
            formState={formState}
          />

          <RegisteredFormRadioInput
            title={livingConditionsMapping.learnerlivingconditions.clientInFosterCare[chosenLanguage]}
            items={YesNoRadioItems}
            formKey="learnerlivingconditions.clientInFosterCare"
            formState={formState}
            disabled
          />

          {clientInFosterCare ? (
            <>
              <DoubleColumnContainer>
                <RegisteredFormInput
                  readOnly
                  label={livingConditionsMapping.learnerlivingconditions.cyfdCaseManagerName?.[chosenLanguage] ?? ""}
                  formState={formState}
                  formKey="learnerlivingconditions.cyfdCaseManagerName"
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>

              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={livingConditionsMapping.learnerlivingconditions.cyfdCaseManagerPhone?.[chosenLanguage] ?? ""}
                  readOnly
                  formState={formState}
                  formKey="learnerlivingconditions.cyfdCaseManagerPhone"
                  isLoading={formLoading}
                />
              </DoubleColumnContainer>
            </>
          ) : null}

          <RegisteredFormRadioInput
            title={
              livingConditionsMapping.learnerlivingconditions.clientLivesBetweenParentsAndGrandparents?.[
                chosenLanguage
              ] ?? ""
            }
            items={YesNoRadioItems}
            formKey={`learnerlivingconditions.clientLivesBetweenParentsAndGrandparents`}
            formState={formState}
            disabled
          />

          <DoubleColumnContainer>
            <RegisteredFormRadioInput
              title={livingConditionsMapping.learnerlivingconditions.clientHouseholdParentCount?.[chosenLanguage] ?? ""}
              items={[
                { id: "TWO_PARENTS", title: "Two Parents" },
                { id: "ONE_PARENT", title: "One Parent" },
              ]}
              formKey={`learnerlivingconditions.clientHouseholdParentCount`}
              formState={formState}
              disabled
            />
          </DoubleColumnContainer>

          {clientLivesInSingleParentHousehold && (
            <>
              <DoubleColumnContainer>
                <RegisteredFormInput
                  label={
                    livingConditionsMapping.learnerlivingconditions.clientTimeSpentWithOtherParent?.[chosenLanguage] ??
                    ""
                  }
                  readOnly
                  formState={formState}
                  formKey="learnerlivingconditions.clientTimeSpentWithOtherParent"
                />
              </DoubleColumnContainer>
            </>
          )}

          <Divider />
        </div>
      </FormContainer>
    </div>
  );
};

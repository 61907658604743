import { SelectItem } from "@fronterahealth/frontera-ui-components";

import { MoveGoalBankLongTermGoalInput, useMoveLongTermGoalToFolderMutation } from "@api/graphql/types-and-hooks";
import { FolderManagementAction } from "@components/GoalsBank/constants";
import { FolderManagementDialog } from "@components/GoalsBank/dialogs/FolderManagementDialog";
import { FormContainer } from "@components/forms/FormLayout";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifySuccess } from "@components/notifications/notifications";

interface MoveLongTermGoalToFolderProps {
  currentFolderId: string;
  goalBankLongTermId?: string;
  onCloseClick: () => void;
  open: boolean;
  foldersToSelectFrom: SelectItem[];
  refetch: () => void;
}

export const MoveLongTermGoalToFolder: React.FC<MoveLongTermGoalToFolderProps> = ({
  currentFolderId,
  goalBankLongTermId,
  onCloseClick,
  open,
  foldersToSelectFrom,
  refetch,
}) => {
  const moveLongTermGoal = useMoveLongTermGoalToFolderMutation({});
  const { formState, RegisteredFormSelected, reset, onSubmit } = useFormUtils<MoveGoalBankLongTermGoalInput>({
    mutationFn: async (params) => {
      if (params.newFolderId && goalBankLongTermId)
        moveLongTermGoal.mutateAsync(
          {
            input: {
              goalBankLongTermId,
              currentFolderId,
              newFolderId: params.newFolderId,
            },
          },
          {
            onSuccess: async () => {
              await refetch();
              notifySuccess(`Successfully Moved Long Term Goal`);
              reset();
              onCloseClick();
            },
            onError: async (error: unknown) => {
              console.error(`Error when moving long term goal `, error);
            },
          },
        );
    },
  });

  return (
    <FolderManagementDialog
      action={FolderManagementAction.MOVE}
      open={open}
      onCancelClick={onCloseClick}
      onSaveClick={onSubmit}
    >
      <FormContainer onSubmit={onSubmit}>
        <RegisteredFormSelected
          formKey="newFolderId"
          required
          formState={formState}
          items={foldersToSelectFrom}
          title={"Select Folder"}
          placeholderText={"Select Folder"}
        />
      </FormContainer>
    </FolderManagementDialog>
  );
};

import { CommunicationQuestionType } from "@api/graphql/types-and-hooks";
import { LanguageObjectType } from "@components/forms/interfaces";

export const communicationQuestionsMapping: {
  [key in CommunicationQuestionType]: LanguageObjectType;
} = {
  HOW_CLIENT_LET_YOU_KNOW_WANTED: {
    en: "How would the client let you know what they wanted?",
    es: "¿Cómo te hacía saber el cliente lo que quería?",
  },
  USED_HAND_AS_TOOL: {
    en: "Did they ever use your hand as a tool?",
    es: "¿Alguna vez usaron tu mano como herramienta?",
  },
  TOOK_HAND_AND_LEAD_TO_WANTED_ITEM: {
    en: "Would they ever take your hand and lead you to what they wanted?",
    es: "¿Alguna vez tomaron tu mano y te llevaron a lo que querían?",
  },
  PLACED_HAND_ON_SOMETHING_TO_GET_MANIPULATION: {
    en: "What would they do once you got to what they wanted? Did they ever place your hand on something to get you to manipulate it?",
    es: "¿Qué hacían una vez que llegaban a lo que querían? ¿Alguna vez colocaron tu mano en algo para que lo manipularas?",
  },
  DIFFERENT_ODD_LANGUAGE_USE_EARLY_ON: {
    en: "Was there anything different or odd about how your child used language early on?",
    es: "¿Había algo diferente o extraño en cómo tu hijo usaba el lenguaje al principio?",
  },
  EXPRESSIVE_COMMUNICATION: {
    en: "Does the client use expressive communication?",
    es: "¿El cliente utiliza la comunicación expresiva?",
  },
  COMMUNICATION_CHALLENGES: {
    en: "What about now? Is there anything that gets in the way or makes communication hard for your child now?",
    es: "¿Qué hay de ahora? ¿Hay algo que dificulte o haga difícil la comunicación para tu hijo ahora?",
  },
  OFF_WORDS_OR_PHRASES: {
    en: "Do they ever use certain words or phrases that seem a little off to you?",
    es: "¿Alguna vez usan palabras o frases que te parecen un poco extrañas?",
  },
  REPETITIVE_PHRASES: {
    en: "Does the client ever use repetitive phrases?",
    es: "¿El cliente usa frases repetitivas?",
  },
  QUOTING_SCRIPTING: {
    en: "Does they ever seem to be quoting something or speaking from a script?",
    es: "¿Alguna vez parece que están citando algo o hablando a partir de un guion?",
  },
  NEOLOGISMS: {
    en: "Does the client ever use neologisms (creating new words or expressions)?",
    es: "¿El cliente alguna vez utiliza neologismos (creando nuevas palabras o expresiones)?",
  },
  IDIOSYNCRATIC_PHRASES_WORDS: {
    en: "Do they use idiosyncratic phrases/words (using normal words in an abnormal way)?",
    es: "¿Usan frases o palabras idiosincráticas (usando palabras normales de manera anormal)?",
  },
  UNDERSTANDING_WHAT_OTHERS_SAY: {
    en: "Do they generally understand what others say and the points they are trying to make?",
    es: "¿Suelen entender lo que dicen los demás y los puntos que intentan hacer?",
  },
  UNDERSTAND_DIRECTION_INSTRUCTIONS: {
    en: "Do they understand directions/instructions?",
    es: "¿Entienden direcciones/instrucciones?",
  },
  FIGURATIVE_LANGUAGE_SLANG: {
    en: "Do they use figurative language and slang?",
    es: "¿Usan lenguaje figurativo y jerga?",
  },
  PICK_UP_ON_SOCIAL_CUES: {
    en: "Can the client pick up on social cues? Are they able to tell when someone is bored, annoyed, mad, happy, joking?",
    es: "¿El cliente puede captar señales sociales? ¿Pueden decir cuándo alguien está aburrido, molesto, enojado, feliz, bromeando?",
  },
  USE_OF_EYE_CONTACT: {
    en: "Use of Eye Contact",
    es: "Uso del contacto visual",
  },
  USE_OF_FACIAL_EXPRESSIONS: {
    en: "Use of Facial Expressions",
    es: "Uso de expresiones faciales",
  },
  GESTURES: {
    en: "Gestures",
    es: "Gestos",
  },
  INTERACTING_WITH_OTHERS: {
    en: "How are they interacting with others? How has the client been with their peers?",
    es: "¿Cómo interactúan con los demás? ¿Cómo ha sido el cliente con sus compañeros?",
  },
  SOCIAL_INTEREST: {
    en: "Social Interest",
    es: "Interés social",
  },
  EFFORTS_TO_BE_WITH_OTHERS: {
    en: "Does the client make efforts to be with others? Does it seem like they want to?",
    es: "¿El cliente se esfuerza por estar con los demás? ¿Parece que quieren?",
  },
  INTERACTING_WITH_OTHERS_DESCRIPTION: {
    en: "Description of interacting with others",
    es: "Descripción de cómo interactúan con los demás",
  },
  UNDERSTANDING_PERSONAL_SPACE: {
    en: "Do they understand personal space?",
    es: "¿Entienden el espacio personal?",
  },
  STRANGER_DANGER_OR_OVERLY_FAMILIAR: {
    en: "Is the client aware of 'stranger danger' or when someone is being overly familiar?",
    es: "¿El cliente es consciente del 'peligro de los desconocidos' o cuando alguien es demasiado familiar?",
  },
  ACTING_DIFFERENTLY_IN_DIFFERENT_SITUATIONS: {
    en: "Do they act differently in different situations, like being more reserved in a church or library?",
    es: "¿Actúan de manera diferente en diferentes situaciones, como siendo más reservados en una iglesia o biblioteca?",
  },
  INTERPRETING_BEHAVIORS_AND_WORDS_OF_OTHERS_CORRECTLY: {
    en: "Interpreting behaviors and words of others correctly?",
    es: "¿Interpretan correctamente los comportamientos y palabras de los demás?",
  },
  KNOWING_WHEN_OTHERS_ARE_TAKING_ADVANTAGE: {
    en: "Knowing when others are taking advantage?",
    es: "¿Saben cuándo otros se están aprovechando?",
  },
  UNDERSTANDING_ACCIDENTS_AND_JOKES: {
    en: "Understanding accidents and jokes?",
    es: "¿Entienden accidentes y bromas?",
  },
  SEE_SITUATIONS_FROM_DIFFERENT_PERSPECTIVES: {
    en: "Can the client see situations from different perspectives?",
    es: "¿Pueden ver situaciones desde diferentes perspectivas?",
  },
  KNOW_IMPACTS_OF_ACTIONS_WORDS: {
    en: "Do they know the impacts of their actions/words?",
    es: "¿Conocen los impactos de sus acciones/palabras?",
  },
  KNOWING_WHEN_OFFENSIVE_COMMENT_WAS_MADE: {
    en: "Does the client know when an offensive comment was made?",
    es: "¿Saben cuándo se hizo un comentario ofensivo?",
  },
};

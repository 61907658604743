import { isAuthorised } from "@utils/utils";
import React, { PropsWithChildren } from "react";
import { roleType } from "src/layouts/config";

import { Error } from "@components/Error/Error";

interface AuthorizeProps extends PropsWithChildren {
  redirect?: boolean;
  to?: string;
  allowedRoles: roleType[];
}

export const Authorize: React.FC<AuthorizeProps> = ({ children, allowedRoles = [] }) => {
  const authorized = isAuthorised(allowedRoles);

  if (!authorized) {
    return <Error />;
  }
  return children;
};

import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { pathNameToBreadCrumbLinks } from "@utils/helpers";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Breadcrumb, Button, Heading, Small } from "@fronterahealth/frontera-ui-components";

import { ProvidersQuery } from "@api/graphql/types-and-hooks";
import { FronteraProvider, ProviderTable } from "@components/ProviderTable/ProviderTable";
import { useProvidersData } from "@providers/ProvidersDataProvider";

const providersToFronteraProvider = (providers: ProvidersQuery["provider"]): (FronteraProvider | null)[] => {
  return (
    providers?.map((provider) => {
      if (!provider.providermetadata) return null;
      const { createdDate, id } = provider;
      const { firstName, lastName, email } = provider.providermetadata!;
      const fronteraClient: FronteraProvider = {
        id: id,
        name: `${firstName} ${lastName}`,
        email: email,
        created: new Date(createdDate),
        assignedClientsCount: provider.learnerproviderSet.edges.length,
      };
      return fronteraClient;
    }) || []
  );
};

export const Providers: React.FC = () => {
  const { pathname } = useLocation();
  const go = useNavigate();
  const breadcrumbLinks = pathNameToBreadCrumbLinks(pathname);
  const { providersQuery } = useProvidersData();

  if (!providersQuery) return null;

  const { data, isFetching, refetch } = providersQuery;

  const providers = providersToFronteraProvider(data?.provider);

  return (
    <div className="mx-auto flex w-full max-w-5xl flex-col content-between items-center lg:flex-col lg:items-start">
      <Breadcrumb
        links={breadcrumbLinks}
        homeLink={{
          name: "Home",
          Component: <Link to={`/providers`}>Home</Link>,
          current: false,
          type: "component",
        }}
      />
      <div className="mt-4 flex w-full items-center justify-between">
        {pathname === "/providers" ? (
          <Heading className="" type="h1">
            Providers
          </Heading>
        ) : null}
        {pathname === "/providers" ? (
          <div className="flex items-center">
            <Button
              text="New Provider"
              appearance="primary"
              onClick={() => {
                go("/providers/new");
              }}
            />
            <Button
              text={
                <div className="flex items-center">
                  <Small>Update</Small>
                  <ArrowPathIcon className={`ml-1 h-4 w-4 text-text-secondary ${isFetching ? "animate-spin" : ""}`} />
                </div>
              }
              appearance="link"
              onClick={() => {
                refetch();
              }}
            />
          </div>
        ) : null}
      </div>
      {pathname === "/providers" ? (
        <ProviderTable
          loading={isFetching}
          providers={providers}
          onRowClick={(provider) =>
            go(`/providers/${provider.id}`, {
              state: {
                id: provider.id,
                name: provider.name,
              },
            })
          }
        />
      ) : (
        <Outlet />
      )}
    </div>
  );
};

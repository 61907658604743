import { PlusIcon, TrophyIcon } from "@heroicons/react/24/outline";
import React, { PropsWithChildren } from "react";

import { Button, Paragraph, Small } from "@fronterahealth/frontera-ui-components";
import { GoalCardItem } from "@fronterahealth/frontera-ui-components/GoalCarousel/GoalCard";
import { GoalCarouselProps } from "@fronterahealth/frontera-ui-components/GoalCarousel/GoalCarousel";

import { AiSuggestedShortTermGoalsTypeEdge } from "@api/graphql/types-and-hooks";
import { AddShortTermGoalsBank } from "@components/GoalsBank/AddShortTermGoalsBank/AddShortTermGoalsBank";

interface ShortTermGoalParentCardProps extends PropsWithChildren {
  title: string;
  aiGoals: GoalCardItem[];
  aiGoalList?: AiSuggestedShortTermGoalsTypeEdge[];
  assessmentId?: string;
  longTermGoalId?: string;
  longTermGoalBankId?: string;
  learnerId?: string;
  refetch?: () => void;
  aiGoalsBeingUsed: string[];
  addAiGoals: GoalCarouselProps["onAdd"];
  subtitle?: string;
  onButtonClick: () => void;
  onAiGoalUpDownVote: (goal: GoalCardItem, vote: boolean) => Promise<void>;
}

export const ShortTermGoalParentCard: React.FC<ShortTermGoalParentCardProps> = ({
  children,
  onButtonClick,
  title,
  aiGoalList,
  assessmentId,
  longTermGoalBankId,
  learnerId,
  longTermGoalId,
  refetch,
  subtitle,
}) => {
  return (
    <div className="border border-limestone-100 rounded-2xl p-4 bg-white my-4 ">
      <div className="flex items-center justify-between mb-4">
        <div>
          <Paragraph displayType="loud" colorType="primary">
            <TrophyIcon className="mr-2 h-4 w-4 inline-block" />
            {title}
          </Paragraph>
          <Small className="ml-6" displayType="normal" colorType="secondary">
            {subtitle}
          </Small>
        </div>
        <div className="flex flex-row gap-2 justify-items-center items-center">
          <AddShortTermGoalsBank
            longTermGoalBankId={longTermGoalBankId}
            learnerId={learnerId ?? "<missing-learner-id"}
            longTermGoalId={longTermGoalId ?? "<missing-long-term-goal-id>"}
            assessmentId={assessmentId ?? "<missing-assessment-id>"}
            onAdd={async () => {
              if (refetch) await refetch();
            }}
            aiGoals={aiGoalList ?? []}
          />

          <Button
            onClick={() => onButtonClick()}
            text={
              <Small className={"flex items-center px-2 text-white"}>
                <PlusIcon className="mr-2 h-4 w-4" />
                Custom Goal
              </Small>
            }
            buttonAction="action"
            appearance="primary"
            className=" border-secondary"
          />
        </div>
      </div>

      {children}
    </div>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";

import { Divider, Heading } from "@fronterahealth/frontera-ui-components";

import {
  CreateProviderMutationVariables,
  ProviderRoleEnums,
  useCreateProviderMutation,
} from "@api/graphql/types-and-hooks";
import { FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifySuccess } from "@components/notifications/notifications";
import { useProvidersData } from "@providers/ProvidersDataProvider";

function getCustomString(key: ProviderRoleEnums): string {
  switch (key) {
    case ProviderRoleEnums.Bcba:
      return "BCBA";
    case ProviderRoleEnums.Rbt:
      return "RBT";
    case ProviderRoleEnums.AssessmentBcba:
      return "Assessment BCBA";
    default:
      // Capitalize the entire string if not matched
      return (key as string).replace(/_/g, " ").toUpperCase();
  }
}

export const NewProvider: React.FC = () => {
  const { providersQuery } = useProvidersData();
  const createProvider = useCreateProviderMutation({
    onSuccess: async () => {
      providersQuery.refetch();
    },
  });
  const go = useNavigate();

  const { formState, onSubmit, RegisteredFormInput, RegisteredCheckboxList } =
    useFormUtils<CreateProviderMutationVariables>({
      mutationFn: async (params) => {
        await createProvider!.mutateAsync({ provider: { ...params.provider, learners: [] } }).then(() => {
          notifySuccess("Provider Created Successfully");
          go("/providers");
        });
      },
    });

  return (
    <FormContainer onSubmit={(e) => onSubmit(e)}>
      <div className="mt-10 flex w-full flex-col">
        <div className="flex w-full items-start">
          <div className="grid w-full grid-cols-5 ">
            <div className="col-span-2 flex flex-col">
              <Heading type="h2">Provider Details</Heading>
            </div>
            <div className="col-span-3 flex flex-col">
              <div className="gap-x-4  lg:grid lg:grid-cols-2">
                <RegisteredFormInput
                  formKey="provider.firstName"
                  inputSize="full"
                  formState={formState}
                  label="First Name"
                />
                <RegisteredFormInput
                  formKey="provider.lastName"
                  inputSize="full"
                  formState={formState}
                  label="Last Name"
                />
              </div>
              <div className="gap-x-4  lg:grid lg:grid-cols-2">
                <RegisteredFormInput
                  formKey="provider.email"
                  inputSize="full"
                  formState={formState}
                  label="Email"
                  type="email"
                />
                <div />
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="mt-10 flex w-full items-start">
          <div className="grid w-full grid-cols-5">
            <div className="col-span-2 flex flex-col">
              <Heading type="h2">Role Information</Heading>
            </div>
            <div className="col-span-3 flex flex-col">
              <RegisteredCheckboxList
                formState={formState}
                formKey="provider.providerRole"
                title="Provider Role"
                items={Object.keys(ProviderRoleEnums).map((key) => ({
                  id: ProviderRoleEnums[key as keyof typeof ProviderRoleEnums],
                  title: getCustomString(ProviderRoleEnums[key as keyof typeof ProviderRoleEnums]),
                }))}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <SubmitButton isLoading={formState.isSubmitting} buttonText="Save & Continue" />
      </div>
    </FormContainer>
  );
};

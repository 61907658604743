import { ApiOnboardingFormStatusStatusChoices } from "@api/graphql/types-and-hooks";
import { links } from "@pages/ClientDetailsSubPages/IntakeForm/Links/config";

interface FormStatusNode {
  formName: string;
  status: ApiOnboardingFormStatusStatusChoices;
  createdDate: string;
}

export const numberOfOnboardingSectionsCompleted = (
  formStatusNodes: (FormStatusNode | undefined | null)[] | undefined,
) => {
  const count = Object.entries(
    formStatusNodes
      ?.filter((node) => node?.status === ApiOnboardingFormStatusStatusChoices.Completed)
      .reduce(
        (acc, node) => {
          const section = node?.formName;
          const [route, subroute] = section?.split("/") || "";

          if (acc[route]) acc[route].push(subroute);
          else acc[route] = [subroute];

          return acc;
        },
        {} as { [key: string]: string[] },
      ) || {},
  ).reduce((acc, curr) => {
    const [route, listOfCompletedRoutes] = curr;

    const linkObject = links.find((link) => link.name === route);

    if (linkObject) {
      if (linkObject.sublinks) {
        if (linkObject.sublinks.length === listOfCompletedRoutes.length) {
          return acc + 1;
        }
      } else {
        return acc + 1;
      }
    }
    return acc;
  }, 0);

  return count;
};

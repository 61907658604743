import { LanguageMappingInterface } from "src/components/forms/interfaces";

import { LearnerBirthHistoryQuery } from "@api/graphql/types-and-hooks";

export type BirthHistoryDataType = NonNullable<
  NonNullable<NonNullable<LearnerBirthHistoryQuery["learnerById"]>["learnerbirthhistory"]>
>;

export const learnerBirthHistoryLanguageMap: LanguageMappingInterface<
  Omit<BirthHistoryDataType, "id" | "createdDate" | "learner" | "lastUpdatedDate">
> = {
  pregnancyDeliveryConcerns: {
    en: "Were there any concerns/issues regarding the pregnancy or delivery?",
    es: "¿Preocupaciones / problemas relacionados con el embarazo o el parto?",
  },
  birthWeightInPounds: {
    en: "Pounds (lb)",
    es: "Peso al nacer (libras)",
  },
  birthWeightInOunces: {
    en: "Ounces (oz)",
    es: "Peso al nacer (onzas)",
  },
  clientLikeAsBaby: {
    en: "What was the client like as a baby?",
    es: "¿Cómo era el cliente de bebé?",
  },
  babyTemperament: {
    en: "How was their temperament?",
    es: "¿Cómo era su temperamento?",
  },
  separatedFromParentFirst24Months: {
    en: "Was the client separated from either parent during the first 24 months of their life, for more than four weeks?",
    es: "¿Estuvo el cliente separado de alguno de sus padres durante los primeros 24 meses de su vida, por más de cuatro semanas?",
  },
  separatedFromParentFirst24MonthsExplanation: {
    en: "Explain",
    es: "Explicación",
  },
  normalFullTermPregnancy: {
    en: "Normal full term pregnancy",
    es: "Embarazo a término normal completo",
  },
  pregnancyComplications: {
    en: "Complications during pregnancy",
    es: "Complicaciones durante el embarazo",
  },
  prematureBirth: {
    en: "Premature birth",
    es: "Parto prematuro",
  },
  cesareanBirth: {
    en: "Cesarean birth",
    es: "Parto por cesárea",
  },
  difficultLaborDelivery: {
    en: "Difficult labor and/or delivery",
    es: "Parto difícil y/o complicado",
  },
  overdueBirth: {
    en: "Overdue birth",
    es: "Parto pasado",
  },
  breathingDifficulty: {
    en: "Difficulty breathing",
    es: "Dificultad para respirar",
  },
  suckingDifficulty: {
    en: "Difficulty sucking",
    es: "Dificultad para succionar",
  },
  touchResponseDifficulty: {
    en: "Difficulty responding to touch",
    es: "Dificultad para responder al tacto",
  },
  pregnancyInfections: {
    en: "Infections",
    es: "Infecciones",
  },
  pregnancyPreEclampsia: {
    en: "Pre-eclampsia",
    es: "Pre-eclampsia",
  },
  pregnancyHypertension: {
    en: "Hypertension",
    es: "Hipertensión",
  },
  pregnancyFevers: {
    en: "Fevers",
    es: "Fiebres",
  },
  gestationalDiabetes: {
    en: "Gestational Diabetes",
    es: "Diabetes gestacional",
  },
  otherPregnancyIllnesses: {
    en: "Other",
    es: "Otro",
  },
  prescriptionDrugsUsed: {
    en: "Prescription drugs or medications",
    es: "Medicamentos recetados",
  },
  prescriptionList: {
    en: "List prescriptions",
    es: "Lista de medicamentos recetados",
  },
  alcoholUsed: {
    en: "Alcohol",
    es: "Alcohol",
  },
  recreationalDrugsUsed: {
    en: "Recreational drugs",
    es: "Drogas recreativas",
  },
  tobaccoUsed: {
    en: "Tobacco",
    es: "Tabaco",
  },
  hospitalizedForHealthConcernsAtBirth: {
    en: "Was your child hospitalized for health concerns at birth?",
    es: "¿Estuvo su hijo hospitalizado por problemas de salud al nacer?",
  },
  hospitalizedForHealthConcernsAtBirthExplanatiion: {
    en: "Explain",
    es: "Explicación",
  },
};

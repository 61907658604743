import { CaregiverMetadataType, RelationshipType } from "@api/graphql/types-and-hooks";
import { LanguageMappingInterface } from "@components/forms/interfaces";

type CaregiverMetaData = NonNullable<
  Omit<
    CaregiverMetadataType,
    | "id"
    | "createdDate"
    | "caregiver"
    | "lastUpdatedDate"
    | "active"
    | "createdBy"
    | "nearestLocation"
    | "referralSource"
    | "organization"
  >
>;
export type RelationShipDataType = {
  socialRelationship: NonNullable<Omit<RelationshipType, "organization">["socialRelationship"]>;
  caregiverMetadata: CaregiverMetaData;
};

export type GuardianDetailsDataType = {
  guardianDetails: RelationShipDataType[];
};

export const guardianDetailsMapping: LanguageMappingInterface<GuardianDetailsDataType> = {
  guardianDetails: {
    socialRelationship: {
      en: "Relationship To Client",
      es: "Relación con el Cliente",
    },
    // @ts-ignore: Ignoring the compiler and risking bugs because: @rinkal needs to check here
    caregiverMetadata: {
      firstName: {
        en: "First Name",
        es: "Nombre Uno",
      },
      lastName: {
        en: "Last Name",
        es: "Apellido",
      },

      preferredLanguage: {
        en: "Primary Language",
        es: "Idioma Principal",
      },
      requiresInterpreter: {
        en: "Requires Interpreter",
        es: "Requiere Intérprete",
      },
      birthDate: {
        en: "Date of Birth",
        es: "Fecha de Nacimiento",
      },
      gender: {
        en: "Gender",
        es: "Género",
      },
      specifyGender: {
        en: "Specify Gender",
        es: "Especificar género",
      },
      maritalStatus: {
        en: "Marital Status",
        es: "Estado Civil",
      },
      addressLine1: {
        en: "Address Line 1",
        es: "Dirección Línea 1",
      },
      addressLine2: {
        en: "Address Line 2",
        es: "Dirección Línea 2",
      },
      city: {
        en: "City",
        es: "Ciudad",
      },
      state: {
        en: "State",
        es: "Estado",
      },
      zip: {
        en: "Zip",
        es: "Código Postal",
      },
      phoneNumber1: {
        en: "Phone Number",
        es: "Número de Teléfono",
      },
      phoneNumber2: {
        en: "Secondary Phone Number",
        es: "numero de telefono secundario",
      },
      email: {
        en: "Email",
        es: "Correo Electrónico",
      },
      occupation: {
        en: "Occupation",
        es: "Ocupación",
      },
      employer: {
        en: "Employer",
        es: "Empleador",
      },
      abaClientGoalsForGuardian: {
        en: "What are the main ABA client goals for the guardian",
        es: "¿Cuáles son los principales objetivos del cliente de ABA para el tutor?",
      },
    },
  },
};

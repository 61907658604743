import { capitalizeString } from "@utils/utils";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  UpdateLearnerAvailabilityDetailsMutationVariables,
  useLearnerByIdQuery,
  useUpdateLearnerAvailabilityDetailsMutation,
} from "@api/graphql/types-and-hooks";
import { FormContainer } from "@components/forms/FormLayout";
import SubmitButton from "@components/forms/FormSubmitButton/FormSubmitButton";
import { useFormUtils } from "@components/forms/useFormUtils";
import { notifyError, notifySuccess } from "@components/notifications/notifications";
import { AvailabilityDays, AvailabilityTimes } from "@pages/NewClient/NewClient.interfaces";

export const ClientDetailsAvailability: React.FC = () => {
  const { clientId } = useParams();

  const learnerDetailsQuery = useLearnerByIdQuery(
    { learnerId: clientId ? clientId : "" },
    {
      queryKey: ["learner-by-id", clientId],
      enabled: !!clientId,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );

  const { data, refetch } = learnerDetailsQuery;

  useEffect(() => {
    if (data) {
      Object.entries(
        data.learnerById?.learnerAvailability.edges
          .map((edge) => edge?.node)
          .reduce(
            (acc, node) => {
              const { day, timeOfDay } = node || {};
              if (acc?.[day!]) acc[day!].push(timeOfDay!);
              else acc[day!] = [timeOfDay!];
              return acc;
            },
            {} as { [key in string]: string[] },
          ) || {},
      ).forEach(([day, timesOfDay]) => {
        const key = `details.${day}`;
        const value = timesOfDay;

        // @ts-ignore: Ignoring the compiler and risking bugs because: We are overriding how the React Hook Form typings work
        setValue(key, value);
      });
    }
  }, [data]);

  const updateLearnerAvailabilityDetailsMutation = useUpdateLearnerAvailabilityDetailsMutation({});

  const { data: updateData } = updateLearnerAvailabilityDetailsMutation;

  useEffect(() => {
    if (updateData?.updateLearnerAvailabilityDetails) {
      if (updateData?.updateLearnerAvailabilityDetails.status) {
        notifySuccess(
          updateData.updateLearnerAvailabilityDetails?.message
            ? updateData.updateLearnerAvailabilityDetails?.message
            : "",
        );
      } else {
        notifyError(
          updateData.updateLearnerAvailabilityDetails?.message
            ? updateData.updateLearnerAvailabilityDetails?.message
            : "",
        );
      }
    }
  }, [updateData]);

  const { formState, onSubmit, RegisteredCheckboxList, setValue } =
    useFormUtils<UpdateLearnerAvailabilityDetailsMutationVariables>({
      mutationFn: async (_, e) => {
        // @ts-ignore: Ignoring the compiler and risking bugs because: FormData is not type correctly here
        const formData = new FormData(e?.nativeEvent?.target);
        const slots = Array.from(formData.entries())
          .filter(([name]) => name.includes("details"))
          // this split on "." works because our formKey for these Form inputs look like this
          // `details.${day}` and the value is "morning" | "afternoon"  | etc
          .map(([name, value]) => ({
            day: name.split(".").pop()?.toUpperCase() || "",
            timeOfDay: (value as string).toUpperCase(),
          }));

        const newParams = { learner: clientId ? clientId : "", details: { slots } };
        await updateLearnerAvailabilityDetailsMutation!.mutateAsync(
          newParams as UpdateLearnerAvailabilityDetailsMutationVariables,
        );
        await refetch();
      },
    });

  return (
    <div className="flex w-full flex-col items-start">
      <FormContainer onSubmit={onSubmit}>
        <div className="col-span-3 flex flex-col">
          {AvailabilityDays.map((day) => {
            const formKey = `details.${day}`;
            const formattedDay = capitalizeString(day);
            return (
              <RegisteredCheckboxList
                key={formattedDay}
                // @ts-ignore: Ignoring the compiler and risking bugs because: We are overriding how the React Hook Form typings work
                formKey={formKey}
                title={formattedDay}
                required={false}
                legend={formattedDay}
                orientation="horizontal"
                items={AvailabilityTimes}
              />
            );
          })}
        </div>
        <div className="mt-6">
          <SubmitButton isLoading={formState.isSubmitting} buttonText="Update" />
        </div>
      </FormContainer>
    </div>
  );
};

import { GenderApiValues, GenderMappingToApi, GenderMappingToUi, GenderUiValues } from "@utils/constants";
import { Link } from "react-router-dom";

import { BreadcrumbLink } from "@fronterahealth/frontera-ui-components";

import {
  ApiAssessmentClientDetailsGenderChoices,
  ApiLearnerMetadataGenderChoices,
  ApiProviderMetadataGenderChoices,
  ApiReportClientDetailsGenderChoices,
} from "@api/graphql/types-and-hooks";

export const getGenderValue = (
  value:
    | GenderApiValues
    | null
    | undefined
    | ApiProviderMetadataGenderChoices
    | ApiAssessmentClientDetailsGenderChoices
    | ApiLearnerMetadataGenderChoices
    | ApiReportClientDetailsGenderChoices,
) => {
  const genderValue = value === "M" || value === "F" ? value : value?.toLowerCase();
  if (genderValue) return GenderMappingToUi[genderValue as GenderApiValues];

  return null;
};

export const setGenderValue = (value: GenderUiValues | null | undefined | string) => {
  if (value) return GenderMappingToApi[value as GenderUiValues];
  return null;
};

export const capitalizeString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const pathNameToBreadCrumbLinks = (pathname: string): BreadcrumbLink[] => {
  const links = pathname.slice(1).split("/");

  const linksToRender = links.length > 2 ? links.slice(0, 2) : links;

  return linksToRender.map((link, index) => {
    const decodedLink = decodeURI(link);

    return {
      name: link.toLocaleUpperCase(),
      current: link === links[links.length - 1],
      type: "component",
      Component: (
        <Link
          to={`/${links.slice(0, index + 1).join("/")}`}
        >{`${decodedLink[0].toUpperCase()}${decodedLink.slice(1)}`}</Link>
      ),
    };
  });
};
